import { ReactElement } from "react";
import { Link } from "react-router-dom";
const TopHeading = (): ReactElement => {
  return (
    <div className="top-heading-user-details top-heading-content">
      <div className="container-fluid no-padding">
        <div className="col-lg-12 no-padding">
          <h3>Issue Details</h3>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/issues">Issues</Link>
            </li>
            <li>Issue Details</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TopHeading;
