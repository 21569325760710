import { Avatar, Divider, Tag } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { ReactElement, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getImgUrl } from "../../utils";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";

const AdminDetails = (
  { visibleData }: { visibleData: any }
): ReactElement => {
  const [status, setStatus] = useState(undefined);

  const [adminData, setAdminData] = useState(undefined);
  const [adminGroup, setAdminGroup] = useState([]);

  const handleChangeStatus = (value: any) => {
    setStatus(value);
  };

  // ******************************
  const fetchCorporateDetails = async (visibleData: any) => {
    if (visibleData) {
      await fetch(
        `${process.env.REACT_APP_AUTH_API}/admin/details?email=${visibleData}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            setAdminData(res?.admin);
            setAdminGroup(res?.groups);
            console.log("res", res);

            // setGeneratedImgUrl(res?.company?.image);
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      fetchCorporateDetails(visibleData);
    }
  }, [visibleData]);

  return (
    <React.Fragment>
      <div className="user-deatils-area">
        <div className="user-detals-wrapper-content">
          <div className="user-details-inner-content">
            <div className="user-image-content text-center">
              <div className="user-banner">
                <Avatar
                  size={200}
                  draggable={true}
                  shape="circle"
                  src={getImgUrl(adminData?.profilePicture)}
                  alt=""
                />
              </div>
              <div className="user-image-content-body">
                <div className="body">
                  <h4 className="font-weight-bold text-capitalize mt-2">
                    {adminData?.fullName}
                  </h4>
                  <span className="px-5">{adminData?.email}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="user-information-show-tab-wrapper">
          <div className="inner-content">
            <div className="single-content">
              <div className="single-information-user-wrapper mb-3">
                <div className="single-information-user-content">
                  <div className="info-body user-personal-inforamtion">
                    <h3 className="title">Admin Information</h3>
                    <div className="inner-body-wrapper">
                      <div className="inner-body">
                        <div className="single">
                          <div className="info-name">
                            <span>Full Name</span>
                          </div>

                          <div className="info-desc text-capitalize">
                            <span>{adminData?.fullName}</span>
                          </div>
                        </div>

                        <div className="single">
                          <div className="info-name">
                            <span>Email Address</span>
                          </div>
                          <div className="info-desc">
                            <span>{adminData?.email}</span>
                          </div>
                        </div>

                        {adminData?.createdAt && (
                          <div className="single">
                            <div className="info-name">
                              <span>Created Date</span>
                            </div>

                            <div className="info-desc">
                              <span>
                                {moment(adminData?.createdAt).format("LL")}
                              </span>
                            </div>
                          </div>
                        )}

                        {adminData?.updatedAt && (
                          <div className="single">
                            <div className="info-name">
                              <span>Updated Date</span>
                            </div>

                            <div className="info-desc">
                              <span>
                                {moment(adminData?.updatedAt).format("LL")}
                              </span>
                            </div>
                          </div>
                        )}

                        {adminData?.status && (
                          <div className="single">
                            <div className="info-name">
                              <span>Admin Status</span>
                            </div>

                            <div className="info-desc">
                              <div className="single-product">
                                <span
                                  className={`radius-btn ${adminData?.status?.toLowerCase()}-btn`}
                                >
                                  {adminData?.status}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                        {adminGroup?.length ? (
                          <div className="mt-5 text-uppercase">
                            <Divider orientation="left">User Groups</Divider>
                            {adminGroup?.length
                              ? adminGroup?.map((grp: any, index: any) => (
                                  <Tag
                                    color="#f50"
                                    key={index}
                                    className="mb-2 rounded"
                                  >
                                    {grp?.group?.name}
                                  </Tag>
                                ))
                              : ``}
                          </div>
                        ) : undefined}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="single-information-user-wrapper mb-3">
                <div className="single-information-user-content">
                  <div className="info-icon">
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.4167 1.91669H5.75004C5.24171 1.91669 4.7542 2.11862 4.39475 2.47807C4.03531 2.83751 3.83337 3.32502 3.83337 3.83335V19.1667C3.83337 19.675 4.03531 20.1625 4.39475 20.522C4.7542 20.8814 5.24171 21.0834 5.75004 21.0834H17.25C17.7584 21.0834 18.2459 20.8814 18.6053 20.522C18.9648 20.1625 19.1667 19.675 19.1667 19.1667V7.66669L13.4167 1.91669Z"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.4166 1.91669V7.66669H19.1666"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.3333 12.4583H7.66663"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.3333 16.2917H7.66663"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.58329 8.625H8.62496H7.66663"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
AdminDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default AdminDetails;
