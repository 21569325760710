import { ReactElement } from 'react'
import Layout from '../../components/Layout'
import HubList from '../../components/Hub'

const HubPage = (): ReactElement => {
  return (
    <Layout>
      <HubList />
    </Layout>
  )
}

export default HubPage
