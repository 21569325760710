import { LeftCircleOutlined } from "@ant-design/icons";
import React, { ReactElement } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";

const ShopHeader = (params): ReactElement => {
  const history = useHistory();
  const { merchantId }: any = useParams();
  return (
    <React.Fragment>
      <div className="title d-flex justify-content-between border-bottom">
        <h5 className="mb-0">
          <Link
            to={`/merchants/${merchantId}/details`}
            className="mr-2 text-dark"
            // onClick={(e) => {
            //   e.preventDefault();
            //   history?.goBack();
            // }}
          >
            <LeftCircleOutlined />
          </Link>
          {params.leftarea}
        </h5>
        {params.rightarea}
      </div>
    </React.Fragment>
  );
};

export default ShopHeader;
