import { ReactElement } from "react";
import Layout from "../../components/Layout";
import SettlemenByDate from "../../components/SettlemenByDate";

const SettlementByDatePage = (): ReactElement => {
  return (
    <Layout>
      <SettlemenByDate />
    </Layout>
  );
};

export default SettlementByDatePage;
