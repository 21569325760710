import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Rate,
  Select,
  Spin,
  Table,
} from "antd";
import { BranchesOutlined, ClockCircleOutlined, EyeFilled, FileOutlined, FilePdfFilled } from "@ant-design/icons";

import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { MerchantInvoice } from "../invoice-pdf";
import { authenticateToken } from "../../utils/auth";
import Loader from "../common/Loader";
import {
  getPage,
  getParamValue,
  getUpdatedUrl,
  OrderStatus,
} from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import TopHeading from "./TopHeading";
import { debounce } from "lodash";

import moment from "moment";
import Currency from "../common/Currency";
import _ from "lodash";
import Empty from "../common/Empty";
import { check } from "prettier";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { replaceNewlinesForCSV } from "../../utils/functions";
const { Option } = Select;
const { RangePicker }: any = DatePicker;

const SettlemenByDate = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const page = getParamValue(location.search, "page") || 0;
  const [status, setStatus] = useState(
    getParamValue(location.search, "status") || ""
  );
  const [range, setRange] = useState({
    from: getParamValue(location.search, "from")
      ? getParamValue(location.search, "from")
      : null,
    to: getParamValue(location.search, "to")
      ? getParamValue(location.search, "to")
      : null,
  });
  const [driverMobileNumber, setDriverMobileNumber] = useState(
    getParamValue(location.search, "driverMobileNumber") || ""
  );
  const [orderOptions, setOrderOptions] = useState({
    list: [],
    loading: false,
  });
  const [userMobileNumber, setUserMobileNumber] = useState(
    getParamValue(location.search, "userMobileNumber") || ""
  );
  const [selectedMerchant, setSelectedMerchant] = useState(
    getParamValue(location.search, "selectedMerchant") || ""
  );
  const [limit, setLimit] = useState(16);
  const [tripsData, setTripsData] = useState({
    loading: false,
    data: null,
  });
  const [OptionsDriver, setOptionsDriver] = useState({
    loading: false,
    list: null,
  });
  const [OptionsUser, setOptionsUser] = useState({
    loading: false,
    list: null,
  });
  const [selectedTrip, setSelectedTrip] = useState(undefined);
  const [orderId, setOrderId] = useState("");

  // checkbox
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [orders, setOrders] = useState<any>();

  const [settleHistoryEditModalOpen, setSettleHistoryEditModalOpen] = useState("");
  const [ordersForModal, setOrdersForModal] = useState<any>();
  const [selectedInvoiceToPdf, setSelectedInvoiceToPdf] = useState(undefined);

  const fetchRef = useRef(null);

  const reseAllFieldData = () => {
    setStatus("");
    setDriverMobileNumber("");
    setSelectedMerchant("");
    setUserMobileNumber("");
    form.resetFields(["driverSearch", "userSearch", "statusSearch"]);
    history.replace(location.pathname);
    window.location.reload();
    setOrderId("");
    setRange({
      from: null,
      to: null,
    });
  };

  const getTrips = useCallback(async () => {
    // if (orderId || !userMobileNumber) return;
    let shouldResetPage = false;
    if (
      userMobileNumber &&
      getParamValue(location.search, "userMobileNumber") != userMobileNumber
    ) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("userMobileNumber", `${userMobileNumber}`));
    }
    if (
      driverMobileNumber &&
      getParamValue(location.search, "driverMobileNumber") != driverMobileNumber
    ) {
      shouldResetPage = true;
      history.push(
        getUpdatedUrl("driverMobileNumber", `${driverMobileNumber}`)
      );
    }
    if (status && getParamValue(location.search, "status") != status) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("status", `${status}`));
    }
    if (shouldResetPage) history.push(getUpdatedUrl("page", `${0}`));
    setTripsData({ loading: true, data: null });
    axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/admin/order?type=Q_PARCEL` +
        `&page=${page || 0}` +
        `&limit=${limit || 16}` +
        `&status=DELIVERED` +
        `&paymentStatus=Unpaid` +
        `&forSettlement=yes`+
        (userMobileNumber ? `&mobileNumber=${userMobileNumber}` : ``) +
        (range?.from ? `&from=${moment(range?.from).toISOString()}` : ``) +
        (range?.to ? `&to=${moment(range?.to).toISOString()}` : ``) +
        (orderId ? `&orderId=${orderId}` : ``),

        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setTripsData({ loading: false, data: res.data });
        }
      })
      .catch((err) => {
        setTripsData({ loading: false, data: [] });
        console.error("Trips: Error", err);
      });
  }, [
    limit,
    driverMobileNumber,
    userMobileNumber,
    status,
    page,
    orderId,
    range,
  ]);

  const getOrderOptions = useCallback(
    async (getOrderId) => {
      setOrderOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order?type=Q_PARCEL` +
          (getOrderId
            ? `&orderId=${getOrderId}`
            : `` + getOrderId
              ? `&orderId=${getOrderId}`
              : ``) +
          `&page=${page || 0}` +
          `&status=DELIVERED` +
          `&paymentStatus=Unpaid` +
          `&forSettlement=yes`+
          (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setTripsData({ loading: false, data: res.data });
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map((order, index) => ({
              key: index,
              label: order.orderId,
              value: order.orderId,
            })),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, status]
  );

  const getOrdersDetailsByOrderId = async (orderId) => {
    try {
      const path =
        `${process.env.REACT_APP_ORDER_API}` +
        `/admin/order/details?` +
        (orderId ? `&orderId=${orderId}` : ``);

      const response = await fetch(path, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
        method: "GET",
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getOptionsDriver = useCallback(
    async (mobileNumber) => {
      setOptionsDriver({ loading: true, list: null });
      // const encodedUri = `${process.env.REACT_APP_FARE_API}`;
      axios
        .get(
          `${process.env.REACT_APP_USER_API}/admin/driver?` +
          `page=${0}` +
          `&limit=${20}` +
          // (status ? `&status=${status}` : ``) +
          (mobileNumber ? `&mobile=${mobileNumber}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOptionsDriver({
            loading: false,
            list: _.without(
              res.data?.drivers?.map((driver) => {
                if (driver?.name && driver?.mobileNumber) {
                  return {
                    label: driver?.name,
                    value: driver?.mobileNumber,
                  };
                }
              }),
              undefined
            ),
          });
        })
        .catch((err) => {
          setOptionsDriver({ loading: false, list: [] });
          console.error("Trips: Error", err);
        });
    },
    [status]
  );

  const getOptionsUser = useCallback(
    async (mobileNumber) => {
      setOptionsUser({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
          `page=${0}` +
          `&limit=${500}` +
          // (status ? `&status=${status}` : ``) +
          (mobileNumber
            ? `&mobileNumber=%2B88${mobileNumber.replaceAll("+88", "")}`
            : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOptionsUser({
            loading: false,
            list: _.without(
              res.data?.businesses?.map((user) => {
                if (user?.companyName && user?.mobileNumber) {
                  return {
                    label: `${user?.ownersName} (${user?.mobileNumber})`,
                    value: user?.mobileNumber,
                  };
                }
              }),
              undefined
            ),
          });
        })
        .catch((err) => {
          setOptionsUser({ loading: false, list: [] });
          console.error("Trips: Error", err);
        });
    },
    [status]
  );

  useEffect(() => {
    getOptionsUser("");
  }, [getOptionsUser]);

  useEffect(() => {
    getTrips();
  }, [getTrips]);

  useEffect(() => {
    const filteredResult = tripsData.data?.orders.filter((order) => {
      return checkedList?.indexOf(order.orderId) >= 0;
    });
    setOrders(filteredResult);
  }, [tripsData, checkedList]);

  const getUserOptionsDebounce = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptionsUser({ loading: false, list: null });

      if (fetchId !== fetchRef.current) {
        return;
      }

      getOptionsUser(value);
    };

    return debounce(loadOptions, 800);
  }, [getOptionsUser]);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions]);

  // checkbox funcs
  const plainOptions = tripsData?.data?.orders?.map((order) => order?.orderId);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onChangeSingle = (id: string) => {
    let list = [...(checkedList || [])];
    list = list?.filter((item) => item !== id);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  let refinedData = [];

  const getKeys = (obj) => {
    // @ts-ignore
    const titleKeys = Object.keys(obj).map((key) => getVal(key));
    return titleKeys;
  };

  const convertData = (order) => {
    console.log(orders);
    return order?.map((order) => ({
      orderId: order?.orderId,
      createdAt: moment(order?.createdAt).format("MM-DD-yyyy"),
      collectedAmount: '\"' +  order?.parcelOrder?.amountToCollect + '\"',
      recipientName: '\"' + order?.parcelOrder?.recipientName + '\"',
      recipientPhone: '\"' + order?.parcelOrder?.recipientPhone + '\"',
      collectableAmount: '\"' + order?.parcelOrder?.amountToCollect + '\"',
      codFee: '\"' + order?.parcelOrder?.codCharge + '\"',
      deliveryCharge: '\"' + order?.parcelOrder?.deliveryCharge + '\"',
      finalFee: '\"' + order?.deliveryCharge + order?.parcelOrder?.codCharge + '\"',
      discount: '\"' + (order?.discount || 0) + '\"',
      additionalCharge: '\"' + (order?.additionalCharge || 0) + '\"',
      compensationCost: '\"' + (order?.compensationCost || 0) + '\"',
      promoDiscount: '\"' + (order?.promoDiscount || 0) + '\"',
      expectedPayoutAmount: '\"' + (order?.parcelOrder?.amountToCollect - (order?.parcelOrder?.deliveryCharge + order?.parcelOrder?.codCharge)) + '\"',
      merchantOrderId: order?.parcelOrder?.merchantOrderId,
      recipientAddress: '\"' + order?.parcelOrder?.recipientAddress + '\"',
    }));
  };

  const convertDataForInvoiceDetails = (res) => {
    console.log(res);
    return res?.orders?.map((order) => ({
      orderId: order?.orderId,
      createdAt: moment(order?.createdAt).format("DD-MM-yyyy"),
      collectedAmount: order?.parcelOrder?.amountToCollect,
      recipientName: order?.parcelOrder?.recipientName,
      recipientPhone: order?.parcelOrder?.recipientPhone,
      collectableAmount: order?.parcelOrder?.amountToCollect,
      storeName: order?.parcelOrder?.storeName,
      paymentMethod: order?.paymentMethod,
      paymentStatus: order?.paymentStatus,
      deliveryCharge: order?.parcelOrder?.deliveryCharge,
      finalFee: order?.deliveryCharge,
      discount: order?.discount || 0,
      additionalCharge: order?.additionalCharge || 0,
      compensationCost: order?.compensationCost || 0,
      promoDiscount: order?.promoDiscount || 0,
      payout:
        order?.parcelOrder?.amountToCollect -
        order?.parcelOrder?.deliveryCharge,
      merchantOrderId: order?.parcelOrder?.merchantOrderId,
      recipientAddress: order?.parcelOrder?.recipientAddress,
    }));
  };

  const generatePDF = async (orderId: any) => {
    const result = await getOrdersDetailsByOrderId(orderId);
    const doc = <MerchantInvoice data={[result.order]} invoice={result.order} />;

    setSelectedInvoiceToPdf(doc);
    setTimeout(() => setSelectedInvoiceToPdf(undefined), 0);
    // const asPdf = pdf();
    // asPdf.updateContainer(doc);
    // const blob = await asPdf.toBlob();
    // saveAs(blob, `invoice-${item.invoiceId}`);
  };

  const generateCSVFile = async (orderId) => {
    const result = await getOrdersDetailsByOrderId(orderId);
    const r = replaceNewlinesForCSV(result)
    const convertedData = convertData([r?.order]);
    const titleKeys = getKeys(convertedData[0]);
    refinedData.push(titleKeys);

    // @ts-ignore
    convertedData.forEach((item) => {
      refinedData.push(Object.values(item));
    });

    let csvContent = "";
    refinedData.forEach((row) => {
      // @ts-ignore
      csvContent += row.join(",") + "\n";
    });
    // @ts-ignore
    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8,",
    });

    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", objUrl);
    link.setAttribute("download", `order-${orderId}.csv`);
    link?.click();
    refinedData = [];
    //console.log(csvContent)
  };

  function getVal(val) {
    let escaped = val;
    if (typeof val === "string") {
      escaped = ("" + val).replace(/"/g, '"');
      // @ts-ignore
      escaped = escaped?.replace(/[\t\n\"]/gi, " ");
    }
    return `"${escaped}"`; // To escape the comma in a address like string.
  }

  console.log(tripsData?.data)

  return (
    <React.Fragment>
      <TopHeading
        mobileNumber={userMobileNumber}
        total={tripsData?.data?.totalElements}
        orders={tripsData?.data?.orders || []}
        checkedList={checkedList}
        refetch={getTrips}
        range={range}
      />

      <section className="search_area">
        <Form form={form} className="search_form">


          <Form.Item name="range" className="mr-2">
            <RangePicker
              use12Hours
              defaultValue={
                range.from != null && range.to != null
                  ? [moment(range.from), moment(range.to)]
                  : null
              }
              onChange={(_, f) => {
                console.log("moment", moment.utc(f[0])?.toISOString());
                setRange({ from: f?.[0], to: f?.[1] });
              }}
            // showTime
            />
          </Form.Item>


          {/* <Form.Item
            name="driverSearch"
            className="mb-0"
            initialValue={driverMobileNumber}
          >
            <AutoComplete
              onSearch={getDriverTripsOptionsDebounce}
              onSelect={(val) =>
                setDriverMobileNumber(
                  val.startsWith("+88") ? val.replace("+88", "") : val
                )
              }
              options={OptionsDriver?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                OptionsDriver?.loading ? <Spin size="small" /> : "No Data"
              }
            >
              <Input.Search
                size="large"
                placeholder="Driver Phone (01...)"
                onSearch={(val) =>
                  setDriverMobileNumber(
                    val.startsWith("+88") ? val.replace("+88", "") : val
                  )
                }
                enterButton
                loading={OptionsDriver.loading}
                pattern={`[0-9]`}
                maxLength={11}
              />
            </AutoComplete>
          </Form.Item> */}
        </Form>
        {/* <div className="search_btn">
          <Button type="dashed" size="large">
             Search
          </Button>
        </div> */}

        <div className="search_btn">
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      </section>

      {/* <div className="flex">
        {tripsData?.data?.orders?.length > 0 ? (
          <Checkbox
            className="mb-2"
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            Check all
          </Checkbox>
        ) : undefined}
      </div> */}

      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  <div className="single-rider-heading  major-col">
                    <span>Order Id</span>
                  </div>
                  <div className="single-rider-heading  major-col">
                    <span>Store Name</span>
                  </div>
                  <div className="single-rider-heading  major-col">
                    <span>Merchant Info</span>
                  </div>
                  <div className="single-rider-heading major-col">
                    <span>Collected</span>
                  </div>
                  <div className="single-rider-heading major-col">
                    <span>COD Fee</span>
                  </div>
                  <div className="single-rider-heading major-col">
                    <span>Delivery Charge</span>
                  </div>
                  <div className="single-rider-heading major-col">
                    <span>Total Charge</span>
                  </div>
                  <div className="single-rider-heading major-col">
                    <span>Payout</span>
                  </div>
                  <div className="single-rider-heading major-col">
                    <span>Status</span>
                  </div>
                  <div className="single-rider-heading major-col last-column">
                    <span>Action</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {tripsData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div className="rider-table-area">
                  {tripsData?.data?.orders?.length ? (
                    tripsData?.data?.orders?.map((trip, index) => (
                      <React.Fragment key={index}>
                        <Link to={`/orders/${trip?.orderId}/details`}>
                          <div className="single-rider-wrapper">

                            <div className="single-rider major-col">
                              <span className="name">{trip?.orderId}</span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name">
                                {trip?.parcelOrder?.storeName}
                              </span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name">
                                {trip?.customer?.name}<br/>
                                {trip?.customer?.mobileNumber}
                              </span>
                            </div>

                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳{trip?.parcelOrder?.amountToCollect}
                              </span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳
                                {Math.round(
                                  trip?.parcelOrder?.codCharge
                                )}
                              </span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳
                                {(trip?.parcelOrder?.deliveryCharge).toFixed(2)}
                              </span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳{(trip?.parcelOrder?.codCharge + trip?.parcelOrder?.deliveryCharge).toFixed(2)}
                              </span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳
                                {(trip?.parcelOrder?.amountToCollect - (trip?.parcelOrder?.codCharge+trip?.parcelOrder?.deliveryCharge)).toFixed(2)}
                              </span>
                            </div>
                            <div className="single-rider">
                              <span
                                className={
                                  trip?.status === "REQUESTED"
                                    ? "pending-btn radius-btn"
                                    : trip?.status === "COMPLETED"
                                      ? "active-btn radius-btn"
                                      : "inactive-btn radius-btn"
                                }
                              >
                                {trip?.status}
                              </span>
                            </div>
                            <div className="single-rider last-column">

                              {/* <Button 
                                color="primary"
                                className="text-warning outlined mb-1 mr-1 compressed collapsed d-none d-md-block fw-normal"
                                onClick={()=>{
                                  getOrderByInvoiceIdView(trip?.orderId)

                                }}
                                >
                                <EyeFilled />
                              </Button> */}

                              <Button
                                key="A"
                                color="success"
                                className="text-success outlined mb-1 mr-1 compressed collapsed d-none d-md-block fw-normal"
                                onClick={(e) => {
                                  e.preventDefault()
                                  generatePDF(trip?.orderId);
                                }}
                              >
                                <FilePdfFilled />

                              </Button>
                              <Button
                                key="B"
                                color="danger"
                                className="text-danger outlined mb-1 me-1 compressed collapsed d-none d-md-block fw-normal"
                                onClick={(e) => {
                                  e.preventDefault();
                                  generateCSVFile(trip?.orderId);
                                }}
                              >
                                <FileOutlined />

                              </Button>
                            </div>
                          </div>
                        </Link>
                      </React.Fragment>
                    ))
                  ) : (
                    <Empty
                      data={
                        orderId ? "Empty Data" : "Please select a Date Range"
                      }
                    />
                  )}

                </div>
              </div>
            )}
          </div>
          {/* <Drawer
            title="Rider Details"
            width={800}
            onClose={() => {
              setSelectedTrip(null);
              getTrips();
            }}
            visible={selectedTrip}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
          >
            <TripDetails tripDetails={selectedTrip} />
          </Drawer> */}
          <PaginationTwo
            {...tripsData?.data}
            limit={limit}
            page={tripsData?.data?.currentPageNumber || 0}
          />
          <div>

            <Modal
              open={!!selectedInvoiceToPdf}
              onCancel={() => setSelectedInvoiceToPdf(undefined)}
            >
              <Loader />
              <div style={{ height: 0, overflow: "hidden" }}>
                {selectedInvoiceToPdf}
              </div>
            </Modal>

            <Modal
              open={settleHistoryEditModalOpen && settleHistoryEditModalOpen.length > 0}
              onOk={() => setSettleHistoryEditModalOpen('')}
              onCancel={() => setSettleHistoryEditModalOpen('')}
              width="1000px">
              <div className="mb-2">
                <h4 className="mb-0">
                  <b> Settlement History Details</b>{" "}
                </h4>
                <h5><b> Invoice ID : {settleHistoryEditModalOpen}</b>{" "}</h5>


              </div>
              <Table columns={[
                {
                  title: 'Order Id',
                  dataIndex: 'orderId',
                  key: 'orderId',
                },
                {
                  title: 'Store Name',
                  dataIndex: 'storeName',
                  key: 'storeName',
                },
                {
                  title: 'Delivery Charge',
                  dataIndex: 'deliveryCharge',
                  key: 'deliveryCharge',
                },
                {
                  title: 'Payment Method',
                  dataIndex: 'paymentMethod',
                  key: 'paymentMethod',
                },
                {
                  title: 'Payment Status',
                  dataIndex: 'paymentStatus',
                  key: 'paymentStatus',
                },
                {
                  title: 'Amount To Collect',
                  dataIndex: 'collectedAmount',
                  key: 'collectedAmount',
                },
                {
                  title: 'Delivery Charge',
                  dataIndex: 'deliveryCharge',
                  key: 'deliveryCharge',
                },
                {
                  title: 'Payout',
                  dataIndex: 'payout',
                  key: 'payout',
                },
              ]}
                dataSource={ordersForModal}

              />
            </Modal>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default SettlemenByDate;
