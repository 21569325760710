import { ReactElement } from "react";
import Layout from "../../../components/Layout";
import RunsheetListDetails from "../../../components/logistic-runsheet-details";

const RunSheetDetails = (): ReactElement => {
  return (
    <Layout>
      <RunsheetListDetails />
    </Layout>
  );
};

export default RunSheetDetails;
