import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { ReactElement } from "react";
import { connect, useDispatch } from "react-redux";
import { logout } from "../../../utils/auth";

const TopHeader = ({ userName }: any): ReactElement => {
  const dispatch = useDispatch()

  return (
    <div className="top-header-area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="admin-information-sidebar">
              <ul>
                <li>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.25 15.6042C10.1575 15.6042 9.27079 16.4908 9.27079 17.5833C9.27079 18.6758 10.1575 19.5625 11.25 19.5625C12.3425 19.5625 13.2291 18.6758 13.2291 17.5833C13.2291 16.4908 12.3425 15.6042 11.25 15.6042ZM20.75 15.6042C19.6575 15.6042 18.7708 16.4908 18.7708 17.5833C18.7708 18.6758 19.6575 19.5625 20.75 19.5625C21.8425 19.5625 22.7291 18.6758 22.7291 17.5833C22.7291 16.4908 21.8425 15.6042 20.75 15.6042ZM16 0.166672C7.25996 0.166672 0.166626 7.26001 0.166626 16C0.166626 24.74 7.25996 31.8333 16 31.8333C24.74 31.8333 31.8333 24.74 31.8333 16C31.8333 7.26001 24.74 0.166672 16 0.166672ZM16 28.6667C9.01746 28.6667 3.33329 22.9825 3.33329 16C3.33329 15.5408 3.36496 15.0817 3.41246 14.6383C7.14913 12.9758 10.11 9.92001 11.6616 6.13584C14.5275 10.1892 19.2458 12.8333 24.5816 12.8333C25.8166 12.8333 27.0041 12.6908 28.1441 12.4217C28.4766 13.5458 28.6666 14.7492 28.6666 16C28.6666 22.9825 22.9825 28.6667 16 28.6667Z"
                      fill="#3A494E"
                    />
                  </svg>
                  <span>{userName}</span>
                  {/* <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon> */}
                  <ul className="drop-down-menu">
                    {/* <li>
                        <Link to="/setting">Settings</Link>
                      </li>
                      <li>
                        <Link to="/information">Account Information</Link>
                      </li> */}
                    <li>
                      <button onClick={() => logout("/login", dispatch)}>Logout</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
TopHeader.propTypes = {
  userName: PropTypes.string,
};
const mapStateTopProps = (state: {
  authReducer: { name: string; email: string };
}) => {
  return {
    userName:
      state.authReducer.name || state.authReducer?.email?.split("@")?.[0],
  };
};
export default connect(mapStateTopProps, null)(TopHeader);
