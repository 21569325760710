import { ReactElement } from "react";

import Layout from "../../components/Layout";
import OFFLinrRiderList from "../../components/offLineRiders";

const RidersPage = (): ReactElement => {
  return (
    <Layout>
      <OFFLinrRiderList />
    </Layout>
  );
};

export default RidersPage;
