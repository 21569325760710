import { ReactElement } from "react";
import Layout from "../../../components/Layout";
import LogisticSortingOrderList from "../../../components/logistic-sorting";

const LogisticSortingListPage = (): ReactElement => {
  return (
    <Layout>
      <LogisticSortingOrderList />
    </Layout>
  );
};

export default LogisticSortingListPage;
