import {
  ControlOutlined,
  EditOutlined,
  FilePdfOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Menu,
  Rate,
  Row,
  Skeleton,
  Image,
  Form,
  Modal,
  Drawer,
  Input,
  Cascader,
  DatePicker,
} from "antd";
import { CSVLink, CSVDownload } from "react-csv";
import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation, useParams } from "react-router-dom";
import { authenticateToken } from "../../../utils/auth";
import {
  getImgUrl,
  riderStatusArray,
  getPage,
  getParamValue,
} from "../../../utils/index";
import moment from "moment";
import TopHeading from "./TopHeading";
import { responseNotification } from "../../../utils/notify";
import axios from "axios";
import EditRider from "../EditRider";
import PaginationTwo from "../../common/PaginationTwo";
import Loading from "../../common/Loader";
import { locations } from "../../../utils/location";
import { useCreateStore } from "../../../hooks/use-create-store";
import RiderOrdersHistoryPdf from "../../invoice-pdf/rider-orders-history-pdf";

type uniqueZones = any;
type uniqueAreas = any;

const SingleRider = (): ReactElement => {
  const [form] = Form.useForm();
  const [update, setUpdate] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(16);
  const [riderDetails, setRiderDetails] = useState(undefined);
  const [getTripAddress, setTripAddress] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [riderName, setRiderName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [drivingLicense, setDrivingLicense] = useState("");
  const [city, setCity] = useState("");
  const [driversData, setdriversData] = useState({
    loading: false,
    data: null,
  });
  const route = useParams();
  const tripId = (route as any).tripId;
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [open, setOpen] = useState(false);
  const formRef = useRef();
  const hook = useCreateStore(formRef);
  const [options, setOptions] = useState([]);

  const [pdfModal, setPdfModal] = useState("");

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // const loc = useLocation();

  // const handleChangeStatus = (value) => {
  //   setStatus(value);
  // };

  const fetchriderDetails = useCallback(() => {
    if (tripId) {
      fetch(`${process.env.REACT_APP_USER_API}/admin/driver/${tripId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setRiderDetails(data?.driver);
          hook.handleCity(data?.driver.city);
          hook.handleZone(data?.driver.zone);
          setRiderName(data?.driver?.name);
          setMobileNumber(data?.driver?.mobileNumber?.split("+88")[1]);
          setDrivingLicense(data?.driver?.drivingLicense?.drivingLicenseNumber);
        })
        .catch((err) => console.log(err));
    }
  }, [tripId]);

  // console.log("Status:", status);

  const onStatusChange = async (status) => {
    console.log(tripId);
    setLoading(true);

    await fetch(`${process.env.REACT_APP_USER_API}/driver/status`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        driverId: riderDetails?.id,
        note: "",
        status,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          fetchriderDetails();
          responseNotification("Status Updated Successfully", "success");
          form.resetFields();
          // if (onCloseMethod) {
          //   onCloseMethod();
          // }
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  // useEffect(() => {
  //   if (status) {
  //     onStatusChange(status);
  //   }
  // }, [status, onStatusChange]);

  useEffect(() => {
    fetchriderDetails();
  }, [fetchriderDetails]);

  const UpdateStatus = useCallback(() => {
    console.log(riderDetails);
    if (status) {
      axios
        .put(
          `${process.env.REACT_APP_USER_API}/driver/status`,
          {
            id: riderDetails?.id,
            status: status,
          },
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((data) => {
          if (data?.status === 200) {
            responseNotification("Status updated successfully!", "success");
            fetchriderDetails();
          }
        })
        .catch((err) => console.log(err));
    }
  }, [status]);

  const getUserOrders = useCallback(async () => {
    setdriversData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    const today = moment().format("yyyy-MM-DD");
    console.log(today);
    axios
      .get(
        `${encodedUri}/admin/order/order-list-for-delivery-man/${today}/${today}?` +
          `deliveryManId=${tripId || ``}` +
          `&type=${`Q_PARCEL`}` +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setdriversData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setdriversData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [limit, status, page]);

  const getOrderByDeliveryMan = useCallback(
    async (date) => {
      setdriversData({ loading: true, data: null });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      axios
        .get(
          `${encodedUri}/admin/order/order-list-for-delivery-man/${date}/${date}?` +
            `&deliveryManId=${tripId || ``}` +
            `&type=${`Q_PARCEL`}` +
            `&page=${page || 0}` +
            `&limit=${limit || 16}`,
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setdriversData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setdriversData({ loading: false, data: [] });
          console.error("Users: Error", err);
        });
    },
    [limit, status, page]
  );

  useEffect(() => {
    getUserOrders();
  }, [getUserOrders]);

  useEffect(() => {
    UpdateStatus();
  }, [UpdateStatus]);

  /*======================================
 fetch Trip transcation history  api call
  =======================================*/
  // const getSingleTripOptions = async () => {
  //   setSingleTripTranscation({ loading: true, data: null })
  //   const encodedUri = `${process.env.REACT_APP_ORDER_PAYMENT}`
  //   const res = await axios.get(
  //     `${encodedUri}/payment/history/all?page=${
  //       getPage(loc.search) || 0
  //     }&limit=${limit || 16}&TripId=${TripId}` +
  //       (getOrderId ? `&orderId=${getOrderId}` : ``) +
  //       (getStatus ? `&status=${getStatus}` : ``) +
  //       (getChangePayment ? `&paymentMethod=${getChangePayment}` : ``),
  //     {
  //       headers: {
  //         Authorization: `Bearer ${authenticateToken()}`,
  //       },
  //     }
  //   )
  //   setSingleTripTranscation({ loading: false, data: res?.data })
  // }

  // const reseAllFieldData = () => {
  //   // window.location.reload()
  //   window.location.href = `${process.env.PUBLIC_URL}/Trips/${TripId}`;
  // };

  const onFinish = async (data) => {
    console.log(
      JSON.stringify({
        city: hook.city,
        zone: hook.zone,
        name: riderName,
        //mobileNumber: `+88${mobileNumber}`,
        year: moment(data?.year).format("YYYY"),
        driverId: riderDetails?.id,
      })
    );
    await fetch(`${process.env.REACT_APP_USER_API}/admin/driver`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        city: hook.city,
        zone: hook.zone,
        name: riderName,
        // drivingLicenseNumber : drivingLicense,
        // mobileNumber: `+88${mobileNumber}`,
        year: moment(data?.year).format("YYYY"),
        id: riderDetails?.id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Rider Updated Successfully", "success");
          form.resetFields();
          fetchriderDetails();
          // if (onCloseMethod) {
          //   onCloseMethod();
          // }
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const changeDate = (date, dateString) => {
    console.log("value changes", date, dateString);
    getOrderByDeliveryMan(dateString);
  };

  //console.log(riderDetails?.id)
  console.log(tripId);

  useEffect(() => {
    setOptions(
      locations.map((location) => {
        let uniqueZones: uniqueZones = new Set(location?.zones);
        uniqueZones = [...uniqueZones];

        return {
          value: location?.district,
          label: location?.district,
          children: uniqueZones?.map((zone) => {
            let uniqueAreas: uniqueAreas = new Set(zone?.areas);
            uniqueAreas = [...uniqueAreas];
            return {
              value: zone?.name,
              label: zone?.name,
              code: zone.postCode,
            };
          }),
        };
      })
    );
  }, []);

  return (
    // <Layout>
    <React.Fragment>
      <TopHeading
        left={"Rider"}
        right={
          <>
            <Dropdown.Button
              type="dashed"
              overlay={
                <Menu>
                  {Object.values(riderStatusArray).map((status, i) => (
                    <Menu.Item key={i} onClick={() => onStatusChange(status)}>
                      {status?.split("_").join(" ")}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              disabled={
                riderDetails?.status === "VERIFIED" ||
                riderDetails?.status === "INACTIVE"
              }
            >
              {riderDetails?.status || "Status"}
            </Dropdown.Button>

            {/* <Button
              type="default"
              onClick={() => setUpdate(true)}
              className="ml-2"
            >
              <EditOutlined />
              Edit
            </Button> */}
          </>
        }
      />
      <div className="rider-information-area">
        {riderDetails?.loading ? (
          <>
            <Skeleton avatar active paragraph={{ rows: 20 }} />
          </>
        ) : (
          <>
            <Row gutter={24}>
              <Col
                span={24}
                flex="column"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* <Select
            labelInValue
            defaultValue={riderDetails?.status}
            style={{ width: 180 }}
            onChange={(val) => setStatus(val)}
          >
            {Object.values(riderStatuses)?.map((status, i) => (
              <Select.Option value={status} key={i}>{status}</Select.Option>
            ))}
          </Select> */}
              </Col>
            </Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="basic">
              <Row gutter={16} className="bg-white py-4">
                <Col span={5}>
                  <div className="rider-details-wrapper-content">
                    <div className="show-details-inner-content">
                      <div className="rider-image-content">
                        <div className="rider-banner">
                          <div className="rider-logo">
                            <Avatar
                              size={200}
                              src={getImgUrl(riderDetails?.profilePictureUrl)}
                            />
                          </div>
                          <div className="rider-image-content-body">
                            <div className="body">
                              <h4 className="d-flex d-flex-dir-col">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                   
                                  <path
                                    d="M13.75 6.92492V7.49992C13.7492 8.84768 13.3128 10.1591 12.5058 11.2386C11.6989 12.318 10.5646 13.1077 9.2721 13.4899C7.97964 13.872 6.59829 13.8261 5.33404 13.359C4.0698 12.892 2.99041 12.0287 2.25685 10.8981C1.52329 9.76744 1.17487 8.42996 1.26355 7.08511C1.35223 5.74027 1.87325 4.46012 2.74892 3.43559C3.6246 2.41105 4.80799 1.69703 6.12262 1.40001C7.43725 1.10298 8.81267 1.23888 10.0438 1.78742"
                                    stroke="#34A852"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                   
                                  <path
                                    d="M13.75 2.5L7.5 8.75625L5.625 6.88125"
                                    stroke="#34A852"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                   
                                </svg>

                                <span>{riderDetails?.name}</span>
                              </h4>
                              <p>{riderDetails?.mobileNumber?.split("+88")}</p>

                              <div className="single-rider">
                                <Rate
                                  disabled
                                  defaultValue={riderDetails?.rating || 5}
                                  className="mr-2"
                                />
                                <small style={{ color: "#777" }}>
                                  ({riderDetails?.numberOfRating || 5})
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rider-information-tab-menu">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="basic" className="d-flex-l">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                               
                              <path
                                d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M6 11.25H12"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M6.75 6.75H6.7575"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M11.25 6.75H11.2575"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                            </svg>
                             Basic Information
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          {/* <Nav.Link eventKey="bank" className="d-flex-l">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                               
                              <path
                                d="M14.25 8.25H3.75C2.92157 8.25 2.25 8.92157 2.25 9.75V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V9.75C15.75 8.92157 15.0784 8.25 14.25 8.25Z"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M5.25 8.25V5.25C5.25 4.25544 5.64509 3.30161 6.34835 2.59835C7.05161 1.89509 8.00544 1.5 9 1.5C9.99456 1.5 10.9484 1.89509 11.6517 2.59835C12.3549 3.30161 12.75 4.25544 12.75 5.25V8.25"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                            </svg>
                            Vehicle Inforamation
                          </Nav.Link> */}
                        </Nav.Item>
                        <Nav.Item>
                          {/* <Nav.Link eventKey="sellerinfo" className="d-flex-l">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                               
                              <path
                                d="M4.5 1.5L2.25 4.5V15C2.25 15.3978 2.40804 15.7794 2.68934 16.0607C2.97064 16.342 3.35218 16.5 3.75 16.5H14.25C14.6478 16.5 15.0294 16.342 15.3107 16.0607C15.592 15.7794 15.75 15.3978 15.75 15V4.5L13.5 1.5H4.5Z"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M2.25 4.5H15.75"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M12 7.5C12 8.29565 11.6839 9.05871 11.1213 9.62132C10.5587 10.1839 9.79565 10.5 9 10.5C8.20435 10.5 7.44129 10.1839 6.87868 9.62132C6.31607 9.05871 6 8.29565 6 7.5"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                            </svg>
                            License Information
                          </Nav.Link> */}
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link
                            eventKey="orderhistory"
                            className="d-flex-l"
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                               
                              <path
                                d="M4.5 1.5L2.25 4.5V15C2.25 15.3978 2.40804 15.7794 2.68934 16.0607C2.97064 16.342 3.35218 16.5 3.75 16.5H14.25C14.6478 16.5 15.0294 16.342 15.3107 16.0607C15.592 15.7794 15.75 15.3978 15.75 15V4.5L13.5 1.5H4.5Z"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M2.25 4.5H15.75"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M12 7.5C12 8.29565 11.6839 9.05871 11.1213 9.62132C10.5587 10.1839 9.79565 10.5 9 10.5C8.20435 10.5 7.44129 10.1839 6.87868 9.62132C6.31607 9.05871 6 8.29565 6 7.5"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                            </svg>
                            Order History
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                </Col>
                <Col span={16}>
                  <div className="rider-information-show-tab-wrapper">
                    <div className="inner-content">
                      <div className="single-content">
                        <Tab.Content>
                          <Tab.Pane eventKey="basic">
                            <div className="single-information-rider-wrapper">
                              <div className="single-information-rider-content">
                                <div className="info-icon">
                                  <FileTextOutlined />
                                </div>
                                <div className="info-body">
                                  <h3
                                    className="title"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    BASIC INFORMATION
                                    <Button type="primary" onClick={showDrawer}>
                                      Edit
                                    </Button>
                                  </h3>
                                  <div className="inner-body">
                                    <div className="single">
                                      <span className="info-name">
                                        Rider Name
                                      </span>
                                      <span className="info-desc">
                                        {riderDetails?.name}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Mobile Number
                                      </span>
                                      <span className="info-desc">
                                        {riderDetails?.mobileNumber?.split(
                                          "+88"
                                        )}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">City</span>
                                      <span className="info-desc">
                                        {riderDetails?.city}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Joined</span>
                                      <span className="info-desc">
                                        {moment(riderDetails?.createdAt).format(
                                          "LLL"
                                        )}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Driver Id
                                      </span>
                                      <span className="info-desc">
                                        {riderDetails?.driverId}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Personal Info Status
                                      </span>
                                      <span className="info-desc">
                                        {riderDetails?.personalInfoStatus}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Vehicle Type
                                      </span>
                                      <span className="info-desc">
                                        {riderDetails?.vehicleType}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Status</span>
                                      <span className="info-desc">
                                        {" "}
                                        <span
                                          className={
                                            riderDetails?.status ===
                                            "UNVERIFIED"
                                              ? "inactive-btn radius-btn"
                                              : riderDetails?.status ===
                                                "VERIFIED"
                                              ? "active-btn radius-btn"
                                              : "pending-btn radius-btn"
                                          }
                                        >
                                          {riderDetails?.status}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="inner-body">
                                    <h4 className="title">NID</h4>
                                    <div className="single">
                                      <span className="info-name">
                                        NID Number
                                      </span>
                                      <span className="info-desc">
                                        {riderDetails?.nid?.nidNumber}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Capture</span>
                                      <span className="info-desc">
                                        <Image
                                          src={getImgUrl(
                                            riderDetails?.nid?.frontUrl
                                          )}
                                          alt="nid"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="bank">
                            <div className="single-information-rider-wrapper">
                              <div className="single-information-rider-content">
                                <div className="info-icon">
                                  <FileTextOutlined />
                                </div>
                                <div className="info-body">
                                  <h3 className="title">VEHICLE INFORMATION</h3>
                                  <div className="inner-body">
                                    <div className="single">
                                      <span className="info-name">
                                        Vehicle Model
                                      </span>
                                      <span className="info-desc">
                                        {riderDetails?.car?.model}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Vehicle Registration Number
                                      </span>
                                      <span className="info-desc">
                                        {
                                          riderDetails?.car
                                            ?.carRegistrationNumber
                                        }
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Registered
                                      </span>
                                      <span className="info-desc">
                                        {moment(
                                          riderDetails?.car?.createdAt
                                        ).format("LLL")}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">City</span>
                                      <span className="info-desc">
                                        {riderDetails?.car?.city}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Manufacturer
                                      </span>
                                      <span className="info-desc">
                                        {riderDetails?.car?.manufacturer}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Status</span>
                                      <span className="info-desc">
                                        <span
                                          className={
                                            riderDetails?.car?.status ===
                                            "UNVERIFIED"
                                              ? "inactive-btn radius-btn"
                                              : riderDetails?.car?.status ===
                                                "VERIFIED"
                                              ? "active-btn radius-btn"
                                              : "pending-btn radius-btn"
                                          }
                                        >
                                          {riderDetails?.car?.status}
                                        </span>
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Year</span>
                                      <span className="info-desc">
                                        {riderDetails?.car?.year}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="sellerinfo">
                            <div className="single-information-rider-wrapper">
                              <div className="single-information-rider-content">
                                <div className="info-icon">
                                  <FileTextOutlined />
                                </div>
                                <div className="info-body">
                                  <h3 className="title">DRIVING LICENSE</h3>
                                  <div className="inner-body">
                                    <div className="single">
                                      <span className="info-name">
                                        Driving License Number
                                      </span>
                                      <span className="info-desc">
                                        {
                                          riderDetails?.drivingLicense
                                            ?.drivingLicenseNumber
                                        }
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Capture</span>
                                      <span className="info-desc">
                                        <Image
                                          src={getImgUrl(
                                            riderDetails?.drivingLicense
                                              ?.frontUrl
                                          )}
                                          alt="drivingLicense"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="inner-body">
                                    <h4 className="title">
                                      Registration Paper
                                    </h4>
                                    <div className="single">
                                      <span className="info-name">Capture</span>
                                      <span className="info-desc">
                                        <Image
                                          src={getImgUrl(
                                            riderDetails?.registrationPaper
                                              ?.frontUrl
                                          )}
                                          alt="registrationPaper"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="orderhistory"
                            style={{ width: "115%" }}
                          >
                            <div className="single-information-rider-wrapper">
                              <div className="single-information-rider-content">
                                <div className="info-icon">
                                  <FileTextOutlined />
                                </div>
                                <div className="info-body">
                                  <h3
                                    className="title"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    ORDER HISTORY
                                    <>
                                      {/* @ts-ignore */}
                                      <DatePicker
                                        onChange={changeDate}
                                        defaultValue={moment()}
                                      />
                                      {driversData?.data?.orders &&
                                      driversData?.data?.orders?.length ? (
                                        <React.Fragment>
                                          <CSVLink
                                            data={driversData?.data?.orders?.map(
                                              (data) => {
                                                let temp = { ...data };
                                                console.log(data);
                                                temp[`Order Id`] = temp.orderId;
                                                temp[`Assigned To`] =
                                                  temp?.deliveryMan?.name;
                                                temp[`Merchant Name`] =
                                                  temp.customer.name;
                                                temp[`Merchant OrderId`] =
                                                  temp.parcelOrder.merchantOrderId;
                                                temp[`Merchant Number`] =
                                                  temp.customer.mobileNumber;
                                                temp[`Customer Name`] =
                                                  temp.customer.name;
                                                temp[`Customer Number`] =
                                                  temp.customer.mobileNumber;
                                                temp[`Customer Address`] =
                                                  temp.shippingAddress;
                                                temp[`Zone`] =
                                                  temp.parcelOrder.recipientZone;
                                                temp[`Amount`] = temp.total;
                                                temp[`Instruction`] =
                                                  temp.orderNote;
                                                temp[`Status`] = temp.status;
                                                temp[`Pickup Location`] =
                                                  temp.parcelOrder.pickupLocation;
                                                temp[`Remarks`] = "";

                                                delete temp["parcelOrder"];
                                                delete temp["paymentMethod"];
                                                delete temp["totalPaid"];
                                                delete temp["deliveryManId"];
                                                delete temp["createdAt"];
                                                delete temp["statusOfMerchant"];
                                                delete temp[
                                                  "statusOfDeliveryMan"
                                                ];
                                                delete temp["subTotal"];
                                                delete temp["orderId"];
                                                delete temp["status"];
                                                delete temp["baseOrderId"];
                                                delete temp["orderId"];
                                                delete temp["total"];
                                                delete temp["temp"];
                                                delete temp["temp"];
                                                delete temp["customer"];
                                                delete temp["instruction"];
                                                delete temp["baseAmount"];
                                                delete temp[
                                                  "courierCustomerInfo"
                                                ];
                                                delete temp["deliveryCharge"];
                                                delete temp["deliveryMan"];
                                                delete temp[
                                                  "deliveryManLocation"
                                                ];
                                                delete temp["discount"];
                                                delete temp["hub"];
                                                delete temp["isInsideDhaka"];
                                                delete temp["isPinRequired"];
                                                delete temp["merchantAmount"];
                                                delete temp["orderNote"];
                                                //delete temp["parcelOrder"]
                                                delete temp["paymentStatus"];
                                                delete temp["products"];
                                                delete temp["shippingAddress"];
                                                delete temp["shippingLocation"];
                                                delete temp["shop"];
                                                delete temp["shopLocation"];
                                                delete temp["stores"];
                                                delete temp["tokenNumber"];
                                                delete temp["supplier"];
                                                delete temp["updatedAt"];
                                                delete temp["vat"];

                                                return temp;
                                              }
                                            )}
                                            filename={`transactions-${driversData?.data?.orders?.length}.csv`}
                                            type="primary"
                                            className="btn btn-primary"
                                            asyncOnClick={true}
                                            onClick={(_, done) => {
                                              if (
                                                driversData?.data?.orders
                                                  ?.length
                                              ) {
                                                done(true);
                                              } else {
                                                return false;
                                              }
                                            }}
                                            style={{
                                              borderRadius: "0px",
                                              border: "0px",
                                              fontSize: "13px",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Download
                                          </CSVLink>
                                        </React.Fragment>
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            // getAllShopProductForDownload();
                                          }}
                                          disabled
                                          type="primary"
                                          title="Download Products"
                                          style={{
                                            padding: "0.2rem 0.75rem",
                                            borderRadius: "0px",
                                            background: "#ff9e00",
                                          }}
                                          className="mr-2"
                                        >
                                          Download
                                        </Button>
                                      )}

                                      <Button
                                        shape="round"
                                        size="middle"
                                        loading={loading}
                                        disabled={
                                          driversData?.data?.orders &&
                                          driversData?.data?.orders?.length
                                            ? false
                                            : true
                                        }
                                        danger
                                        style={{ width: "100px" }}
                                        onClick={() => setPdfModal("PDF")}
                                      >
                                        <FilePdfOutlined />
                                        PDF
                                      </Button>
                                    </>
                                  </h3>

                                  <div className="inner-body">
                                    <div
                                      className="rider-list-item-area white-bg section-padding-bottom"
                                      style={{ marginLeft: "-50px" }}
                                    >
                                      <div className="container-fluid">
                                        <div className="row">
                                          <div className="col-lg-12">
                                            <div className="rider-list-items-inner-content">
                                              <div className="single-rider-wrapper">
                                                <div className="single-rider-heading">
                                                  <span>Date & Time</span>
                                                </div>
                                                <div className="single-rider-heading">
                                                  <span>Order ID</span>
                                                </div>
                                                <div className="single-rider-heading">
                                                  <span>
                                                    Status Of DeliveryMan
                                                  </span>
                                                </div>
                                                <div
                                                  className="single-rider-heading"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <span>Status</span>
                                                </div>
                                                <div className="single-rider-heading">
                                                  <span>Payment Method</span>
                                                </div>

                                                <div className="single-rider-heading">
                                                  <span>Total</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          {driversData?.loading ? (
                                            <Loading />
                                          ) : (
                                            <div className="col-lg-12">
                                              <div className="rider-table-area">
                                                {driversData?.data?.orders
                                                  ?.length ? (
                                                  driversData?.data?.orders?.map(
                                                    (
                                                      order: any,
                                                      index: any
                                                    ) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <div
                                                          className="single-rider-wrapper"
                                                          onClick={() =>
                                                            setSelectedUser(
                                                              order
                                                            )
                                                          }
                                                        >
                                                          <div className="single-rider">
                                                            <span className="name text-capitalize">
                                                              {moment(
                                                                order?.createdAt
                                                              ).format("ll")}
                                                              <br />
                                                              {moment(
                                                                order?.createdAt
                                                              ).format(
                                                                "hh:mm:ss A"
                                                              )}
                                                            </span>
                                                          </div>
                                                          <div className="single-rider">
                                                            <span className="name">
                                                              {order?.orderId}
                                                            </span>
                                                          </div>
                                                          {/* <div className="single-rider">
                                                          <span className="name">
                                                            {order?.statusOfMerchant}
                                                          </span>
                                                        </div> */}
                                                          <div className="single-rider">
                                                            <span
                                                              className={`radius-btn ${order?.statusOfDeliveryMan?.toLowerCase()}-btn`}
                                                            >
                                                              {
                                                                order?.statusOfDeliveryMan
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="single-rider">
                                                            <span
                                                              className={`text-nowrap radius-btn ${order?.status?.toLowerCase()}-btn`}
                                                            >
                                                              {order?.status
                                                                ?.split("_")
                                                                .join(" ")}
                                                            </span>
                                                          </div>

                                                          <div className="single-rider">
                                                            <span className="name">
                                                              {order?.paymentMethod
                                                                ?.split("_")
                                                                .join(" ")
                                                                .toUpperCase()}
                                                            </span>
                                                          </div>

                                                          <div className="single-rider">
                                                            <span className="name">
                                                              &#2547;
                                                              {parseFloat(
                                                                order?.total
                                                              )}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </React.Fragment>
                                                    )
                                                  )
                                                ) : (
                                                  <p
                                                    style={{
                                                      textAlign: "center",
                                                      width: "100%",
                                                      padding: "40px 0",
                                                      color: "red",
                                                    }}
                                                  >
                                                    No History Found
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>

                                        <PaginationTwo
                                          {...driversData?.data}
                                          limit={limit}
                                          page={getPage(loc.search)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
        <Modal
          // size="lg"
          className="update-campaign-modal edit-product-modal-area"
          // show={!!singleProduct}
          visible={!!riderDetails && update}
          onCancel={() => setUpdate(false)}
          onOk={() => {
            setUpdate(false);
            fetchriderDetails();
          }}
          // backdrop="static"
          keyboard={false}
          // animation
          width={1000}
          title="Update Rider"
        >
          <EditRider
            singleRiderData={riderDetails}
            fetchRiderDetails={() => fetchriderDetails()}
          />
        </Modal>

        <Drawer
          title="Edit Basic Info"
          placement="right"
          onClose={onClose}
          open={open}
          width="400"
        >
          <Form
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            className="riderInformation"
          >
            <div>
              <label htmlFor="RiderName">Rider Name</label>
              <Input
                type="text"
                value={riderName}
                name="riderName"
                onChange={(e) => setRiderName(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <label htmlFor="mobileNumber"> Mobile Number</label>
              <Input
                type="text"
                disabled
                value={mobileNumber}
                name="mobileNumber"
                // onChange={(e)=>setMobileNumber(e.target.value)}
              />
            </div>
            {/* <div className="mt-3">
              <label htmlFor="drivingLicense"> Driving License</label>
              <Input type="text" value={drivingLicense} name="drivingLicense" onChange={(e)=>setDrivingLicense(e.target.value)}/>
            </div> */}

            <div className="mt-3">
              <label htmlFor="city">City</label>
              <Cascader
                options={options}
                size="large"
                value={
                  hook.city || hook.zone ? [hook.city, hook.zone] : undefined
                }
                //displayRender={displayRender}
                style={{ width: "100%", borderRadius: 4 }}
                // bordered={false}
                placeholder="Select location"
                //showSearch={{ filter }}
                onChange={hook.handleLocation}
                onSearch={(value) => console.log("search", value)}
              />
            </div>

            <Form.Item className="mt-3">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </div>

      <Modal
        visible={!!pdfModal}
        onCancel={() => setPdfModal("")}
        footer={null}
        width={"100%"}
      >
        <RiderOrdersHistoryPdf orderList={driversData?.data?.orders ?? []} />
      </Modal>
    </React.Fragment>
    // </Layout>
  );
};
SingleRider.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default SingleRider;
