import decode from "jwt-decode";
import PropTypes from "prop-types";
import { ReactElement } from "react";
import { Redirect, Route } from "react-router-dom";
import Login from "../components/login";

export const PrivateRoute = ({
  component: Component,
  exact,
  path,
  ...rest
}: any): ReactElement => {
  if (localStorage.getItem("authToken")) {
    if (
      (decode(localStorage.getItem("authToken")) as any)?.exp >
      Date.now() / 1000
    ) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    } else {
      localStorage.removeItem("authToken");
      localStorage.removeItem("services");
      return <Redirect to="/login" />;
    }
  } else {
    return <Redirect to="/login" />;
  }
};
PrivateRoute.propTypes = {
  component: PropTypes.any,
  exact: PropTypes.bool,
  path: PropTypes.any,
};
export default PrivateRoute;
