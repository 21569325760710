import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  LOGOUT_SUCCESS,
  SET_MERCHANT_INFO,
} from "./authType";

const initialState = {
  name: "",
  email: "",
  role: "",
  exp: null,
  error: false,
  success: false,
  loading: false,
  services: [],
  merchantInfo: null,
};

const authRedcer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      state.loading = true;
      return state;
    }
    case LOGIN_SUCCESS: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }
    case LOGIN_LOADING: {
      return state;
    }
    case LOGOUT_SUCCESS: {
      state = {
        name: "",
        email: "",
        role: "",
        exp: null,
        error: false,
        success: false,
        loading: false,
        services: [],
        merchantInfo: null,
      };
      state.loading = false;
      return state;
    }
    case SET_MERCHANT_INFO: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }
    default: {
      state.loading = false;
      return state;
    }
  }
};

export default authRedcer;
