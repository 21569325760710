import * as React from "react";
import * as ReactDOM from "react-dom";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
// import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
// import {
//     Chart,
//     ChartLegend,
//     ChartSeries,
//     ChartSeriesItem,
//     ChartSeriesLabels,
//     ChartCategoryAxis,
//     ChartCategoryAxisItem
// } from "@progress/kendo-react-charts";
import products from "./products.json";
import data from "./p.json";
// @ts-ignore
import logo from "../../images/logo-vertical.png";
import JsBarcode from "jsbarcode";
import moment from "moment";

import "../../styles/pages/pdf.css";
import "@progress/kendo-theme-default/dist/all.css";

// Create styles
const styles = {
  page: {
    backgroundColor: "white",
    fontSize: 12,
    lineHeight: 1.4,
    color: "gray",
    breakAfter: "page",
  },
  bold: {
    fontWeight: 'bold',
  },
  section: {
    // paddingTop: 10,
    paddingBottom: 10,
  },
  headerArea: {
    display: "flex",
    marginBottom: 15,
    width: "100%",
    flexDirection: "column",
  },
  headerLeft: {
    alignSelf: "center",
  },
  headerRight: {
    marginTop: "-70px",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  logo: {
    width: 60,
    // marginBottom: 10,
  },
  titleValue: {
    fontSize: 18,
    fontWeight: 900,
    color: "black",
    margin: 0,
    lineHeight: 1,
    marginBottom: 0,
  },
  image: {
    flexDirection: "row",
    flex: 1,
    height: 35,
    width: 35,
    backgroundColor: "#ebebeb",
    borderRadius: 3,
  },
  red: {
    color: "#cd113b",
  },
  divider: {
    borderBottom: "1px solid #000",
    width: "100%",
    margin: "2.5px 0",
  },
  spacedLabel: {
    width: 100,
  },
  flex: {
    display: "flex",
  },
  div: {
    display: "block",
  },

  shopName: {
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  shopText: {
    fontSize: 10,
    fontWeight: 300,
    maxWidth: 200,
  },

  titleArea: {
    // backgroundColor: "#E5E5E5",
    border: "2px solid #e3e3e3",
    backgroundColor: "white",
    textAlign: "center",
    padding: "5px 20px",
    lineHeight: 0,
    paddingTop: 10,
    width: "200px",
  },

  orderItem: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    flexDirection: "row-reverse",
    marginBottom: 15,
    width: "100%",
    marginTop: -60,
  },

  middleLeft: {
    minWidth: 225,
    marginLeft: 25,
  },
  middleRight: {
    width: "55%",
  },

  bilingTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "black",
    marginBottom: 0,
  },
  bilingTitleValue: {
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
    marginBottom: 0,
  },

  userName: {
    fontSize: 10,
    display: "flex",
    justifyContent: "flex-start",
  },
  userText: {},

  middleArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 15,
  },
  totalDue: {
    marginBottom: 0,
    color: "#bf1a0d",
    fontSize: 12,
  },

  productHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "#efefef",
    borderRadius: 2,
    padding: "8px 15px",
    color: "#000",
    fontSize: 11,
  },
  productHeaderText: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    flex: 1,
    fontWeight: "bold",
  },
  productList: {
    borderBottom: "1px solid #f6f6f5",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 5px",
  },

  producDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  producText: {
    fontSize: 10,
    fontWeight: 400,
    width: 100,
  },
  producName: {
    width: 200,
    fontSize: 10,
    textAlign: "left",
  },
  imageArea: {
    height: 35,
    width: 35,
    display: "flex",
    flexDirection: "column",
    marginRight: 8,
  },
  image: {
    flexDirection: "row",
    flex: 1,
    height: 35,
    width: 35,
    backgroundColor: "#ebebeb",
    borderRadius: 3,
  },

  acountSection: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "row",
    padding: "20px 0",
  },
  acountArea: {
    backgroundColor: "#f8f8f8",
    padding: 15,
  },

  acountAreaPara: {
    fontSize: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: "row",
    width: 200,
    padding: "0px 5px",
    fontWeight: "bold",
  },
  fromSmall: {
    fontSize: 10,
    fontWeight: 400,
    display: "flex",
    justifyContent: "flex-end",
  },

  footerArea: {
    textAlign: "left",
    // maxWidth: 300,
    display: "flex",
    alignSelf: "baseline",
    justifyContent: "flex-end",
    marginTop: "auto",
  },
  footerText: {
    // display: "flex",
    flexDirection: "row",
    fontSize: 10,
    fontWeight: 200,
    marginTop: 5,
  },
  textRed: {
    color: "#cd113b",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  tdth: {
    border: "1px solid #dddddd",
    textAlign: "center",
    padding: "2px 8px",
  },
  title: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'black',
  },
  titleM: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'black',
  },
  titleL: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'black',
  }
};

const RiderOrdersHistoryPdf = ({ orderList }) => {
  console.log(134, orderList);
  const container = React.useRef();
  const pdfExportComponent = React.useRef();
  const config = {
    paperSize: "A4",
    margin: 20,
    fileName: `Pdf for ${new Date().getDate()}`,
    author: "Piickme Express Limited",
    scale: 0.7,
    forcePageBreak: ".page-break",
    landscape: true,
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  const printPDFWithComponent = () => {
    const printContents = document.getElementById("print").innerHTML;
    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();
    // window.location.reload(false);
    // document.body.innerHTML = originalContents;


    const winPrint = window.open('');
    winPrint.document.write(printContents);
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    winPrint.close();
  };

  return (
    <div id="example">
      <div className="example-config box wide hidden-on-narrow">
        <div className="box-col">
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={exportPDFWithComponent}
          >
            Download
          </button>
          {/* <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base ml-2"
            onClick={printPDFWithComponent}
          >
            Print
          </button> */}
        </div>
      </div>
      <div className="page-container hidden-on-narrow">
        <div className="border rounded p-2 k-pdf-export app-content">
          <PDFExport ref={pdfExportComponent} {...config}>
            <kendo-pdf-document>
              <div ref={container} id="print">
                <OrderList orderList={orderList} />
              </div>
            </kendo-pdf-document>
          </PDFExport>
        </div>
      </div>
    </div>
  );
};

const OrderList = ({ orderList }) => {
  return (
    <div style={styles.page} className="pdf-page">
      <div className="inner-page">
        <div style={styles.section}>
          <div className="pdf-header">
            <div style={styles.headerArea}>
              <div style={{ ...styles.headerLeft, textAlign: 'center' }}>
                <img src={logo} style={styles.logo} alt="piickme logo" />
                <h3 style={{ ...styles.titleL, marginLeft: '30px' }}>PIICKME EXPRESS LIMITED</h3>
              </div>

              <div style={styles?.orderItem}>
                <div style={styles.middleLeft}>
                  <div>
                    Assigned Date:{" "}
                    <span style={styles.title}>{moment(orderList[0]?.deliveryManAssignDate).format("ll")}</span>
                  </div>
                  <div>
                    Assigned Time:{" "}
                    <span style={styles.title}><span style={styles.title}>
                      {moment(orderList[0]?.createdAt).format("hh:mm:ss A")}</span></span>
                  </div>
                  <div>
                    Total Order:{" "}
                    <span style={styles.title}>{orderList.length}</span>
                  </div>
                </div>

                <div style={styles?.middleRight}>
                  <div>
                    Assigned To:{" "}
                    <span style={styles.title}>{orderList[0]['deliveryMan']['name']}</span>
                  </div>
                  <div>
                    Assigned By:{" "}
                    <span style={styles.title}></span>
                  </div>

                  <div>
                    Phone:{" "}
                    <span style={styles.title}>{orderList[0]['deliveryMan']['mobileNumber']}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <table style={styles.table}>
            <thead style={{ backgroundColor: "#efefef" }}>
              <tr>
                <th style={{ ...styles.tdth, width: '280px' }}>Order ID</th>
                <th style={{ ...styles.tdth, width: '200px' }}>Merchant</th>
                <th style={{ ...styles.tdth, width: '200px' }}>Customer</th>
                <th style={{ ...styles.tdth, width: '100px' }}>Amount</th>
                <th style={{ ...styles.tdth, width: '120px' }}>Instruction</th>
                <th style={{ ...styles.tdth, width: '100px' }}>Status</th>
                <th style={{ ...styles.tdth, }}>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {orderList?.map((item, index) => (
                <OrderListItem orderItem={item} key={index} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const OrderListItem = ({ orderItem }) => {
  let canvas;
  canvas = document.createElement("canvas");
  JsBarcode(canvas, orderItem?.orderId, {
    displayValue: false,
    // width: 80,
    // minWidth: 180,
    height: 40,
    padding: 0,
    margin: 0,
  });
  const barcode = canvas.toDataURL();

  // a
  return (
    <tr>
      <td style={{ ...styles.tdth, width: '280px' }}>
        <div>{orderItem?.orderId}</div>
        <img src={barcode} alt="barcode" />
      </td>
      <td style={{ ...styles.tdth, width: '200px', textAlign: 'left' }}>
        <div><span>{orderItem?.customer?.name}</span></div>
        <div><span style={styles.title}>{orderItem?.customer?.mobileNumber}</span></div>
        {/* <div>Address: <br /><span style={styles.title}>{orderItem?.shippingAddress}</span></div> */}
      </td>
      <td style={{ ...styles.tdth, width: '200px', textAlign: 'left' }}>
        <div><span>{orderItem?.parcelOrder?.recipientName}</span></div>
        <div><span style={styles.title}>{orderItem?.parcelOrder?.recipientPhone},</span></div>
        <div><span>{orderItem?.parcelOrder?.recipientAddress}</span></div>
      </td>
      <td style={{ ...styles.tdth, width: '100px' }}><span style={styles.title}>{orderItem?.total}</span></td>
      <td style={{ ...styles.tdth, width: '120px' }}><span>{orderItem?.parcelOrder?.specialInstruction}</span></td>
      <td style={{ ...styles.tdth, width: '100px' }}><span>{orderItem?.status}</span></td>
      <td style={{ ...styles.tdth, }}></td>
    </tr>
  );
};

export default RiderOrdersHistoryPdf;
