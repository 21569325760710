import * as React from "react";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
// @ts-ignore
import logo from "../../images/logo-vertical.png";
import JsBarcode from "jsbarcode";
import moment from "moment";
import "../../styles/pages/pdf.css";

// Create styles
const styles = {
  page: {
    flexDirection: "row",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop:20,
    paddingBottom:40,
    fontSize: 8,
    //lineHeight: 1.3,
    // height: 800,
  },
  sm: {
    fontSize: 7,
  },
  md: {
    fontSize: 10,
  },
  lg: {
    fontSize: 12,
  },
  bold: {
    fontWeight: "bold",
  },
  info: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  icon: {
    width: 15,
    display: "flex",
    flexDirection: "column",
    margin: "0 5px",
  },
  section: {
    flexGrow: 1,
    //height: 842,
    // backgroundColor: 'white',
  },
  headerArea: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 10,
    width: "100%",
  },
  headerLeft: {},
  logo: {
    width: 80,
    height:80,
    marginBottom: 5,
  },
  headerRight: {
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  shopName: {
    marginBottom: 5,
    textAlign: "right",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  shopText: {
    marginBottom: 3,
    fontWeight: 300,
    textAlign: "right",
    maxWidth: 200,
  },

  titleArea: {
    // backgroundColor: "#E5E5E5",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 6,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
    width: 125,
    border: "2px solid #e3e3e3",
  },
  title: {
    fontWeight: 600,
    lineHeight: 1,
  },

  orderDetails: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    flexDirection: "row",
    marginBottom: 25,
    marginTop: 10,
    width: "100%",
  },

  middleLeft: {
    maxWidth: 220,
    lineHeight: 1.5,
  },
  bilingTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: "black",
  },
  bilingTitleValue: {
    fontSize: 14,
    marginBottom: 0,
    fontWeight: "bold",
    color: "black",
  },
  middleRight: {
    width: "45%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column",
  },

  userName: {
    marginBottom: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  userText: {
    textAlign: "left",
    fontWeight: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },

  middleArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 5,
  },
  totalDue: {
    marginBottom: 0,
    color: "#bf1a0d",
  },

  productHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "#efefef",
    borderRadius: 2,
    padding: "8px 15px",
    color: "#000",
  },
  productHeaderText: {
    textAlign: "right",
    fontWeight: "bold",
    padding: "0 5px",
    width: 100,
    whiteSpace: "break-spaces",
  },
  productList: {
    borderTop: "1px solid #f6f6f5",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 5px",
  },

  producDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  producText: {
    fontWeight: 400,
    textAlign: "left",
    width: 100,
    whiteSpace: "break-spaces",
    padding: "0 8px",

  },
  producName: {
    width: 100,
    textAlign: "left",
    fontSize:5,
    marginBottom:4
  },
  imageArea: {
    height: 35,
    width: 35,
    display: "flex",
    flexDirection: "column",
    marginRight: 8,
  },
  image: {
    flexDirection: "row",
    flex: 1,
    height: 35,
    width: 35,
    backgroundColor: "#ebebeb",
    borderRadius: 3,
  },

  acountSection: {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "row",
    padding: "20px 0",
  },
  acountArea: {
    textAlign: "right",
    marginBottom: 25,
    backgroundColor: "#f8f8f8",
    padding: 15,
  },

  acountAreaPara: {
    textAlign: "right",
    marginBottom: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: "row",
    width: 200,
    padding: "0px 5px",
    fontWeight: "bold",
  },
  fromSmall: {
    fontWeight: 400,
    display: "flex",
    justifyContent: "flex-end",
  },

  footerArea: {
    textAlign: "left",
    // maxWidth: 300,
    display: "flex",
    alignSelf: "baseline",
    justifyContent: "flex-end",
    marginTop: "auto",
  },
  footerText: {
    // display: "flex",
    flexDirection: "row",
    fontWeight: 200,
    marginTop: 5,
  },
  textRed: {
    color: "#cd113b",
  },

  table: {
    border: "1.5px solid #e0e0e0",
    position:"absolute",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  th: {
    fontWeight: "bold",
    padding: 5,
    borderRight: "1.5px solid #e0e0e0",
    flex: "1 1 50%",
  },
  td: {
    padding: 5,
    flex: "1 1 50%",
  },
};

const MerchantInvoice = ({ data: orderList, invoice }) => {
  const btnRef = React.useRef();
  const container = React.useRef();
  const pdfExportComponent = React.useRef();

  const config = {
    paperSize: "A4",
    margin: 20,
    fileName: `Invoiceß for ${new Date().getDay()}`,
    author: "Piickme Express Limited",
    scale: 0.9,
    landscape:true,
    forcePageBreak: ".page-break",
    repeatHeaders: true,
  };

  const exportPDFWithMethod = () => {
    let element = container.current || document.body;
    savePDF(element, config);
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

//   React.useEffect(() => {
//     console.log("shown");
//     btnRef?.current?.click();
//   }, [orderList]);

  return (
    <div>
      <div className="example-config">
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base mb-4"
          ref={btnRef}
          onClick={exportPDFWithComponent}
        >
          Download
        </button>
      </div>
      <div className="border rounded p-2">
        <PDFExport ref={pdfExportComponent} {...config} forcePageBreak=".page-break">
          <div ref={container}>
            <MerchantInvoiceUnit
              orderList={orderList}
              orderDetails={orderList[0]}
              invoice={invoice}
            />
          </div>
        </PDFExport>
      </div>
    </div>
  );
};

const MerchantInvoiceUnit = ({ orderList, orderDetails, invoice }) => {
  let canvas;
  canvas = document.createElement("canvas");
  JsBarcode(canvas, orderDetails?.orderId, {
    displayValue: false,
    height: 25,
  });
  const barcode = canvas.toDataURL();

  const getCodCharge = () => {
    const user = localStorage.getItem("user-data");
    if (user) {
      return JSON.parse(user)?.codCharge || 1;
    }
    return 1;
  };

  const totalCalc = orderList.reduce(
    (obj, order) => {
      const data = order?.parcelOrder;
      obj.amountToCollect += data?.amountToCollect;
      obj.cod += data?.codCharge;
      obj.deliveryCharge += data?.deliveryCharge;
      obj.totalCharge += data?.deliveryCharge + data?.codCharge;
      obj.payout +=
        data?.amountToCollect - (data?.deliveryCharge + data?.codCharge);
      return obj;
    },
    {
      amountToCollect: 0,
      deliveryCharge: 0,
      cod: 0,
      totalCharge: 0,
      payout: 0,
    }
  );

  const total = {
    amountToCollect: totalCalc?.amountToCollect || 0,
    deliveryCharge: totalCalc?.deliveryCharge || 0,
    cod: totalCalc?.cod || 0,
    totalCharge: totalCalc?.totalCharge || 0,
    payout: totalCalc?.payout || 0,
  };

  return (
    <div title="Order Invoice">
      <div style={styles.page}>
        <div style={styles.section}>
          <div style={styles.headerArea}>
            <div style={styles.headerLeft}>
              <img src={logo} style={styles.logo} />
              <div style={styles.info}>
                <div>
                  <div
                    style={{
                      ...styles.userText,
                      maxWidth: 120,
                      ...styles.sm,
                      color: "gray",
                      lineHeight: "16px",
                    }}
                  >
                    Niketan, House 86, Flat A2, Road 10/1, Block D, Dhaka 1212
                  </div>
                </div>
              </div>
            </div>

            <div style={styles.headerRight}>
              <div style={styles.info}>
                <div style={styles.icon}>
                  <Email />
                </div>
                <div>
                  <div style={{ ...styles.userText, marginBottom: 2 }}>
                    support@piickmeexpress.com
                  </div>
                </div>
              </div>
              <div style={styles.info}>
                <div style={{ ...styles.icon, marginBottom: 2 }}>
                  <Call />
                </div>
                <div>
                  <div style={styles.userText}>+8809609414141 (Sat-Thu)</div>
                </div>
              </div>
              <div style={styles.info}>
                <div style={styles.icon}>
                  <Web />
                </div>
                <div>
                  <div style={styles.userText}>www.piickmeexpress.com</div>
                </div>
              </div>
            </div>
          </div>

          <div style={styles?.orderDetails}>
            <div style={styles.middleLeft}>
              <div>
                <strong style={styles.bilingTitle}>INVOICE TO : {orderDetails?.parcelOrder?.storeName}</strong>
              </div>
              {/* <div>
                <h4 style={styles.bilingTitleValue}>
                  
                </h4>
              </div> */}
              <div>
                <div style={styles.userText}>
                  Contact:{" "}
                  {orderDetails?.customer?.mobileNumber?.split("+88") || "-"}
                </div>
              </div>
              <div>
                <div style={{ ...styles.userText, marginBottom: 5 }}>
                  Address: {orderDetails?.parcelOrder?.storeAddress || "-"}
                </div>
              </div>
            </div>

            <div style={styles?.middleRight}>
              <div style={styles?.table}>
                <div style={{ ...styles?.tr, backgroundColor: "#efefef" }}>
                  <div style={styles?.th}>
                    <div>Total Paid Out</div>
                  </div>
                  <div style={styles?.td}>
                    <div>BDT {total.payout}</div>
                  </div>
                </div>
                <div style={styles?.tr}>
                  <div style={styles?.th}>
                    <div>Invoice Date</div>
                  </div>
                  <div style={styles?.td}>
                    <div>{moment(invoice?.createdAt).format("DD-MM-yyyy")}</div>
                  </div>
                </div>
                <div style={{ ...styles?.tr, backgroundColor: "#efefef" }}>
                  <div style={styles?.th}>
                    <div>Invoice Id #</div>
                  </div>
                  <div style={styles?.td}>
                    <div>{invoice?.invoiceId}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={styles.middleArea}>
            <div>
              <div style={styles.userText}>Details:</div>
            </div>
          </div>

          <div>
            <div style={styles.productList}>
              <div style={{ ...styles.producDetails, color: "black" }}>
                <div style={{...styles.producName,width:190}}>Order Info</div>
                <div style={{ ...styles.producText, fontWeight: "bold"}}>
                  Invoice Type
                </div>
                <div style={{ ...styles.producText, fontWeight: "bold" }}>
                  MOI
                </div>
                <div style={{ ...styles.producText, fontWeight: "bold" }}>
                  Collected Amount
                </div>
                <div style={{ ...styles.producText, fontWeight: "bold" }}>
                  COD Fee
                </div>
                <div style={{ ...styles.producText, fontWeight: "bold" }}>
                  Delivery Charge
                </div>
                <div style={{ ...styles.producText, fontWeight: "bold" }}>
                  Total Charge
                </div>
                <div style={{ ...styles.producText, fontWeight: "bold" }}>
                  Payout
                </div>
              </div>
            </div>

            {orderList?.map((order, index) => (
              <div key={index} style={styles.productList}>
                <div style={{ ...styles.producDetails, color: "gray" }}>
                  <div style={{width:190,wordBreak:"break-all"}}>
                    {/* <div style={styles.producName}>Invoice #</div> */}
                    <div style={styles.producName}>{order?.orderId}</div>
                    <div style={styles.producName}>
                      {order?.parcelOrder?.recipientName}
                    </div>
                    <div style={styles.producName}>
                      {order?.parcelOrder?.recipientAddress}
                    </div>
                  </div>
                  <div style={styles.producText}>
                    {order?.parcelOrder?.deliveryType}
                  </div>
                  <div style={styles.producText}>
                    {order?.parcelOrder?.merchantOrderId || "-"}
                  </div>{" "}
                  <div style={styles.producText}>
                    {order?.parcelOrder?.amountToCollect || 0}
                  </div>{" "}
                  <div style={styles.producText}>
                    {order?.parcelOrder?.codCharge || 0}
                  </div>{" "}
                  <div style={styles.producText}>
                    {order?.parcelOrder?.deliveryCharge || 0}
                  </div>{" "}
                  <div style={styles.producText}>
                    {order?.parcelOrder?.codCharge +
                      order?.parcelOrder?.deliveryCharge || 0}
                  </div>{" "}
                  <div style={styles.producText}>
                    {order?.parcelOrder?.amountToCollect -
                      (order?.parcelOrder?.codCharge +
                        order?.parcelOrder?.deliveryCharge) || 0}
                  </div>
                </div>
              </div>
            ))}
            <div style={styles.productHeader}>
              <div style={styles.producName}>Total</div>
              <div style={styles.productHeaderText}></div>
              <div style={styles.productHeaderText}></div>
              <div style={styles.productHeaderText}>
                {total.amountToCollect}
              </div>
              <div style={styles.productHeaderText}>{total.cod}</div>
              <div style={styles.productHeaderText}>{total.deliveryCharge}</div>
              <div style={styles.productHeaderText}>{total.totalCharge}</div>
              <div style={styles.productHeaderText}>{total.payout}</div>
            </div>
          </div>

          <div style={styles.footerArea}>
            <div>
              <div style={styles.footerText}>
                *Terms and Conditions: Payment should be made within 48 hours by
                bank or mobile-banking.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantInvoice;

const Email = () => (
  <svg width="25px" height="12px" viewBox="0 0 80 80">
    <path
      fill="#e83330"
      d="M58.0034485,8H5.9965506c-3.3136795,0-5.9999995,2.6862001-5.9999995,6v36c0,3.3137016,2.6863203,6,5.9999995,6  h52.006897c3.3137016,0,6-2.6862984,6-6V14C64.0034485,10.6862001,61.3171501,8,58.0034485,8z M62.0034485,49.1108017  L43.084549,30.1919994l18.9188995-12.0555992V49.1108017z M5.9965506,10h52.006897c2.2056007,0,4,1.7943001,4,4v1.7664003  L34.4677505,33.3134003c-1.4902,0.9492989-3.3935013,0.9199982-4.8495998-0.0703011L1.9965508,14.4694996V14  C1.9965508,11.7943001,3.7910507,10,5.9965506,10z M1.9965508,16.8852005L21.182251,29.9251003L1.9965508,49.1108017V16.8852005z   M58.0034485,54H5.9965506c-1.6473999,0-3.0638998-1.0021019-3.6760998-2.4278984l20.5199013-20.5200024l5.6547985,3.843401  c1.0859013,0.7383003,2.3418007,1.1083984,3.5995998,1.1083984c1.1953011,0,2.3925018-0.3339996,3.4463005-1.0048981  l5.8423996-3.7230015l20.2961006,20.2961025C61.0673485,52.9978981,59.6508713,54,58.0034485,54z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

const Call = () => (
  <svg width="25px" height="12px" viewBox="0 0 28 24">
    <rect data-name="Rectangle 4" width="24" height="24" fill="none" />
    <path
      d="M7.02,15.976,5.746,13.381a.7.7,0,0,0-.579-.407l-1.032-.056a.662.662,0,0,1-.579-.437,9.327,9.327,0,0,1,0-6.5.662.662,0,0,1,.579-.437l1.032-.109a.7.7,0,0,0,.589-.394L7.03,2.446l.331-.662a.708.708,0,0,0,.07-.308.692.692,0,0,0-.179-.467A3,3,0,0,0,4.693.017l-.235.03L4.336.063A1.556,1.556,0,0,0,4.17.089l-.162.04C1.857.679.165,4.207,0,8.585V9.83c.165,4.372,1.857,7.9,4,8.483l.162.04a1.556,1.556,0,0,0,.165.026l.122.017.235.03a3,3,0,0,0,2.558-.993.692.692,0,0,0,.179-.467.708.708,0,0,0-.07-.308Z"
      transform="translate(3.887 6.093) rotate(-30)"
      fill="none"
      stroke="#e83330"
      strokeMiterlimit="10"
      strokeWidth="1.5"
    />
  </svg>
);

const Web = () => (
  <svg
    style={{ fontWeight: 100 }}
    width="25px"
    height="10px"
    viewBox="0 0 28 24"
  >
    <path
      d="M3.51211712,15 L8.17190229,15 C8.05949197,14.0523506 8,13.0444554 8,12 C8,10.9555446 8.05949197,9.94764942 8.17190229,9 L3.51211712,9 C3.18046266,9.93833678 3,10.9480937 3,12 C3,13.0519063 3.18046266,14.0616632 3.51211712,15 L3.51211712,15 Z M3.93551965,16 C5.12590433,18.3953444 7.35207678,20.1851177 10.0280093,20.783292 C9.24889451,19.7227751 8.65216136,18.0371362 8.31375067,16 L3.93551965,16 L3.93551965,16 Z M20.4878829,15 C20.8195373,14.0616632 21,13.0519063 21,12 C21,10.9480937 20.8195373,9.93833678 20.4878829,9 L15.8280977,9 C15.940508,9.94764942 16,10.9555446 16,12 C16,13.0444554 15.940508,14.0523506 15.8280977,15 L20.4878829,15 L20.4878829,15 Z M20.0644804,16 L15.6862493,16 C15.3478386,18.0371362 14.7511055,19.7227751 13.9719907,20.783292 C16.6479232,20.1851177 18.8740957,18.3953444 20.0644804,16 L20.0644804,16 Z M9.18440269,15 L14.8155973,15 C14.9340177,14.0623882 15,13.0528256 15,12 C15,10.9471744 14.9340177,9.93761183 14.8155973,9 L9.18440269,9 C9.06598229,9.93761183 9,10.9471744 9,12 C9,13.0528256 9.06598229,14.0623882 9.18440269,15 L9.18440269,15 Z M9.3349823,16 C9.85717082,18.9678295 10.9180729,21 12,21 C13.0819271,21 14.1428292,18.9678295 14.6650177,16 L9.3349823,16 L9.3349823,16 Z M3.93551965,8 L8.31375067,8 C8.65216136,5.96286383 9.24889451,4.27722486 10.0280093,3.21670804 C7.35207678,3.81488234 5.12590433,5.60465556 3.93551965,8 L3.93551965,8 Z M20.0644804,8 C18.8740957,5.60465556 16.6479232,3.81488234 13.9719907,3.21670804 C14.7511055,4.27722486 15.3478386,5.96286383 15.6862493,8 L20.0644804,8 L20.0644804,8 Z M9.3349823,8 L14.6650177,8 C14.1428292,5.03217048 13.0819271,3 12,3 C10.9180729,3 9.85717082,5.03217048 9.3349823,8 L9.3349823,8 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z"
      fill="none"
      stroke="#e83330"
      strokeMiterlimit="10"
      strokeWidth="1.5"
    />
  </svg>
);
