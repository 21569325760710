export const getBoolValue = (data) => {
  const form = data?.toLowerCase();
  if (form === "true") {
    return true;
  }
  return false;
};

export const isBool = (data) => {
  if (typeof data === "boolean") {
    return true;
  } else {
    const form = data?.toLowerCase();
    if (form === "true" || form === "false") {
      return true;
    }
  }
  return false;
};

export const getData = (data) => {
  const form = (data as string)?.trim();
  return form
    ? isNaN(Number(form))
      ? isBool(form)
        ? getBoolValue(form)
        : form
      : Number(form)
    : undefined;
};

export const replaceNewlinesForCSV = (obj) =>{
  if (typeof obj === 'string') {
    return obj.replace(/\n/g, '');
  } else if (Array.isArray(obj)) {
    return obj.map(item => replaceNewlinesForCSV(item));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj = {};
    for (const key in obj) {
      newObj[key] = replaceNewlinesForCSV(obj[key]);
    }
    return newObj;
  } else {
    return obj;
  }
}
