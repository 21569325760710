import {
  AutoComplete,
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Rate,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import dot from "../../images/dot.png";
import { authenticateToken } from "../../utils/auth";
import { getPage, getParamValue } from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import { debounce } from "lodash";
import Currency from "../common/Currency";
import TopHeading from "./TopHeading";
import moment from "moment";

import { responseNotification } from "../../utils/notify";

const { Option } = Select;
const { RangePicker }: any = DatePicker;
const RunSheetList = () => {
  const [status, setStatus] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [limit, setLimit] = useState(16);
  const location = useLocation();
  

  const [parcelRunSheetData, setParcelRunSheetData] = useState({
    loading: false,
    data: null,
  });
  const [editDrawer, setEditDrawer] = useState();
  const [range, setRange] = useState({
    from: getParamValue(location.search, "from")
      ? getParamValue(location.search, "from")
      : null,
    to: getParamValue(location.search, "to")
      ? getParamValue(location.search, "to")
      : null,
  });

  const fetchRef = useRef(null);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");

  const reseAllFieldData = () => {
    setStatus("");
    setMobileNumber("");
    setCompanyName("");
    setEmail("");
    setLimit(16);
    form.resetFields();
  };

  const getRunSheetData = useCallback(async () => {
    setParcelRunSheetData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/courier/parcel-run-sheet?` +
          `page=${page || 0}` +
          `&limit=${limit || 16}` +
          (status ? `&status=${status}` : ``) +
          (range?.from ? `&from=${moment(range?.from).toISOString()}` : ``) +
          (range?.to ? `&to=${moment(range?.to).toISOString()}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setParcelRunSheetData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setParcelRunSheetData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [page, limit, range, status]);

  useEffect(() => {
    getRunSheetData();
  }, [getRunSheetData]);

  

  // const handleCancel = (e) => {
  //   e.preventDefault();
  //   setRunsheetModalOpen(false)
  //   setSortingId('')
  // };

  // const openRunSheet = (id) => {
  //   setSortingId(id)
  //   setRunsheetModalOpen(true)
  // };

  // const createRunSheet = (data)=>{
  //   console.log(data)
  //   const request = {
  //     "assignedName": data.assignedName,
  //     "attachment": data.attachment,
  //     "cnNumber": data.cnNumber,
  //     "courierAssignment": data.courierAssignment,
  //     "hour": data.hour,
  //     "orderSortingId": sortingId,
  //     "paymentAmount": data.paymentAmount,
  //     "paymentStatus": data.paymentStatus,
  //     "phoneNumber": data.phoneNumber,
  //     "remarks": data.remarks,
  //     "sackNumber": data.sackNumber
  //   }

    
  //   axios.post(`${process.env.REACT_APP_ORDER_API}/courier/create-parcel-run-sheet`,request,
  //     {
  //         headers: {
  //             Authorization: `Bearer ${authenticateToken()}`,
  //         },
  //     }
  //     ).then((res) => {
  //       // console.log(res)
  //       setLoading(false)
  //       if(res?.status === 200){
  //           responseNotification('Order Runsheet created','success')
  //           getRunSheetData();
  //       }else{
  //           responseNotification(res?.data?.message,'error')
  //       }
  //     })
  //     .catch((err) => {
  //         console.error("Order Sorting: Error", err);
  //     });
  // }


  return (
    <React.Fragment>
      <TopHeading total={parcelRunSheetData?.data?.totalElements} refetch={getRunSheetData} />
      <section className="search_area">
        <Form form={form} className="search_form" style={{overflow:"visible"}}>

        <Form.Item name="status" style={{ minWidth: "30%" }}>
            <Select 
              options={[
                { value: null, label: "All" },
                { value: 'OPEN', label: "OPEN" },
                { value: 'CLOSE', label: "CLOSE" },
              ]}
              value={status}
              onChange={(value) => setStatus(value)}
            >
            </Select>
          </Form.Item>
              &nbsp;&nbsp;&nbsp;
          <Form.Item name="range" className="mr-2">
            <RangePicker
              use12Hours
              style={{ minWidth: 100, width: "calc(100% - 50px)" }}
              defaultValue={
                range.from != null && range.to != null
                  ? [moment(range.from), moment(range.to)]
                  : null
              }
              onChange={(_, f) => {
                console.log("moment", moment.utc(f[0])?.toISOString());
                setRange({ from: f?.[0], to: f?.[1] });
              }}
              // showTime
            />
          </Form.Item>
        </Form>
              &nbsp;
        <div className="search_btn" style={{marginLeft:"30px"}}>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      </section>

      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  <div className="single-rider-heading">
                    <span>Courier Name</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Sack Number</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Assigned Name</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Assigned Number</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Runsheet Created At</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Runsheet Created By</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Total Order</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Sum Amount</span>
                  </div>
                  
                  <div className="single-rider-heading" style={{float:'right'}}>
                    <span>Status</span>
                  </div>
                  {/* <div className="single-rider-heading">
                    <span>Action</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {parcelRunSheetData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div className="rider-table-area">
                  {parcelRunSheetData?.data?.parcelRunSheet?.length ? (
                    parcelRunSheetData?.data?.parcelRunSheet?.map((runSheet, index) => (
                      <React.Fragment key={index}>
                        <Link to={`/courier-management/parcel-runsheet-details?sortingOrderId=${runSheet.orderSortingId}`}>
                          <div className="single-rider-wrapper">
                            <div className="single-rider">
                              <span className="name">{runSheet?.courierAssignment}</span>
                            </div>

                            <div className="single-rider">
                              <span className="name">{runSheet?.sackNumber}</span>
                            </div>
                            <div className="single-rider">
                              <span className="name">{runSheet?.assignedName}</span>
                            </div>
                            <div className="single-rider">
                              <span className="name">{runSheet?.phoneNumber}</span>
                            </div>
                            <div className="single-rider">
                              <span className="name">
                                {moment(runSheet?.createdAt).format(
                                  "DD-MM-YY HH:MMa"
                                )}
                              </span>
                            </div>
                            <div className="single-rider">
                              <span className="name">
                                {runSheet?.createdBy?.name}
                              </span>
                            </div>
                            <div className="single-rider">
                              <span className="name">
                                {runSheet.total}
                              </span>
                            </div>
                            <div className="single-rider">
                              <span className="name">
                                {runSheet.paymentAmount}
                              </span>
                            </div>
                            
                            <div className="single-rider">
                              <span className={
                                runSheet?.status === "OPEN"
                                    ? `active-btn radius-btn ${runSheet?.status}`
                                    : `inactive-btn radius-btn ${runSheet?.status}`}>
                                {runSheet?.status}
                              </span>
                            </div>

                            {/* <div className="single-rider">
                              <Button type="primary" danger size="middle" onClick={()=>{
                                  openRunSheet(runSheet?.id)
                                }}>
                              Create RunSheet
                              </Button>
                            </div> */}
                          </div>
                        </Link>
                      </React.Fragment>
                    ))
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        width: "100%",
                        padding: "40px 0",
                        color: "red",
                      }}
                    >
                      No Sorting Data Found
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>


          <PaginationTwo
            {...parcelRunSheetData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default RunSheetList;
