import { ReactElement } from 'react'
import Layout from "../../components/Layout";
import MerchantDetails from '../../components/Merchants/Details'

const MerchantDetailsPage = (): ReactElement => {
  return (
    <Layout >
      <MerchantDetails />
    </Layout>
  )
}

export default MerchantDetailsPage
