import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ShopLayout from "../Layout";
import ShopHeader from "../Layout/topHeader";
import OrderList from "../../Orders";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { Button } from "antd";

const ShopOrderList = ({}: any) => {
  const route = useParams();
  const [activeSearch, setActiveSearch] = useState(false);

  return (
    <React.Fragment>
      <ShopLayout shopId={(route as any)?.merchantId as string}>
        <div className="product-list-item-area white-bg">
          <ShopHeader
            leftarea={"Order List"}
            rightarea={
              <>
                {activeSearch ? (
                  <Button
                    type="text"
                    danger
                    onClick={() => {
                      setActiveSearch(false);
                    }}
                    className="mr-2"
                  >
                    <ZoomOutOutlined />
                  </Button>
                ) : (
                  <Button
                    type="text"
                    onClick={() => {
                      setActiveSearch(true);
                    }}
                    className="mr-2"
                  >
                    <ZoomInOutlined />
                  </Button>
                )}
              </>
            }
          />
          <OrderList inShop={true} activeSearch={activeSearch} />
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

export default ShopOrderList;
