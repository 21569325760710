import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";

const HubForm = ({
  hub,
  onCloseMethod,
}: {
  hub?: any;
  onCloseMethod: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  //   const [hub, setHub] = useState<any>();
  const [form] = Form.useForm();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const url = new URL(
        `${process.env.REACT_PUBLIC_CATALOG_WRITER_API ?? "https://staging-catalog-writer.qcoom.com/api/v1"}${
          hub ? `/pi-hub/${hub.id}` : "/pi-hub"
        }`
      );

      const res = await fetch(url, {
        method: hub ? "PUT" : "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data }),
      });

      if(!res.ok) throw new Error("Form submitting failed");

      const result = await res.json();

      if(result.statusCode !== 200) throw new Error(result.message);
      responseNotification(result.message, "success");

      onCloseMethod();

    } catch (error) {
      responseNotification((error as Error).message, "error");
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (hub) {
      form.resetFields();
    }
  }, [hub]);

  return (
    <div className="add-rider drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          initialValues={{ ...hub }}
          form={form} // like ref
          layout="vertical"
          // requiredMark="optional"
          autoComplete="off"
        >
          <Form.Item
            hasFeedback
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is Required!",
              },
            ]}
            name="name"
            style={{ width: "100%" }}
          >
            <Input id="name" type="text" placeholder="Enter Name" />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Address"
            rules={[
              {
                required: true,
                message: "Address is Required!",
              },
            ]}
            name="address"
            style={{ width: "100%" }}
          >
            <Input id="address" type="text" placeholder="Enter Address" />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Contact Number"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Contact Number is required!",
              },
              {
                validator: async (_, names) => {
                  if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                    return Promise.reject(
                      new Error("Enter valid contact number")
                    );
                  }
                },
              },
            ]}
            name="contractNumber"
          >
            <Input
              id="contractNumber"
              type="text"
              addonBefore={"+88"}
              placeholder="Enter Contact Number"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Email Address is Required!",
              },
            ]}
            name="email"
            style={{ width: "100%" }}
          >
            <Input id="name" type="email" placeholder="Enter Email Address" />
          </Form.Item>

          <div className="buttons-container">
            <Button
              disabled={loading}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={() => form?.resetFields()}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default HubForm;
