import { EditOutlined, FileTextOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Rate,
  Row,
  Select,
  Skeleton,
  Image,
  Form,
} from "antd";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation, useParams } from "react-router-dom";
import Layout from "../../Layout/index";
import { authenticateToken } from "../../../utils/auth";
import { getImgUrl, riderStatusArray } from "../../../utils/index";
import Currency from "../../common/Currency";
import moment from "moment";
import TopHeading from "./TopHeading";
import { responseNotification } from "../../../utils/notify";

const { Option } = Select;
const { Search } = Input;

const SingleRider = (): ReactElement => {
  const [form] = Form.useForm();
  const [update, setUpdate] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(16);
  const [tripDetails, setTripDetails] = useState(undefined);
  const [getTripAddress, setTripAddress] = useState(undefined);
  const route = useParams();
  const tripId = (route as any).tripId;

  // const loc = useLocation();

  // const handleChangeStatus = (value) => {
  //   setStatus(value);
  // };

  const fetchTripDetails = useCallback(() => {
    if (tripId) {
      fetch(`${process.env.REACT_APP_USER_API}/admin/driver/${tripId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setTripDetails(data?.driver);
        })
        .catch((err) => console.log(err));
    }
  }, [tripId]);

  // console.log("Status:", status);

  const onStatusChange = async (status) => {
    setLoading(true);

    await fetch(`${process.env.REACT_APP_USER_API}/driver/status`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        driverId: tripDetails?.driverId,
        note: "",
        status,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          fetchTripDetails();
          responseNotification("Status Updated Successfully", "success");
          form.resetFields();
          // if (onCloseMethod) {
          //   onCloseMethod();
          // }
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  // useEffect(() => {
  //   if (status) {
  //     onStatusChange(status);
  //   }
  // }, [status, onStatusChange]);

  useEffect(() => {
    fetchTripDetails();
  }, [fetchTripDetails]);

  /*======================================
 fetch Trip transcation history  api call
  =======================================*/
  // const getSingleTripOptions = async () => {
  //   setSingleTripTranscation({ loading: true, data: null })
  //   const encodedUri = `${process.env.REACT_APP_ORDER_PAYMENT}`
  //   const res = await axios.get(
  //     `${encodedUri}/payment/history/all?page=${
  //       getPage(loc.search) || 0
  //     }&limit=${limit || 16}&TripId=${TripId}` +
  //       (getOrderId ? `&orderId=${getOrderId}` : ``) +
  //       (getStatus ? `&status=${getStatus}` : ``) +
  //       (getChangePayment ? `&paymentMethod=${getChangePayment}` : ``),
  //     {
  //       headers: {
  //         Authorization: `Bearer ${authenticateToken()}`,
  //       },
  //     }
  //   )
  //   setSingleTripTranscation({ loading: false, data: res?.data })
  // }

  // const reseAllFieldData = () => {
  //   // window.location.reload()
  //   window.location.href = `${process.env.PUBLIC_URL}/Trips/${TripId}`;
  // };

  return (
    // <Layout>
    <React.Fragment>
      <TopHeading
        left={"Rider"}
        right={
          <>
            {/* <Dropdown.Button
              type="dashed"
              overlay={
                <Menu>
                  {Object.values(riderStatusArray).map((status, i) => (
                    <Menu.Item key={i} onClick={() => onStatusChange(status)}>
                      {status?.split("_").join(" ")}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              {tripDetails?.status || "Status"}
            </Dropdown.Button> */}

            {/* <Button
              type="default"
              onClick={() => setUpdate(true)}
              className="ml-2"
            >
              <EditOutlined />
              Edit
            </Button> */}
          </>
        }
      />
      <div className="rider-information-area">
        {tripDetails?.loading ? (
          <>
            <Skeleton avatar active paragraph={{ rows: 20 }} />
          </>
        ) : (
          <>
            <Row gutter={24}>
              <Col
                span={24}
                flex="column"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* <Select
            labelInValue
            defaultValue={tripDetails?.status}
            style={{ width: 180 }}
            onChange={(val) => setStatus(val)}
          >
            {Object.values(riderStatuses)?.map((status, i) => (
              <Select.Option value={status} key={i}>{status}</Select.Option>
            ))}
          </Select> */}
              </Col>
            </Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="basic">
              <Row gutter={16} className="bg-white py-4">
                <Col span={8}>
                  <div className="rider-details-wrapper-content">
                    <div className="show-details-inner-content">
                      <div className="rider-image-content">
                        <div className="rider-banner">
                          <div className="rider-logo">
                            <Avatar
                              size={200}
                              src={
                                <Image
                                  src={getImgUrl(
                                    tripDetails?.profilePictureUrl
                                  )}
                                />
                              }
                            />
                          </div>
                          <div className="rider-image-content-body">
                            <div className="body">
                              <h4 className="d-flex d-flex-dir-col">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                   
                                  <path
                                    d="M13.75 6.92492V7.49992C13.7492 8.84768 13.3128 10.1591 12.5058 11.2386C11.6989 12.318 10.5646 13.1077 9.2721 13.4899C7.97964 13.872 6.59829 13.8261 5.33404 13.359C4.0698 12.892 2.99041 12.0287 2.25685 10.8981C1.52329 9.76744 1.17487 8.42996 1.26355 7.08511C1.35223 5.74027 1.87325 4.46012 2.74892 3.43559C3.6246 2.41105 4.80799 1.69703 6.12262 1.40001C7.43725 1.10298 8.81267 1.23888 10.0438 1.78742"
                                    stroke="#34A852"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                   
                                  <path
                                    d="M13.75 2.5L7.5 8.75625L5.625 6.88125"
                                    stroke="#34A852"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                   
                                </svg>

                                <span>{tripDetails?.name}</span>
                              </h4>
                              <p>{tripDetails?.mobileNumber?.split("+88")}</p>

                              <div className="single-rider">
                                <Rate
                                  disabled
                                  defaultValue={tripDetails?.rating || 1}
                                  className="mr-2"
                                />
                                <small style={{ color: "#777" }}>
                                  ({tripDetails?.numberOfRating})
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rider-information-tab-menu">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="basic" className="d-flex-l">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                               
                              <path
                                d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M6 11.25H12"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M6.75 6.75H6.7575"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M11.25 6.75H11.2575"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                            </svg>
                             Basic Information
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="bank" className="d-flex-l">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                               
                              <path
                                d="M14.25 8.25H3.75C2.92157 8.25 2.25 8.92157 2.25 9.75V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V9.75C15.75 8.92157 15.0784 8.25 14.25 8.25Z"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M5.25 8.25V5.25C5.25 4.25544 5.64509 3.30161 6.34835 2.59835C7.05161 1.89509 8.00544 1.5 9 1.5C9.99456 1.5 10.9484 1.89509 11.6517 2.59835C12.3549 3.30161 12.75 4.25544 12.75 5.25V8.25"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                            </svg>
                            Vehicle Inforamation
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="sellerinfo" className="d-flex-l">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                               
                              <path
                                d="M4.5 1.5L2.25 4.5V15C2.25 15.3978 2.40804 15.7794 2.68934 16.0607C2.97064 16.342 3.35218 16.5 3.75 16.5H14.25C14.6478 16.5 15.0294 16.342 15.3107 16.0607C15.592 15.7794 15.75 15.3978 15.75 15V4.5L13.5 1.5H4.5Z"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M2.25 4.5H15.75"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                              <path
                                d="M12 7.5C12 8.29565 11.6839 9.05871 11.1213 9.62132C10.5587 10.1839 9.79565 10.5 9 10.5C8.20435 10.5 7.44129 10.1839 6.87868 9.62132C6.31607 9.05871 6 8.29565 6 7.5"
                                stroke="#737373"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                               
                            </svg>
                            License Information
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                </Col>
                <Col span={16}>
                  <div className="rider-information-show-tab-wrapper">
                    <div className="inner-content">
                      <div className="single-content">
                        <Tab.Content>
                          <Tab.Pane eventKey="basic">
                            <div className="single-information-rider-wrapper">
                              <div className="single-information-rider-content">
                                <div className="info-icon">
                                  <FileTextOutlined />
                                </div>
                                <div className="info-body">
                                  <h3 className="title">BASIC INFORMATION</h3>
                                  <div className="inner-body">
                                    <div className="single">
                                      <span className="info-name">
                                        Rider Name
                                      </span>
                                      <span className="info-desc">
                                        {tripDetails?.name}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Mobile Number
                                      </span>
                                      <span className="info-desc">
                                        {tripDetails?.mobileNumber?.split(
                                          "+88"
                                        )}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">City</span>
                                      <span className="info-desc">
                                        {tripDetails?.city}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Joined</span>
                                      <span className="info-desc">
                                        {moment(tripDetails?.createdAt).format(
                                          "LLL"
                                        )}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Driver Id
                                      </span>
                                      <span className="info-desc">
                                        {tripDetails?.driverId}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Personal Info Status
                                      </span>
                                      <span className="info-desc">
                                        {tripDetails?.personalInfoStatus}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Vehicle Type
                                      </span>
                                      <span className="info-desc">
                                        {tripDetails?.vehicleType}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Status</span>
                                      <span className="info-desc">
                                        {" "}
                                        <span
                                          className={
                                            tripDetails?.status === "UNVERIFIED"
                                              ? "inactive-btn radius-btn"
                                              : tripDetails?.status ===
                                                "VERIFIED"
                                              ? "active-btn radius-btn"
                                              : "pending-btn radius-btn"
                                          }
                                        >
                                          {tripDetails?.status}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="inner-body">
                                    <h4 className="title">NID</h4>
                                    <div className="single">
                                      <span className="info-name">
                                        NID Number
                                      </span>
                                      <span className="info-desc">
                                        {tripDetails?.nid?.nidNumber}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Capture</span>
                                      <span className="info-desc">
                                        <Image
                                          src={getImgUrl(
                                            tripDetails?.nid?.frontUrl
                                          )}
                                          alt="nid"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="bank">
                            <div className="single-information-rider-wrapper">
                              <div className="single-information-rider-content">
                                <div className="info-icon">
                                  <FileTextOutlined />
                                </div>
                                <div className="info-body">
                                  <h3 className="title">VEHICLE INFORMATION</h3>
                                  <div className="inner-body">
                                    <div className="single">
                                      <span className="info-name">
                                        Vehicle Model
                                      </span>
                                      <span className="info-desc">
                                        {tripDetails?.car?.model}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Vehicle Registration Number
                                      </span>
                                      <span className="info-desc">
                                        {
                                          tripDetails?.car
                                            ?.carRegistrationNumber
                                        }
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Registered
                                      </span>
                                      <span className="info-desc">
                                        {moment(
                                          tripDetails?.car?.createdAt
                                        ).format("LLL")}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">City</span>
                                      <span className="info-desc">
                                        {tripDetails?.car?.city}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">
                                        Manufacturer
                                      </span>
                                      <span className="info-desc">
                                        {tripDetails?.car?.manufacturer}
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Status</span>
                                      <span className="info-desc">
                                        <span
                                          className={
                                            tripDetails?.car?.status ===
                                            "UNVERIFIED"
                                              ? "inactive-btn radius-btn"
                                              : tripDetails?.car?.status ===
                                                "VERIFIED"
                                              ? "active-btn radius-btn"
                                              : "pending-btn radius-btn"
                                          }
                                        >
                                          {tripDetails?.car?.status}
                                        </span>
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Year</span>
                                      <span className="info-desc">
                                        {tripDetails?.car?.year}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="sellerinfo">
                            <div className="single-information-rider-wrapper">
                              <div className="single-information-rider-content">
                                <div className="info-icon">
                                  <FileTextOutlined />
                                </div>
                                <div className="info-body">
                                  <h3 className="title">DRIVING LICENSE</h3>
                                  <div className="inner-body">
                                    <div className="single">
                                      <span className="info-name">
                                        Driving License Number
                                      </span>
                                      <span className="info-desc">
                                        {
                                          tripDetails?.drivingLicense
                                            ?.drivingLicenseNumber
                                        }
                                      </span>
                                    </div>
                                    <div className="single">
                                      <span className="info-name">Capture</span>
                                      <span className="info-desc">
                                        <Image
                                          src={getImgUrl(
                                            tripDetails?.drivingLicense
                                              ?.frontUrl
                                          )}
                                          alt="drivingLicense"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="inner-body">
                                    <h4 className="title">
                                      Registration Paper
                                    </h4>
                                    <div className="single">
                                      <span className="info-name">Capture</span>
                                      <span className="info-desc">
                                        <Image
                                          src={getImgUrl(
                                            tripDetails?.registrationPaper
                                              ?.frontUrl
                                          )}
                                          alt="registrationPaper"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
        {/* <Modal
        size="lg"
        className="update-campaign-modal edit-product-modal-area"
        // show={!!singleProduct}
        show={!!setSingleRiderInfo && update}
        onHide={onEditModalClose}
        backdrop="static"
        keyboard={false}
        animation
        width={1200}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Rider
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRider
            singleRiderData={singleRiderInfo?.data}
            fetchRiderDetails={() => fetchRiderDetails(selectedRiderId)}
          />
        </Modal.Body>
      </Modal> */}
      </div>
    </React.Fragment>
    // </Layout>
  );
};
SingleRider.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default SingleRider;
