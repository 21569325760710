import { Layout } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { withRouter } from "react-router-dom";
import TopHeader from "./Header/topHeader";
import Sidebar from "./Sidebar/index";
const { Content } = Layout;

const WholeLayout = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <Sidebar />
        <Layout>
          <TopHeader />
          <Content style={{ padding: "15px" }} className="main-content-layout">
            {children}
          </Content>
        </Layout>
      </Layout>
    </React.Fragment>
  );
};

WholeLayout.propType = {
  children: PropTypes.element,
};

export default withRouter(WholeLayout as any);
