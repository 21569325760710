import { authenticateToken } from "../utils/auth";

const auth_url = process.env.REACT_APP_AUTH_API;
const user_url = process.env.REACT_APP_USER_API;
const order_url = process.env.REACT_APP_ORDER_API;
const writer_url = process.env.REACT_APP_CATALOG_WRITER_API;
const reader_url = process.env.REACT_APP_CATALOG_READER_API;

const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authenticateToken()}`,
};


export const loadHubList = async()=> {
    try {
    const path = reader_url + `/pi-hubs`;
    const response = await fetch(path, {
        headers: {
        ...headers,
        },
        method: 'GET',
    });
    if (!response.ok) return null;
    const result = await response.json();
    return result;
    } catch (err) {
    console.log(err);
    return null;
    }
};