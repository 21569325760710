import { ReactElement } from "react";
import Layout from "../../components/Layout";
import ReportList from "../../components/Reports";

const ReportPage = (): ReactElement => {
  return (
    <Layout>
      <ReportList />
    </Layout>
  );
};

export default ReportPage;
