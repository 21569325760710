import React, { ReactElement } from "react";
import "bootstrap/scss/bootstrap.scss";
import "../src/styles/main.scss";
import "antd/dist/antd.css";
import Routes from "./routes/routes";
import { useQuery } from "@tanstack/react-query";
import { redirect } from "./utils";
import { authenticateToken } from "./utils/auth";
import "./styles/css/loader.min.css";
import { useLocation, withRouter } from "react-router";

async function verifyToken(type) {
  try {
    const res = await fetch(
      new URL(
        `${process.env.REACT_APP_AUTH_API}/admin/token/verify?type=${type}`
      ),
      {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    );
    if (!res.ok) throw new Error(res.statusText);

    const output = await res.json();

    return output;
  } catch (error) {
    return null;
  }
}

const App = (): ReactElement => {
  const type = "Q_PARCEL";
  const location = useLocation();
  const pathname = location.pathname;
  const query = useQuery({
    queryKey: ["verify_token", pathname],
    queryFn: () => verifyToken(type),
  });

  if (query.isLoading && pathname !== "/login") {
    return <LoadingSpinner />;
  }

  if (
    !query.isLoading &&
    query.data === null &&
    pathname !== "/login" &&
    pathname !== "/forget-password-verify" &&
    pathname !== "/forgotpassword"
  ) {
    localStorage.clear();
    redirect("/login");
  }

  //   console.log("pathname", location, pathname);
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
};

export default withRouter(App);

function LoadingSpinner() {
  return (
    <div className="min-h-screen w-full h-full flex items-center justify-center">
      <svg
        className="animate-spin h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
}
