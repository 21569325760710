import { Button, Drawer, Modal, Popconfirm, Table, Typography } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { authenticateToken } from "../../utils/auth";
import AddBtn from "../common/AddBtn";
import { responseNotification } from "../../utils/notify";
import { ColumnsType } from "antd/lib/table";

const TopHeading = ({ mobileNumber,companyName, total, orders, checkedList,refetch }): ReactElement => {
  const [visible, setVisible] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [merchantData, setMerchantData] = useState<any>();

  const amountData = useMemo(() => {
    const total = orders.reduce(
      (obj, order) => {
        const data = order?.parcelOrder;
        obj.amountToCollect += data?.amountToCollect;
        obj.cod += data.codCharge;
        obj.deliveryCharge +=
          data?.deliveryCharge;
        obj.totalCharge += data?.deliveryCharge+data?.codCharge;
        obj.payout += data?.amountToCollect - (data?.deliveryCharge+data?.codCharge);
        return obj;
      },
      {
        amountToCollect: 0,
        deliveryCharge: 0,
        cod: 0,
        totalCharge: 0,
        payout: 0,
      }
    );
    return total;
  }, [orders]);

  const getAllCheckList = useMemo(() => {
    console.log(checkedList);
    return checkedList;
  }, [checkedList]);

  const doSettle = useCallback(async () => {
    if (getAllCheckList && getAllCheckList.length == 0) {
      responseNotification(
        "Please select at least one order to settle",
        "error"
      );
      return;
    }
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    if (orders?.length) {
      if (amountData.payout) {
        axios
          .put(
            `${encodedUri}/admin/order/settle-parcel`,

            {
              orderIds: orders
                ?.map((order) => order?.orderId)
                .filter((item) => item),
            },
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
            responseNotification(res?.data?.message || "Settlement done");
            setVisible(false);
            setConfirm(false);
            refetch?.();
          })
          .catch((err) => {
            console.error("Categories: Error", err);
            responseNotification(err.message || "Something went wrong");
          });
      } else {
        responseNotification("Amount should be valid");
      }
    } else {
      responseNotification("No orders found for settlement");
    }
  }, [orders]);

  const getMerchantInfo = useCallback(async () => {
    const encodedUri = `${process.env.REACT_APP_USER_API}`;
    if (orders?.length) {
      axios
        .get(`${encodedUri}/business/${orders?.[0]?.customer?.id}`, {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        })
        .then((res) => {
          setMerchantData(res?.data?.business);
        })
        .catch((err) => {
          console.error("Categories: Error", err);
          responseNotification(
            err.message || "Something went wrong to fetch payment details"
          );
        });
    } else {
      responseNotification("No orders found for settlement");
    }
  }, [orders]);

  useEffect(() => {
    if (visible) getMerchantInfo();
  }, [getMerchantInfo, visible]);

  const onClose = () => {
    setConfirm(false);
    setTimeout(() => setVisible(false), 500);
  };

  const { Text } = Typography;

  interface DataType {
    key: string;
    name: string;
    borrow: number;
    repayment: number;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "OrderId",
      dataIndex: "orderId",
      fixed: true,
      width: 100,
    },
    {
      title: "Collected",
      dataIndex: "collected",
    },
    {
      title: "COD Fee",
      dataIndex: "codFee",
    },
    {
      title: "DeliveryCharge",
      dataIndex: "deliveryCharge",
    },
    {
      title: "Total Charge",
      dataIndex: "totalCharge",
    },
    {
      title: "Payout",
      dataIndex: "payout",
    },
  ];

  let data: DataType[] = orders?.map((order, i) => {
    const data = order?.parcelOrder;
    return {
      key: i + 1,
      orderId: order?.orderId,
      collected: data?.amountToCollect,
      codFee: data.codCharge,
      deliveryCharge:
        data?.deliveryCharge,
      totalCharge: data?.deliveryCharge+data?.codCharge,
      payout: data?.amountToCollect - (data?.deliveryCharge+data?.codCharge),
    };
  });

  const showDrawer = () => {
    setVisible(true);
  };
  return (
    <div className="top-heading-products top-heading-content">
      <div className="container-fluid">
        <div className="row pt-3 pb-3 d-flex-sb">
          <div className="col-lg-6 col-md-6 no-padding">
            <div className="page-heading-content">
              <h2 className="d-flex-l wrap d-flex-wrap d-flex-base">
                Settlement List{" "}
                <span className="d-flex-l ml-1">{total} Order(s)</span>
              </h2>
            </div>
          </div>
          <div className="single-button">
            <Button
              type="primary"
              onClick={showDrawer}
              disabled={!(orders && orders?.length && (mobileNumber || companyName))}
            >
              Settle
            </Button>
          </div>

          <Modal
            visible={visible}
            onOk={() => {
              setConfirm(true);
            }}
            okText={`Pay (${amountData?.payout})`}
            onCancel={onClose}
            cancelButtonProps={{ disabled: confirm }}
            okButtonProps={{ disabled: confirm }}
            width="900px"
          >
            <div className="mb-2">
              <h4 className="mb-0">
                <b> Payment Method </b>{" "}
                <b className="highlighted-txt" style={{ fontSize: 14 }}>
                  {merchantData?.mfsInfo
                    ? "(MFS)"
                    : merchantData?.bankInfo
                    ? "(Bank)"
                    : undefined}
                </b>
              </h4>
              {merchantData?.mfsInfo ? (
                <div>
                  <p className="mb-0 txt-sm">
                    <b className="gray-txt">Name: </b>
                    {merchantData?.mfsInfo?.mfsName}
                  </p>
                  <p className="mb-0 txt-sm">
                    <b className="gray-txt">Mobile Number: </b>
                    {merchantData?.mfsInfo?.mobileNumber}
                  </p>
                </div>
              ) : merchantData?.bankInfo ? (
                <div
                  className="pl-2 my-1"
                  style={{ borderLeft: "2px solid rgba(0, 0, 0, 1)" }}
                >
                  <div className="d-flex-l" style={{ flexWrap: "wrap" }}>
                    <p className="mb-0 txt-sm mr-3">
                      <b className="gray-txt">Account Name: </b>
                      {merchantData?.bankInfo?.accountName}
                    </p>
                    <p className="mb-0 txt-sm mr-3">
                      <b className="gray-txt">Account Number: </b>
                      {merchantData?.bankInfo?.accountNumber}
                    </p>
                  </div>
                  <div className="d-flex-l" style={{ flexWrap: "wrap" }}>
                    <p className="mb-0 txt-sm mr-3">
                      <b className="gray-txt">Bank Name: </b>
                      {merchantData?.bankInfo?.bankName}
                    </p>
                    <p className="mb-0 txt-sm mr-3">
                      <b className="gray-txt">Branch Name: </b>
                      {merchantData?.bankInfo?.branchName}
                    </p>
                    <p className="mb-0 txt-sm mr-3">
                      <b className="gray-txt">Routing Number: </b>
                      {merchantData?.bankInfo?.routingNumber}
                    </p>
                  </div>
                </div>
              ) : (
                <div>No payment method found!</div>
              )}
            </div>
            <>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 800, y: 800 }}
                bordered
                summary={() => {
                  const total = amountData;
                  console.log(data);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          <span style={{ fontWeight: "bold" }}>Total</span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <Text style={{ fontWeight: "bold" }}>
                            {total.amountToCollect}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Text style={{ fontWeight: "bold" }}>
                            {total.cod}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Text style={{ fontWeight: "bold" }}>
                            {total.deliveryCharge}
                          </Text>
                        </Table.Summary.Cell>{" "}
                        <Table.Summary.Cell index={4}>
                          <Text style={{ fontWeight: "bold" }}>
                            {total.totalCharge}
                          </Text>
                        </Table.Summary.Cell>{" "}
                        <Table.Summary.Cell index={5}>
                          <Text style={{ fontWeight: "bold" }}>
                            {total.payout}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>

                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2}>
                          <span style={{ fontWeight: "bold" }}>
                            Total Payable Amount
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={4}>
                          <Text type="danger" style={{ fontWeight: "bold" }}>
                            {total.payout}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </>

            <Popconfirm
              visible={confirm}
              onCancel={() => setConfirm(false)}
              title="Confirm?"
              onConfirm={() => {
                doSettle();
                setConfirm(false);
              }}
            >
              <div style={{ position: "absolute", right: 50 }}></div>
            </Popconfirm>
          </Modal>
        </div>
      </div>
    </div>
  );
};

TopHeading.propTypes = {
  UserId: PropTypes.string,
  GetStatus: PropTypes.string,
  GetPrime: PropTypes.string,
  GetFlagship: PropTypes.string,
  GetRocket: PropTypes.string,
  name: PropTypes.string,
};

export default TopHeading;
