import * as React from "react";
import * as ReactDOM from "react-dom";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
// import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
// import {
//     Chart,
//     ChartLegend,
//     ChartSeries,
//     ChartSeriesItem,
//     ChartSeriesLabels,
//     ChartCategoryAxis,
//     ChartCategoryAxisItem
// } from "@progress/kendo-react-charts";
import products from "./products.json";
import data from "./p.json";
// @ts-ignore
import logo from "../../images/logo-vertical.png";
import JsBarcode from "jsbarcode";
import moment from "moment";

import "../../styles/pages/pdf.css";
import "@progress/kendo-theme-default/dist/all.css";

// Create styles
const styles = {
  page: {
    height: 800,
    backgroundColor: "white",
    fontSize: 14,
    lineHeight: 1.4,
    color: "gray",
    breakAfter: 'page',
  },
  section: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  headerArea: {
    display: "flex",
    marginBottom: 15,
    width: "100%",
    flexDirection: "column",
  },
  headerLeft: {
    alignSelf: "center",
  },
  headerRight: {
    marginTop: 5,
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  logo: {
    width: 60,
    marginBottom: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
    margin: 0,
    lineHeight: 1,
    marginBottom: 0,
  },
  titleValue: {
    fontSize: 18,
    fontWeight: 900,
    color: "black",
    margin: 0,
    lineHeight: 1,
    marginBottom: 0,
  },
  image: {
    flexDirection: "row",
    flex: 1,
    height: 35,
    width: 35,
    backgroundColor: "#ebebeb",
    borderRadius: 3,
  },
  red: {
    color: "#cd113b",
  },
  divider: {
    borderBottom: "1px solid #000",
    width: "100%",
    margin: "2.5px 0",
  },
  spacedLabel: {
    width: 100,
  },
  flex: {
    display: "flex",
  },
  div: {
    display: "block",
  },

  shopName: {
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  shopText: {
    fontSize: 10,
    fontWeight: 300,
    maxWidth: 200,
  },

  titleArea: {
    // backgroundColor: "#E5E5E5",
    border: "2px solid #e3e3e3",
    backgroundColor: "white",
    textAlign: "center",
    padding: "5px 20px",
    lineHeight: 0,
    paddingTop: 10,
    width: "200px",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1,
  },

  orderDetails: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    flexDirection: "row-reverse",
    marginBottom: 15,
    width: "100%",
  },

  middleLeft: {
    minWidth: 225,
    marginLeft: 25,
  },
  middleRight: {
    width: "55%",
  },

  bilingTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: "black",
    marginBottom: 0,
  },
  bilingTitleValue: {
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
    marginBottom: 0,
  },

  userName: {
    fontSize: 10,
    display: "flex",
    justifyContent: "flex-start",
  },
  userText: {},

  middleArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 15,
  },
  totalDue: {
    marginBottom: 0,
    color: "#bf1a0d",
    fontSize: 14,
  },

  productHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "#efefef",
    borderRadius: 2,
    padding: "8px 15px",
    color: "#000",
    fontSize: 11,
  },
  productHeaderText: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    flex: 1,
    fontWeight: "bold",
  },
  productList: {
    borderBottom: "1px solid #f6f6f5",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 5px",
  },

  producDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  producText: {
    fontSize: 10,
    fontWeight: 400,
    width: 100,
  },
  producName: {
    width: 200,
    fontSize: 10,
    textAlign: "left",
  },
  imageArea: {
    height: 35,
    width: 35,
    display: "flex",
    flexDirection: "column",
    marginRight: 8,
  },
  image: {
    flexDirection: "row",
    flex: 1,
    height: 35,
    width: 35,
    backgroundColor: "#ebebeb",
    borderRadius: 3,
  },

  acountSection: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "row",
    padding: "20px 0",
  },
  acountArea: {
    backgroundColor: "#f8f8f8",
    padding: 15,
  },

  acountAreaPara: {
    fontSize: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: "row",
    width: 200,
    padding: "0px 5px",
    fontWeight: "bold",
  },
  fromSmall: {
    fontSize: 10,
    fontWeight: 400,
    display: "flex",
    justifyContent: "flex-end",
  },

  footerArea: {
    textAlign: "left",
    // maxWidth: 300,
    display: "flex",
    alignSelf: "baseline",
    justifyContent: "flex-end",
    marginTop: "auto",
  },
  footerText: {
    // display: "flex",
    flexDirection: "row",
    fontSize: 10,
    fontWeight: 200,
    marginTop: 5,
  },
  textRed: {
    color: "#cd113b",
  },
};

const CustomerInvoicePdf = ({ data: orderDetails }) => {
  const container = React.useRef();
  const pdfExportComponent = React.useRef();
  const config = {
    paperSize: "A4",
    margin: 40,
    fileName: `Pdf for ${new Date().getDate()}`,
    author: "Piickme Express Limited",
    scale: 0.9,
    forcePageBreak: ".page-break",
  };

  const exportPDFWithMethod = () => {
    let element = container.current || document.body;
    savePDF(element, config);
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const printPDFWithComponent = () => {
    const printContents = document.getElementById("print").innerHTML;
    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();
    // window.location.reload(false);
    // document.body.innerHTML = originalContents;


    const winPrint = window.open('');
    winPrint.document.write(printContents);
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    winPrint.close(); 
  };

  return (
    <div id="example">
      <div className="example-config box wide hidden-on-narrow">
        <div className="box-col">
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={exportPDFWithComponent}
          >
            Download
          </button>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base ml-2"
            onClick={printPDFWithComponent}
          >
            Print
          </button>
        </div>
      </div>
      <div className="page-container hidden-on-narrow">
        <div className="border rounded p-2 k-pdf-export app-content">
          <PDFExport ref={pdfExportComponent} {...config}>
            <kendo-pdf-document>
              <div ref={container} id="print">
                {orderDetails?.map((order) => (
                  <CustomerInvoicePdfUnit data={order} key={order?.orderId} />
                ))}
              </div>
            </kendo-pdf-document>
          </PDFExport>
        </div>
      </div>
    </div>
  );
};

const CustomerInvoicePdfUnit = ({ data: orderDetails }) => {
  let canvas;
  canvas = document.createElement("canvas");
  JsBarcode(canvas, orderDetails?.orderId, {
    displayValue: false,
    // width: 280,
    height: 40,
  });
  const barcode = canvas.toDataURL();

  // a
  return (
    <div style={styles.page} className="pdf-page">
      <div className="inner-page">
        <div style={styles.section}>
          <div className="pdf-header">
            <div style={styles.headerArea}>
              <div style={styles.headerLeft}>
                <img src={logo} style={styles.logo} />
              </div>

              <div style={styles.headerRight}>
                <div>
                  <h3 style={styles.bilingTitle}>Shipped from</h3>
                </div>
                <div>
                  <div style={{ ...styles.bilingTitleValue }}>
                    {orderDetails?.parcelOrder?.storeName}
                  </div>
                </div>
                <div>
                  <div style={{ ...styles.userText }}>
                    {orderDetails?.parcelOrder?.shippingAddress}
                  </div>
                </div>
                <div>
                  <div style={{ ...styles.userText }}>
                    Contact:{" "}
                    {orderDetails?.parcelOrder?.storeContact ||
                      orderDetails?.customer?.mobileNumber.replace("+88", "")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              ...styles?.orderDetails,
              backgroundColor: "#efefef",
              padding: "15px 15px",
              alignItems: "center",
            }}
          >
            <div style={styles.middleLeft}>
              <div style={styles.titleArea}>
                <div
                  style={{
                    ...styles.userText,
                    display: "flex",
                    width: "100%",
                    flexGrow: "1 1 100%",
                    fontWeight: "bold",
                    padding: 5,
                    paddingBottom: 10,
                  }}
                >
                  {orderDetails?.parcelOrder?.deliveryType?.replace(/_/g, " ")}
                </div>
              </div>
            </div>

            {
                (orderDetails?.orderId.startsWith('E') || orderDetails?.orderId.startsWith('P') || orderDetails?.orderId.startsWith('R')) ? (
                    <div style={styles?.middleRight}>
                    <div>
                        <h3 style={styles.bilingTitle}>Shipped to:</h3>
                    </div>
                    <div style={styles.userText}>
                        Name: 
                        {orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName) ? orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName).name: undefined}
                    </div>
                    <div style={styles.userText}>
                        Phone: 
                        {orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName) ? orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName).contactNumber: undefined}
                    </div>
                    <div style={styles.flex}>
                        Address: 
                        <div style={styles.userText}>
                        {orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName) ? orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName).address: undefined},
                        {orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName) ? orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName).area: undefined},
                        {orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName) ? orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName).zone: undefined},
                        {orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName) ? orderDetails?.parcelOrder?.stores.find(store => store.name === orderDetails?.parcelOrder?.storeName).city: undefined}
                        </div>
                    </div>
                    </div>
                ) : (

                <div style={styles?.middleRight}>
                <div>
                    <h3 style={styles.bilingTitle}>Shipped to:</h3>
                </div>
                <div style={styles.userText}>
                    Name:
                    {orderDetails?.parcelOrder?.recipientName}
                </div>
                <div style={styles.userText}>
                    Phone:
                    {orderDetails?.parcelOrder?.recipientPhone}
                </div>
                <div style={styles.flex}>
                    Address:
                    <div style={styles.userText}>
                    {orderDetails?.parcelOrder?.recipientAddress
                        ? `${orderDetails?.parcelOrder?.recipientAddress}, `
                        : ``}
                    {orderDetails?.parcelOrder?.recipientArea
                        ? `${orderDetails?.parcelOrder?.recipientArea}, `
                        : ``}
                    {orderDetails?.parcelOrder?.recipientZone
                        ? `${orderDetails?.parcelOrder?.recipientZone}, `
                        : ``}
                    {orderDetails?.parcelOrder?.recipientCity
                        ? `${orderDetails?.parcelOrder?.recipientCity}, `
                        : ``}
                    </div>
                </div>
                </div>
                )
            }
          </div>

          <div style={styles?.orderDetails}>
            <div style={{ ...styles.middleLeft, marginTop: 10 }}>
              <div>
                <div style={styles.userText}>
                  Weight:{orderDetails?.parcelOrder?.itemWeight}
                </div>
              </div>
              <div>
                <div
                  style={{
                    ...styles.bilingTitleValue,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    margin: "2px 0",
                  }}
                >
                  <div>Total Amount:</div> <div>BDT {orderDetails?.total}</div>
                </div>
              </div>
              <div>
                <div style={styles.userText}>Special Instruction</div>
              </div>
              <div>
                <div style={styles.bilingTitle}>
                  {orderDetails?.parcelOrder?.specialInstruction || "-"}
                </div>
              </div>
            </div>

            <div style={{ ...styles?.middleRight, alignItems: "flex-start" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginLeft: -5,
                  marginBottom: 10,
                }}
              >
                <img src={barcode} />
              </div>
              <div style={{ marginLeft: 0 }}>
                <div
                  style={{ fontWeight: "bold", fontSize: 14, marginBottom: 10 }}
                >
                  {orderDetails?.orderId}
                </div>
                <div style={styles.flex}>
                  <div style={styles.spacedLabel}>Order Date</div>
                  <div style={styles.userText}>
                    {moment(orderDetails?.createdAt).format("lll")}
                  </div>
                </div>
                <div style={styles.flex}>
                  <div style={styles.spacedLabel}>Zone: </div>
                  <div style={styles.userText}>
                    {orderDetails?.parcelOrder?.recipientZone || "-"}
                  </div>
                </div>
                <div style={styles.flex}>
                  <div style={styles.spacedLabel}> Area:</div>
                  <div style={styles.userText}>
                    {orderDetails?.parcelOrder?.recipientArea || "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInvoicePdf;
