import { ReactElement } from "react";
import Layout from "../../components/Layout";
import PickMeList from "../../components/Pickme";

const PickMePage = (): ReactElement => {
  return (
    <Layout>
      <PickMeList />
    </Layout>
  );
};

export default PickMePage;
