import { ReactElement } from "react";
import RunSheetDetails from "../../components/CourierRunSheetDetails";
import Layout from "../../components/Layout";

const RunSheetDetailsPage = (): ReactElement => {
  return (
    <Layout>
      <RunSheetDetails />
    </Layout>
  );
};

export default RunSheetDetailsPage;
