import { Button, Drawer, Form } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement, useState } from "react";
import AddBtn from "../common/AddBtn";
import AddRider from "../Riders/AddRider";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";


const TopHeading = ({ total, refetch }): ReactElement => {
  const [visible, setvisible] = useState<boolean>(false);
  const [parcelSortingForm] = Form.useForm();
  const [totalScanned, setTotalScanned] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [orderIds, setOrderIds] = useState([]);

  const showDrawer = () => {
    setvisible(true);
  };

  const createSortingOrder = (formData)=>{
    const scannedOrderIds = formData.orderIds?.split("\n").filter(Boolean);
    const request = {
      orderIds : scannedOrderIds
    }
    if(scannedOrderIds){
      setLoading(true)
      axios.post(`${process.env.REACT_APP_ORDER_API}/courier/sorting-parcel`,request,
      {
          headers: {
              Authorization: `Bearer ${authenticateToken()}`,
          },
      }
      ).then((res) => {
        // console.log(res)
        setLoading(false)
        if(res?.status === 200){
            responseNotification('Order Sorting created','success')
            refetch?.();
        }else{
            responseNotification(res?.data?.message,'error')
        }
      })
      .catch((err) => {
          console.error("Order Sorting: Error", err);
      });
    }
    }
    

  const onClose = () => {
    setvisible(false);
  };

  return (
    <div className="top-heading-products top-heading-content">
      <div className="container-fluid">
        <div className="row pt-3 pb-3 d-flex-sb">
          <div className="col-lg-6 col-md-6 no-padding">
            <div className="page-heading-content">
              <h2 className="d-flex-l wrap d-flex-wrap d-flex-base">
                Parcel Runsheet List{" "}
                <span className="d-flex-l ml-1">{total} Runsheet List(s)</span>
              </h2>
            </div>
          </div>
          

          <Drawer
            title={`Scan Order ID Barcode`}
            onClose={() => {
              onClose()
              setTotalScanned(0);
              parcelSortingForm.resetFields();
            }}
            visible={!!visible}
            destroyOnClose={true}
            width="460px"
          >
            <Form
              layout="vertical"
              form={parcelSortingForm}
              onFinish={createSortingOrder}
            >
              
              <Form.Item
                label={`Scan Order ID (Total Scanned : ${totalScanned})`}
                name="orderIds"
                rules={[
                  {
                    required: true,
                    message: "Order id is required!",
                  },
                ]}
              >
                <TextArea
                  onChange={(e) => {
                    const val = e.target.value?.split("\n").length - 1;
                    // console.log(val);
                    setTotalScanned(val);
                  }}
                  rows={25}
                />
              </Form.Item>
              <div className="buttons-container d-flex">
                <Button
                  type="ghost"
                  htmlType="button"
                  onClick={() => parcelSortingForm.resetFields()}
                  className="reset-submit-btn text-center mr-2"
                >
                  Reset
                </Button>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="add-submit-btn text-center mr-2"
                >
                  Sort Orders
                </Button>
              </div>
            </Form>
          </Drawer>
          
        </div>
      </div>
    </div>
  );
};

TopHeading.propTypes = {
  UserId: PropTypes.string,
  GetStatus: PropTypes.string,
  GetPrime: PropTypes.string,
  GetFlagship: PropTypes.string,
  GetRocket: PropTypes.string,
  name: PropTypes.string,
};

export default TopHeading;
