import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Rate,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import {
  BranchesOutlined,
  ClockCircleOutlined,
  EyeFilled,
  FileOutlined,
  FilePdfFilled,
} from "@ant-design/icons";

import axios from "axios";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { MerchantInvoice } from "../invoice-pdf";
import { authenticateToken } from "../../utils/auth";
import Loader from "../common/Loader";
import {
  getPage,
  getParamValue,
  getUpdatedUrl,
  OrderStatus,
} from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import TopHeading from "./TopHeading";
import { debounce } from "lodash";

import moment from "moment";
import Currency from "../common/Currency";
import _ from "lodash";
import Empty from "../common/Empty";
import { check } from "prettier";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { replaceNewlinesForCSV } from "../../utils/functions";
import { ColumnsType } from "antd/lib/table";
import { responseNotification } from "../../utils/notify";
const { Option } = Select;
const { RangePicker }: any = DatePicker;
const { Text } = Typography;

const CourierSettlementList = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const page = getParamValue(location.search, "page") || 0;
  const [visible, setVisible] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  
  const [status, setStatus] = useState(
    getParamValue(location.search, "status") || ""
  );
  const [range, setRange] = useState({
    from: getParamValue(location.search, "from")
      ? getParamValue(location.search, "from")
      : null,
    to: getParamValue(location.search, "to")
      ? getParamValue(location.search, "to")
      : null,
  });
  
  const [userMobileNumber, setUserMobileNumber] = useState(
    getParamValue(location.search, "userMobileNumber") || ""
  );
  
  const [limit, setLimit] = useState(100);
  const [tripsData, setTripsData] = useState({
    loading: false,
    data: null,
  });
  const [selectedTrip, setSelectedTrip] = useState(undefined);
  const [orderId, setOrderId] = useState("");
  const [hubSettlementId, setHubSettlementId] = useState("");
  const [hubSettlementData, setHubSettlementData] = useState<any>();

  // checkbox
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [orders, setOrders] = useState<any>();

  const [selectedInvoiceToPdf, setSelectedInvoiceToPdf] = useState(undefined);

  const fetchRef = useRef(null);

  interface DataType {
    key: string;
    name: string;
    borrow: number;
    repayment: number;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "OrderId",
      dataIndex: "orderId",
      fixed: true,
      width: 300,
    },
    {
      title: "Collected",
      dataIndex: "collected",
      width: 300,
    },
    // {
    //   title: "COD Fee",
    //   dataIndex: "codFee",
    // },
    // {
    //   title: "DeliveryCharge",
    //   dataIndex: "deliveryCharge",
    // },
    // {
    //   title: "Total Charge",
    //   dataIndex: "totalCharge",
    // },
    // {
    //   title: "Payout",
    //   dataIndex: "payout",
    // },
  ];

  const amountData = useMemo(() => {
    const total = hubSettlementData?.orderList?.reduce(
      (obj, order) => {
        const data = order?.parcelOrder;
        obj.amountToCollect += data?.amountToCollect;
        obj.cod += data.codCharge;
        obj.deliveryCharge +=
          data?.deliveryCharge;
        obj.totalCharge += data?.deliveryCharge+data?.codCharge;
        obj.payout += data?.amountToCollect - (data?.deliveryCharge+data?.codCharge);
        return obj;
      },
      {
        amountToCollect: 0,
        deliveryCharge: 0,
        cod: 0,
        totalCharge: 0,
        payout: 0,
      }
    );
    return total;
  }, [hubSettlementData]);
  

  useEffect(()=>{
    if(hubSettlementData){
      prepareOrderData();
    }
    
  },[hubSettlementData])
  const prepareOrderData = ()=>{
    let data: DataType[] = hubSettlementData?.orderList?.map((order, i) => {
      const data = order?.parcelOrder;
      return {
        key: i + 1,
        orderId: order?.orderId,
        collected: data?.amountToCollect,
        //codFee: data.codCharge,
        //deliveryCharge:data?.deliveryCharge,
        //totalCharge: data?.deliveryCharge+data?.codCharge,
        //payout: data?.amountToCollect - (data?.deliveryCharge+data?.codCharge),
      };
    });
    setOrders(data)
    console.log(data)
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const reseAllFieldData = () => {
    setStatus("");
    setUserMobileNumber("");
    form.resetFields(["driverSearch", "userSearch", "statusSearch"]);
    history.replace(location.pathname);
    window.location.reload();
    setOrderId("");
    setRange({
      from: null,
      to: null,
    });
  };

  const getTrips = useCallback(async () => {
    // if (orderId || !userMobileNumber) return;
    let shouldResetPage = false;
    if (
      userMobileNumber &&
      getParamValue(location.search, "userMobileNumber") != userMobileNumber
    ) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("userMobileNumber", `${userMobileNumber}`));
    }
    
    if (status && getParamValue(location.search, "status") != status) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("status", `${status}`));
    }
    if (shouldResetPage) history.push(getUpdatedUrl("page", `${0}`));
    setTripsData({ loading: true, data: null });
    axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/courier/hub-settlement-list?` +
          `page=${page || 0}` +
          `&limit=${limit || 16}` +
          (status ? `&status=${status}` : ``) +
          (range?.from ? `&from=${moment(range?.from).toISOString()}` : ``) +
          (range?.to ? `&to=${moment(range?.to).toISOString()}` : ``),
          {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setTripsData({ loading: false, data: res.data });
        }
      })
      .catch((err) => {
        setTripsData({ loading: false, data: [] });
        console.error("Trips: Error", err);
      });
  }, [
    limit,
    status,
    page,
    range,
  ]);


  const getOrdersDetailsByOrderId = async (orderId) => {
    try {
      const path =
        `${process.env.REACT_APP_ORDER_API}` +
        `/admin/order/details?` +
        (orderId ? `&orderId=${orderId}` : ``);

      const response = await fetch(path, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
        method: "GET",
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const showOderData = (hubSettlementId)=>{
    const hubSettlementObject = tripsData?.data?.hubSettlementList.find(settlement => settlement.id==hubSettlementId)
    setHubSettlementData(hubSettlementObject)
    showDrawer();
  }
  const onClose = () => {
    setConfirm(false);
    setTimeout(() => setVisible(false), 500);
  };




  useEffect(() => {
    getTrips();
  }, [getTrips]);

  useEffect(() => {
    const filteredResult = tripsData.data?.orders?.filter((order) => {
      return checkedList?.indexOf(order.orderId) >= 0;
    });
    setOrders(filteredResult);
  }, [tripsData, checkedList]);

  

  const setRecordPerPage = (e) => {
    setLimit(e);
  };

  // checkbox funcs
  const plainOptions = tripsData?.data?.hubSettlementList?.map((settlement) => settlement?.id);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onChangeSingle = (id: string) => {
    let list = [...(checkedList || [])];
    list = list?.filter((item) => item !== id);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  let refinedData = [];

  const getKeys = (obj) => {
    // @ts-ignore
    const titleKeys = Object.keys(obj).map((key) => getVal(key));
    return titleKeys;
  };

  const convertData = async(orderList) => {
    console.log(orderList);
    return orderList?.map((o) => ({
      orderId: o?.orderId,
      createdAt: moment(o?.createdAt).format("MM-DD-yyyy"),
      collectedAmount: '"' + o?.parcelOrder?.amountToCollect + '"',
      recipientName: '"' + o?.parcelOrder?.recipientName + '"',
      recipientPhone: '"' + o?.parcelOrder?.recipientPhone + '"',
      collectableAmount: '"' + o?.parcelOrder?.amountToCollect + '"',
      codFee: '"' + o?.parcelOrder?.codCharge + '"',
      deliveryCharge: '"' + o?.parcelOrder?.deliveryCharge + '"',
      finalFee:
        '"' + o?.parcelOrder?.deliveryCharge + o?.parcelOrder?.codCharge + '"',
      discount: '"' + (o?.discount || 0) + '"',
      additionalCharge: '"' + (o?.additionalCharge || 0) + '"',
      compensationCost: '"' + (o?.compensationCost || 0) + '"',
      promoDiscount: '"' + (o?.promoDiscount || 0) + '"',
      expectedPayoutAmount:
        '"' +
        (o?.parcelOrder?.amountToCollect -
          (o?.parcelOrder?.deliveryCharge +
            o?.parcelOrder?.codCharge)) +
        '"',
      merchantOrderId: o?.parcelOrder?.merchantOrderId,
      recipientAddress: '"' + o?.parcelOrder?.recipientAddress + '"',
    }));
  };

  const convertDataForInvoiceDetails = (res) => {
    console.log(res);
    return res?.orders?.map((order) => ({
      orderId: order?.orderId,
      createdAt: moment(order?.createdAt).format("DD-MM-yyyy"),
      collectedAmount: order?.parcelOrder?.amountToCollect,
      recipientName: order?.parcelOrder?.recipientName,
      recipientPhone: order?.parcelOrder?.recipientPhone,
      collectableAmount: order?.parcelOrder?.amountToCollect,
      storeName: order?.parcelOrder?.storeName,
      paymentMethod: order?.paymentMethod,
      paymentStatus: order?.paymentStatus,
      deliveryCharge: order?.parcelOrder?.deliveryCharge,
      finalFee: order?.deliveryCharge,
      discount: order?.discount || 0,
      additionalCharge: order?.additionalCharge || 0,
      compensationCost: order?.compensationCost || 0,
      promoDiscount: order?.promoDiscount || 0,
      payout:
        order?.parcelOrder?.amountToCollect -
        order?.parcelOrder?.deliveryCharge,
      merchantOrderId: order?.parcelOrder?.merchantOrderId,
      recipientAddress: order?.parcelOrder?.recipientAddress,
    }));
  };

  const generatePDF = async (orderId: any) => {
    const result = await getOrdersDetailsByOrderId(orderId);
    const doc = (
      <MerchantInvoice data={[result.order]} invoice={result.order} />
    );

    setSelectedInvoiceToPdf(doc);
    setTimeout(() => setSelectedInvoiceToPdf(undefined), 0);
    // const asPdf = pdf();
    // asPdf.updateContainer(doc);
    // const blob = await asPdf.toBlob();
    // saveAs(blob, `invoice-${item.invoiceId}`);
  };

  const approve = ()=>{
    approveRejectByAccounts('APPROVED')
  }
  const reject = ()=>{
    approveRejectByAccounts('REJECT')
  }

  const approveRejectByAccounts = (status)=>{
    
      const request ={
        ids : [hubSettlementData?.id],
        status : status,
      }
      if(hubSettlementData?.id){
        setLoading(true)
        axios.put(`${process.env.REACT_APP_ORDER_API}/courier/hub-settlement-approve`,request,
        {
            headers: {
                Authorization: `Bearer ${authenticateToken()}`,
            },
        }
        ).then((res) => {
          // console.log(res)
          setLoading(false)
          if(res?.status === 200){
              responseNotification(`Parcel settlement ${status} successfully`,'success')
          }else{
              responseNotification(res?.data?.message,'error')
          }
        })
        .catch((err) => {
            console.error("Order Sorting: Error", err);
        });
      }
      
  }

  const generateCSVFile = async (settlementId) => {
    const result = await tripsData?.data?.hubSettlementList.find(settlement => settlement.id==settlementId);
    const r = replaceNewlinesForCSV(result);
    const convertedData = await convertData(r?.orderList);
    console.log(convertedData)
    const titleKeys = getKeys(convertedData[0]);
    refinedData.push(titleKeys);

    // @ts-ignore
    convertedData.forEach((item) => {
      refinedData.push(Object.values(item));
    });

    let csvContent = "";
    refinedData.forEach((row) => {
      // @ts-ignore
      csvContent += row.join(",") + "\n";
    });
    // @ts-ignore
    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8,",
    });

    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", objUrl);
    link.setAttribute("download", `order-${result?.trackingId}.csv`);
    link?.click();
    refinedData = [];
    //console.log(csvContent)
  };

  function getVal(val) {
    let escaped = val;
    if (typeof val === "string") {
      escaped = ("" + val).replace(/"/g, '"');
      // @ts-ignore
      escaped = escaped?.replace(/[\t\n\"]/gi, " ");
    }
    return `"${escaped}"`; // To escape the comma in a address like string.
  }

  

  return (
    <React.Fragment>
      <TopHeading
        total={tripsData?.data?.totalElements}
        hubSettlementList={tripsData?.data?.hubSettlementList || []}
        checkedList={checkedList}
        refetch={getTrips}
        hubSettlementId = {hubSettlementId}
      />

      <section className="search_area">
        <Form form={form} className="search_form">
          

          <Form.Item name="range" className="mr-2">
            <RangePicker
              use12Hours
              defaultValue={
                range.from != null && range.to != null
                  ? [moment(range.from), moment(range.to)]
                  : null
              }
              onChange={(_, f) => {
                console.log("moment", moment.utc(f[0])?.toISOString());
                setRange({ from: f?.[0], to: f?.[1] });
              }}
              // showTime
            />
          </Form.Item>

          
          <Form.Item name="pageSearch" className="mb-0 mr-2">
            <Select
              defaultValue="100"
              style={{ width: 120 }}
              onChange={setRecordPerPage}
              options={[
                {
                  value: "100",
                  label: "100",
                },
                {
                  value: "200",
                  label: "200",
                },
                {
                  value: "300",
                  label: "300",
                },
                {
                  value: "400",
                  label: "400",
                },
                {
                  value: "500",
                  label: "500",
                },
              ]}
            />
          </Form.Item>

          <Form.Item name="pageSearch" className="mb-0 mr-2">
            <Select
              style={{ width: 120 }}
              options={[
                { value: null, label: "All" },
                { value: 'OPEN', label: "OPEN" },
                { value: 'CLOSE', label: "CLOSE" },
              ]}
              value={status}
              onChange={(value) => setStatus(value)}
            />
          </Form.Item>

          
        </Form>
        

        <div className="search_btn">
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>

        {/* <div style={{ marginLeft: "auto" }}>
          <Button
            type="primary"
            size="large"
            disabled={tripsData?.data?.orders?.length == 0}
            onClick={() => {
              console.log(tripsData.data);
              let orderList = [];
              tripsData?.data?.orders?.forEach((order) => {
                let obj = {};
                order = replaceNewlinesForCSV(order);
                obj["merchantName"] = order?.customer?.name;
                obj["amountToCollect"] = order?.parcelOrder?.amountToCollect;

                obj["codFee"] = order?.parcelOrder?.codCharge;
                obj["deliveryCharge"] = order?.parcelOrder?.deliveryCharge;
                obj["totalCharge"] =
                  parseInt(order?.parcelOrder?.codCharge) +
                  parseInt(order?.parcelOrder?.deliveryCharge);
                obj["payout"] =
                  order?.parcelOrder?.amountToCollect -
                  (order?.parcelOrder?.codCharge +
                    order?.parcelOrder?.deliveryCharge);

                orderList.push(obj);
              });

              const titleKeys = Object.keys(orderList[0]).map((key) =>
                getVal(key)
              );

              const refinedData = [];
              refinedData.push(titleKeys);

              orderList.forEach((item) => {
                refinedData.push(Object.values(item));
              });
              let csvContent = "";
              refinedData.forEach((row) => {
                csvContent += row.join(",") + "\n";
              });

              // @ts-ignore
              const blob = new Blob([csvContent], {
                type: "text/csv;charset=utf-8,",
              });

              const objUrl = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.setAttribute("href", objUrl);
              link.setAttribute(
                "download",
                `invoices-${orderList?.length}.csv`
              );
              link?.click();
            }}
          >
            Download CSV
          </Button>
        </div> */}
      </section>

      <div className="flex">
        {tripsData?.data?.hubSettlementList?.length > 0 ? (
          <Checkbox
            className="mb-2"
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            Check all
          </Checkbox>
        ) : undefined}
      </div>

      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  <div className="single-rider-heading  major-col  ml-4">
                    <span>Tracking ID</span>
                  </div>
                  <div className="single-rider-heading  major-col">
                    <span>Payment Method</span>
                  </div>
                  <div className="single-rider-heading  major-col">
                    <span>Total</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Amount</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Status</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Request Created At</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Request Created By</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Request Updated At</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Request Updated By</span>
                  </div>
                  <div className="single-rider-heading last-column">
                    <span>Action</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {tripsData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div className="rider-table-area">
                  <Checkbox.Group
                    value={checkedList}
                    onChange={onChange}
                    className="rider-table-area"
                    style={{ width: "100%", height: "100%" }}
                  >
                    {tripsData?.data?.hubSettlementList?.length ? (
                      tripsData?.data?.hubSettlementList?.map((settlement, index) => (
                        <React.Fragment key={index}>
                          
                            <div className="single-rider-wrapper">
                              <div className="single-riderx major-colx mr-2">
                                <Checkbox
                                  value={settlement?.id}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onChangeSingle(settlement?.id);
                                  }}
                                />
                              </div>
                              <div className="single-rider major-col">
                                <span className="name">{settlement?.trackingId}</span>
                              </div>
                              <div className="single-rider major-col">
                                <span className="name">
                                  {settlement?.paymentMethod}
                                </span>
                              </div>
                              <div className="single-rider major-col">
                                <span className="name">
                                  {settlement?.total}
                                </span>
                              </div>

                              <div className="single-rider">
                                <span className={"name text-uppercase"}>
                                  ৳{settlement?.amount}
                                </span>
                              </div>
                              <div className="single-rider major-col">
                                <span className={settlement?.status === "CLOSE" ? "inactive-btn radius-btn" : "active-btn radius-btn"}>
                                  {settlement?.status}
                                </span>
                              </div>
                              <div className="single-rider major-col">
                                <span className="name text-uppercase">
                                  {moment(settlement?.createdAt).format(
                                    "DD-MM-YY"
                                  )}
                                  <br/>
                                  {moment(settlement?.createdAt).format(
                                    "HH:MMa"
                                  )}
                                </span>
                              </div>
                              <div className="single-rider major-col">
                                <span className="name text-uppercase">
                                  {settlement?.createdBy?.name}
                                </span>
                              </div>
                              <div className="single-rider major-col">
                                <span className="name text-uppercase">
                                  {moment(settlement?.updatedAt).format(
                                    "DD-MM-YY"
                                  )}
                                  <br/>
                                  {moment(settlement?.updatedAt).format(
                                    "HH:MMa"
                                  )}
                                </span>
                              </div>
                              <div className="single-rider">
                                <span className={"name text-uppercase"}>
                                  {settlement?.updatedBy?.name}
                                </span>
                              </div>
                              <div className="single-rider last-column">
                                
                              <Button
                                type="primary"
                                onClick={()=>{
                                  //setHubSettlementId(settlement?.id)
                                  showOderData(settlement?.id)
                                }}
                              >
                                Settle
                              </Button>
                              &nbsp;
                              &nbsp;

                                {/* <Button
                                  key="A"
                                  color="success"
                                  className="text-success outlined mb-1 mr-1 compressed collapsed d-none d-md-block fw-normal"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    generatePDF(settlement?.id);
                                  }}
                                >
                                  <FilePdfFilled />
                                </Button> */}
                                <Button
                                  key="B"
                                  color="danger"
                                  className="text-danger outlined mb-1 me-1 compressed collapsed d-none d-md-block fw-normal"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    generateCSVFile(settlement?.id);
                                  }}
                                >
                                  <FileOutlined />
                                </Button>
                              </div>
                            </div>
                          
                        </React.Fragment>
                      ))
                    ) : (
                      <Empty
                        data={
                          orderId ? "Empty Data" : "Please select a merchant"
                        }
                      />
                    )}
                  </Checkbox.Group>
                </div>
              </div>
            )}
          </div>
          {/* <Drawer
            title="Rider Details"
            width={800}
            onClose={() => {
              setSelectedTrip(null);
              getTrips();
            }}
            visible={selectedTrip}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
          >
            <TripDetails tripDetails={selectedTrip} />
          </Drawer> */}
          <PaginationTwo
            {...tripsData?.data}
            limit={limit}
            page={tripsData?.data?.currentPageNumber || 0}
          />
          <div>
            <Modal
              open={!!selectedInvoiceToPdf}
              onCancel={() => setSelectedInvoiceToPdf(undefined)}
            >
              <Loader />
              <div style={{ height: 0, overflow: "hidden" }}>
                {selectedInvoiceToPdf}
              </div>
            </Modal>

            <Modal
              visible={visible}
              onOk={() => {
                setConfirm(true);

              }}
              okText={`Pay (${amountData?.amountToCollect})`}
              onCancel={onClose}
              cancelButtonProps={{ disabled: confirm }}
              okButtonProps={{ disabled: confirm }}
              width="700px"
            >
            <div className="mb-2">
              <h4 className="mb-0">
                <b> Payment Method </b>{" : "}
                <b className="highlighted-txt" style={{ fontSize: 14 }}>
                  {hubSettlementData?.paymentMethod}
                </b>
              </h4>
              
            </div>
            <>
              <Table
                columns={columns}
                dataSource={orders}
                pagination={false}
                //scroll={{ x: 800, y: 800 }}
                bordered
                summary={() => {
                  const total = amountData;
                  console.log(total);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          <span style={{ fontWeight: "bold" }}>Total</span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <Text style={{ fontWeight: "bold" }}>
                            {total.amountToCollect}
                          </Text>
                        </Table.Summary.Cell>
                        {/* <Table.Summary.Cell index={2}>
                          <Text style={{ fontWeight: "bold" }}>
                            {total.cod}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Text style={{ fontWeight: "bold" }}>
                            {total.deliveryCharge}
                          </Text>
                        </Table.Summary.Cell>{" "}
                        <Table.Summary.Cell index={4}>
                          <Text style={{ fontWeight: "bold" }}>
                            {total.totalCharge}
                          </Text>
                        </Table.Summary.Cell>{" "}
                        <Table.Summary.Cell index={5}>
                          <Text style={{ fontWeight: "bold" }}>
                            {total.payout}
                          </Text>
                        </Table.Summary.Cell> */}
                      </Table.Summary.Row>

                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={1}>
                          <span style={{ fontWeight: "bold" }}>
                            Total Payable Amount
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={4}>
                          <Text type="danger" style={{ fontWeight: "bold" }}>
                            {total.amountToCollect}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </>

            <Popconfirm
              visible={confirm}
              onCancel={() => {
                setConfirm(false)
                reject();
              }}
              cancelText={'Rejct'}
              okText={'Approve'}
              title="Confirm?"
              onConfirm={() => {
                approve();
                setConfirm(false);
              }}
            >
              <div style={{ position: "absolute", right: 50 }}></div>
            </Popconfirm>
          </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourierSettlementList;
