import { ReactElement } from "react";
import Layout from "../../components/Layout";
import OrderList from "../../components/Orders";

const OrderPage = (): ReactElement => {
  return (
    <Layout>
      <OrderList />
    </Layout>
  );
};

export default OrderPage;
