import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Drawer,
  Pagination,
  Popconfirm,
  Space,
  Switch,
  Table,
} from "antd";
import { authenticateToken } from "../../utils/auth";
import { useState } from "react";
import HubForm from "./HubForm";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { responseNotification } from "../../utils/notify";

interface PieHubType {
  id: string;
  name: string;
  email: number;
  address: string;
  isActive: boolean;
}

async function getHubList() {
  try {
    const url = new URL(
      `${
        process.env.REACT_PUBLIC_CATALOG_READER_API ??
        "https://staging-catalog-reader.qcoom.com/api/v1"
      }/pi-hubs`
    );
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
      },
    });
    if (!res.ok) throw new Error(res.statusText);

    const output = await res.json();

    return output.piHubs;
  } catch (error) {
    return [];
  }
}

export default function HubList() {
  // const { type } = useSelector((state) => (state as any)?.authReducer);
  // const [pageLimit, setPageLimit] = useState<number>(10);
  // const [pageNumber, setPageNumber] = useState<number>(1);
  // const [pageTotal, setPageTotal] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [editHub, setEditHub] = useState<PieHubType>();
  // Queries
  const query = useQuery<PieHubType[]>({
    queryKey: ["pi-hubs"],
    queryFn: getHubList,
  });

  //   const onChange: PaginationProps["onChange"] = (pageNumber) => {
  //     console.log("Page: ", pageNumber);
  //     setPageNumber(pageNumber);
  //   };
  const handleOpenForm = (hub?: PieHubType) => {
    setShowDrawer(true);
    hub ? setEditHub(hub) : setEditHub(undefined);
  };

  const deleteHub = async (hub: PieHubType) => {
    try {
      const url = new URL(
        `${
          process.env.REACT_PUBLIC_CATALOG_WRITER_API ??
          "https://staging-catalog-writer.qcoom.com/api/v1"
        }/pi-hub/${hub.id}`
      );

      const res = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isActive: !hub.isActive }),
      });

      if (!res.ok) throw new Error("Form submitting failed");

      const result = await res.json();

      if (result.statusCode !== 200) throw new Error(result.message);
      query.refetch();
      responseNotification(result.message, "success");
    } catch (error) {
      responseNotification((error as Error).message, "error");
    }
  };

  return (
    <div>
      <div className="top-heading-products top-heading-content">
        <div className="container-fluid">
          <div className="row pt-3 pb-3 d-flex-sb">
            <div className="col-lg-6 col-md-6 no-padding">
              <div className="page-heading-content">
                <h2 className="d-flex-l wrap d-flex-wrap d-flex-base">
                  Hub List
                </h2>
              </div>
            </div>
            <div className="single-button">
              <Button onClick={() => handleOpenForm()}> + Create</Button>
            </div>

            <Drawer
              destroyOnClose={true}
              title="Hub Create Form"
              width={600}
              onClose={() => setShowDrawer(false)}
              visible={!!showDrawer}
              bodyStyle={{ paddingBottom: 0 }}
              footer={
                <div
                  style={{
                    textAlign: "left",
                  }}
                ></div>
              }
            >
              <HubForm
                hub={editHub}
                onCloseMethod={() => {
                  setShowDrawer(false);
                  query.refetch();
                }}
              />
            </Drawer>
          </div>
        </div>
      </div>
      <br />
      <Table
        pagination={false}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Address",
            dataIndex: "address",
            key: "address",
          },
          //   {
          //     title: "Status",
          //     key: "isActive",
          //     dataIndex: "isActive",
          //     render: (_, record) => <Switch checked={record.isActive} onChange={() => updateStatus(record)} />,
          //   },
          {
            title: "Action",
            key: "action",
            render: (_, record) => (
              <Space size="middle">
                <Button onClick={() => handleOpenForm(record)}>
                  <EditOutlined />
                </Button>
                <Popconfirm
                  title="Are you sure you want to ACTIVE the Merchant?"
                  onConfirm={() => deleteHub(record)}
                  //   onCancel={handleCancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
        dataSource={query.data}
      />

      {/* {!query.isLoading && query.data.length && (
        <Pagination
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          defaultCurrent={pageNumber}
          total={query.data.totalElements}
          onChange={onChange}
          itemRender={itemRender}
        />
      )} */}
    </div>
  );
}
