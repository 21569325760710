import {
    faBuilding,
    faCalendar,
    faCalendarAlt,
    faCar,
    faChartLine,
    faCheckDouble,
    faCheckSquare,
    faCode,
    faCopy,
  faFlag,
  faHistory,
  faLaptop,
  faMotorcycle,
  faSort,
  faUserLock,
  faUsers,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { authenticateToken } from "./auth";


const auth_url = process.env.REACT_APP_AUTH_API;
const user_url = process.env.REACT_APP_USER_API;
const order_url = process.env.REACT_APP_ORDER_API;
const writer_url = process.env.REACT_APP_CATALOG_WRITER_API;
const reader_url = process.env.REACT_APP_CATALOG_READER_API;

export const isAllowedService = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const list = JSON.parse(services);
    const data = list.find((item) => item.serviceName === name);
    if (data) {
      return true;
    }
  }
  return false;
};

export const isAllowedRoute = (routePath: string) => {
  console.log("isAllowedRoute", routePath);
  const services = localStorage.getItem("services");

  if (services) {
    const permittedServiceList = JSON.parse(services);

    let serviceName: string | undefined = undefined;

    adminServices.forEach((e) => {
      if (e.path === routePath) {
        serviceName = e.serviceName;
        return;
      } else if (e.sub && e.sub.length) {
        e.sub.forEach((s) => {
          if (`${e.path + s.path}` === routePath) {
            // console.log(`${e.path + s.path}`, e.path + s.path)
            serviceName = s.serviceName;
            return;
          }
        });
      }
    });
    console.log("currentService", serviceName);
    const data = permittedServiceList.find(
      (item) => item.serviceName === serviceName
    );
    if (data) {
      return true;
    }
  }
  return false;
};

export const allowedActions = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const list = JSON.parse(services);
    const data = list.filter((item) => item.serviceName === name);
    if (data?.length > 0) {
      data?.map((d) => d.action);
    }
  }
  return [];
};

export const isEditableService = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const list = JSON.parse(services);
    const data = list.find(
      (item) => item.serviceName === name && item.action === "all"
    );

    if (data) {
      return true;
    }
  }
  return false;
};

export const getAllServices = () => {
  const services = localStorage.getItem("services");
  if (services) {
    return services;
  }
  return [];
};

type AdminService = {
  serviceName: string;
  menuName?: string;
  path: string;
  icon?: any;
  //   component?: any;
  sub?: AdminService[];
  isParent?: boolean;
  hidden?: boolean;
};

export const adminServices: AdminService[] = [
  {
    serviceName: "DASHBOARD_GET",
    menuName: "Dashboard",
    path: "/",
    icon: faLaptop,
  },
  {
    serviceName: "ORDERS_GET",
    menuName: "Order List",
    path: "/orders",
    icon: faCopy,
  },
  {
    serviceName: "SETTLEMENT_GET",
    menuName: "Settlements",
    path: "/settlements",
    icon: faCheckDouble,
  },
  {
    serviceName: "SETTLEMENT_BY_DATE_GET",
    menuName: "Settlement By Date",
    path: "/settlement-by-date",
    icon: faCalendarAlt,
  },
  {
    serviceName: "SETTLEMENT_HISTORY_GET",
    menuName: "Settlement History",
    path: "/settlement-history",
    icon: faHistory,
  },
  {
    serviceName: "MERCHANTS_GET",
    menuName: "Merchants",
    path: "/merchants",
    icon: faUsers,
  },
  {
    serviceName: "HUB_GET",
    menuName: "Hub",
    path: "/hub",
    icon: faBuilding,
  },
  {
    serviceName: "RIDERS_GET",
    menuName: "Riders",
    path: "/riders",
    icon: faMotorcycle,
  },
  {
    serviceName: "PICKUP",
    menuName: "Pick Up",
    path: "",
    icon: faUsers,
    sub: [
      {
        serviceName: "PICKUP_GET",
        menuName: "Pick Up Assign",
        path: "/pickup",
        icon: faUserLock,
        // isParent: true,
      },
      {
        serviceName: "PICKUP_ORDER_GET",
        menuName: "Pick Up Order List",
        path: "/pickup-order-list",
        icon: faUsers,
      },
    ],
  },
  {
    serviceName: "SORTING_ORDER_GET",
    menuName: "Sorting",
    path: "/sorting-orders",
    icon: faSort,
  },
  {
    serviceName: "REPORTS_GET",
    menuName: "Reports",
    path: "/reports",
    icon: faChartLine,
  },
  {
    serviceName: "LOGISTIC_GET",
    menuName: "Qcoom Logistic",
    path: "",
    icon: faCar,
    sub: [
      {
        serviceName: "LOGISTIC_PICKUP_GET",
        menuName: "Pickup",
        path: "/logistic-pickup",
        icon: faCar,
        // isParent: true,
      },
      {
        serviceName: "RUNSHEET_GET",
        menuName: "Pickup Runsheet",
        path: "/runsheet",
        icon: faUsers,
      },
      {
        serviceName: "LOGISTIC_ORDER_GET",
        menuName: "Pickup Order List",
        path: "/logistic-orderlist",
        icon: faUsers,
      },
      {
        serviceName: "LOGISTIC_SORTING_GET",
        menuName: "Sorting",
        path: "/logistic-sorting",
        icon: faUsers,
      },
    ],
  },
  {
    serviceName: "ADMINISTRATION",
    menuName: "Administration",
    path: "/administration",
    icon: faUserShield,
    sub: [
      {
        serviceName: "ADMINISTRATION_ADMINS_GET",
        menuName: "Admins",
        path: "/admins",
        icon: faUsers,
        // isParent: true,
      },
      {
        serviceName: "ADMINISTRATION_SERVICES_GET",
        menuName: "Services",
        path: "/services",
        icon: faFlag,
      },
      {
        serviceName: "ADMINISTRATION_GROUPS_GET",
        menuName: "Groups",
        path: "/groups",
        icon: faUserLock,
      },
    ],
  },

  {
    serviceName: "COURIER_MANAGEMENT",
    menuName: "Courier Management",
    path: "/courier-management",
    icon: faUserShield,
    sub: [
      {
        serviceName: "COURIER_MANAGEMENT_SORTING_GET",
        menuName: "Parcel Sorting",
        path: "/parcel-sorting",
        icon: faUsers,
      },
      {
        serviceName: "COURIER_MANAGEMENT_RUNSHEET_GET",
        menuName: "Parcel Run Sheet",
        path: "/parcel-runsheet",
        icon: faUsers,
      },
      {
        serviceName: "COURIER_MANAGEMENT_SETTLEMENT_GET",
        menuName: "Settlement",
        path: "/parcel-settlement",
        icon: faUsers,
      },
    ],
  },
];


export const getCityList = async (storeId,deliveryType) =>{
  if(storeId){
    try {
      const path = reader_url + `/common-city/cityListForOrder?storeId=${storeId}&deliveryType=${deliveryType}`;
      const response = await fetch(path, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        method: 'GET',
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
};

export const getZoneList = async (cityId,deliveryType)=> {
  if(cityId){
    try {
      const path = reader_url + `/common-zone?commonCityId=${cityId}&deliveryType=${deliveryType}`;
      const response = await fetch(path, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        method: 'GET',
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
};

export const getAreaList = async (zoneId,deliveryType)=> {
  if(zoneId){
    try {
      const path = reader_url + `/common-area?commonZoneId=${zoneId}&deliveryType=${deliveryType}`;
      const response = await fetch(path, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        method: 'GET',
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
};