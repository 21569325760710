import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBars,
  faBell,
  faBiking,
  faBullhorn,
  faCartArrowDown,
  faComment,
  faComments,
  faCopy,
  faCreditCard,
  faDotCircle,
  faDraftingCompass,
  faEnvelopeOpen,
  faFile,
  faFileInvoiceDollar,
  faGift,
  faGifts,
  faHandsHelping,
  faImages,
  faIndustry,
  faLaptop,
  faList,
  faMapMarker,
  faMarker,
  faOilCan,
  faSatelliteDish,
  faSitemap,
  faStore,
  faStoreSlash,
  faTruckPickup,
  faUpload,
  faUserLock,
  faUsers,
  faUserShield,
  faUserTag,
  faVideo,
  faWeightHanging,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";
import { adminServices, isAllowedService } from "../../../utils/services";
import Icon, {
  LineChartOutlined,
  ProfileOutlined,
  CodeOutlined,
  AppstoreOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  CarOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import logo from "../../../images/logo.svg";

const { SubMenu } = Menu;

const { Sider } = Layout;
const rootSubmenuKeys = ["sub1"];

function Sidebar() {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const location = useLocation();
  const pathname = location.pathname;

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [openKeys, setOpenKeys] = useState<any>([]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onOpenChange = (keys: any[]) => {
    const latestOpenKey = keys.find(
      (key: string) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    const output = pathname
      ?.split("/")
      ?.find((item: string) => item === "global-category")
      ? ["/global-category"]
      : pathname?.split("/")?.find((item: string) => item === "finance")
      ? ["/finance"]
      : pathname?.split("/")?.find((item: string) => item === "locations")
      ? ["/location"]
      : pathname?.split("/")?.find((item: string) => item === "keywords")
      ? ["/keyword"]
      : pathname?.split("/")?.find((item: string) => item === "notification")
      ? ["/notification"]
      : pathname?.split("/")?.find((item: string) => item === "feedback")
      ? ["/feedback"]
      : pathname?.split("/")?.find((item: string) => item === "administration")
      ? ["/administration"]
      : [];
    setOpenKeys(output);
  }, []);

  return (
    <Sider
      width={230}
      collapsible
      theme="light"
    >
      <div className="sidebar-menu">
        <div className="dashboard-logo" style={{ display: "flex" }}>
          <Link to="/">
            <img
                src={logo}
                alt="Piickme"
                style={{ height: "100%", maxHeight: 40, width: "100%" }}
            />
          </Link>
        </div>

        <div className="inner-sidebar-content">
          <Menu
            defaultSelectedKeys={["0"]}
            mode="inline"
            inlineCollapsed={collapsed}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
          >
            {adminServices?.map((service) => {
              if (service?.sub && service?.sub?.length) {
                if (
                  service?.sub?.filter((item) => {
                    const excludeIcon = item;
                    return (
                      excludeIcon?.icon &&
                      isAllowedService(`${item.serviceName}`)
                    );
                  })?.length === 1 &&
                  service?.sub?.find((item) =>
                    isAllowedService(`${item.serviceName}`)
                  )?.isParent
                ) {
                  const actualPath = `${service?.path}${service?.sub?.[0]?.path}`;

                  return (
                    isAllowedService(`${service.serviceName}`) && (
                      <Menu.Item
                        key={service?.path}
                        icon={<FontAwesomeIcon icon={service?.icon} />}
                        className={
                          pathname === actualPath && service?.path
                            ? "active"
                            : ""
                        }
                      >
                        <Link to={actualPath}>{service?.menuName}</Link>
                      </Menu.Item>
                    )
                  );
                } else {
                  return (
                    service?.sub?.find((item) =>
                      isAllowedService(`${item.serviceName}`)
                    ) && (
                      <SubMenu
                        key={service?.path}
                        icon={<FontAwesomeIcon icon={service?.icon} />}
                        title={service?.menuName}
                      >
                        {service?.sub?.map((sub) => {
                          const actualPath = `${service?.path}${sub?.path}`;
                          const excludeIcon = sub;
                          return (
                            !actualPath.includes(":") &&
                            excludeIcon?.icon &&
                            isAllowedService(`${sub.serviceName}`) && (
                              <Menu.Item
                                key={actualPath}
                                icon={<FontAwesomeIcon icon={sub?.icon} />}
                                className={
                                  pathname === actualPath ? "active" : ""
                                }
                              >
                                <Link to={actualPath}>{sub?.menuName}</Link>
                              </Menu.Item>
                            )
                          );
                        })}
                      </SubMenu>
                    )
                  );
                }
              } else {
                return (
                  isAllowedService(`${service.serviceName}`) && (
                    <Menu.Item
                      key={service?.path}
                      icon={<FontAwesomeIcon icon={service?.icon} />}
                      className={pathname === service?.path ? "active" : ""}
                    >
                      <Link to={service?.path}>{service?.menuName}</Link>
                    </Menu.Item>
                  )
                );
              }
            })}
          </Menu>
        </div>
      </div>
    </Sider>
  );
}

const mapStateTopProps = (state: { authReducer: { role: any } }) => {
  return { userRole: state.authReducer.role };
};
export default connect(mapStateTopProps, null)(withRouter(Sidebar as any));
