import { Alert, Button, Form, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import Dragger from "antd/lib/upload/Dragger";
import React, { ReactElement, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { OrderStatus } from "../../../utils";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";

const DetailsTopHeading = ({ refetch, data: orderData }): ReactElement => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRequired, setIsRequired] = useState(false);

  const orderId = (useParams() as any)?.orderId;
  const [form] = useForm();

  const onSubmit = async (data) => {
    if (data?.status) {
      const readyData = {
        orderIds: [orderData?.orderId],
        status: data?.status,
        note: data?.note || "",
      };
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/order/status-update`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            responseNotification(
              "Order Status Updated Successfully",
              "success"
            );
            refetch?.();
            setModal(undefined);
            resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      responseNotification(
        `Please select a status & upload minimum 1 order ID`,
        "error"
      );
    }
  };

  const resetFields = () => {
    form.resetFields();
  };

  return (
    <div className="top-heading-user-details top-heading-content">
      <div className="container-fluid no-padding">
        <div className="row g-4 gutter-8 px-3">
          <div className="col-lg-10 no-padding">
            <h3>Order Details</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/orders">Orders</Link>
              </li>
              <li>Order Details</li>
            </ul>
          </div>
          <div className="col-lg-2 no-padding d-flex">
            <Button
              onClick={() => {
                setModal(true);
              }}
              type="primary"
            >
              Status Update
            </Button>
          </div>
        </div>
      </div>
      <Modal visible={modal} onCancel={() => setModal(false)} footer={null}>
        <Form layout="vertical" onFinish={onSubmit} form={form}>
          <Form.Item
            name="status"
            rules={[
              {
                required: true,
                message: "Status is required!",
              },
            ]}
          >
            <Select
              className="w-full w-100"
              placeholder="Select status"
              onChange={(val) => {
                if (
                  val === "HOLD" ||
                  val === "RETURN" ||
                  val === "PARTIALLY_DELIVERED"
                ) {
                  setIsRequired(true);
                } else {
                  setIsRequired(false);
                }
              }}
            >
              {OrderStatus?.map((status, i) => (
                <Select.Option key={i} value={status}>
                  {status}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="note"
            rules={[
              {
                required: isRequired,
                message: "Order note is required!",
              },
            ]}
          >
            <TextArea />
          </Form.Item>
          <div className="buttons-container">
            <Button
              disabled={loading}
              loading={loading}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Update Status
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={resetFields}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default DetailsTopHeading;
