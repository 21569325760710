import { ReactElement } from "react";
import Layout from "../../../components/Layout";
import RunsheetList from "../../../components/logistic-runsheet";

const LogisticRunSheet = (): ReactElement => {
  return (
    <Layout>
      <RunsheetList />
    </Layout>
  );
};

export default LogisticRunSheet;
