// Settings Context - src/context/Settings
import React, { createContext, ReactElement, useEffect, useState } from "react";

export const SellerContext = createContext<any>(undefined);

const SellerInfoProvider = ({
  children,
  infos,
  setInfos,
}: any): ReactElement => {
  const [currentSettings, setCurrentSettings] = useState({});
  console.log(setInfos);
  const saveInfos = (values: React.SetStateAction<{}>): void => {
    setCurrentSettings(values);
  };

  useEffect(() => saveInfos(infos || {}), [infos]);

  return (
    <SellerContext.Provider
      value={{ infos: currentSettings || {}, setInfos: saveInfos }}
    >
      {children}
    </SellerContext.Provider>
  );
};

export default SellerInfoProvider;
