import React from 'react'
import Layout from '../../components/Layout';
import DisbursedIteam from '../../components/DisbursedItem';
import SettlementHistoryList from '../../components/SettlementHistory';

const index = () => {
  return (
    <Layout>
        <SettlementHistoryList/>
    </Layout>
  )
}

export default index
