import { ReactElement } from "react";
import Layout from "../../components/Layout";
import CourierSettlementList from "../../components/CourierSettlement";

const CourierSettlementPage = (): ReactElement => {
  return (
    <Layout>
      <CourierSettlementList />
    </Layout>
  );
};

export default CourierSettlementPage;
