import React, { ReactElement } from "react";
import Layout from "../../components/Layout";
import ServiceList from "../../components/service";

const ServicePage = (): ReactElement => {
  return (
    <Layout>
      <ServiceList />
    </Layout>
  );
};

export default ServicePage;
