import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Select,
  Spin,
  Upload,
  message,
} from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import { UploadOutlined } from "@ant-design/icons";
import { text } from "@fortawesome/fontawesome-svg-core";
import { Redirect } from "react-router-dom";

interface DocUploadProps {
  driverId: string;
  documentType: string;
  frontImage?: string;
  backImage?: string;
  documentIdNumber?: string;
  onNext?: () => void;
  fetchRiderDetails: () => void;
}

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const props = {
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onPreview: async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  },
  beforeUpload(file): Promise<any> {
    console.log("file uploaded", file);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1.1;
    if (!isLt2M) {
      message.error("Image must smaller than 1.1MB!");
    }
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log("file", reader);

        const img = document.createElement("img");
        (img as any).src = reader.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          ctx.fillStyle = "red";
          ctx.textBaseline = "middle";
          ctx.font = "24px Arial";
          ctx.fillText("Piickme", 20, 20);
          canvas.toBlob(resolve);
        };
      };
    });
  },
};

const DocUpload = ({
  driverId,
  documentType,
  onNext,
  frontImage,
  backImage,
  documentIdNumber,
  fetchRiderDetails,
}: DocUploadProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileCount, setFileCount] = useState({
    frontImage: 0,
    backImage: 0,
  });

  const onSubmit = async (data) => {
    if (
      !!!(
        (!data?.frontImage?.[0]?.originFileObj && data?.frontImage?.[0]?.url) ||
        (!data?.backImage?.[0]?.originFileObj && data?.backImage?.[0]?.url)
      )
    ) {
      if (documentType && driverId) {
        setLoading(true);

        const frontImage = await new Promise((resolve) => {
          if (data?.frontImage?.[0]?.originFileObj) {
            const reader = new FileReader();
            reader.readAsDataURL(data?.frontImage?.[0]?.originFileObj);
            reader.onload = () => resolve(reader.result);
          } else {
            resolve(undefined);
          }
        });

        const backImage = await new Promise((resolve) => {
          if (data?.backImage?.[0]?.originFileObj) {
            const reader = new FileReader();
            reader.readAsDataURL(data?.backImage?.[0]?.originFileObj);
            reader.onload = () => resolve(reader.result);
          } else {
            resolve(undefined);
          }
        });

        const formData = new FormData();
        formData.append("frontImage", data?.frontImage?.[0]?.originFileObj);
        formData.append("backImage", data?.backImage?.[0]?.originFileObj);
        formData.append("documentType", documentType as any);
        formData.append("driverId", driverId as any);
        formData.append("documentIdNumber", data?.documentIdNumber as any);

        await fetch(
          `${process.env.REACT_APP_USER_API}/admin/document/upload`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
            body: formData,
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Rider Create Successfully", "success");
              form.resetFields();

              if (onNext) {
                onNext();
              }
              if (fetchRiderDetails) {
                console.log("fetchRiderDetails");
                fetchRiderDetails();
              }
            } else if (res.statusCode === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        responseNotification(`Document Type & Driver Id Required!`, "warning");
      }
    } else {
      responseNotification(`Update needs newly uploaded docs only!`, "warning");
    }
  };

  useEffect(() => {
    if (frontImage && backImage) {
      setFileCount({ frontImage: 1, backImage: 1 });
    } else if (frontImage) {
      setFileCount({ frontImage: 1, backImage: fileCount?.backImage });
    } else if (backImage) {
      setFileCount({ frontImage: fileCount?.frontImage, backImage: 1 });
    }
  }, [frontImage, backImage]);

  return (
    <div className="add-rider drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          initialValues={{
            documentIdNumber,
            frontImage: frontImage && [
              {
                uid: "-1",
                name: "frontImage.png",
                status: "done",
                url: frontImage,
              },
            ],
            backImage: backImage && [
              {
                uid: "-1",
                name: "backImage.png",
                status: "done",
                url: backImage,
              },
            ],
          }}
          form={form}
          layout="vertical"
          autoComplete="off"
        >
          <h4 style={{ fontWeight: "bolder", textTransform: "capitalize" }}>
            {documentType?.split("_")?.join(" ")}
          </h4>

          <Form.Item
            name="frontImage"
            label="Front Image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra="(png, jpg, jpeg), < 1.1MB"
            rules={[
              {
                required: true,
                message: "Driving License is required!",
              },
            ]}
          >
            <Upload
              {...props}
              listType="picture-card"
              id="frontImage"
              onChange={(file) => {
                if (file.fileList?.length) {
                  setFileCount({
                    frontImage: fileCount.frontImage + 1,
                    backImage: fileCount.backImage,
                  });
                } else {
                  setFileCount({
                    frontImage: 0,
                    backImage: fileCount.backImage,
                  });
                }
              }}
            >
              {fileCount?.frontImage < 1 && "Upload"}
            </Upload>
          </Form.Item>

          {documentType !== "profile_picture" && (
            <>
              <Form.Item
                name="backImage"
                label="Back Image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra="(png, jpg, jpeg), < 1.1MB"
              >
                <Upload
                  {...props}
                  listType="picture-card"
                  id="backImage"
                  onChange={(file) => {
                    if (file.fileList?.length) {
                      setFileCount({
                        frontImage: fileCount.frontImage,
                        backImage: fileCount.backImage + 1,
                      });
                    } else {
                      setFileCount({
                        frontImage: fileCount.frontImage,
                        backImage: 0,
                      });
                    }
                  }}
                >
                  {fileCount?.backImage < 1 && "Upload"}
                </Upload>
              </Form.Item>

              {documentType !== "registration_paper" && (
                <Form.Item
                  hasFeedback
                  label="Document Id Number"
                  name="documentIdNumber"
                  initialValue={documentIdNumber ? documentIdNumber : undefined}
                >
                  <Input
                    id="documentIdNumber"
                    type="text"
                    placeholder="Enter Document Id Number"
                  />
                </Form.Item>
              )}
            </>
          )}
          <div className="buttons-container">
            <Button
              disabled={loading}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={() => {
                form?.resetFields();
              }}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

DocUpload.propTypes = {
  driverId: PropTypes.string,
  documentType: PropTypes.string,
  onNext: PropTypes.func,
  frontImage: PropTypes.string,
  backImage: PropTypes.string,
  documentIdNumber: PropTypes.string,
  fetchRiderDetails: PropTypes.func,
};

export default DocUpload;
