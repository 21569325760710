import * as React from "react";
import * as ReactDOM from "react-dom";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
// import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
// import {
//     Chart,
//     ChartLegend,
//     ChartSeries,
//     ChartSeriesItem,
//     ChartSeriesLabels,
//     ChartCategoryAxis,
//     ChartCategoryAxisItem
// } from "@progress/kendo-react-charts";
import products from "./products.json";
import data from "./p.json";
// @ts-ignore
import logo from "../../images/logo-vertical.png";
import JsBarcode from "jsbarcode";
import moment from "moment";

import "../../styles/pages/pdf.css";

const labelContent = (e) => e.category;

const categories = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];
const firstSeries = [123, 276, 310, 212, 240, 156, 98];
const secondSeries = [165, 210, 287, 144, 190, 167, 212];
const thirdSeries = [56, 140, 195, 46, 123, 78, 95];

// Create styles
const styles = {
  page: {
    backgroundColor: "white",
    fontSize: 12,
    lineHeight: 1.4,
    color: "black",
    // boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.3)",
  },
  section: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  headerArea: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 15,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  headerLeft: {},
  headerRight: {
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logo: {
    width: 40,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
    margin: 0,
    lineHeight: 1.1,
    marginBottom: 0,
  },
  titleValue: {
    fontSize: 14,
    fontWeight: 900,
    color: "black",
    margin: 0,
    lineHeight: 1.2,
    marginBottom: 0,
  },
  image: {
    flexDirection: "row",
    flex: 1,
    height: 35,
    width: 35,
    backgroundColor: "#ebebeb",
    borderRadius: 3,
  },
  red: {
    color: "#cd113b",
  },
  divider: {
    borderBottom: "1px solid #000",
    width: "100%",
    margin: "2.5px 0",
  },
  spacedLabel: {
    width: 100,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    display: "block",
  },
};

const CustomerInvoiceSticker = ({ data: orderDetails }) => {
  const container = React.useRef();
  const pdfExportComponent = React.useRef();
  const config = {
    paperSize: "A6",
    margin: 10,
    fileName: `Sticker for ${new Date().getDay()}`,
    author: "Piickme Express Limited",
    scale: 0.8,
  };

  const exportPDFWithMethod = () => {
    let element = container.current || document.body;
    savePDF(element, config);
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const printPDFWithComponent = () => {
    const printContents = document.getElementById("print").innerHTML;
    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();
    // window.location.reload(false);
    // document.body.innerHTML = originalContents;


    const winPrint = window.open('');
    winPrint.document.write(printContents);
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    winPrint.close(); 
  };

  return (
    <div>
      <div className="example-config">
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base mb-4"
          onClick={exportPDFWithComponent}
        >
          Download
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base mb-4 ml-2"
          onClick={printPDFWithComponent}
        >
          Print
        </button>
      </div>
      <div className="border rounded p-2">
        <PDFExport forcePageBreak=".page-break" ref={pdfExportComponent} {...config}>
          <div ref={container} id="print">
            {orderDetails?.map((order, index) => (
              <CustomerInvoiceStickerUnit data={order} index={index} key={order?.orderId} />
            ))}
          </div>
        </PDFExport>

      </div>
    </div>
  );
};

const CustomerInvoiceStickerUnit = ({ data: orderDetails, index }) => {
  let canvas;
  canvas = document.createElement("canvas");
  JsBarcode(canvas, orderDetails?.orderId, {
    displayValue: false,
    // width: 280,
    height: 25,
  });
  const barcode = canvas.toDataURL();

  // a
  return (
    <div className={index && "page-break"}>
      <div style={styles.page}>
        <div style={styles.x}>
          <div style={{ ...styles.headerArea, flexDirection: 'row', justifyContent: 'start' }}>
            <div style={styles.headerLeft}>
              <img src={logo} style={styles.logo} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginLeft: '-20px' }}>
                <div style={styles.headerRight}>
                <div>
                    <h4 style={styles.titleValue}>Piickme Express Limited</h4>
                </div>
                <div>
                    <h4 style={styles.titleValue}>8801810031699</h4>
                </div>
                <div>
                    <h4 style={{ ...styles.titleValue, marginTop: 0 }}>
                    ORDER DESCRIPTION
                    </h4>
                </div>
                </div>
            </div>
          </div>

          <div className={styles.section}>
            <div style={styles.userp}>
              <div style={styles.flex}>
                <div style={styles.spacedLabel}>
                  <span style={styles.text}>Created at:</span>
                </div>
                <span style={styles.text}>
                  {moment(orderDetails?.createdAt).format("lll")}
                </span>
              </div>
            </div>
            <div style={styles.userp}>
              <div style={styles.flex}>
                <div style={styles.spacedLabel}>
                  <span style={styles.text}>Order Id:</span>
                </div>
                <span style={styles.text}>{orderDetails?.orderId}</span>
              </div>
            </div>
            <div style={styles.userp}>
              <div style={styles.flex}>
                <div style={styles.spacedLabel}>
                  <span style={styles.text}>MO Id:</span>
                </div>
                <span style={styles.text}>{orderDetails?.parcelOrder?.merchantOrderId}</span>
              </div>
            </div>
            <div style={styles.userp}>
              <div style={styles.flex}>
                <div style={styles.spacedLabel}>
                  <span style={styles.text}>Zone:</span>
                </div>
                <span style={styles.text}>
                  {orderDetails?.parcelOrder?.recipientZone || "-"}
                </span>
              </div>
            </div>

            <div style={styles.userp}>
              <div style={styles.flex}>
                <div style={styles.spacedLabel}>
                  <span style={styles.text}>Store Info:</span>
                </div>
                
                  {orderDetails?.parcelOrder?.stores.slice(0,3).map((store)=>(
                    <span style={styles.text}>
                     { store.name|| "-"}<br/>
                     { store.contactNumber|| "-"}<br/>
                  </span>
                  ))}
                
              </div>
            </div>

            <div style={styles.userp}>
              <div style={styles.flex}>
                <div style={styles.spacedLabel}>
                  <span style={styles.text}>Instruction:</span>
                </div>
                <span style={styles.text}>
                  {orderDetails?.parcelOrder?.specialInstruction || "-"}
                </span>
              </div>
            </div>
          </div>

          <div style={styles.divider} />

          <div
            style={{
              textAlign: "center",
            }}
          >
            <img src={barcode} />
          </div>

          <div style={styles.divider} />

          <div style={styles?.section}>
            <div style={styles?.x}>
              <div>
                <span style={styles.title}>Contact Details:</span>
              </div>
              <div style={styles.userp}>
                {orderDetails?.parcelOrder?.recipientName}, {orderDetails?.parcelOrder?.recipientPhone}
              </div>
              {/* <div style={styles.userp}>
                
              </div> */}
              <div style={styles.userp}>
                {orderDetails?.parcelOrder?.recipientAddress
                  ? `${orderDetails?.parcelOrder?.recipientAddress}, `
                  : ``}
                {orderDetails?.parcelOrder?.recipientArea
                  ? `${orderDetails?.parcelOrder?.recipientArea}, `
                  : ``}
                {orderDetails?.parcelOrder?.recipientZone
                  ? `${orderDetails?.parcelOrder?.recipientZone}, `
                  : ``}
                {orderDetails?.parcelOrder?.recipientCity
                  ? `${orderDetails?.parcelOrder?.recipientCity}, `
                  : ``}
              </div>
            </div>
          </div>

          <div style={styles.divider} />

          <div style={styles?.section}>
            <div style={styles?.x}>
              <div>
                <span style={styles.title}>Merchant Details:</span>
              </div>
              <div style={styles.userp}>{orderDetails?.customer?.name}</div>
              <div style={styles.userp}>
                {orderDetails?.customer?.mobileNumber}
              </div>
            </div>
          </div>

          <div style={styles.divider} />

          <div style={{ paddingBottom: 20 }}>
            <div style={styles.userp}>
              <div style={styles.flex}>
                <div style={styles.spacedLabel}>
                  <span style={styles.title}>Total:</span>
                </div>
                <span style={{ fontSize: 14 }}>{orderDetails?.total} BDT</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInvoiceSticker;
