import { ReactElement } from "react";
import Layout from "../../components/Layout";
import SettlementList from "../../components/Settlement";

const SettlementPage = (): ReactElement => {
  return (
    <Layout>
      <SettlementList />
    </Layout>
  );
};

export default SettlementPage;
