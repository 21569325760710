import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Rate,
  Select,
  Spin,
  Table,
} from "antd";
import {
  BranchesOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  EyeFilled,
  FileOutlined,
  FilePdfFilled,
} from "@ant-design/icons";

import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { MerchantInvoice } from "../invoice-pdf";
import { authenticateToken } from "../../utils/auth";
import Loader from "../common/Loader";
import {
  getPage,
  getParamValue,
  getUpdatedUrl,
  OrderStatus,
} from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import TopHeading from "./TopHeading";
import { debounce } from "lodash";

import moment from "moment";
import _ from "lodash";
import Empty from "../common/Empty";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { responseNotification } from "../../utils/notify";
const { RangePicker }: any = DatePicker;
const { Option } = Select;

const ReportList = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const fetchRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const page = getParamValue(location.search, "page") || 0;
  const [status, setStatus] = useState("");
  const [reportType, setReportType] = useState("");
  const [limit, setLimit] = useState(100);
  const [reportsData, setReportsData] = useState({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState({
    from: getParamValue(location.search, "from")
      ? getParamValue(location.search, "from")
      : null,
    to: getParamValue(location.search, "to")
      ? getParamValue(location.search, "to")
      : null,
  });
  const [userMobileNumber, setUserMobileNumber] = useState(
    getParamValue(location.search, "userMobileNumber") || ""
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [OptionsUser, setOptionsUser] = useState({
    loading: false,
    list: null,
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getOptionsUser = useCallback(
    async (mobileNumber) => {
      setOptionsUser({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${500}` +
            // (status ? `&status=${status}` : ``) +
            (mobileNumber
              ? `&mobileNumber=%2B88${mobileNumber.replaceAll("+88", "")}`
              : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOptionsUser({
            loading: false,
            list: _.without(
              res.data?.businesses?.map((user) => {
                if (user?.companyName && user?.mobileNumber) {
                  return {
                    label: `${user?.companyName} (${user?.mobileNumber})`,
                    value: user?.mobileNumber,
                  };
                }
              }),
              undefined
            ),
          });
        })
        .catch((err) => {
          setOptionsUser({ loading: false, list: [] });
          console.error("Trips: Error", err);
        });
    },
    [status]
  );

  useEffect(() => {
    getOptionsUser("");
  }, [getOptionsUser]);

  const getTrips = useCallback(async () => {
    let shouldResetPage = false;

    if (shouldResetPage) history.push(getUpdatedUrl("page", `${0}`));
    setReportsData({ loading: true, data: null });
    axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/admin/order/download/parcel-order-link-list?type=Q_PARCEL` +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setReportsData({ loading: false, data: res.data });
        }
      })
      .catch((err) => {
        setReportsData({ loading: false, data: [] });
        console.error("Trips: Error", err);
      });
  }, [limit, page]);

  useEffect(() => {
    getTrips();
  }, [getTrips]);

  const getUserOptionsDebounce = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptionsUser({ loading: false, list: null });

      if (fetchId !== fetchRef.current) {
        return;
      }

      getOptionsUser(value);
    };

    return debounce(loadOptions, 800);
  }, [getOptionsUser]);

  console.log(reportsData);

  console.log(range);

  const onSubmit = async (data) => {
    console.log(data);
    setIsModalOpen(false);
    axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/admin/order/download/parcel-order?type=Q_PARCEL` +
          `&page=${page || 0}` +
          `&limit=${limit || 16}` +
          (status ? `&status=${status}` : ``) +
          (reportType ? `&reportType=${reportType}` : ``) +
          (range?.from ? `&from=${moment(range?.from).toISOString()}` : ``) +
          (range?.to ? `&to=${moment(range?.to).toISOString()}` : ``) +
          (userMobileNumber ? `&mobileNumber=${userMobileNumber}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          responseNotification("Manufacturer Create Successfully", "success");
          console.log(res);
        } else {
          responseNotification("Selected date has no records!", "error");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("Trips: Error", err);
      });
  };

  const confirmDelete = useCallback(async (reportId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/order/${reportId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
  
      if (response.status === 200) {
        responseNotification('Image has been deleted');
        getTrips();
      } 
      else {
        const data = await response.json();
        console.error("Error deleting image:", data);
      }
    } catch (error) {
      console.error("Data Error:", error);
    }
  }, [reportsData]); 

  console.log("111", reportsData?.data?.orderReportUrls)

  return (
  <React.Fragment>
    <div className="page-heading-content d-flex justify-content-between mb-3">
      <h2>Report Page</h2>
      <button className="btn bg-danger text-white" onClick={showModal}>
        Generate
      </button>
    </div>
    <div className="rider-list-item-area white-bg section-padding-bottom">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading-content mt-3 ml-2">
              <h4 className="d-flex-l wrap d-flex-wrap d-flex-base font-weight-bold">
                Download Reports
              </h4>
            </div>
            <div className="rider-list-items-inner-content">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Creator Name</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Report Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Url’s</th>
                      <th scope="col">Action</th>

                    </tr>
                  </thead>
                  <tbody>
                    {reportsData?.loading ? (
                      <tr>
                        <td>
                          <Loading />
                        </td>
                      </tr>
                    ) : (
                      reportsData?.data?.orderReportUrls?.length ? (
                        reportsData?.data?.orderReportUrls?.map((report, index) => (
                          report?.deleted === false &&
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td className="text-uppercase">{report?.createdBy?.name}</td>
                            <td className="text-uppercase">
                              {report?.createdAt ? moment(report?.createdAt).format("ll") : undefined}
                            </td>
                            <td className="text-uppercase">
                              {report?.createdAt ? moment(report?.createdAt).format("hh:mm:ss A") : undefined}
                            </td>
                            <td className="text-uppercase">{report?.reportType}</td>
                            <td className="text-uppercase">{report?.status}</td>
                            <td>
                              <ol>
                                {report?.urls?.map((url, index) => (
                                  <li key={index} style={{width: "auto"}}>
                                    <a
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                      className="length-url"
                                      href={url}
                                      download
                                    >
                                      {url}
                                    </a>
                                  </li>
                                ))}
                              </ol>
                            </td>
                            <td>
                            <Popconfirm
                              title="Delete Image"
                              onConfirm={() => confirmDelete(report?.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button danger>
                                <DeleteOutlined/>
                              </Button>
                            </Popconfirm>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PaginationTwo
          {...reportsData?.data}
          limit={limit}
          page={reportsData?.data?.currentPageNumber || 0}
        />
      </div>
    </div>
    <Modal
      title="Report Create"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="reportType"
          label="Select Type"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select a option and change input text above"
            allowClear
            onChange={(val) => {
              setReportType(val);
            }}
          >
            <option value="ORDER">Order List</option>
            <option value="SETTLEMENT">Settlement</option>
            <option value="SETTLEMENT_HISTORY">History</option>
          </Select>
        </Form.Item>
        {reportType === "ORDER" && (
          <Form.Item
            name="statusSearch"
            label="StatusSearch"
            className="mr-2"
          >
            <Select
              defaultValue={status}
              placeholder="Status"
              //onChange={(val) => setStatus(val)}
              onDeselect={(val) => console.log(val)}
              onChange={(val) => {
                console.log(status);
                //const s = { ...status, val };
                //setStatus(status.concat(val))
                //console.log(s.val);
                setStatus(val);
              }}
              value={status}
              style={{ minWidth: 350 }}
            >
              <Option value={""}>ALL</Option>
              {OrderStatus?.map((status) => (
                <Option value={status}>{status}</Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item name="userSearch" label="Select Merchant" className="mr-2">
          <Select
            showSearch
            onSearch={getUserOptionsDebounce}
            onSelect={(val) =>
              setUserMobileNumber(
                val.startsWith("+88") ? val.replace("+88", "") : val
              )
            }
            options={OptionsUser?.list}
            defaultActiveFirstOption={false}
            notFoundContent={
              OptionsUser?.loading ? <Spin size="small" /> : "No Data"
            }
            placeholder="Select Merchant"
            optionFilterProp="children"
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              ((option?.label ?? "") as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="range" label="Select Date" className="mr-2">
          <RangePicker
            use12Hours
            defaultValue={
              range.from != null && range.to != null
                ? [moment(range.from), moment(range.to)]
                : null
            }
            onChange={(_, f) => {
              console.log("moment", moment.utc(f[0])?.toISOString());
              setRange({ from: f?.[0], to: f?.[1] });
            }}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  </React.Fragment>
  );
};


export default ReportList;
