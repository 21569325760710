import { Button, Col, Drawer, Form, Input, Modal, Popconfirm, Row, Table, Typography } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { authenticateToken } from "../../utils/auth";
import AddBtn from "../common/AddBtn";
import { responseNotification } from "../../utils/notify";
import { ColumnsType } from "antd/lib/table";
import TextArea from "antd/lib/input/TextArea";

const TopHeading = ({ total, hubSettlementList, checkedList,refetch ,hubSettlementId}): ReactElement => {
  const [visible, setVisible] = useState<boolean>(false);
  const [totalCollectedAmount, setTotalCollectedAmount] = useState("0");
  const [scannedOrderIds, setScannedOrderIds] = useState<any>();
  const [checkList, setCheckList] = useState([]);
  const [orderIds, setOrderIds] = useState('');
  const [orderList, setOrderList] = useState([]);
  const [parcelSortingForm] = Form.useForm();
  const [totalScanned, setTotalScanned] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const [attachment, setAttachment] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [remarks, setRemarks] = useState('');


  const getAllCheckList = useMemo(() => {
    console.log(checkedList);
    return checkedList;
  }, [checkedList]);

  useEffect(()=>{
    console.log(hubSettlementList)
    setCheckList(checkedList)
  },[checkedList])

  const doSettle = (formData) => {
    setOrderList([])
    const scannedOrderIds = formData.orderIds?.split("\n").filter(Boolean);
    setScannedOrderIds(scannedOrderIds)
    
    parcelSortingForm.resetFields();
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    
      if (scannedOrderIds) {
        axios.post(
            `${encodedUri}/courier/order-collected-amount`,

            {
              orderIds :scannedOrderIds,
            },
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
            setTotalCollectedAmount(res.data.totalCollectedAmount)
            setOrderList(res.data?.orderListWithAmount)
          })
          .catch((err) => {
            console.error("Categories: Error", err);
            responseNotification(err.message || "Something went wrong");
          });
      } else {
        responseNotification("No OrderIds given");
      }
    } 

  const calculate =()=>{
    console.log(totalScanned)
  }



  const onClose = () => {
    //setConfirm(false);
    setTimeout(() => setVisible(false), 500);
  };

  

  const showDrawer = () => {
    setVisible(true);
  };
  useEffect(()=>{
    if(hubSettlementId){
      showDrawer()
    }
    
  },[hubSettlementId])

  
  const remove = (orderId)=>{
    const ol = orderList.filter(order=>order?.orderId!=orderId);
    setOrderList(ol)
    setTotalScanned(ol?.length)
    if(ol && ol.length > 0){
      console.log(ol?.reduce((accumulator, currentValue) => accumulator + currentValue.totalCollectedAmount).collectedAmount)
      setTotalCollectedAmount(ol?.reduce((accumulator, currentValue) => accumulator + currentValue.totalCollectedAmount).collectedAmount)
    }else{
      setTotalCollectedAmount('0')
    }
  }

  const createHubSettlement =()=>{
    const request = {
      attachment : attachment,
      paymentMethod : paymentMethod,
      transactionId : transactionId,
      remarks : remarks,
      orderIds : orderList?.map(o=>o.orderId)
    }
    console.log(request)
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    if(orderList?.length > 0){
      axios.put(`${encodedUri}/courier/hub-settlement-request`,{...request},
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        refetch?.();
        responseNotification('Hub settlement request successfull.');
      })
      .catch((err) => {
        console.error("Categories: Error", err);
        responseNotification(err.message || "Something went wrong");
      });
    }else{
      responseNotification('No order ID found', 'error');
    }
    
  }
   
  
  return (
    <div className="top-heading-products top-heading-content">
      <div className="container-fluid">
        <div className="row pt-3 pb-3 d-flex-sb">
          <div className="col-lg-6 col-md-6 no-padding">
            <div className="page-heading-content">
              <h2 className="d-flex-l wrap d-flex-wrap d-flex-base">
                Settlement List{" "}
                <span className="d-flex-l ml-1">{total} Order(s)</span>
              </h2>
            </div>
          </div>
          {/* <div className="single-button">
            <Button
              type="primary"
              onClick={showDrawer}
              disabled={typeof checkList =='undefined' || checkList?.length==0}
            >
              Settle
            </Button>
          </div> */}

        <div className="single-button">
            <AddBtn onClick={showDrawer} />
          </div>

          <Drawer
            title={`Scan Order ID Barcode`}
            onClose={() => {
              onClose()
              setTotalScanned(0);
              parcelSortingForm.resetFields();
            }}
            visible={!!visible}
            destroyOnClose={true}
            width="960px"
          >
            <Row style={{display:'flex', justifyContent:'space-between'}} className="row">
              <Col>
                <Form
                layout="vertical"
                form={parcelSortingForm}
                onFinish={doSettle}
                >
                  
                  <Form.Item
                    label={`Scan Order ID (Total Scanned : ${totalScanned}). Total Amount : ${totalCollectedAmount}`}
                    name="orderIds"
                    rules={[
                      {
                        required: true,
                        message: "Order id is required!",
                      },
                    ]}
                  >
                    <TextArea
                      onChange={(e) => {
                        const val = e.target.value?.split("\n").length - 1;
                        setTotalScanned(val);

                      }}
                      rows={15}
                      value={orderIds}
                    />
                  </Form.Item>
                  <div className="buttons-container d-flex">
                    <Button
                      type="ghost"
                      htmlType="button"
                      onClick={() => parcelSortingForm.resetFields()}
                      className="reset-submit-btn text-center mr-2"
                    >
                      Reset
                    </Button>
                    {/* <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      className="add-submit-btn text-center mr-2"
                    >
                      Add Order Ids
                    </Button> */}
                    <Button
                      disabled={totalScanned == 0}
                      loading={loading}
                      type="primary"
                      htmlType='submit'
                      className="add-submit-btn text-center mr-2"
                    >
                      Calculate Amount
                    </Button>
                  </div>
                </Form>

                <Form
                  layout="vertical"
                  
                >
                  <Form.Item
                    label={`Attachment`}
                    name="attachment"
                    rules={[
                      {
                        required: true,
                        message: "Attachment URL is required!",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        setAttachment(e.target.value)
                      }}
                      type="text"
                      style={{border: '1px solid grey'}}
                    />
                  </Form.Item>

                  <Form.Item
                    label={`Payment Method`}
                    name="paymentMethod"
                    rules={[
                      {
                        required: true,
                        message: "Payment Method is required!",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        setPaymentMethod(e.target.value)
                      }}
                      type="text"
                      style={{border: '1px solid grey'}}
                    />
                  </Form.Item>

                  <Form.Item
                    label={`Transaction ID`}
                    name="transactionId"
                    rules={[
                      {
                        required: true,
                        message: "Transaction ID is required!",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        setTransactionId(e.target.value)
                      }}
                      type="text"
                      style={{border: '1px solid grey'}}
                    />
                  </Form.Item>

                  <Form.Item
                    label={`Remarks`}
                    name="remarks"
                  >
                    <Input
                      onChange={(e) => {
                        setRemarks(e.target.value)
                      }}
                      type="text"
                      style={{border: '1px solid grey'}}
                    />
                  </Form.Item>
                  
                </Form>
              </Col>
              <Col flex="1 0 25%" style={{marginLeft:"20px"}}>
              
              <div className="scanner-data-list">
                {orderList?.map((order)=>(
                  <>
                  <div >
                    <p style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                      <span>{order?.orderId}</span>
                      <span>{order?.collectedAmount}</span>
                      <span><button className="bg-danger text-white border-0" style={{width:"25px",height:'25px',marginRight:"8px"}} onClick={()=>{remove(order?.orderId)}}>X</button></span>
                    </p>
                  </div>

                  </>
                ))}
                </div>
                
              
              </Col>
            </Row>
            <div className="d-flex justify-content-center">

            {orderList?.length > 0 && <Button
                  loading={loading}
                  type="primary"
                  style={{float:"left"}}
                  className="add-submit-btn text-center mr-2"
                  block
                  onClick={createHubSettlement}
                >
                  Create Hub Settlement Request
                </Button>}
            </div>
            
            
          </Drawer>

          
        </div>
      </div>
    </div>
  );
};

TopHeading.propTypes = {
  UserId: PropTypes.string,
  GetStatus: PropTypes.string,
  GetPrime: PropTypes.string,
  GetFlagship: PropTypes.string,
  GetRocket: PropTypes.string,
  name: PropTypes.string,
};

export default TopHeading;
