import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  Switch,
} from "antd";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { responseNotification } from "../../../utils/notify";
import { OrderStatus as qTypeArray } from "../../../utils";
import { authenticateToken } from "../../../utils/auth";
import { getDeliveryCharge, getZone } from "../../../utils/delivery-change";

const { TextArea } = Input;
const { Option } = Select;

interface EditOrderProps {
  visibleData?: any;
  onCloseMethod?: () => void;
  setType?: (val) => void;
  handleSearch?: (val, field) => void;
  type?: string;
}

const EditOrder = (props: EditOrderProps) => {
  const { visibleData, onCloseMethod, setType, handleSearch, type } = props;
  const [form] = Form.useForm();
  const [order, setOrder] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [itemWeight, setItemWeight] = useState(1);
  const [amountToCollect, setAmountToCollect] = useState(0);
  const [zoneType, setZoneType] = useState<
    "INSIDE_DHAKA" | "OUTSIDE_DHAKA" | "DHAKA_SUBURBS" | "SAME_DAY_DELIVERY"
  >();
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [merchant, setMerchant] = useState<any>();

  const initialCharge = useRef(null);

  const productTypes = ["PARCEL", "DOCUMENT", "FRAGILE"];
  console.log(visibleData)
  useEffect(() => {
    setOrder(visibleData);
    setAmountToCollect(visibleData?.parcelOrder?.amountToCollect);
    setItemWeight(visibleData?.parcelOrder?.itemWeight);

    const zone = getZone(visibleData?.parcelOrder);

    setZoneType(zone as any);
  }, [visibleData]);

  useEffect(() => {
    if (order) {
      form.resetFields();
    }
  }, [order]);

  useEffect(() => {
    if (order) {
      fetch(
        `${process.env.REACT_APP_USER_API}/business/${order?.customer?.id}`,
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
        .then((res) => res?.json())
        .then((res) => {
          setMerchant(res?.business);
        });
    }
  }, [form, order]);

  useEffect(() => {
    if (order) {
      console.log("fsfs"+ order)
      const charge = getDeliveryCharge(
        merchant,
        amountToCollect,
        itemWeight,
        zoneType
      );

      charge !== null && setDeliveryCharge(charge);

      if (initialCharge.current === null && merchant)
        initialCharge.current = charge;
    }
  }, [itemWeight, amountToCollect, order, merchant, zoneType]);

  const onSubmit = async (data) => {
    setLoading(true);

    const readyData = data && {
      ...(order?.parcelOrder || {}),
      ...data,
      amountToCollect,
      itemWeight,
      //deliveryCharge,
      orderId: order?.orderId,
    };
    delete readyData.deliveryCharge
    console.log(readyData)
    await axios
      .put(`${process.env.REACT_APP_ORDER_API}/admin/order/parcel`, readyData, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoading(false);

        if (res.status === 200) {
          responseNotification("Order Create Successfully", "success");
          form.resetFields();
          if (onCloseMethod) {
            onCloseMethod();
          }
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(
            res.data?.message || "something wrong",
            "warning"
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const resetData = () => {
    form?.resetFields();
    setItemWeight(1);
    setAmountToCollect(0);
    setType?.("");
  };

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          initialValues={{
            ...order,
            ...(order?.parcelOrder || {}),
            itemWeight: itemWeight || order?.parcelOrder?.itemWeight,
            amountToCollect,
          }}
          form={form} // like ref
          layout="horizontal"
          // requiredMark="optional"
          autoComplete="off"
        >
          <h4 style={{ fontWeight: "bolder", marginTop: 0 }}>
            Basic Information
          </h4>

          <div className="form-row-half">
            <Form.Item
              hasFeedback
              label="Product Type"
              rules={[
                {
                  required: true,
                  message: "Product Type is required!",
                },
              ]}
              name="itemType"
            >
              <Select
                placeholder="Product Type"
                // onChange={(val) => {
                //    setType(val);
                //    form.resetFields(["shopId", "categoryId", "brandId"]);
                // }}
              >
                {productTypes?.map((type, i) => (
                  <Select.Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Merchant Order Id"
              rules={[
                {
                  required: false,
                  message: "Merchant Order Id is Required!",
                },
              ]}
              name="merchantOrderId"
            >
              <Input
                id="name"
                type="text"
                placeholder="Enter Merchant Order Id"
              />
            </Form.Item>
          </div>
          <h4 style={{ fontWeight: "bolder" }}>Recipient Details</h4>

          <div className="form-row-half">
            <Form.Item
              hasFeedback
              label="Recipient Name"
              rules={[
                {
                  required: true,
                  message: "Recipient Name is Required!",
                },
              ]}
              name="recipientName"
            >
              <Input id="name" type="text" placeholder="Enter Recipient Name" />
            </Form.Item>
            <Form.Item
              hasFeedback
              label="Recipient Phone"
              rules={[
                {
                  required: true,
                  message: "Recipient Phone is Required!",
                },
              ]}
              name="recipientPhone"
            >
              <Input
                id="Phone"
                type="text"
                placeholder="Enter Recipient Phone"
              />
            </Form.Item>
          </div>
          <div className="form-row-half">
            <Form.Item
              hasFeedback
              label="Recipient Address"
              rules={[
                {
                  required: true,
                  message: "Recipient Address is Required!",
                },
              ]}
              name="recipientAddress"
            >
              <Input
                id="Address"
                type="text"
                placeholder="Enter Recipient Address"
              />
            </Form.Item>
          </div>

          <h4 style={{ fontWeight: "bolder" }}>Delivery Details</h4>

          <div className="form-row-half">
            <Form.Item
              hasFeedback
              label="Item Weight"
              rules={[
                {
                  required: true,
                  message: "Item Weight is Required!",
                },
              ]}
              name="itemWeight"
            >
              <InputNumber
                id="name"
                type="number"
                min={0}
                placeholder="Enter Item Weight"
                onChange={(val) => {
                  val && val < 0
                    ? message.warn("Should be positive number")
                    : setItemWeight(val || 0);
                }}
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Item Quantity"
              rules={[
                {
                  required: true,
                  message: "Item Quantity is Required!",
                },
              ]}
              name="itemQuantity"
            >
              <InputNumber
                id="name"
                type="number"
                min={0}
                placeholder="Enter Item Quantity"
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Amount To Collect"
              rules={[
                {
                  required: true,
                  message: "Amount To Collect is Required!",
                },
              ]}
              name="amountToCollect"
            >
              <Input
                id="name"
                type="text"
                placeholder="Enter Amount To Collect"
                onChange={(e) => {
                  const amount = parseFloat(e.target.value);
                  amount && amount < 0
                    ? message.warn("Should be positive number")
                    : setAmountToCollect(amount || 0);
                }}
              />
            </Form.Item>
          </div>

          <Form.Item
            hasFeedback
            label="Item Description"
            name="itemDesc"
          >
            <TextArea placeholder="Enter Item Description" />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Special Instruction"
            rules={[
              {
                required: false,
                message: "Special Instruction is Required!",
              },
            ]}
            name="specialInstruction"
          >
            <TextArea placeholder="Enter Special Instruction" />
          </Form.Item>

          <div className="mb-4">
            <h6>Delivery Charge: BDT {order?.parcelOrder?.deliveryCharge}</h6>
            <h6>COD Charge: BDT {order?.parcelOrder?.codCharge}</h6>
              
            <h4 style={{borderTop:'1px solid #ddd'}}>Total Charge: BDT {order?.parcelOrder?.deliveryCharge+order?.parcelOrder?.codCharge}</h4>
            {console.log(
              "charge when created : ",
              order?.parcelOrder?.deliveryCharge
            )}
            {initialCharge.current !== null &&
              initialCharge.current !== order?.parcelOrder?.deliveryCharge && (
                <small className="red-txt">
                  NB: Delivery charge rate has changed from that was during
                  order was created. It was Tk.
                  {order?.parcelOrder?.deliveryCharge} when created.
                </small>
              )}
          </div>
          <div className="buttons-container">
            <Button
              disabled={loading}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={resetData}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditOrder;
