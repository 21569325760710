import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Rate,
  Select,
  Spin,
  Table,
} from "antd";
import {
  BranchesOutlined,
  ClockCircleOutlined,
  FilePdfOutlined,
  FileOutlined,
  EyeOutlined,
  RedoOutlined,
} from "@ant-design/icons";

import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import { authenticateToken } from "../../utils/auth";
import { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import {
  getPage,
  getParamValue,
  getUpdatedUrl,
  OrderStatus,
} from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import TopHeading from "./TopHeading";
import { debounce } from "lodash";

import moment from "moment";
import Currency from "../common/Currency";
import _ from "lodash";
import Empty from "../common/Empty";
import { check } from "prettier";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { MerchantInvoice } from "../invoice-pdf";
import saveAs from "file-saver";
import Loader from "../common/Loader";
import { replaceNewlinesForCSV } from "../../utils/functions";
import { responseNotification } from "../../utils/notify";
const { Option } = Select;
const { RangePicker }: any = DatePicker;

const SettlementHistoryList = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const page = getParamValue(location.search, "page") || 0;
  const [status, setStatus] = useState(
    getParamValue(location.search, "status") || ""
  );
  const [range, setRange] = useState({
    from: getParamValue(location.search, "from")
      ? getParamValue(location.search, "from")
      : null,
    to: getParamValue(location.search, "to")
      ? getParamValue(location.search, "to")
      : null,
  });
  const [driverMobileNumber, setDriverMobileNumber] = useState(
    getParamValue(location.search, "driverMobileNumber") || ""
  );
  const [orderOptions, setOrderOptions] = useState({
    list: [],
    loading: false,
  });
  const [userMobileNumber, setUserMobileNumber] = useState(
    getParamValue(location.search, "userMobileNumber") || ""
  );
  const [selectedMerchant, setSelectedMerchant] = useState(
    getParamValue(location.search, "selectedMerchant") || ""
  );
  const [limit, setLimit] = useState(16);
  const [tripsData, setTripsData] = useState({
    loading: false,
    data: null,
  });
  const [OptionsDriver, setOptionsDriver] = useState({
    loading: false,
    list: null,
  });
  const [OptionsUser, setOptionsUser] = useState({
    loading: false,
    list: null,
  });
  const [selectedInvoiceToPdf, setSelectedInvoiceToPdf] = useState(undefined);
  const [orderId, setOrderId] = useState("");

  const [usersOptionsByName, setUsersOptionsByName] = useState({
    loading: false,
    list: null,
  });

  const [companyName, setCompanyName] = useState("");
  // view modal
  const [settleHistoryEditModalOpen, setSettleHistoryEditModalOpen] =
    useState("");

  // checkbox
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<any>();

  const [ordersForModal, setOrdersForModal] = useState<any>();

  const fetchRef = useRef(null);

  const reseAllFieldData = () => {
    setStatus("");
    setDriverMobileNumber("");
    setSelectedMerchant("");
    setUserMobileNumber("");
    form.resetFields(["driverSearch", "userSearch", "statusSearch"]);
    history.replace(location.pathname);
    window.location.reload();
    setOrderId("");
    setRange({
      from: null,
      to: null,
    });
  };

  const getOrdersByInvoiceId = async (page, limit, invoiceId) => {
    try {
      const path =
        `${process.env.REACT_APP_ORDER_API}` +
        `/order/order-by-invoice?page=${page || 0}&limit=${limit || 100}` +
        (invoiceId ? `&invoiceId=${invoiceId}` : ``);

      const response = await fetch(path, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
        method: "PUT",
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const generatePDF = async (item, invoiceId: any) => {
    const result = await getOrdersByInvoiceId(0, 3000, invoiceId);
    const doc = <MerchantInvoice data={result.orders} invoice={item} />;

    setSelectedInvoiceToPdf(doc);
    // setTimeout(() => setSelectedInvoiceToPdf(undefined), 0);
    // const asPdf = pdf();
    // asPdf.updateContainer(doc);
    // const blob = await asPdf.toBlob();
    // saveAs(blob, `invoice-${item.invoiceId}`);
  };

  const regenerateInvoice = async(invoiceId)=>{
    setLoading(true)
    try {
      const path =
        `${process.env.REACT_APP_ORDER_API}` +
        `/admin/order/invoice-regenerate/${invoiceId}`

      const response = await fetch(path, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
        method: "PUT",
      });
      if (!response.ok) return null;
      const result = await response.json();
      setLoading(false)
      if(result && result?.statusCode == 200){
        responseNotification('Invoice Regenerate Successfully.')
      }
      
    } catch (err) {
      console.log(err);
      return null;
    }
  }



  let refinedData = [];

  const generateCSVFile = async (invoiceId) => {
    const result = await getOrdersByInvoiceId(0, 3000, invoiceId);
    const r = replaceNewlinesForCSV(result);
    
    const convertedData = convertData(r);
    const titleKeys = getKeys(convertedData[0]);
    refinedData.push(titleKeys);

    // @ts-ignore
    convertedData.forEach((item) => {
      refinedData.push(Object.values(item));
    });

    let csvContent = "";
    refinedData.forEach((row) => {
      // @ts-ignore
      csvContent += row.join(",") + "\n";
    });
    // @ts-ignore
    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8,",
    });

    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", objUrl);
    link.setAttribute("download", `invoice-${invoiceId}.csv`);
    link?.click();
    refinedData = [];
    //console.log(csvContent)
  };

  const convertData = (res) => {
    console.log(res);
    return res?.orders?.map((order) => ({
      orderId: order?.orderId,
      createdAt: moment(order?.createdAt).format("MM-DD-yyyy"),
      collectedAmount: '\"' +  order?.parcelOrder?.amountToCollect + '\"',
      recipientName: '\"' + order?.parcelOrder?.recipientName + '\"',
      recipientPhone: '\"' + order?.parcelOrder?.recipientPhone + '\"',
      collectableAmount: '\"' + order?.parcelOrder?.amountToCollect + '\"',
      codFee: '\"' + order?.parcelOrder?.codCharge + '\"',
      deliveryCharge: '\"' + order?.parcelOrder?.deliveryCharge + '\"',
      finalFee: '\"' + (Number(order?.parcelOrder?.deliveryCharge) + Number(order?.parcelOrder?.codCharge)) + '\"',
      discount: '\"' + (order?.discount || 0) + '\"',
      additionalCharge: '\"' + (order?.additionalCharge || 0) + '\"',
      compensationCost: '\"' + (order?.compensationCost || 0) + '\"',
      promoDiscount: '\"' + (order?.promoDiscount || 0) + '\"',
      expectedPayoutAmount: '\"' + (order?.parcelOrder?.amountToCollect - (order?.parcelOrder?.deliveryCharge + order?.parcelOrder?.codCharge)) + '\"',
      merchantOrderId: order?.parcelOrder?.merchantOrderId,
      recipientAddress: '\"' + order?.parcelOrder?.recipientAddress + '\"',
    }));
  };

  const convertDataForInvoiceDetails = (res) => {
    console.log(res);
    return res?.orders?.map((order) => ({
      orderId: order?.orderId,
      createdAt: moment(order?.createdAt).format("DD-MM-yyyy"),
      collectedAmount: order?.parcelOrder?.amountToCollect,
      recipientName: order?.parcelOrder?.recipientName,
      recipientPhone: order?.parcelOrder?.recipientPhone,
      collectableAmount: order?.parcelOrder?.amountToCollect,
      storeName: order?.parcelOrder?.storeName,
      paymentMethod: order?.paymentMethod,
      paymentStatus: order?.paymentStatus,
      deliveryCharge: (
        order?.parcelOrder?.deliveryCharge + order?.parcelOrder?.codCharge
      ).toFixed(2),
      finalFee: order?.deliveryCharge,
      discount: order?.discount || 0,
      additionalCharge: order?.additionalCharge || 0,
      compensationCost: order?.compensationCost || 0,
      promoDiscount: order?.promoDiscount || 0,
      payout: (
        order?.parcelOrder?.amountToCollect -
        (order?.parcelOrder?.deliveryCharge + order?.parcelOrder?.codCharge)
      ).toFixed(2),
      merchantOrderId: order?.parcelOrder?.merchantOrderId,
      recipientAddress: order?.parcelOrder?.recipientAddress,
    }));
  };

  function getVal(val) {
    let escaped = val;
    if (typeof val === "string") {
      escaped = ("" + val).replace(/"/g, '"');
      // @ts-ignore
      escaped = escaped?.replace(/[\t\n\"]/gi, " ");
    }
    return `"${escaped}"`; // To escape the comma in a address like string.
  }

  const getKeys = (obj) => {
    // @ts-ignore
    const titleKeys = Object.keys(obj).map((key) => getVal(key));
    return titleKeys;
  };

  const getOrderByInvoiceIdView = async (invoiceId) => {
    const result = await getOrdersByInvoiceId(0, 3000, invoiceId);
    const modifiedData = convertDataForInvoiceDetails(result);
    setOrdersForModal(modifiedData);
    setSettleHistoryEditModalOpen(invoiceId);
  };

  const getTrips = useCallback(async () => {
    //if (orderId || !userMobileNumber) return;
    let shouldResetPage = false;
    if (
      userMobileNumber &&
      getParamValue(location.search, "userMobileNumber") != userMobileNumber
    ) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("userMobileNumber", `${userMobileNumber}`));
    }
    if (
      driverMobileNumber &&
      getParamValue(location.search, "driverMobileNumber") != driverMobileNumber
    ) {
      shouldResetPage = true;
      history.push(
        getUpdatedUrl("driverMobileNumber", `${driverMobileNumber}`)
      );
    }
    if (status && getParamValue(location.search, "status") != status) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("status", `${status}`));
    }
    if (shouldResetPage) history.push(getUpdatedUrl("page", `${0}`));
    setTripsData({ loading: true, data: null });
    axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/invoice/getAll?type=Q_PARCEL` +
          `&page=${page || 0}` +
          `&limit=${limit || 16}` +
          `&status=DELIVERED` +
          `&paymentStatus=Paid` +
          (userMobileNumber ? `&mobileNumber=%2B88${userMobileNumber}` : ``) +
          (range?.from ? `&from=${moment(range?.from).toISOString()}` : ``) +
          (range?.to ? `&to=${moment(range?.to).toISOString()}` : ``) +
          (companyName ? `&companyName=${companyName}` : ``) +
          (orderId ? `&invoiceId=${orderId}` : ``),

        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setTripsData({ loading: false, data: res.data });
        }
      })
      .catch((err) => {
        setTripsData({ loading: false, data: [] });
        console.error("Trips: Error", err);
      });
  }, [
    limit,
    driverMobileNumber,
    userMobileNumber,
    companyName,
    status,
    page,
    orderId,
    range,
  ]);

  const getOrderOptions = useCallback(
    async (getOrderId) => {
      setOrderOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/invoice/getAll?type=Q_PARCEL` +
            (getOrderId
              ? `&invoiceId=${getOrderId}`
              : `` + getOrderId
              ? `&invoiceId=${getOrderId}`
              : ``) +
            `&page=${page || 0}` +
            `&status=DELIVERED` +
            `&paymentStatus=Paid` +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setTripsData({ loading: false, data: res.data });
          setOrderOptions({
            loading: false,
            list: res.data?.invoices?.map((invoice, index) => ({
              key: index,
              label: invoice.invoiceId,
              value: invoice.invoiceId,
            })),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, status]
  );

  const getOptionsDriver = useCallback(
    async (mobileNumber) => {
      setOptionsDriver({ loading: true, list: null });
      // const encodedUri = `${process.env.REACT_APP_FARE_API}`;
      axios
        .get(
          `${process.env.REACT_APP_USER_API}/admin/driver?` +
            `page=${0}` +
            `&limit=${20}` +
            // (status ? `&status=${status}` : ``) +
            (mobileNumber ? `&mobile=${mobileNumber}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOptionsDriver({
            loading: false,
            list: _.without(
              res.data?.drivers?.map((driver) => {
                if (driver?.name && driver?.mobileNumber) {
                  return {
                    label: driver?.name,
                    value: driver?.mobileNumber,
                  };
                }
              }),
              undefined
            ),
          });
        })
        .catch((err) => {
          setOptionsDriver({ loading: false, list: [] });
          console.error("Trips: Error", err);
        });
    },
    [status]
  );

  const getOptionsUser = useCallback(
    async (mobileNumber) => {
      setOptionsUser({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${500}` +
            // (status ? `&status=${status}` : ``) +
            (mobileNumber
              ? `&mobileNumber=%2B88${mobileNumber.replaceAll("+88", "")}`
              : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOptionsUser({
            loading: false,
            list: _.without(
              res.data?.businesses?.map((user) => {
                if (user?.companyName && user?.mobileNumber) {
                  return {
                    label: `${user?.companyName} (${user?.mobileNumber})`,
                    value: user?.mobileNumber,
                  };
                }
              }),
              undefined
            ),
          });
        })
        .catch((err) => {
          setOptionsUser({ loading: false, list: [] });
          console.error("Trips: Error", err);
        });
    },
    [status]
  );

  useEffect(() => {
    getOptionsUser("");
  }, [getOptionsUser]);

  useEffect(() => {
    getTrips();
  }, [getTrips]);

  useEffect(() => {
    const filteredResult = tripsData?.data?.invoices.filter((invoice) => {
      return checkedList?.indexOf(invoice.invoiceId) >= 0;
    });
    setOrders(filteredResult);
  }, [tripsData, checkedList]);

  const getUserOptionsDebounce = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptionsUser({ loading: false, list: null });

      if (fetchId !== fetchRef.current) {
        return;
      }

      getOptionsUser(value);
    };

    return debounce(loadOptions, 800);
  }, [getOptionsUser]);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions]);

  // checkbox funcs
  const plainOptions = tripsData?.data?.invoices?.map(
    (invoice) => invoice?.invoiceId
  );

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onChangeSingle = (id: string) => {
    let list = [...(checkedList || [])];
    list = list?.filter((item) => item !== id);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const getUsersOptionsByName = useCallback(
    async (value) => {
      setUsersOptionsByName({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${20}` +
            (value ? `&companyName=${value}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setUsersOptionsByName({
            loading: false,
            list: res.data?.businesses?.map((user) => ({
              label: user.companyName,
              value: user.companyName,
            })),
          });
        })
        .catch((err) => {
          setUsersOptionsByName({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [status]
  );

  useEffect(() => {
    getUsersOptionsByName("");
  }, [getUsersOptionsByName]);

  const getUsersOptionsDebounceForName = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      getUsersOptionsByName(value);
    };

    return debounce(loadOptions, 800);
  }, []);

  return (
    <React.Fragment>
      <TopHeading
        total={tripsData?.data?.totalElements}
        orders={orders || []}
        checkedList={checkedList}
        refetch={getTrips}
      />

      <section className="search_area">
        <Form form={form} className="search_form">
          <Form.Item name="companyName">
            <AutoComplete
              onSearch={getUsersOptionsDebounceForName}
              onSelect={(val) => setCompanyName(val)}
              options={usersOptionsByName?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                usersOptionsByName?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                size="large"
                placeholder="Search by Company Name"
                onSearch={(val) => setCompanyName(val)}
                enterButton
                loading={usersOptionsByName.loading}
              />
            </AutoComplete>
          </Form.Item>

          <Form.Item name="userSearch" className="mb-0 mr-2">
            <Select
              showSearch
              onSearch={getUserOptionsDebounce}
              onSelect={(val) =>
                setUserMobileNumber(
                  val.startsWith("+88") ? val.replace("+88", "") : val
                )
              }
              options={OptionsUser?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                OptionsUser?.loading ? <Spin size="small" /> : "No Data"
              }
              placeholder="Select Merchant"
              optionFilterProp="children"
              style={{ width: 200 }}
              filterOption={(input, option) =>
                ((option?.label ?? "") as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item name="range" className="mr-2">
            <RangePicker
              use12Hours
              defaultValue={
                range.from != null && range.to != null
                  ? [moment(range.from), moment(range.to)]
                  : null
              }
              onChange={(_, f) => {
                console.log("moment", moment.utc(f[0])?.toISOString());
                setRange({ from: f?.[0], to: f?.[1] });
              }}
              // showTime
            />
          </Form.Item>

          <Form.Item name="orderId" initialValue={orderId} className="mr-2">
            <AutoComplete
              dropdownMatchSelectWidth={250}
              style={{ width: 250 }}
              onSearch={(e) => handleSearch(e, "order")}
              onSelect={(val) => {
                setOrderId(val);
              }}
              options={orderOptions?.list}
              defaultActiveFirstOption={false}
              placeholder="Invoice ID"
              notFoundContent={
                orderOptions?.loading ? <Spin size="small" /> : null
              }
            ></AutoComplete>
          </Form.Item>

          {/* <Form.Item
            name="driverSearch"
            className="mb-0"
            initialValue={driverMobileNumber}
          >
            <AutoComplete
              onSearch={getDriverTripsOptionsDebounce}
              onSelect={(val) =>
                setDriverMobileNumber(
                  val.startsWith("+88") ? val.replace("+88", "") : val
                )
              }
              options={OptionsDriver?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                OptionsDriver?.loading ? <Spin size="small" /> : "No Data"
              }
            >
              <Input.Search
                size="large"
                placeholder="Driver Phone (01...)"
                onSearch={(val) =>
                  setDriverMobileNumber(
                    val.startsWith("+88") ? val.replace("+88", "") : val
                  )
                }
                enterButton
                loading={OptionsDriver.loading}
                pattern={`[0-9]`}
                maxLength={11}
              />
            </AutoComplete>
          </Form.Item> */}
        </Form>
        {/* <div className="search_btn">
          <Button type="dashed" size="large">
             Search
          </Button>
        </div> */}

        <div className="search_btn">
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              console.log(tripsData.data);
              let invoiceList = [];
              tripsData?.data?.invoices?.forEach((invoice) => {
                let obj = {};
                obj["invoiceId"] = invoice?.invoiceId;
                obj["payout"] = invoice?.receivable;
                obj["createdAt"] = moment(invoice?.createdAt).format(
                  "MM-DD-yyyy hh:mma"
                );
                obj["businessName"] = invoice?.createdBy?.name;
                const merchantPaymentInfo = invoice?.merchantPaymentInfo;
                if (
                  merchantPaymentInfo &&
                  merchantPaymentInfo?.bankInfo?.bankName
                ) {
                  obj["bankName"] =
                    invoice?.merchantPaymentInfo?.bankInfo?.bankName;
                } else {
                  obj["bankName"] = "";
                }
                if (
                  merchantPaymentInfo &&
                  merchantPaymentInfo?.bankInfo?.routingNumber
                ) {
                  obj["routingNumber"] =
                    invoice?.merchantPaymentInfo?.bankInfo?.routingNumber;
                } else {
                  obj["routingNumber"] = "";
                }
                if (
                  merchantPaymentInfo &&
                  merchantPaymentInfo?.bankInfo?.accountName
                ) {
                  obj["accountName"] =
                    invoice?.merchantPaymentInfo?.bankInfo?.accountName;
                } else {
                  obj["bankNaccountNameame"] = "";
                }
                if (
                  merchantPaymentInfo &&
                  merchantPaymentInfo?.bankInfo?.accountNumber
                ) {
                  obj["accountNumber"] =
                    invoice?.merchantPaymentInfo?.bankInfo?.accountNumber;
                } else {
                  obj["accountNumber"] = "";
                }
                if (
                  merchantPaymentInfo &&
                  merchantPaymentInfo?.bankInfo?.branchName
                ) {
                  obj["branchName"] =
                    invoice?.merchantPaymentInfo?.bankInfo?.branchName;
                } else {
                  obj["branchName"] = "";
                }
                if (
                  merchantPaymentInfo &&
                  merchantPaymentInfo?.mfsInfo?.mfsName
                ) {
                  obj["mfsName"] =
                    invoice?.merchantPaymentInfo?.mfsInfo?.mfsName;
                } else {
                  obj["mfsName"] = "";
                }
                if (
                  merchantPaymentInfo &&
                  merchantPaymentInfo?.mfsInfo?.mobileNumber
                ) {
                  obj["mfsNumber"] =
                    invoice?.merchantPaymentInfo?.mfsInfo?.mobileNumber;
                } else {
                  obj["mfsNumber"] = "";
                }

                invoiceList.push(obj);
              });

              const titleKeys = Object.keys(invoiceList[0]).map((key) =>
                getVal(key)
              );

              const refinedData = [];
              refinedData.push(titleKeys);

              invoiceList.forEach((item) => {
                refinedData.push(Object.values(item));
              });
              let csvContent = "";
              refinedData.forEach((row) => {
                csvContent += row.join(",") + "\n";
              });

              // @ts-ignore
              const blob = new Blob([csvContent], {
                type: "text/csv;charset=utf-8,",
              });

              const objUrl = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.setAttribute("href", objUrl);
              link.setAttribute(
                "download",
                `invoices-${invoiceList?.length}.csv`
              );
              link?.click();
            }}
          >
            Download CSV
          </Button>
        </div>
      </section>

      <div className="flex hidden" style={{ visibility: "hidden", opacity: 0 }}>
        {
          // tripsData?.data?.invoices?.length > 0 ?
          // <Checkbox
          //   className="mb-2"
          //   indeterminate={indeterminate}
          //   onChange={onCheckAllChange}
          //   checked={checkAll}
          // >
          // Check all
          // </Checkbox> : undefined
        }
      </div>

      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  <div className="single-rider-heading">
                    <span>Invoice Id</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Merchant Info</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Collected</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Delivery Charge</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Payout</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Created At</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Docs</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {tripsData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div className="rider-table-area custom-single-rider-flex">
                  <Checkbox.Group
                    value={checkedList}
                    onChange={onChange}
                    className="rider-table-area"
                    style={{ width: "100%", height: "100%" }}
                  >
                    {tripsData?.data?.invoices?.length ? (
                      tripsData?.data?.invoices?.map((trip, index) => (
                        <React.Fragment key={index}>
                          <div className="single-rider-wrapper">
                            {/* <div className="single-riderx major-colx mr-2">
                                <Checkbox
                                  value={trip?.invoiceId}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onChangeSingle(trip?.invoiceId);
                                  }}
                                />
                            </div> */}

                            <div className="single-rider major-col">
                              {/* <Link to={`/orders/${trip?.invoiceId}/details`}> */}
                              <span className="name">{trip?.invoiceId}</span>
                              {/* </Link> */}
                            </div>

                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                {trip?.name}
                                <br />
                                {trip?.mobileNumber}
                              </span>
                            </div>

                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳{trip?.collectedAmount}
                              </span>
                            </div>

                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳{trip?.fee}
                              </span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳{trip?.receivable}
                              </span>
                            </div>

                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                {moment(trip?.createdAt).format("ll")}
                                <br />
                                {moment(trip?.createdAt).format("hh:mm:ss A")}
                              </span>
                            </div>

                            <div className="single-rider last-column">
                              <Button
                                id=""
                                color="danger"
                                title={'Regenerate Invoice'}
                                className="text-danger outlined mb-1 mr-1 compressed collapsed d-none d-md-block fw-normal"
                                onClick={() => {
                                  regenerateInvoice(trip?.invoiceId);
                                }}
                              >
                                <RedoOutlined />
                              </Button>
                            
                              <Button
                                color="primary"
                                title="View Invoice Details"
                                className="text-warning outlined mb-1 mr-1 compressed collapsed d-none d-md-block fw-normal"
                                onClick={() => {
                                  getOrderByInvoiceIdView(trip?.invoiceId);
                                }}
                              >
                                <EyeOutlined />
                                
                              </Button>

                              <Button
                                key="A"
                                title="Generate PDF Report"
                                color="success"
                                className="text-success outlined mb-1 mr-1 compressed collapsed d-none d-md-block fw-normal"
                                onClick={() => {
                                  generatePDF(trip, trip?.invoiceId);
                                }}
                              >
                                <FilePdfOutlined />
                                
                              </Button>
                              <Button
                                key="B"
                                title="Generate CSV Report"
                                color="danger"
                                className="text-danger outlined mb-1 me-1 compressed collapsed d-none d-md-block fw-normal"
                                onClick={() => {
                                  generateCSVFile(trip?.invoiceId);
                                }}
                              >
                                <FileOutlined />
                              </Button>
                            </div>
                          </div>
                        </React.Fragment>
                      ))
                    ) : (
                      <Empty
                        data={
                          orderId ? "Empty Data" : "Please select a merchant"
                        }
                      />
                    )}
                  </Checkbox.Group>
                </div>
              </div>
            )}
          </div>
          {/* <Drawer
            title="Rider Details"
            width={800}
            onClose={() => {
              setSelectedTrip(null);
              getTrips();
            }}
            visible={selectedTrip}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
          >
            <TripDetails tripDetails={selectedTrip} />
          </Drawer> */}
          <PaginationTwo
            {...tripsData?.data}
            limit={limit}
            page={tripsData?.data?.currentPageNumber || 0}
          />
          <Modal
            open={!!selectedInvoiceToPdf}
            onCancel={() => setSelectedInvoiceToPdf(undefined)}
          >
            {/* <Loader />
            <div style={{ height: 0, overflow: "hidden" }}> */}
            <div style={{ overflow: "hidden" }}>{selectedInvoiceToPdf}</div>
          </Modal>

          <Modal
            open={
              settleHistoryEditModalOpen &&
              settleHistoryEditModalOpen.length > 0
            }
            onOk={() => setSettleHistoryEditModalOpen("")}
            onCancel={() => setSettleHistoryEditModalOpen("")}
            width="1000px"
          >
            <div className="mb-2">
              <h4 className="mb-0">
                <b> Settlement History Details</b>{" "}
              </h4>
              <h5>
                <b> Invoice ID : {settleHistoryEditModalOpen}</b>{" "}
              </h5>
            </div>
            <Table
              columns={[
                {
                  title: "Order Id",
                  dataIndex: "orderId",
                  key: "orderId",
                },
                {
                  title: "Store Name",
                  dataIndex: "storeName",
                  key: "storeName",
                },

                {
                  title: "Payment Method",
                  dataIndex: "paymentMethod",
                  key: "paymentMethod",
                },
                {
                  title: "Payment Status",
                  dataIndex: "paymentStatus",
                  key: "paymentStatus",
                },
                {
                  title: "Amount To Collect",
                  dataIndex: "collectedAmount",
                  key: "collectedAmount",
                },
                {
                  title: "Delivery Charge",
                  dataIndex: "deliveryCharge",
                  key: "deliveryCharge",
                },
                {
                  title: "Payout",
                  dataIndex: "payout",
                  key: "payout",
                },
              ]}
              dataSource={ordersForModal}
            />
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SettlementHistoryList;
