
// import styles from '../styles/Home.module.css'
import Dashboard from '../components/Dashboard'

const Home = () => {
  return (
    <div>
      <Dashboard />
    </div>
  )
}


export default Home
