import { useDispatch } from "react-redux";
import { redirect } from "./index";
import { LOGOUT_SUCCESS } from "../redux/auth/authType";

export const logout = async (redirect_url: string, dispatch: any) => {
  try {
    await localStorage.removeItem("authToken");
    await localStorage.removeItem("services");
    dispatch({ type: LOGOUT_SUCCESS });
    redirect(redirect_url);
  } catch (e) {
    console.error("Error: logout", e);
  }
};

// Token related
export const authenticateToken = () => {
  const token = localStorage.getItem("authToken");
  if (token) {
    return token;
  }
  return false;
};

export const setAuthToken = (token: string) => {
  try {
    localStorage.setItem("authToken", token);
    return true;
  } catch (e) {
    console.error("Error: Token set", e);
    return false;
  }
};

export const removeAuthToken = () => {
  try {
    localStorage.removeItem("authToken");
    localStorage.removeItem("services");
    return true;
  } catch (e) {
    console.error("Error: Token remove", e);
    return false;
  }
};
