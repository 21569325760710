import React, { ReactElement } from "react";
import Layout from "../../components/Layout";
import GroupList from "../../components/group";

const GroupPage = (): ReactElement => {
  return (
    <Layout>
      <GroupList />
    </Layout>
  );
};

export default GroupPage;
