import {
  ApartmentOutlined,
  AppstoreOutlined,
  AuditOutlined,
  DeleteOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ShopSidebar = ({ shopId }): ReactElement => {
  const pathname = window.location?.pathname;
  const { merchantInfo } = useSelector((state) => (state as any)?.authReducer);

  return (
    <>
      <div className="h-100 product-details-wrapper-content">
        <div className="aside-menu">
          <Menu defaultSelectedKeys={["0"]} mode="inline">
            <Menu.Item
              key="/details"
              className={
                pathname.includes(`merchants/${shopId}/details`) ? "active" : ""
              }
              icon={<ShopOutlined />}
            >
              <Link to={`/merchants/${shopId}/details`}>Infos</Link>
            </Menu.Item>

            <Menu.Item
              key="/orders"
              icon={<ShoppingCartOutlined />}
              className={pathname.includes("/orders") ? "active" : ""}
            >
              <Link to={`/merchants/${shopId}/orders`}>Orders</Link>
            </Menu.Item>

            {/* <Menu.Item
              key="/transactions"
              icon={<AuditOutlined />}
              className={pathname.includes("/transactions") ? "active" : ""}
            >
              <Link to={`/merchants/${shopId}/transactions`}>
                Transaction History
              </Link>
            </Menu.Item> */}
          </Menu>
        </div>
      </div>
    </>
  );
};

export default ShopSidebar;
