import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { qTypeArray } from "../../utils";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import { useSelector } from "react-redux";

const ServiceForm = ({ visibleData, onCloseMethod }: any) => {
  const type = "Q_PARCEL";
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [serviceData, setServiceData] = useState(undefined);
  const [iconUrl, setIconUrl] = useState(undefined);
  const [error, setError] = useState("");

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      serviceName: data.name,
      type: type,
      apiPath: data.apiPath,
      methodName: data.methodName,
    };

    if (serviceData) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/service`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: serviceData?.id,
          isActive: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Service Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_AUTH_API}/service`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Service Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setServiceData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (visibleData) {
      form.resetFields(Object.keys(visibleData));
      setIconUrl(visibleData?.icon);
    }
  }, [form, serviceData, visibleData]);

  return (
    <div className="add-product drawer-toggle-wrapper">
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...serviceData,
        }}
      >
        {/* <Form.Item
          hasFeedback
          label="Type"
          rules={[
            {
              required: true,
              message: "Type is required!",
            },
          ]}
          name="type"
        >
          <Select placeholder="Service Type">
            {Object.values(qTypeArray)?.map((type, i) => (
              <Select.Option value={type} key={i}>
                {type?.replace("_", " ")}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item
          hasFeedback
          label="Service Name"
          rules={[
            {
              required: true,
              message: "Service Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Service Name" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Method Name"
          rules={[
            {
              required: true,
              message: "Method Name is Required!",
            },
          ]}
          name="methodName"
        >
          <Input id="methodName" type="text" placeholder="Enter Method Name" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="API Path"
          rules={[
            {
              required: true,
              message: "API Path is Required!",
            },
          ]}
          name="apiPath"
        >
          <Input id="apiPath" type="text" placeholder="Enter API Path" />
        </Form.Item>

        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>
          <Button
            type="ghost"
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ServiceForm;
