import React from "react";
import { useHistory } from "react-router-dom";
//import { useAlert, useLoading } from '../contexts';
import { formatMobile, responseNotification } from "../utils/notify";

//import { useApi } from './use-api';

export function useCreateStore(formRef) {
  const [invoice, setInvoice] = React.useState("");
  const [collection, setCollection] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [customerMobile, setCustomerMobile] = React.useState("");
  const [deliveryDivision, setDeliveryDivision] = React.useState("");
  const [deliveryUpazila, setDeliveryUpazila] = React.useState("");
  const [deliveryDistrict, setDeliveryDistrict] = React.useState("");
  const [deliveryArea, setDeliveryArea] = React.useState("");
  const [deliveryStreet, setDeliveryStreet] = React.useState("");
  const [deliveryHouse, setDeliveryHouse] = React.useState("");
  const [productPrice, setProductPrice] = React.useState("");
  const [productWeight, setProductWeight] = React.useState("");
  const [productType, setProductType] = React.useState("");
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [shopId, setShopId] = React.useState("");
  const [city, setCity] = React.useState("");
  const [area, setArea] = React.useState("");
  const [zone, setZone] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  //const { responseNotification } = useAlert();
  //const { setLoading } = useLoading();
  //const { createStore } = useApi();
  const navigate = useHistory();

  const handlers = {};

  handlers.handleInvoice = function (e) {
    setInvoice(e.target.value);
  };

  handlers.handleCollection = function (e) {
    setCollection(e.target.value);
  };

  handlers.handleCustomerName = function (e) {
    setCustomerName(e.target.value);
  };

  handlers.handleCustomerMobile = function (e) {
    setCustomerMobile(e.target.value);
  };

  handlers.handleDeliveryDivision = function (e) {
    setDeliveryDivision(e.target.value);
  };

  handlers.handleDeliveryDistrict = function (e) {
    setDeliveryDistrict(e.target.value);
  };

  handlers.handleDeliveryUpazila = function (e) {
    setDeliveryUpazila(e.target.value);
  };

  handlers.handleDeliveryArea = function (e) {
    setDeliveryArea(e.target.value);
  };

  handlers.handleDeliveryStreet = function (e) {
    setDeliveryStreet(e.target.value);
  };

  handlers.handleDeliveryHouse = function (e) {
    setDeliveryHouse(e.target.value);
  };

  handlers.handleProductPrice = function (e) {
    setProductPrice(e.target.value);
  };

  handlers.handleProductWeight = function (e) {
    setProductWeight(e.target.value);
  };

  handlers.handleProductType = function (e) {
    setProductType(e.target.value);
  };

  handlers.handleAcceptTerms = function (e) {
    setAcceptTerms(e.target.value);
  };

  handlers.updateShopId = function (e) {
    setShopId(e.target.value);
  };

  handlers.handleCity = function (val) {
    setCity(val);
  };

  handlers.handleZone = function (val) {
    setZone(val);
  };

  handlers.handleArea = function (val) {
    setArea(val);
  };

  handlers.handleLocation = function (values) {
    handlers.handleCity(values?.[0]);
    handlers.handleZone(values?.[1]);
    handlers.handleArea(values?.[2]);
  };

  handlers.resetInputs = () => {
    if (formRef?.current) {
      formRef.current.reset();
    }
    setCity("");
    setZone("");
    setArea("");
  };

  // submit the whole form
  handlers.handleSubmit = async function (e) {
    e.preventDefault();
    console.log(city, zone, area);
    if (!city || !zone || !area) {
      responseNotification("Store city, zone and area are required!", "warn");
      return;
    }
    if (true) {
      setLoading(true);
      try {
        const readyData = {
          name: e.target.name?.value,
          contactName: e.target.contactName?.value,
          contactNumber: e.target.contactNumber?.value,
          secondaryContactNumber: e.target.secondaryContactNumber?.value,
          city: city,
          zone: zone,
          area: area,
          address: e.target.address?.value,
        };

        //const res = await createStore(readyData);
        const res = await readyData;

        setLoading(false);

        if (!res) {
          responseNotification("Ops! Something went wrong", "error");
          return;
        }
        if (res?.statusCode !== 200) {
          responseNotification(res.message || "Something went wrong!", "error");
          return;
        }
        responseNotification(res?.message || "Store created sucessfully!");
        handlers.resetInputs();
        // ('/stores');
      } catch (e) {
        console.error(e);
        setLoading(false);
        return;
      }
    } else {
      responseNotification(
        "Please read & accept our terms & service doc",
        "error"
      );
    }
  };

  return {
    invoice,
    collection,
    customerName,
    customerMobile,
    deliveryDivision,
    deliveryUpazila,
    deliveryDistrict,
    deliveryArea,
    deliveryStreet,
    deliveryHouse,
    productPrice,
    productWeight,
    productType,
    acceptTerms,
    shopId,
    city,
    zone,
    area,
    ...handlers,
  };
}
