import { ReactElement } from "react";

import Layout from "../../components/Layout";
import OnLinrRiderList from "../../components/OnLineRiders";

const RidersPage = (): ReactElement => {
  return (
    <Layout>
      <OnLinrRiderList />
    </Layout>
  );
};

export default RidersPage;
