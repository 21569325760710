import { responseNotification } from "../../utils/notify";
import { LOGIN_REQUEST, LOGIN_SUCCESS } from "./authType";

//  login action here
export const login = async (dispatch, { email, password }) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });

    const loginRes = await fetch(
      `${process.env.REACT_APP_AUTH_API}/admin/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          'type' : 'Q_PARCEL'
        }),
      }
    );

    const loginResData = await loginRes.json();
    const { token } = loginResData;

    if (token) {
      // ..... services

      fetch(`${process.env.REACT_APP_AUTH_API}/admin/permissions?type=Q_PARCEL`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((serviceResData) => {
          const { serviceGroups } = serviceResData;

          const formatedServices = serviceGroups?.map((item) => ({
            action: item.action,
            serviceNameId: item.serviceNameId,
            serviceName: item.serviceName?.name,
          }));
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { ...loginResData, services: formatedServices || [] },
          });
          localStorage.setItem("authToken", token);
          localStorage.removeItem("services");
          localStorage.setItem("services", JSON.stringify(formatedServices));
          responseNotification(
            loginResData?.message?.replace("Operation", "Login"),
            "success"
          );
          window.location.href = `${process.env.PUBLIC_URL}/`;
        })
        .catch((e) => {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { ...loginResData, services: [] },
          });
          localStorage.setItem("authToken", token);
          localStorage.removeItem("services");
          localStorage.setItem("services", JSON.stringify([]));
          responseNotification(
            loginResData?.message?.replace("Operation", "Login"),
            "success"
          );
          window.location.href = `${process.env.PUBLIC_URL}/`;
          console.log("Services error: ", e);
        });

      // .....

      return true;
    } else {
      responseNotification(loginResData?.message, "error");
      return false;
    }
  } catch (error) {
    console.log(error);
    responseNotification(error?.message, "error");
    return false;
  }
};
