import React from "react";
import ForgetPassword from "../components/ForgetPassword";

const ForgetPage = () => {
  return (
    <>
      <ForgetPassword />
    </>
  );
};

export default ForgetPage;
