import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";

const AdminForm = (
  { email, onCloseMethod }: { email: string; onCloseMethod: any }
): ReactElement => {
    const type = 'Q_PARCEL';
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState(undefined);
  const [error, setError] = useState("");
  const [userGroup, setUserGroup] = useState([]);

  const [groupOptions, setGroupOptions] = useState({
    loading: false,
    list: [],
  });

  // ******************************
  const fetchCorporateDetails = async (email: any) => {
    if (email) {
      await fetch(
        `${process.env.REACT_APP_AUTH_API}/admin/details?email=${email}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            const userG = res?.groups.filter(item => item?.group?.type === type).map(item => item?.group?.id)
            const data = {...res?.admin, name: res?.admin.fullName, groupId: userG};
            setAdminData(data);
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (email) {
      fetchCorporateDetails(email);
    }
  }, [email]);

  // ******************************

  const getGroupOptions = useCallback(
    async (key?: string) => {
      setGroupOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      return axios
        .get(
          `${encodedUri}/group/all?type=${type}` + (key ? `&name=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setGroupOptions({
            loading: false,
            list: res.data?.groups?.filter((group) => group.type === type).map(group => ({
                label: group.name,
                value: group.id,
            })),
          });
        })
        .catch((err) => {
          setGroupOptions({ loading: false, list: [] });
          console.error("Groups: Error", err);
        });
    },
    [type]
  );

  const onSubmit = async (data: any) => {
    // console.log(data);return;
    setLoading(true);

    const readyData = data && {
      name: data.name,
      email: data.email,
      password: data.password,
      groupActives: data.groupId.map((groupId) => ({
        groupId,
        isActive: true,
      })),
    //   groupActives: userGroup.map((groupId) => ({
    //     groupId,
    //     isActive: true,
    //   })),
    };

    if (adminData) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/admin`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          email: adminData?.email,
          type: type,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Admin Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_AUTH_API}/admin/register`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Admin Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getGroupOptions();
  }, [getGroupOptions]);

  useEffect(() => {
    if (adminData) {
      form.resetFields();
      // setLogoUrl(visibleData?.logo);
    }
  }, [adminData]);

  console.log(1111, adminData)

  return (
    <div className="add-product drawer-toggle-wrapper">
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        // requiredMark="optional"
        autoComplete="off"
        initialValues={adminData}
      >
        <Form.Item
          hasFeedback
          label="User Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
          initialValue={adminData?.fullName}
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Email"
          rules={[
            {
              required: true,
              message: "Email is required!",
            },
            {
              validator: async (_, email) => {
                if (
                  email &&
                  !String(email)
                    .toLowerCase()
                    ?.match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    )
                ) {
                  return Promise.reject(new Error("Enter valid email"));
                }
              },
            },
          ]}
          name="email"
        >
          <Input id="email" type="email" placeholder="Enter Email" />
        </Form.Item>

        {!adminData && (<Form.Item
          hasFeedback
          label="Password"
          rules={[
            {
              required: false,
              message: "Password is Required!",
            },
          ]}
          name="password"
        >
          <Input id="password" type="password" placeholder="Enter Password" />
        </Form.Item>)}

        <Form.Item
          hasFeedback
          label={`Groups`}
          name="groupId"
        >
          <Select
            mode="multiple"
            placeholder="Select Group"
            onChange={(val) => {
              setUserGroup(val);
            }}
            options={groupOptions?.list?.map((group) => group)}
          />
        </Form.Item>

        
        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>
          <Button
            type="ghost"
            htmlType="button"
            onClick={() => {
              form?.resetFields();
              // setAdminUrl(undefined);
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AdminForm;
