import { Avatar, Card, Col, Layout, Row, Image, Rate, Badge } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import ShopSidebar from "./sidebar";

import { PhoneFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { getImgUrl } from "../../../utils";
const { Content } = Layout;

const ShopLayout = ({
  children,
  shopId,
}: {
  children: ReactElement;
  shopId: string;
}): ReactElement => {
  const { merchantInfo } = useSelector((state) => (state as any)?.authReducer);
  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <section className="h-full h-100">
          <Row gutter={16}>
            <Col span={6}>
              <Card
                className="shop-sedebar card-padding-none h-100 p-0"
                cover={
                  <div className="show-details-inner-content">
                    <div className="shop-image-content_">
                      {/* <div className="shop-banner">
                        <Image
                          src={getImgUrl(merchantInfo?.banner)}
                          alt={merchantInfo?.name}
                          className="rounded"
                          width="100%"
                        />
                      </div> */}

                      <div className="shop-image-content">
                        {/* <div className="shop-logo">
                          <Avatar
                            size={95}
                            src={getImgUrl(merchantInfo?.logo)}
                          />
                        </div> */}
                        <div className="body" style={{ paddingTop: "1.5rem" }}>
                          <h6 className="d-flex align-items-center mb-0 refunded">
                            {merchantInfo?.companyName?.toUpperCase()}
                          </h6>
                          <small
                            className="d-flex align-items-center mb-4 p-2"
                            style={{ background: "rgba(0, 0, 0, .1)" }}
                          >
                            {merchantInfo?.ownersName}
                          </small>
                          <div className="mb-0">
                            <div>
                              <span className="radius-btn active-btn">
                                <span>
                                  <PhoneFilled />{" "}
                                  {merchantInfo?.mobileNumber?.split("+88")}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              >
                <ShopSidebar shopId={shopId} />
              </Card>
            </Col>
            <Col span={18}>
              <Card
                className="product-information-show-tab-wrapper"
                size="small"
              >
                <Layout>
                  <Content className="main-content-layout">{children}</Content>
                </Layout>
              </Card>
            </Col>
          </Row>
        </section>
      </Layout>
    </React.Fragment>
  );
};

ShopLayout.propType = {
  children: PropTypes.element,
};

export default ShopLayout;
