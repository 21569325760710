import { ReactElement } from "react";
import CourierList from "../../components/Courier";
import Layout from "../../components/Layout";

const CourierPage = (): ReactElement => {
  return (
    <Layout>
      <CourierList />
    </Layout>
  );
};

export default CourierPage;
