import { Alert, Button, Drawer, Form, Input, message, Modal, Select } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import AddBtn from "../common/AddBtn";
import {
  CopyOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  InboxOutlined,
  TagOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import { getParamValue, OrderStatus, PickMeStatus } from "../../utils";
import { useForm } from "antd/lib/form/Form";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
// @ts-ignore
import builkcsv from "../../demo-files/order-ids.csv";
// @ts-ignore
import bulkordercsv from "../../demo-files/bulk-order.csv";
import * as d3 from "d3";
import axios from "axios";
import moment from "moment";
import { debounce } from "lodash";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../common/Loader";
import json2csv from "json2csv";
import { faBiking, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomerInvoicePdf, CustomerInvoiceSticker } from "../invoice-pdf";
// @ts-ignore
import { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { formatMobile } from "../../utils/format-mobile";
import { getData } from "../../utils/functions";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useLocation } from "react-router-dom";
const { TextArea } = Input;

const props = {
  name: "file",
  multiple: false,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("drop: ", e);
  },
};

const simpleFileDownload = () => {
  window.location.href = `${builkcsv}`;
};

const downloadBulkOrder = () => {
  window.location.href = `${bulkordercsv}`;
};

const TopHeading = ({
  total,
  refetch,
  filterData,
  orders: items,
  afterStatusUpdate,
}): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [fetchedCSVData, setFetchedCSVData] = useState<any>();
  const [uploadCSVData, setUploadCSVData] = useState<any>();
  const [drawer, setDrawer] = useState<"statusChange" | "uploadFileById">();
  const [isRequired, setIsRequired] = useState(false);
  const [orderDataForDownload, setOrderDataForDownload] = useState({
    loading: false,
    data: [],
  });
  const [orders, setOrders] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [modal, setModal] = useState(false);
  const [openDrawerBarCode, setOpenDrawerBarCode] = useState(false);
  const [totalScanned, setTotalScanned] = useState<number>(0);
  console.log(afterStatusUpdate)
  
  const [pdfModal, setPdfModal] = useState("");
  const location = useLocation();
  const orderStatus = getParamValue(location.search, "status") || "";
  const [assignment, setAssignment] = useState(false);
  const [selectedDeliveryManId, setSelectedDeliveryManId] = useState("");
  const [deliveryManData, setDeliveryManData] = useState({
    loading: false,
    list: [],
  });

  console.log(afterStatusUpdate)
  const fetchRef = useRef(0);

  const [form] = useForm();
  const [manualOrderForm] = useForm();
  const [form2] = Form.useForm();
  const [bulkAssignDeliveryManForm] = Form.useForm();

  useEffect(() => {
    setOrderIds(items?.map((i) => i?.orderId) || []);
    setOrders(items || []);
  }, [items]);

  const resetFields = () => {
    setFetchedCSVData(undefined);
    form.resetFields();
  };

  const structurizeData = async (data) => {
    const filteredData = [];
    data?.forEach((row) => {
      if (row?.orderId) {
        filteredData.push(row?.orderId);
      }
    });
    setFetchedCSVData(filteredData);
  };

 
  const resetManualUploadOrder = () => {
    setUploadCSVData(undefined);
    manualOrderForm.resetFields();
  };


  const fetchDeliveryMan = useCallback(async (value) => {
    setDeliveryManData({ loading: true, list: [] });
    await fetch(
      `${
        process.env.REACT_APP_USER_API
      }/admin/driver?mobileNumber=%2B88${value?.replace(
        "+88",
        ""
      )}&page=0&limit=20&status=VERIFIED`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setDeliveryManData({
          loading: true,
          list: res?.drivers?.map((driver) => ({
            label: `${driver?.mobileNumber} ${driver?.name}`,
            value: driver?.id,
          })),
        });
      });
  }, []);

  const debounceDeliveryManFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (fetchId !== fetchRef.current) {
        return;
      }
      fetchDeliveryMan(value);
    };
    return debounce(loadOptions, 800);
  }, [fetchDeliveryMan]);

  const assignDeliveryHero = () => {
    //console.log(orders,items)
    let request = {...items}
    const result = items.map((r)=>{
      delete r['count'];
      delete r['merchantName'];
      r.deliveryManId = selectedDeliveryManId;
      delete r['storeName'];
      return r
    })
    
    console.log(result)

    let singleOrder =[];
   orders.map(order=>singleOrder.push(...order.orderIds));

   const readyData ={
    "pickups":result,
    type: 'Q_PARCEL',
   } 
  console.log(readyData)

    if (selectedDeliveryManId) {
      setLoading(true);
      axios
        .put(
          `${process.env.REACT_APP_ORDER_API}/admin/order/pickup-assign`,
          readyData
          ,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            responseNotification("Assigned Successfully!");
            refetch();
            setAssignment(false);
          } else {
            responseNotification("Assigmenation Failed!", "error");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(
            err?.message || "Assigmenation Failed!",
            "error"
          );
          console.error(err);
        });
    } else {
      responseNotification("Select a delivery man", "warning");
    }
  };

 
  const { orderId, userMobileNumber, page, type, status, limit, range } = filterData;


  const deliveryHeroAssignmentPermitted = ()=>{
    let errorTag =  false;
    setAssignment(!errorTag)
  }


  

  console.log(items);

  if (orderDataForDownload?.loading) return <Loader />;

  return (
    <div className="top-heading-products top-heading-content">
      <div className="container-fluid">
        <div className="row pt-3 pb-3 d-flex-sb">
          <div className="col-lg-6 col-md-6 no-padding">
            <div className="page-heading-content">
              <h2 className="d-flex-l wrap d-flex-wrap d-flex-base">
                Pick Up List{" "}
                <span className="d-flex-l ml-1">{total} pickup(s)</span>
              </h2>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 no-padding d-flex-r">
            
              <>
                <div className="single-button ml-2">
                  <Button
                    type="dashed"
                    shape="round"
                    className="mx-1"
                    onClick={() => {deliveryHeroAssignmentPermitted()}}
                    disabled={
                      orders &&
                      orders?.length == 0
                    }
                    title="Assign Delivery Hero"
                  >
                    <FontAwesomeIcon
                      icon={faBiking as IconProp}
                      className={"text-success"}
                    />
                  </Button>
                </div>
              </>
          </div>

          <Modal
            title={
              <div className="d-flex-l">
                <FontAwesomeIcon
                  icon={faBiking as IconProp}
                  className="mr-2"
                  style={{ color: "#ff9f00" }}
                />{" "}
                Assign a Delivery Man
              </div>
            }
            okButtonProps={{ disabled: !!!selectedDeliveryManId }}
            visible={!!assignment}
            onOk={assignDeliveryHero}
            okText="Assign"
            cancelButtonProps={{
              color: "red",
              type: "ghost",
              danger: true,
            }}
            confirmLoading={loading}
            onCancel={() => {
              setDeliveryManData({ loading: false, list: [] });
              setSelectedDeliveryManId("");
              setAssignment(false);
            }}
            // destroyOnClose={true}
          >
            <div>
              <Select
                showSearch
                allowClear={true}
                style={{ width: "100%", marginBottom: 15 }}
                value={selectedDeliveryManId}
                placeholder="Select a Delivery Man 01..."
                optionFilterProp="children"
                onChange={(val) => setSelectedDeliveryManId(val as string)}
                onSearch={debounceDeliveryManFetcher}
              >
                {deliveryManData?.list?.map((man, i) => (
                  <Select.Option value={man?.value} key={i}>
                    {man?.label?.replace("+88", "")}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Modal>



        </div>
      </div>
    </div>
  );
};

TopHeading.propTypes = {
  UserId: PropTypes.string,
  GetStatus: PropTypes.string,
  GetPrime: PropTypes.string,
  GetFlagship: PropTypes.string,
  GetRocket: PropTypes.string,
  name: PropTypes.string,
};

export default TopHeading;
