import {
  AutoComplete,
  Button,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Rate,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import dot from "../../images/dot.png";
import { authenticateToken } from "../../utils/auth";
import { getPage, getParamValue } from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import { debounce } from "lodash";
import Currency from "../common/Currency";
import TopHeading from "./TopHeading";
import moment from "moment";
import AddMerchant from "./AddMerchant";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { string } from "prop-types";
import { responseNotification } from "../../utils/notify";

const { Option } = Select;

const MerchantList = () => {
  const [status, setStatus] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [limit, setLimit] = useState(16);
  const [merchantDetailsId, setMerchantDetailsId] = useState("");
  const [merchantDetailsStatus, setMerchantDetailsStatus] = useState("");
  const ACTIVE = "ACTIVE";
  const REJECT = "REJECT";

  const [usersData, setUsersData] = useState({
    loading: false,
    data: null,
  });
  const [usersOptionsByMobile, setUsersOptionsByMobile] = useState({
    loading: false,
    list: null,
  });
  const [usersOptionsByName, setUsersOptionsByName] = useState({
    loading: false,
    list: null,
  });
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [editDrawer, setEditDrawer] = useState();

  const fetchRef = useRef(null);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");

  const reseAllFieldData = () => {
    setStatus("");
    setMobileNumber("");
    setCompanyName("");
    setEmail("");
    setLimit(16);
    form.resetFields();
  };

  const getUsers = useCallback(async () => {
    setUsersData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_USER_API}`;
    axios
      .get(
        `${encodedUri}/business/all?` +
          `page=${page || 0}` +
          `&limit=${limit || 16}` +
          (status ? `&status=${status}` : ``) +
          (mobileNumber
            ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")?.trim()}`
            : ``) +
          (companyName ? `&companyName=${companyName}` : ``) +
          (email ? `&emailAddress=${email}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setUsersData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setUsersData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [page, limit, companyName, mobileNumber, status, email]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const getUsersOptionsByMobile = useCallback(
    async (value) => {
      setUsersOptionsByMobile({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      console.log("value", value);

      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${20}` +
            (value
              ? `&mobileNumber=%2B88${value?.replace("+88", "")?.trim()}`
              : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setUsersOptionsByMobile({
            loading: false,
            list: res.data?.businesses?.map((user) => ({
              label: user.mobileNumber?.replace("+88", ""),
              value: user.mobileNumber,
            })),
          });
        })
        .catch((err) => {
          setUsersOptionsByMobile({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [status]
  );

  const getUsersOptionsByName = useCallback(
    async (value) => {
      setUsersOptionsByName({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${20}` +
            (value ? `&companyName=${value}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setUsersOptionsByName({
            loading: false,
            list: res.data?.businesses?.map((user) => ({
              label: user.companyName,
              value: user.companyName,
            })),
          });
        })
        .catch((err) => {
          setUsersOptionsByName({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [status]
  );

  useEffect(() => {
    getUsersOptionsByMobile("");
    getUsersOptionsByName("");
  }, [getUsersOptionsByMobile, getUsersOptionsByName]);

  // const getUsersOptionsDebounce = (mobileNumber) => {
  //   const fetcher = (mobileNumber) => {
  //     getUsersOptions(mobileNumber);
  //   };

  //   return debounce(fetcher, 0.8);
  // };

  const getUsersOptionsDebounceForMobile = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      getUsersOptionsByMobile(value);
    };

    return debounce(loadOptions, 800);
  }, []);

  const getUsersOptionsDebounceForName = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      getUsersOptionsByName(value);
    };

    return debounce(loadOptions, 800);
  }, []);

  const handleOk = (e) => {
    e.preventDefault();
    const encodedUri = `${process.env.REACT_APP_USER_API}`;
    fetch(
      `${encodedUri}/business/updateMerchantStatus/${merchantDetailsId}/${merchantDetailsStatus}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    )
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        getUsers();
        responseNotification("Selected Item Successfully Update.");
      })
      .catch((err) => {
        responseNotification(
          err.message || "Something went wrong to Merchant List"
        );
      });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    responseNotification("Cancel");
  };

  const approveMerchants = (id, status) => {
    setMerchantDetailsId(id);
    setMerchantDetailsStatus(status);
  };

  return (
    <React.Fragment>
      <TopHeading total={usersData?.data?.totalElements} refetch={getUsers} />
      <section className="search_area">
        <Form form={form} className="search_form">
          <Form.Item name="companyName">
            <AutoComplete
              onSearch={getUsersOptionsDebounceForName}
              onSelect={(val) => setCompanyName(val)}
              options={usersOptionsByName?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                usersOptionsByName?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                size="large"
                placeholder="Search by Company Name"
                style={{ minWidth: 100, width: "calc(100% - 50px)" }}
                onSearch={(val) => setCompanyName(val)}
                enterButton
                loading={usersOptionsByName.loading}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item name="mobileNumber">
            <AutoComplete
              onSearch={getUsersOptionsDebounceForMobile}
              onSelect={(val) => setMobileNumber(val)}
              options={usersOptionsByMobile?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                usersOptionsByMobile?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                size="large"
                placeholder="Search by Phone (01...)"
                style={{ minWidth: 100, width: "calc(100% - 50px)" }}
                onSearch={(val) => setMobileNumber(val)}
                enterButton
                loading={usersOptionsByMobile.loading}
                pattern={`[0-9]`}
                maxLength={11}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item name="email">
            <Input.Search
              size="large"
              style={{ minWidth: 100, width: "calc(100% - 50px)" }}
              placeholder="Enter full email"
              onSearch={(val) => setEmail(val)}
              enterButton
            />
          </Form.Item>
          <Form.Item>
            <Select
              defaultValue={status}
              placeholder="Merchant Status"
              onChange={(val) => setStatus(val)}
              value={status}
              style={{ minWidth: 300 }}
            >
              <Option value={""}>ALL</Option>
              <Option value={"ACTIVE"}>ACTIVE</Option>
              <Option value={"REJECT"}>REJECT</Option>
              <Option value={"HOLD"}>HOLD</Option>
            </Select>
          </Form.Item>
        </Form>

        <div className="search_btn">
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      </section>

      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  <div className="single-rider-heading">
                    <span>Company Name</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Owner Name</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Mobile Number</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Onboarded</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Status</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Action</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {usersData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div className="rider-table-area">
                  {usersData?.data?.businesses?.length ? (
                    usersData?.data?.businesses?.map((user, index) => (
                      <React.Fragment key={index}>
                        <Link to={`/merchants/${user?.id}/details`}>
                          <div className="single-rider-wrapper">
                            <div className="single-rider">
                              <span className="name">{user?.companyName}</span>
                            </div>

                            <div className="single-rider">
                              <span className="name">{user?.ownersName}</span>
                            </div>
                            <div className="single-rider">
                              <span className="name">{user?.mobileNumber}</span>
                            </div>
                            <div className="single-rider">
                              <span className="name">
                                {moment(user?.createdAt).format(
                                  "DD/MM/YY HH:MMa"
                                )}
                              </span>
                            </div>
                            <div className="single-rider">
                              <span
                                className={
                                  user?.status === "ACTIVE"
                                    ? `active-btn radius-btn ${user?.status?.toLowerCase()}`
                                    : user?.status === "REJECT"
                                    ? `inactive-btn radius-btn ${user?.status?.toLowerCase()}`
                                    : user?.status === "HOLD"
                                    ? `pending-btn radius-btn ${user?.status?.toLowerCase()}`
                                    : ""
                                }
                              >
                                {/* {user?.personalInformation
                                  ?.reasonForOpeningAccount || "ACTIVE"} */}
                                {user?.status === "ACTIVE"
                                  ? "ACTIVE"
                                  : user?.status === "REJECT"
                                  ? "REJECT"
                                  : user?.status === "HOLD"
                                  ? "HOLD"
                                  : ""}
                              </span>
                            </div>
                            <div className="single-rider ">
                              <span title="Edit">
                                <Link
                                  to="/"
                                  className="btn btn-sm btn-warning mx-1"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEditDrawer(user);
                                  }}
                                >
                                  <EditOutlined />
                                </Link>
                              </span>
                              {user?.status === "ACTIVE" ? (
                                <span title="Reject">
                                  <Popconfirm
                                    title="Are you sure you want to REJECT the Merchant?"
                                    onConfirm={handleOk}
                                    onCancel={handleCancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button
                                      type="primary"
                                      style={{ padding: "0.25rem 0.5rem" }}
                                      className="btn btn-sm btn-danger mx-1"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        approveMerchants(user?.id, REJECT);
                                      }}
                                    >
                                      <CloseOutlined />
                                    </Button>
                                  </Popconfirm>
                                </span>
                              ) : user?.status === "HOLD" ? (
                                <>
                                  <Popconfirm
                                    title="Are you sure you want to ACTIVE the Merchant?"
                                    onConfirm={handleOk}
                                    onCancel={handleCancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <span title="Approve">
                                      <Button
                                        type="default"
                                        className="mx-1 btn-warning"
                                        style={{
                                          padding: "0.25rem 0.5rem",
                                          background: "green",
                                          borderColor: "green",
                                          color: "white",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          approveMerchants(user?.id, ACTIVE);
                                        }}
                                      >
                                        <CheckOutlined />
                                      </Button>
                                    </span>
                                  </Popconfirm>
                                  <Popconfirm
                                    title="Are you sure you want to REJECT the Merchant?"
                                    onConfirm={handleOk}
                                    onCancel={handleCancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <span title="Reject">
                                      <Button
                                        type="primary"
                                        style={{ padding: "0.25rem 0.5rem" }}
                                        className="btn btn-sm btn-danger mx-1"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          approveMerchants(user?.id, REJECT);
                                        }}
                                      >
                                        <CloseOutlined />
                                      </Button>
                                    </span>
                                  </Popconfirm>
                                </>
                              ) : (
                                <>
                                  <Popconfirm
                                    title="Are you sure you want to ACTIVE the Merchant?"
                                    onConfirm={handleOk}
                                    onCancel={handleCancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <span title="Approve">
                                      <Button
                                        type="default"
                                        className="mx-1 btn-warning"
                                        style={{
                                          padding: "0.25rem 0.5rem",
                                          background: "green",
                                          borderColor: "green",
                                          color: "white",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          approveMerchants(user?.id, ACTIVE);
                                        }}
                                      >
                                        <CheckOutlined />
                                      </Button>
                                    </span>
                                  </Popconfirm>
                                </>
                              )}
                            </div>
                          </div>
                        </Link>
                      </React.Fragment>
                    ))
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        width: "100%",
                        padding: "40px 0",
                        color: "red",
                      }}
                    >
                      No Merchant Found
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>

          <Drawer
            destroyOnClose={true}
            title="Update Merchant"
            width={800}
            onClose={() => setEditDrawer(undefined)}
            visible={!!editDrawer}
            bodyStyle={{ paddingBottom: 0 }}
            footer={
              <div
                style={{
                  textAlign: "left",
                }}
              ></div>
            }
          >
            <AddMerchant
              data={editDrawer}
              onCloseMethod={() => {
                setEditDrawer(undefined);
                getUsers();
              }}
            />
          </Drawer>

          <PaginationTwo
            {...usersData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MerchantList;
