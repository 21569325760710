import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Radio,
    Row,
    Select,
  } from "antd";
  import axios from "axios";
  import _ from "lodash";
  import React, { useCallback, useEffect, useState } from "react";
  import { qTypeArray } from "../../utils";
  import { authenticateToken } from "../../utils/auth";
  import { responseNotification } from "../../utils/notify";
  import { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";
  import { useSelector } from "react-redux";
  const CheckboxGroup = Checkbox.Group;
  
  const GroupForm = ({ visibleData, onCloseMethod }: any) => {
    // const type = "Q_COMMERCE";
    const type = "Q_PARCEL";
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [groupData, setGroupData] = useState(undefined);
    const [iconUrl, setIconUrl] = useState(undefined);
    const [error, setError] = useState("");
    const [key, setKey] = useState<any>("");
  //   const [type, setType] = useState<any>();
    // --------------------------------
    const [checkedList, setCheckedList] = React.useState({});
    const [indeterminate, setIndeterminate] = React.useState(true);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkAllOfAction, setCheckAllOfAction] = React.useState("all");
    // --------------------------------
  
    const [serviceOptions, setServiceOptions] = useState({
      loading: false,
      list: [],
    });
  
    const onSubmit = async (data: any) => {
      setLoading(true);
  
      const readyData = data && {
        name: data.name,
        type: type,
        serviceActions: Object.keys(checkedList)?.map((key) => ({
          action: checkedList?.[key],
          serviceId: key,
        })),
      };
  
      if (groupData) {
        await fetch(`${process.env.REACT_APP_AUTH_API}/group`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: groupData?.id,
            isActive: groupData?.isActive,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);
  
            if (res.statusCode === 200) {
              responseNotification("Group Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_AUTH_API}/group`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);
  
            if (res.statusCode === 200) {
              responseNotification("Group Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    };
  
    const getServiceOptions = useCallback(
      async () => {
        if (type === undefined) return;
        setServiceOptions({ loading: true, list: null });
        const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
        return axios
          .get(
            `${encodedUri}/service/by-type` +
              (type ? `?Type=${type}&IsActive=true` : ``),
              // (key ? `&name=${key}` : ``),
            {
              headers: {
                Authorization: `Bearer ${authenticateToken()}`,
              },
            }
          )
          .then((res) => {
            const dataList = res.data?.services?.map((service) => ({
              label: service.name,
              value: service.id,
            }));
            const sortedByAlphabetically = dataList.sort((a, b) =>
              a.label > b.label ? 1 : -1
            );
            setServiceOptions({
              loading: false,
              list: sortedByAlphabetically,
            });
          })
          .catch((err) => {
            setServiceOptions({ loading: false, list: [] });
            console.error("Services: Error", err);
          });
      },
      [type]
    );
  
    const getServicesByGroup = useCallback(async (groupId?: string) => {
      setLoading(true);
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      return axios
        .get(`${encodedUri}/service-group/services-by-group?groupId=${groupId}`, {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        })
        .then((res: any) => {
          let temp = {};
          res?.data?.serviceGroups?.forEach((service) => {
            if (service?.serviceNameId) {
              temp[service?.serviceNameId] = service?.action;
            }
          });
          setCheckedList(temp);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error("Services: Error", err);
        });
    }, []);
  
  
    const onChange = (list) => {
      try {
        let temp = {};
        list?.forEach((id) => {
          if (checkedList?.[id]) {
            temp[id] = checkedList?.[id];
          } else {
            temp[id] = "all";
          }
        });
  
        setCheckedList(temp);
      } catch (e) {
        console.log(e);
      }
    };
  
    const onChangeAction = (id, value) => {
      const temp = { ...checkedList };
      temp[id] = value;
      setCheckedList(temp);
    };
  
    const onCheckAllChange = (e: CheckboxChangeEvent) => {
      // console.log(1111, serviceOptions?.list);
      try {
          let temp = {};
          if(e.target.checked) {
              serviceOptions?.list?.forEach((item) => {
                  temp[item.value] = checkAllOfAction;
              });
          }
    
          setCheckedList(temp);
      } catch (e) {
          console.log(e);
      }
      // setCheckedList(e.target.checked ? plainOptions : []);
      setIndeterminate(false);
      setCheckAll(e.target.checked);
    };
  
    useEffect(() => {
      if (visibleData) {
        setGroupData(visibleData);
        getServicesByGroup(visibleData?.id);
      //   setType(visibleData.type);
      }
    }, [getServicesByGroup, visibleData]);
  
    useEffect(() => {
      if (groupData) {
        form.resetFields(Object.keys(visibleData));
        setIconUrl(visibleData?.icon);
      }
    }, [form, groupData, visibleData]);
  
    useEffect(() => {
      getServiceOptions();
    }, [getServiceOptions]);
  
    return (
      <div className="add-product drawer-toggle-wrapper">
        <div className="drawer-toggle-inner-wrapper">
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="ant-form ant-form-vertical"
            onFinish={onSubmit}
            form={form} // like ref
            layout="vertical"
            initialValues={{
              ...groupData,
            }}
          >
            <Form.Item
              hasFeedback
              label="Group Name"
              rules={[
                {
                  required: true,
                  message: "Name is Required!",
                },
              ]}
              name="name"
              style={{ minWidth: "100%" }}
            >
              <Input id="name" type="text" placeholder="Enter Type Name" />
            </Form.Item>
  
            <>
              <b className="mb-3 border-bottom w-100 pb-2"> Select Services</b>
  
              <Row style={{ padding: 5, minWidth: "100%" }}>
                <Col span={14}>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                  >
                    Check all
                  </Checkbox>
                </Col>
                {/* <Col span={10}>
                  <Radio.Group
                    onChange={(e) => setCheckAllOfAction(e.target.value)}
                    value={checkAllOfAction}
                  >
                    <Radio value={"all"}>All</Radio>
                    <Radio value={"view"}>View</Radio>
                  </Radio.Group>
                </Col> */}
              </Row>
  
              <Divider />
  
              <CheckboxGroup
                style={{ width: "100%" }}
                onChange={onChange}
                value={Object.keys(checkedList)}
              >
                {serviceOptions?.list?.length
                  ? serviceOptions?.list?.map((serviceGroup) => (
                      <Row style={{ padding: 5 }}>
                        <Col span={14}>
                          <Checkbox
                            value={serviceGroup?.value}
                            checked={!!checkedList?.[serviceGroup]}
                          >
                            {serviceGroup?.label}
                          </Checkbox>
                        </Col>
                        {/* <Col span={10}>
                          <Radio.Group
                            onChange={(e) =>
                              onChangeAction(serviceGroup?.value, e.target.value)
                            }
                            value={checkedList[serviceGroup?.value]}
                          >
                            <Radio value={"all"}>All</Radio>
                            <Radio value={"view"}>View</Radio>
                          </Radio.Group>
                        </Col> */}
                      </Row>
                    ))
                  : ""}
              </CheckboxGroup>
            </>
  
            <div className="buttons-container">
              <Button
                disabled={loading || !!error}
                loading={(loading ? "loading" : undefined) as any}
                type="primary"
                htmlType="submit"
                className="add-submit-btn text-center mr-2"
              >
                Submit
              </Button>
              <Button
                type="ghost"
                htmlType="button"
                onClick={() => {
                  form?.resetFields();
                }}
                className="reset-submit-btn text-center mr-2"
              >
                Reset
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  };
  
  export default GroupForm;
  