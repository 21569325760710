import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Rate,
  Select,
  Spin,
} from "antd";
import { BranchesOutlined, ClockCircleOutlined } from "@ant-design/icons";

import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import { authenticateToken } from "../../utils/auth";
import {
  getPage,
  getParamValue,
  getUpdatedUrl,
  OrderStatus,
} from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import TopHeading from "./TopHeading";
import { debounce } from "lodash";

import moment from "moment";
import Currency from "../common/Currency";
import _ from "lodash";
import Empty from "../common/Empty";

import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const { Option } = Select;
const { RangePicker }: any = DatePicker;

const DisbursedItem = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const page = getParamValue(location.search, "page") || 0;
  const [status, setStatus] = useState(
    getParamValue(location.search, "status") || ""
  );
  const [range, setRange] = useState({
    from: getParamValue(location.search, "from")
      ? getParamValue(location.search, "from")
      : null,
    to: getParamValue(location.search, "to")
      ? getParamValue(location.search, "to")
      : null,
  });
  const [driverMobileNumber, setDriverMobileNumber] = useState(
    getParamValue(location.search, "driverMobileNumber") || ""
  );
  const [orderOptions, setOrderOptions] = useState({
    list: [],
    loading: false,
  });
  const [userMobileNumber, setUserMobileNumber] = useState(
    getParamValue(location.search, "userMobileNumber") || ""
  );
  const [selectedMerchant, setSelectedMerchant] = useState(
    getParamValue(location.search, "selectedMerchant") || ""
  );
  const [limit, setLimit] = useState(16);
  const [tripsData, setTripsData] = useState({
    loading: false,
    data: null,
  });
  const [OptionsDriver, setOptionsDriver] = useState({
    loading: false,
    list: null,
  });
  const [OptionsUser, setOptionsUser] = useState({
    loading: false,
    list: null,
  });
  const [selectedTrip, setSelectedTrip] = useState(undefined);
  const [orderId, setOrderId] = useState("");
  //checkbox 
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const fetchRef = useRef(null);

  const reseAllFieldData = () => {
    setStatus("");
    setDriverMobileNumber("");
    setSelectedMerchant("");
    setUserMobileNumber("");
    form.resetFields(["driverSearch", "userSearch", "statusSearch"]);
    history.replace(location.pathname);
    window.location.reload();
    setOrderId("");
    setRange({
      from: null,
      to: null,
    });
  };

  const getTrips = useCallback(async () => {
    if (!userMobileNumber) return;
    let shouldResetPage = false;
    if (
      userMobileNumber &&
      getParamValue(location.search, "userMobileNumber") != userMobileNumber
    ) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("userMobileNumber", `${userMobileNumber}`));
    }
    if (
      driverMobileNumber &&
      getParamValue(location.search, "driverMobileNumber") != driverMobileNumber
    ) {
      shouldResetPage = true;
      history.push(
        getUpdatedUrl("driverMobileNumber", `${driverMobileNumber}`)
      );
    }
    if (status && getParamValue(location.search, "status") != status) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("status", `${status}`));
    }
    if (shouldResetPage) history.push(getUpdatedUrl("page", `${0}`));
    setTripsData({ loading: true, data: null });
    axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/admin/order?type=Q_PARCEL` +
          `&page=${page || 0}` +
          `&limit=${limit || 16}` +
          `&status=DELIVERED` +
          `&paymentStatus=paid` +
          (userMobileNumber ? `&mobileNumber=${userMobileNumber}` : ``) +
          (range?.from ? `&from=${moment(range?.from).toISOString()}` : ``) +
          (range?.to ? `&to=${moment(range?.to).toISOString()}` : ``) +
          (orderId ? `&orderId=${orderId}` : ``),

        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setTripsData({ loading: false, data: res.data });
        }
      })
      .catch((err) => {
        setTripsData({ loading: false, data: [] });
        console.error("Trips: Error", err);
      });
  }, [
    limit,
    driverMobileNumber,
    userMobileNumber,
    status,
    page,
    orderId,
    range,
  ]);

  const getOrderOptions = useCallback(
    async (getOrderId) => {
      setOrderOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order?type=Q_PARCEL` +
            (getOrderId
              ? `&orderId=${getOrderId}`
              : `` + getOrderId
              ? `&orderId=${getOrderId}`
              : ``) +
            `&page=${page || 0}` +
            `&status=DELIVERED` +
            `&paymentStatus=Unpaid` +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map((order, index) => ({
              key: index,
              label: order.orderId,
              value: order.orderId,
            })),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, status]
  );

  const getOptionsDriver = useCallback(
    async (mobileNumber) => {
      setOptionsDriver({ loading: true, list: null });
      // const encodedUri = `${process.env.REACT_APP_FARE_API}`;
      axios
        .get(
          `${process.env.REACT_APP_USER_API}/admin/driver?` +
            `page=${0}` +
            `&limit=${20}` +
            // (status ? `&status=${status}` : ``) +
            (mobileNumber ? `&mobile=${mobileNumber}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOptionsDriver({
            loading: false,
            list: _.without(
              res.data?.drivers?.map((driver) => {
                if (driver?.name && driver?.mobileNumber) {
                  return {
                    label: driver?.name,
                    value: driver?.mobileNumber,
                  };
                }
              }),
              undefined
            ),
          });
        })
        .catch((err) => {
          setOptionsDriver({ loading: false, list: [] });
          console.error("Trips: Error", err);
        });
    },
    [status]
  );

  const getOptionsUser = useCallback(
    async (mobileNumber) => {
      setOptionsUser({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${500}` +
            // (status ? `&status=${status}` : ``) +
            (mobileNumber ? `&mobileNumber=${mobileNumber}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOptionsUser({
            loading: false,
            list: _.without(
              res.data?.businesses?.map((user) => {
                if (user?.companyName && user?.mobileNumber) {
                  return {
                    label: `${user?.ownersName} (${user?.mobileNumber})`,
                    value: user?.mobileNumber,
                  };
                }
              }),
              undefined
            ),
          });
        })
        .catch((err) => {
          setOptionsUser({ loading: false, list: [] });
          console.error("Trips: Error", err);
        });
    },
    [status]
  );

  useEffect(() => {
    getOptionsUser("");
  }, [getOptionsUser]);

  useEffect(() => {
    getTrips();
  }, [getTrips]);

  const getUserOptionsDebounce = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptionsUser({ loading: false, list: null });

      if (fetchId !== fetchRef.current) {
        return;
      }

      getOptionsUser(value);
    };

    return debounce(loadOptions, 800);
  }, [getOptionsUser]);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions]);
// checkbox funcs
const plainOptions = tripsData?.data?.orders?.map((order) => order?.orderId);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onChangeSingle = (id: string) => {
    let list = [...(checkedList || [])];
    list = list?.filter((item) => item !== id);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <React.Fragment>
      <TopHeading
        total={tripsData?.data?.totalElements}
        orders={tripsData.data?.orders || []}
        refetch={getTrips}
      />

      <section className="search_area">
        <Form form={form} className="search_form">
          <Form.Item name="userSearch" className="mb-0 mr-2">
            <Select
              showSearch
              onSearch={getUserOptionsDebounce}
              onSelect={(val: string) =>
                setUserMobileNumber(
                  val.startsWith("+88") ? val.replace("+88", "") : val
                )
              }
              options={OptionsUser?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                OptionsUser?.loading ? <Spin size="small" /> : "No Data"
              }
              placeholder="Select Merchant"
              optionFilterProp="children"
              style={{ width: 200 }}
              filterOption={(input, option) =>
                ((option?.label ?? "") as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item name="range" className="mr-2">
            <RangePicker
              use12Hours
              defaultValue={
                range.from != null && range.to != null
                  ? [moment(range.from), moment(range.to)]
                  : null
              }
              onChange={(_, f) => {
                console.log("moment", moment.utc(f[0])?.toISOString());
                setRange({ from: f?.[0], to: f?.[1] });
              }}
              // showTime
            />
          </Form.Item>

          <Form.Item name="orderId" initialValue={orderId} className="mr-2">
            <AutoComplete
              dropdownMatchSelectWidth={250}
              style={{ width: 250 }}
              placeholder="Invoice ID"
              onSearch={(e) => handleSearch(e, "order")}
              onSelect={(val) => {
                setOrderId(val);
              }}
              options={orderOptions?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                orderOptions?.loading ? <Spin size="small" /> : null
              }
            >
              {/* <Input.Search
                size="large"
                placeholder="Order ID"
                style={{ minWidth: 100, width: "calc(100% - 0px)" }}
                onSearch={(val) => {
                  setOrderId(val);
                }}
                enterButton
              /> */}
            </AutoComplete>
          </Form.Item>

          {/* <Form.Item
            name="driverSearch"
            className="mb-0"
            initialValue={driverMobileNumber}
          >
            <AutoComplete
              onSearch={getDriverTripsOptionsDebounce}
              onSelect={(val) =>
                setDriverMobileNumber(
                  val.startsWith("+88") ? val.replace("+88", "") : val
                )
              }
              options={OptionsDriver?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                OptionsDriver?.loading ? <Spin size="small" /> : "No Data"
              }
            >
              <Input.Search
                size="large"
                placeholder="Driver Phone (01...)"
                onSearch={(val) =>
                  setDriverMobileNumber(
                    val.startsWith("+88") ? val.replace("+88", "") : val
                  )
                }
                enterButton
                loading={OptionsDriver.loading}
                pattern={`[0-9]`}
                maxLength={11}
              />
            </AutoComplete>
          </Form.Item> */}
        </Form>

        <div className="search_btn">
          <Button type="dashed"  size="large">
             Search
          </Button>
        </div>

        <div className="search_btn">
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      </section>

      <div className="flex">
        <Checkbox
          className="mb-2"
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Check all
        </Checkbox>
      </div>

      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  <div className="single-rider-heading  major-col  ml-4">
                    <span>Invoice Id</span>
                  </div>
                  <div className="single-rider-heading  major-col">
                    <span>Store Name</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Collected</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>COD Fee</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Delivery Charge</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Total Charge</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Payout</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Status</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {tripsData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div className="rider-table-area">
                <Checkbox.Group
                    value={checkedList}
                    onChange={onChange}
                    className="rider-table-area"
                    style={{ width: "100%", height: "100%" }}
                  >
                  {tripsData?.data?.orders?.length ? (
                    tripsData?.data?.orders?.map((trip, index) => (
                      <React.Fragment key={index}>
                        <Link to={`/orders/${trip?.orderId}/details`}>
                          <div className="single-rider-wrapper">
                            <div className="single-riderx major-colx mr-2">
                                <Checkbox
                                  value={trip?.orderId}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onChangeSingle(trip?.orderId);
                                  }}
                                />
                            </div>
                            <div className="single-rider major-col">
                              <span className="name">{trip?.orderId}</span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name">
                                {trip?.parcelOrder?.storeName}
                              </span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳{trip?.parcelOrder?.amountToCollect}
                              </span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳
                                {Math.round(
                                  trip?.parcelOrder?.amountToCollect / 100
                                )}
                              </span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳
                                {trip?.parcelOrder?.deliveryCharge -
                                  Math.round(
                                    trip?.parcelOrder?.amountToCollect / 100
                                  )}
                              </span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳{trip?.parcelOrder?.deliveryCharge}
                              </span>
                            </div>
                            <div className="single-rider major-col">
                              <span className="name text-uppercase">
                                ৳
                                {trip?.parcelOrder?.amountToCollect -
                                  trip?.parcelOrder?.deliveryCharge}
                              </span>
                            </div>
                            <div className="single-rider">
                              <span
                                className={
                                  trip?.status === "REQUESTED"
                                    ? "pending-btn radius-btn"
                                    : trip?.status === "COMPLETED"
                                    ? "active-btn radius-btn"
                                    : "inactive-btn radius-btn"
                                }
                              >
                                {trip?.status}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </React.Fragment>
                    ))
                  ) : (
                    <Empty data="Please select a merchant" />
                  )}
                  </Checkbox.Group>
                </div>
              </div>
            )}
          </div>
          {/* <Drawer
            title="Rider Details"
            width={800}
            onClose={() => {
              setSelectedTrip(null);
              getTrips();
            }}
            visible={selectedTrip}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
          >
            <TripDetails tripDetails={selectedTrip} />
          </Drawer> */}
          <PaginationTwo
            {...tripsData?.data}
            limit={limit}
            page={tripsData?.data?.currentPageNumber || 0}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DisbursedItem;
