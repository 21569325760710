import React, { ReactElement } from "react";

import { BrowserRouter, Switch } from "react-router-dom";
import SignUp from "../components/signUp/signup";
// all routes import here
import Login from "../pages/login";
import { PrivateRoute } from "../routes/PrivateRoutes";
import PublicRoute from "../routes/PublicRoutes";
import Dashboard from "../pages/index";
import RidersPage from "../pages/riders/index";
import UsersPage from "../pages/users";

import UserDetails from "../components/Users/UserDetails";
import IssuePage from "../pages/issues";
import IssueDetailsPage from "../pages/issues/details";
import TripPage from "../pages/trips/index";
import TripDetailsPage from "../pages/trips/details";
import RiderDetailsPage from "../pages/riders/details";
import RiderOnlineDetailsPage from "../pages/onLineRiders/details";
import ManufacturerPage from "../pages/manufacturers";
import OnLineRiders from "../pages/onLineRiders/index";
import OFFLineRiders from "../pages/offLineRiders/index";
import VehiclesPage from "../pages/vehicles/index";
import MerchantPage from "../pages/merchants";
import OrderPage from "../pages/orders/index";
import OrderDetailsPage from "../pages/orders/details";
import SettlementPage from "../pages/settlement";
import DisbursedPage from "../pages/disbursed";
import ShopDetailsPage from "../pages/merchants/details";
import ShopOrderPage from "../pages/merchants/orders";
import SellementHistoryList from "../pages/settlementHistory";
import SettlementByDatePage from "../pages/settlementByDate";
import PickMePage from "../pages/pickme";
import PickmeDetailsPage from "../pages/pickme/details";
import SortingOrderPage from "../pages/sorting-orders";
import PickupOrderPage from "../pages/pickup-orders";
import SingleOrderPage from "../pages/orders/single-order";
import ReportPage from "../pages/report";
import LogisticPickMePage from "../pages/logistic";
import LogisticRunSheet from "../pages/logistic/runsheet/runsheet";
import RunSheetDetails from "../pages/logistic/runsheet/details";
import LogisticOrderListPage from "../pages/logistic/orderlist";
import LogisticSortingListPage from "../pages/logistic/sorting";
import HubPage from "../pages/hub";
import ForgetPage from "../pages/forgetpassword";
import OTPPage from "../pages/otpForm";
import AdminsPage from "../pages/admins";
import ServicePage from "../pages/admins/services";
import GroupPage from "../pages/admins/group";
import CourierPage from "../pages/courier";
import RunSheetPage from "../pages/courier-runsheet";
import RunSheetDetailsPage from "../pages/courier-runsheet-details";
import CourierSettlementPage from "../pages/courier-settlement";

const Routes = (): ReactElement<any, any> => {
  return (
      <Switch>
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/`}
          component={Dashboard}
        />
        <PublicRoute
          exact={true}
          restricted={true}
          path={`${process.env.PUBLIC_URL}/login`}
          component={Login}
        />
        <PublicRoute
          exact={true}
          restricted={true}
          path={`${process.env.PUBLIC_URL}/forgotpassword`}
          component={OTPPage}
        />
        <PublicRoute
          exact={true}
          restricted={true}
          path={`${process.env.PUBLIC_URL}/forget-password-verify`}
          component={ForgetPage}
        />
        <PublicRoute
          exact={true}
          restricted={true}
          path={`${process.env.PUBLIC_URL}/signup`}
          component={SignUp}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/hub`}
          component={HubPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/riders`}
          component={RidersPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/riders/:tripId/details`}
          component={RiderDetailsPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/onLineRiders`}
          component={OnLineRiders}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/online-riders/:tripId/details`}
          component={RiderOnlineDetailsPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/offLineRiders`}
          component={OFFLineRiders}
        />
        {/* <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/users`}
          component={UsersPage}
        /> */}
        {/* <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/vehicles`}
          component={VehiclesPage}
        /> */}
        {/* <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/trips`}
          component={TripPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/trips/:tripId/details`}
          component={TripDetailsPage}
        /> */}
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/sorting-orders`}
          component={SortingOrderPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/pickup-order-list`}
          component={PickupOrderPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/orders`}
          component={OrderPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/orders/:orderId/details`}
          component={OrderDetailsPage}
        />

        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/single-order`}
          component={SingleOrderPage}
        />

        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/merchants`}
          component={MerchantPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/merchants/:merchantId/details`}
          component={ShopDetailsPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/merchants/:merchantId/orders`}
          component={ShopOrderPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/merchants/:merchantId/orders/:orderId/details`}
          component={IssueDetailsPage}
        />

        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/settlements`}
          component={SettlementPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/settlement-by-date`}
          component={SettlementByDatePage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/disbursed`}
          component={DisbursedPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/settlement-history`}
          component={SellementHistoryList}
        />

        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/pickup`}
          component={PickMePage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/pickme/:orderId/details`}
          component={PickmeDetailsPage}
        />

        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/reports`}
          component={ReportPage}
        />

        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/logistic-pickup`}
          component={LogisticPickMePage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/runsheet`}
          component={LogisticRunSheet}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/runsheet/:runsheetId/details`}
          component={RunSheetDetails}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/logistic-orderlist`}
          component={LogisticOrderListPage}
        />

        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/logistic-sorting`}
          component={LogisticSortingListPage}
        />

        {/* <PrivateRoute
         exact={true} 
         path={`${process.env.PUBLIC_URL}/disbursed`}
         component={DisbursedPage}/> */}
        {/* <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/issues`}
          component={IssuePage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/issues/:issueId/details`}
          component={IssueDetailsPage}
        /> */}
        {/* <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/manufacturers`}
          component={ManufacturerPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/tipCancelReassion`}
          component={ManufacturerPage}
        /> */}
        {/* <Route exact path="*" component={NotFound} /> */}
        

        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/administration/admins`}
          component={AdminsPage}
        />

        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/administration/services`}
          component={ServicePage}
        />

        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/administration/groups`}
          component={GroupPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/courier-management/parcel-sorting`}
          component={CourierPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/courier-management/parcel-runsheet`}
          component={RunSheetPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/courier-management/parcel-runsheet-details`}
          component={RunSheetDetailsPage}
        />
        <PrivateRoute
          exact={true}
          path={`${process.env.PUBLIC_URL}/courier-management/parcel-settlement`}
          component={CourierSettlementPage}
        />
      </Switch>
  );
};
export default Routes;
