import React, { ReactElement } from "react";
import Layout from '../../components/Layout';
import AdminList from "../../components/Admin";

const AdminPage = (): ReactElement => {
  return (
    <Layout>
      <AdminList />
    </Layout>
  );
};

export default AdminPage;
