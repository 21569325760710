import {
  AutoComplete,
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Rate,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import dot from "../../images/dot.png";
import { authenticateToken } from "../../utils/auth";
import { getPage, getParamValue } from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import { debounce } from "lodash";
import Currency from "../common/Currency";
import TopHeading from "./TopHeading";
import moment from "moment";

import { responseNotification } from "../../utils/notify";

const { Option } = Select;
const { RangePicker }: any = DatePicker;
const RunSheetDetails = () => {
  const [status, setStatus] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [limit, setLimit] = useState(16);
  const location = useLocation();
  const sortingOrderId = getParamValue(location.search, "sortingOrderId");
  

  const [parcelRunSheetData, setParcelRunSheetData] = useState({
    loading: false,
    data: null,
  });
  const [editDrawer, setEditDrawer] = useState();
  const [range, setRange] = useState({
    from: getParamValue(location.search, "from")
      ? getParamValue(location.search, "from")
      : null,
    to: getParamValue(location.search, "to")
      ? getParamValue(location.search, "to")
      : null,
  });

  const fetchRef = useRef(null);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");

  const reseAllFieldData = () => {
    setStatus("");
    setMobileNumber("");
    setCompanyName("");
    setEmail("");
    setLimit(16);
    form.resetFields();
  };

  const getRunSheetData = useCallback(async () => {
    setParcelRunSheetData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/courier/parcel-run-sheet?` +
          `page=${page || 0}` +
          `&limit=${limit || 16}` +
          `&orderSortingId=${sortingOrderId}` +
          (range?.from ? `&from=${moment(range?.from).toISOString()}` : ``) +
          (range?.to ? `&to=${moment(range?.to).toISOString()}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setParcelRunSheetData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setParcelRunSheetData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [page, limit, companyName, mobileNumber, status, email]);

  useEffect(() => {
    getRunSheetData();
    console.log(parcelRunSheetData)
  }, [getRunSheetData]);

  

  // const handleCancel = (e) => {
  //   e.preventDefault();
  //   setRunsheetModalOpen(false)
  //   setSortingId('')
  // };

  // const openRunSheet = (id) => {
  //   setSortingId(id)
  //   setRunsheetModalOpen(true)
  // };

  // const createRunSheet = (data)=>{
  //   console.log(data)
  //   const request = {
  //     "assignedName": data.assignedName,
  //     "attachment": data.attachment,
  //     "cnNumber": data.cnNumber,
  //     "courierAssignment": data.courierAssignment,
  //     "hour": data.hour,
  //     "orderSortingId": sortingId,
  //     "paymentAmount": data.paymentAmount,
  //     "paymentStatus": data.paymentStatus,
  //     "phoneNumber": data.phoneNumber,
  //     "remarks": data.remarks,
  //     "sackNumber": data.sackNumber
  //   }

    
  //   axios.post(`${process.env.REACT_APP_ORDER_API}/courier/create-parcel-run-sheet`,request,
  //     {
  //         headers: {
  //             Authorization: `Bearer ${authenticateToken()}`,
  //         },
  //     }
  //     ).then((res) => {
  //       // console.log(res)
  //       setLoading(false)
  //       if(res?.status === 200){
  //           responseNotification('Order Runsheet created','success')
  //           getRunSheetData();
  //       }else{
  //           responseNotification(res?.data?.message,'error')
  //       }
  //     })
  //     .catch((err) => {
  //         console.error("Order Sorting: Error", err);
  //     });
  // }


  return (
    <React.Fragment>
      <TopHeading total={parcelRunSheetData?.data?.totalElements} refetch={getRunSheetData} runsheet={parcelRunSheetData?.data?.parcelRunSheet[0]} orders={parcelRunSheetData?.data?.parcelRunSheet[0]?.orderList} />
      {/* <section className="search_area">
        <Form form={form} className="search_form">
          <Form.Item name="range" className="mr-2">
            <RangePicker
              use12Hours
              defaultValue={
                range.from != null && range.to != null
                  ? [moment(range.from), moment(range.to)]
                  : null
              }
              onChange={(_, f) => {
                console.log("moment", moment.utc(f[0])?.toISOString());
                setRange({ from: f?.[0], to: f?.[1] });
              }}
              // showTime
            />
          </Form.Item>
        </Form>

        <div className="search_btn">
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      </section> */}

      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  <div className="single-rider-heading">
                    <span>Order ID</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Status</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Delivery Type</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>City</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Zone</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Created At</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Created By</span>
                  </div>
                  
                  {/* <div className="single-rider-heading">
                    <span>Action</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {parcelRunSheetData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div className="rider-table-area">
                  {parcelRunSheetData?.data?.parcelRunSheet[0]?.orderList?.length ? (
                    parcelRunSheetData?.data?.parcelRunSheet[0]?.orderList?.map((order, index) => (
                      <React.Fragment key={index}>
                        
                          <div className="single-rider-wrapper">
                            <div className="single-rider">
                              <span className="name">{order?.orderId}</span>
                            </div>

                            <div className="single-rider">
                              <span className="name">{order?.status}</span>
                            </div>
                            <div className="single-rider">
                              <span className="name">{order?.parcelOrder?.deliveryType?.toUpperCase()}</span>
                            </div>
                            <div className="single-rider">
                              <span className="name">{order?.parcelOrder?.recipientCity}</span>
                            </div>
                            <div className="single-rider">
                              <span className="name">{order?.parcelOrder?.recipientZone}</span>
                            </div>
                            <div className="single-rider">
                              <span className="name">
                                {moment(order?.createdAt).format(
                                  "DD-MM-YY HH:MMa"
                                )}
                              </span>
                            </div>
                            <div className="single-rider">
                              <span className="name">
                                {order?.customer?.name}
                              </span>
                            </div>
                            

                            {/* <div className="single-rider">
                              <Button type="primary" danger size="middle" onClick={()=>{
                                  openRunSheet(runSheet?.id)
                                }}>
                              Create RunSheet
                              </Button>
                            </div> */}
                          </div>
                        
                      </React.Fragment>
                    ))
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        width: "100%",
                        padding: "40px 0",
                        color: "red",
                      }}
                    >
                      No Order Data Found
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* <Modal
            title="Create Run Sheet"
            open={runsheetModalOpen}
            onCancel={handleCancel}
            footer={null}
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={createRunSheet}
              layout="vertical"
              autoComplete="off"
            >
              <Form.Item
                name="sackNumber"
                label="Sack Number"
                rules={[{ required: true }]}
                className="mb-0"
              >
                <Input id="sackNumber" type="text" placeholder="Enter Sack Number" />
              </Form.Item>
              <Form.Item
                name="courierAssignment"
                label="Courier Assignment"
                rules={[{ required: true }]}
                className="mb-0"
              >
                <Select
                  options={[
                    { value: 'PICKME', label: "PICKME" },
                    { value: 'PATHAO', label: "PATHAO" },
                  ]}
                  //onChange={(value) => setStatus(value)}
                />
              </Form.Item>
              <Form.Item
                name="cnNumber"
                label="CN Number"
                rules={[{ required: true }]}
                className="mb-0"
              >
                <Input id="cnNumber" type="text" placeholder="Enter CN Number" />
              </Form.Item>
              <Form.Item
                name="assignedName"
                label="Assigned Name"
                rules={[{ required: true }]}
                className="mb-0"
              >
                <Input id="assignedName" type="text" placeholder="Enter Assigned Name" />
              </Form.Item>

              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[{ required: true }]}
                className="mb-0"
              >
                <Input id="phoneNumber" type="text" placeholder="Enter Phone Number" />
              </Form.Item>

              <Form.Item
                name="attachment"
                label="Attachment URL"
                rules={[{ required: true }]}
                className="mb-0"
              >
                <Input id="attachment" type="text" placeholder="Enter Attachment URL" />
              </Form.Item>

              <Form.Item
                name="paymentStatus"
                label="Payment Status"
                rules={[{ required: true }]}
                className="mb-0"
              >
                <Select
                  options={[
                    { value: 'PAID', label: "PAID" },
                    { value: 'UNPAID', label: "UNPAID" },
                  ]}
                  //onChange={(value) => setStatus(value)}
                />
              </Form.Item>

              <Form.Item
                name="paymentAmount"
                label="Payment Amount"
                rules={[{ required: true }]}
                className="mb-0"
              >
                <Input id="paymentAmount" type="text" placeholder="Enter Payment Amount" />
              </Form.Item>
              
              <Form.Item
                name="hour"
                label="Required Time to Reach"
                rules={[{ required: true }]}
                className="mb-0"
              >
                <Input id="hour" type="text" placeholder="Enter Hours" />
              </Form.Item>
              
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[{ required: false }]}
                className="mb-0"
              >
                <Input id="remarks" type="text" placeholder="Enter Remarks (If any)" />
              </Form.Item>
              <br/>
              <div className="buttons-container">
                <Button
                  disabled={loading}
                  loading={(loading ? "loading" : undefined) as any}
                  type="primary"
                  htmlType="submit"
                  className="add-submit-btn text-center"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Modal> */}

          <PaginationTwo
            {...parcelRunSheetData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default RunSheetDetails;
