import { ReactElement } from "react";
import SingleIssue from "../../components/Issues/Details";
import Layout from "../../components/Layout";

const IssueDetailsPage = (): ReactElement => {
  return (
    <Layout>
      <SingleIssue inTrip={false} />
    </Layout>
  );
};

export default IssueDetailsPage;
