import {
  Card,
  Col,
  Row,
  Skeleton,
  Button,
  Form,
  Input,
  Radio,
  Comment,
  Avatar,
  Tooltip,
} from "antd";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useParams } from "react-router-dom";
import { authenticateToken } from "../../../utils/auth";
import { getImgUrl } from "../../../utils/index";
import TopHeading from "./TopHeading";
import moment from "moment";
import { responseNotification } from "../../../utils/notify";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import SendOutlined from "@ant-design/icons/lib/icons/SendOutlined";
import { isEditableService } from '../../../utils/services';

const { TextArea } = Input;

const SingleIssue = ({ inTrip = false }: { inTrip: boolean }): ReactElement => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const route = useParams();
  const [status, setStatus] = useState(undefined);
  const [visible, setVisible] = useState(undefined);
  const [issueData, setIssueData] = useState(undefined);

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const fetchIssueDetails = useCallback(() => {
    fetch(
      inTrip
        ? `${process.env.REACT_APP_FARE_API}/issue/issue-by-trip?tripId=${
            (route as any)?.tripId
          }`
        : `${process.env.REACT_APP_FARE_API}/issue/details?issueId=${
            (route as any)?.issueId
          }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setIssueData(data?.issue);
      })
      .catch((err) => console.log(err));
  }, []);

  const onReplySubmit = async (data) => {
    setLoading(true);

    await fetch(`${process.env.REACT_APP_FARE_API}/issue`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        issueId: (route as any).issueId,
        note: data.note,
        status: data.status,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Reply send Successfully", "success");
          fetchIssueDetails();
          form.resetFields();
          setVisible(undefined);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    fetchIssueDetails();
  }, [fetchIssueDetails]);

  const actions = [
    <Button type="text" onClick={() => setVisible(!visible)}>
      <CloseOutlined />
    </Button>,
  ];

  return (
    <React.Fragment>
      {!inTrip && <TopHeading />}
      <div className="user-deatils-area">
        {!issueData ? (
          <Skeleton paragraph={{ rows: 20 }} active />
        ) : (
          <Row gutter={4}>
            <Col span={24} style={{ background: "#fff" }}>
              <div className="user-information-show-tab-wrapper">
                <div className="inner-content">
                  <div className="single-content">
                    <div className="single-information-user-wrapper mb-3">
                      <div className="single-information-user-content">
                        <div className="info-body user-personal-inforamtion ml-0">
                          <h4 className="title">Issue Details</h4>

                          <div className="inner-body-wrapper">
                            <div className="inner-body">
                              {!inTrip && (
                                <>
                                  <div className="single">
                                    <div className="info-name">
                                      <span>Created Date</span>
                                    </div>
                                    <div className="info-desc">
                                      <span className="d-flex justify-content-start">
                                        {moment(issueData?.createdAt).format(
                                          "LLL"
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="single">
                                    <div className="info-name">
                                      <span>Trip Id</span>
                                    </div>
                                    <div className="info-desc">
                                      <span className="text-capitalize">
                                        {issueData?.tripId}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="single">
                                    <div className="info-name">
                                      <span>User Name</span>
                                    </div>
                                    <div className="info-desc">
                                      <span className="text-capitalize">
                                        <Avatar
                                          src={
                                            issueData?.userInfo?.profilePicture
                                          }
                                        />{" "}
                                        {issueData?.userInfo?.name}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="single">
                                    <div className="info-name">
                                      <span>User Type</span>
                                    </div>
                                    <div className="info-desc">
                                      <span className="text-capitalize">
                                        {issueData?.userInfo?.type}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="single">
                                    <div className="info-name">
                                      <span>User Contact Info</span>
                                    </div>
                                    <div className="info-desc">
                                      <span className="">
                                        {issueData?.userInfo?.mobileNumber?.split(
                                          "+88"
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="single">
                                    <div className="info-name">
                                      <span>Status</span>
                                    </div>
                                    <div className="info-desc">
                                      <div className="">
                                        <span
                                          className={
                                            issueData?.status === "PROCESSING"
                                              ? "pending-btn radius-btn"
                                              : issueData?.status === "RESOLVED"
                                              ? "active-btn radius-btn"
                                              : "inactive-btn radius-btn"
                                          }
                                        >
                                          {issueData?.status}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {!inTrip && (
                                <h4 className="title mt-5">Issues</h4>
                              )}
                              <div
                                className="px-4 bg-light py-4"
                                style={{
                                  height: "calc(100vh - 200px)",
                                  overflowY: "scroll",
                                }}
                              >
                                {issueData?.logs?.map((log, i) => (
                                  <Card
                                    key={i}
                                    style={{
                                      maxWidth: "49%",
                                    }}
                                    className={`shadow mb-4 ${
                                      log?.type === "ADMIN"
                                        ? "ml-auto"
                                        : "mr-auto"
                                    }`}
                                  >
                                    <Comment
                                      author={
                                        <a>
                                          {log?.type === "USER"
                                            ? log?.createdBy?.name
                                            : log?.type}
                                        </a>
                                      }
                                      avatar={
                                        <Avatar
                                          src={log?.createdBy?.profilePicture}
                                          alt={log?.createdBy?.name}
                                        />
                                      }
                                      content={log?.note}
                                      datetime={
                                        <Tooltip
                                          title={moment(log?.datetime).format(
                                            "LLL"
                                          )}
                                        >
                                          {moment(log?.datetime).format("LLL")}
                                          <small className="ml-2">
                                            {moment(log?.datetime).fromNow()}
                                          </small>
                                        </Tooltip>
                                      }
                                    />
                                  </Card>
                                ))}
                              </div>

                              {visible && (
                                <div
                                  style={{
                                    boxShadow:
                                      "2px -3px 10px 0 rgba(0,0,0,0.05)",
                                  }}
                                  className="pr-5 pl-2"
                                >
                                  <Comment
                                    avatar={
                                      <Avatar
                                        src="https://joeschmoe.io/api/v1/random"
                                        alt="Han Solo"
                                      />
                                    }
                                    content={
                                      <Form onFinish={onReplySubmit}>
                                        <Form.Item
                                          name="note"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Note is Required!",
                                            },
                                          ]}
                                        >
                                          <Input.TextArea rows={2} />
                                        </Form.Item>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <Form.Item
                                            name="status"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Status is Required!",
                                              },
                                            ]}
                                            initialValue="PROCESSING"
                                            className="mb-0"
                                          >
                                            <Radio.Group>
                                              <Radio value="PROCESSING">
                                                PROCESSING
                                              </Radio>
                                              <Radio value="RESOLVED">
                                                RESOLVED
                                              </Radio>
                                            </Radio.Group>
                                          </Form.Item>
                                          <div className="">
                                            <Button
                                              type="dashed"
                                              danger
                                              onClick={() =>
                                                setVisible(!visible)
                                              }
                                              className="mr-2"
                                            >
                                              Cancel
                                            </Button>
                                            <Button
                                              htmlType="submit"
                                              type="primary"
                                            >
                                              <SendOutlined />
                                              Reply Issue
                                            </Button>
                                          </div>
                                        </div>
                                      </Form>
                                    }
                                  />
                                </div>
                              )}

                              {!visible && isEditableService("Issues") && (
                                <Button
                                  type="primary"
                                  danger
                                  size="large"
                                  onClick={() => setVisible(true)}
                                  disabled={
                                    !!visible ||
                                    issueData?.status === "RESOLVED"
                                  }
                                  className="px-5 mt-4"
                                >
                                  {issueData?.status === "RESOLVED"
                                    ? "RESOLVED"
                                    : "REPLY"}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};
SingleIssue.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default SingleIssue;
