import { ReactElement } from "react";
import RunSheetList from "../../components/CourierRunSheet";
import Layout from "../../components/Layout";

const RunSheetPage = (): ReactElement => {
  return (
    <Layout>
      <RunSheetList />
    </Layout>
  );
};

export default RunSheetPage;
