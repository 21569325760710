import { useQuery } from "@tanstack/react-query";
import {
  AutoComplete,
  Button,
  Drawer,
  Form,
  Input,
  Pagination,
  PaginationProps,
  Popconfirm,
  Select,
  Space,
  Spin,
  Switch,
  Table,
} from "antd";
import { authenticateToken } from "../../utils/auth";
import { useCallback, useMemo, useRef, useState } from "react";
import AdminForm from "./AdminForm";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { responseNotification } from "../../utils/notify";
import AdminDetails from "./details";
import { debounce } from "lodash";
import axios from "axios";

interface AdminType {
  fullName: string;
  email: string;
  role: string;
  isActive: boolean;
  deleted: boolean;
}

async function getAdminList(
  pageNumber: number,
  pageLimit: number,
  status: any,
  fullName: string
) {
  try {
    const isActive = status !== null ? `&isActive=${status}` : "";
    const deleted = status === "deleted" ? `&deleted=true` : "";
    const searchName = fullName ? `&name=${fullName}` : "";
    const url = new URL(
      `${process.env.REACT_APP_AUTH_API}/admin/all?page=${pageNumber}&limit=${pageLimit}${isActive}${deleted}${searchName}`
    );
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
      },
    });
    if (!res.ok) throw new Error(res.statusText);

    const output = await res.json();

    return output;
  } catch (error) {
    return [];
  }
}

export default function AdminList() {
  const type = "Q_PARCEL";
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [showEditDrawer, setShowEditDrawer] = useState<boolean>(false);
  const [editAdmin, setEditAdmin] = useState<string>("");
  const [status, setStatus] = useState<any>(null);
  const [adminsOptions, setAdminsOptions] = useState({
    loading: false,
    list: null,
  });
  const fetchRef = useRef(null);
  const [fullName, setFullName] = useState("");
  // Queries
  const query = useQuery({
    queryKey: ["getAdminList", pageNumber, pageLimit, status, fullName],
    queryFn: () => getAdminList(pageNumber, pageLimit, status, fullName),
  });

  const onChange: PaginationProps["onChange"] = (pageNumber, pageSize) => {
    setPageNumber(pageNumber);
    setPageLimit(pageSize);
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <>Previous</>;
    }
    if (type === "next") {
      return <>Next</>;
    }
    return originalElement;
  };

  const handleOpenForm = (email?: string) => {
    setShowEditDrawer(true);
    email ? setEditAdmin(email) : setEditAdmin(undefined);
  };

  const onStatusChange = async (email, val) => {
    if (email) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/admin`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          isActive: val,
          type: type,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              res.message ?? "Status Updated Successfully",
              "success"
            );
            query.refetch();
          } else if (res.status === 500) {
            responseNotification(
              res.message ?? "Internal server error",
              "error"
            );
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onDeleteAdmin = async (email, value) => {
    if (email) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/admin`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          deleted: value,
          type: type,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              res.message || "Deleted Successfully",
              "success"
            );
            query.refetch();
          } else if (res.status === 500) {
            responseNotification(
              res.message || "Internal server error",
              "error"
            );
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getadminsOptions = useCallback(
    async (fullName) => {
      setAdminsOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      axios
        .get(
          `${encodedUri}/admin/all?` +
            `name=${fullName || ``}` +
            `&page=${0}` +
            `&limit=${20}` +
            (status ? `&status=${status}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setAdminsOptions({
            loading: false,
            list: res.data?.admins?.map((admin: any) => ({
              label: admin.fullName,
              value: admin.fullName,
            })),
          });
        })
        .catch((err) => {
          setAdminsOptions({ loading: false, list: [] });
          console.error("Admins: Error", err);
        });
    },
    [status]
  );

  const getAdminsOptionsDebounce = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setAdminsOptions({ loading: false, list: null });

      if (fetchId !== fetchRef.current) {
        return;
      }

      getadminsOptions(value);
    };

    return debounce(loadOptions, 800);
  }, []);

  const reseAllFieldData = () => {
    setStatus("");
    setFullName("");
  };

  return (
    <div>
      <div className="top-heading-products top-heading-content">
        <div className="container-fluid">
          <div className="row pt-3 pb-3 d-flex-sb">
            <div className="col-lg-6 col-md-6 no-padding">
              <div className="page-heading-content">
                <h2 className="d-flex-l wrap d-flex-wrap d-flex-base">
                  Admin List
                </h2>
              </div>
            </div>
            <div className="single-button">
              <Button onClick={() => handleOpenForm()}> + Create</Button>
            </div>
          </div>
        </div>
      </div>
      <br />
      <section className="search_area">
        {/* <Form form={form} className="search_form"> */}
        <Form.Item name="search" style={{ minWidth: "30%" }}>
          <AutoComplete
            onSearch={getAdminsOptionsDebounce}
            onSelect={(val) => {
              setPageNumber(0);
              setFullName(val.toString());
            }}
            options={adminsOptions?.list}
            defaultActiveFirstOption={false}
            notFoundContent={
              adminsOptions?.loading ? <Spin size="small" /> : null
            }
          >
            <Input.Search
              size="large"
              style={{ minWidth: 100, width: "calc(100% - 50px)" }}
              placeholder="Search by Name"
              onSearch={(val) => setFullName(val)}
              enterButton
              loading={adminsOptions.loading}
              pattern={`[0-9]`}
            />
          </AutoComplete>
        </Form.Item>

        <Form.Item style={{ minWidth: "20%" }}>
          <Select
            options={[
              { value: null, label: "All" },
              { value: true, label: "Active" },
              { value: false, label: "Inactive" },
              { value: "deleted", label: "Deleted" },
            ]}
            value={status}
            onChange={(value) => setStatus(value)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </Form.Item>
      </section>
      <br />
      <Table
        pagination={false}
        columns={[
          {
            title: "Name",
            dataIndex: "fullName",
            key: "fullName",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Role",
            dataIndex: "role",
            key: "role",
          },
          {
            title: "Status",
            key: "isActive",
            dataIndex: "isActive",
            render: (_, record) => (
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={record?.isActive}
                onChange={(val, e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onStatusChange(record?.email, val);
                }}
              />
            ),
          },
          {
            title: "Action",
            key: "action",
            render: (_, record) => (
              <Space size="middle">
                <Button
                  onClick={() => {
                    setShowDrawer(true);
                    setEditAdmin(record?.email);
                  }}
                  style={{ borderColor: "blue", color: "blue" }}
                >
                  <EyeOutlined />
                </Button>
                <Button
                  onClick={() => handleOpenForm(record?.email)}
                  style={{ margin: "0 8px" }}
                >
                  <EditOutlined />
                </Button>
                {record.deleted ? (
                  <Popconfirm
                    //   placement="left"
                    title="Are you sure to restore?"
                    onConfirm={() => onDeleteAdmin(record?.email, false)}
                  >
                    <Button style={{ borderColor: "green", color: "green" }}>
                      <ReloadOutlined />
                    </Button>
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    //   placement="left"
                    title="Are you sure to delete?"
                    onConfirm={() => onDeleteAdmin(record?.email, true)}
                  >
                    <Button danger>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            ),
          },
        ]}
        dataSource={query?.data?.admins.map((item, index) => ({
          key: index,
          ...item,
        }))}
      />

      <br />
      <div style={{ display: "flex", justifyContent: "end" }}>
        {!query.isLoading && query.data.admins.length && (
          <Pagination
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            defaultCurrent={pageNumber}
            total={query.data.totalElements}
            onChange={onChange}
            itemRender={itemRender}
          />
        )}
      </div>

      <Drawer
        destroyOnClose={true}
        title="Admin Create Form"
        width={600}
        onClose={() => setShowDrawer(false)}
        visible={!!showDrawer}
        bodyStyle={{ paddingBottom: 0 }}
        footer={
          <div
            style={{
              textAlign: "left",
            }}
          ></div>
        }
      >
        <AdminDetails visibleData={editAdmin} />
      </Drawer>

      <Drawer
        destroyOnClose={true}
        title="Admin Create Form"
        width={600}
        onClose={() => setShowEditDrawer(false)}
        visible={!!showEditDrawer}
        bodyStyle={{ paddingBottom: 0 }}
        footer={
          <div
            style={{
              textAlign: "left",
            }}
          ></div>
        }
      >
        <AdminForm
          email={editAdmin}
          onCloseMethod={() => {
            setShowEditDrawer(false);
            query.refetch();
          }}
        />
      </Drawer>
    </div>
  );
}
