import PropTypes from "prop-types";
import React, { ReactElement, useState } from "react";
import { connect } from "react-redux";
import LoginBg from "../../images/login/back.jpeg";
import Loader from "../common/Loader";
import SignUp from "../signUp/signup";
import LoginIntro from "./loginIntro";
import ForgetPasswordForm from "./ForgetPasswordForm";

const ForgetPassword = ({ data }: any): ReactElement => {
  return (
    <React.Fragment>
      {data?.loading ? (
        <Loader />
      ) : (
        <div className="login-container">
          <div className="container-fluid no-padding">
            <div className="row g-0">
              <div
                className="col-lg-7 col-md-6 login-banner"
                style={{
                  // width: '100%',
                  backgroundImage: `url(${LoginBg})`,
                  // height: "100vh",
                  backgroundPosition: "center bottom",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  position: "relative",
                  left: "-2px",
                  backgroundColor: "#faf8f4",
                }}
                // {...data}
              >
                <div className="login-banner-overlay"></div>
                <LoginIntro />
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="admin-login-wrapper pt-5">
                  <h2 className="d-title">Verify - Forget Password</h2>
                  <p>Please enter your new password</p>

                  <ForgetPasswordForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

ForgetPassword.propTypes = {
  data: PropTypes.object,
};

const mapStateTopProps = (state: { authReducer: any }) => {
  return { data: state.authReducer };
};

export default connect(mapStateTopProps, null)(ForgetPassword);
