import { ReactElement } from "react";
import { Link } from "react-router-dom";
const TopHeading = ({ left, right }): ReactElement => {
  return (
    <div className="top-heading-user-details top-heading-content">
      <div className="container-fluid no-padding">
        <div className="d-flex align-items-center justify-content-between">
          <div className="no-padding">
            <h3>{left} Information</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/riders">Riders</Link>
              </li>
              <li>{left} Details</li>
            </ul>
          </div>
          <div className="text-right">{right}</div>
        </div>
      </div>
    </div>
  );
};

export default TopHeading;
