import PropTypes from 'prop-types'
import React, { ReactElement } from 'react'
import { Redirect, Route } from 'react-router-dom'
import Dashboard from '../pages/index'

const PublicRoute = ({
  component: Component,
  exact,
  path,
  restricted,
  ...rest
}: any): ReactElement => {
  return <Route
    {...rest}
    render={(props) => {
      if (localStorage.hasOwnProperty('authToken')) {
        return <Redirect to="/" />
      } else {
        return <Component {...props} />
      }
    }}
  />
  }
PublicRoute.propTypes = {
  component: PropTypes.func,
  restricted: PropTypes.bool,
  exact: PropTypes.bool,
  path: PropTypes.any,
}
export default PublicRoute
