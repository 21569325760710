import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import WholeLayout from "../Layout/index";
import { authenticateToken } from "../../utils/auth";
import { Card, Row, Col, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

const Dashboard = ({ userInfo }: any) => {
  const [getTotalReward, setTotalReward] = useState(undefined);
  const [getTotalShop, setTotalShop] = useState(undefined);
  const [getTotalCampaign, setTotalCampaign] = useState(undefined);

  // get total rewards
  const getTotalRewards = () => {
    fetch(
      `${process.env.REACT_APP_CATALOG_READER_API}/rewards?page=0&limit=8`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTotalReward(data.totalElements);
      });
  };
  // get total shops
  const getTotalShops = () => {
    fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/all?page=0&limit=8`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTotalShop(data.totalElements);
      });
  };
  // get total campaign
  const getTotalCampaigns = () => {
    fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/all?page=0&limit=8`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTotalCampaign(data.totalElements);
      });
  };

  // call function
  useEffect(() => {
    getTotalRewards();
    getTotalShops();
    getTotalCampaigns();
  }, []);

  return (
    <WholeLayout>
      <div className="container-fluid dashboard-page w-full w-100">
        <h3>
          <b>Dashboard</b>
        </h3>
        <div className="row">
          <div className="col-lg-12">
            <div className="user-profile-wrapper justify-content-between align-items-center d-flex">
              <div className="inner-wrapper d-flex justify-content-start align-items-center k-gap-5">
                <div className="avatar-role">
                  <div className="user-avatar">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                      alt="avatar"
                      style={{ height: "100px", width: "100px" }}
                    />
                  </div>
                  <div className="role">
                    <h4>{userInfo?.role?.name}</h4>
                  </div>
                </div>
                <div className="user-content">
                  <h3 className="mb-0">{userInfo ? userInfo?.name : ""}</h3>
                  <h4 className="">{userInfo ? userInfo?.email : ""}</h4>
                </div>
              </div>
              <div className="px-4">
                Last logged in : <b>{moment().format("LLLL")?.toString()}</b>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/* <div className="mb-2">
          <div className="">
            <Row className="mt-4" gutter={[16, 24]}>
              <div className="col-md-2 w-100">
                <div className="bg-white d-flex justify-content-between py-1 px-3 shadow">
                  <div>
                    <h3 className="mb-0 mt-2">
                      <b>0</b>
                    </h3>
                    <p className="text-gray-100 ">Total Delivered</p>
                  </div>

                  <div className="card-icon">
                    <FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon>
                  </div>
                </div>
              </div>

              <div className="col-md-2 w-100">
                <div className="bg-white d-flex justify-content-between py-1 px-3 shadow">
                  <div>
                    <h3 className="mb-0 mt-2">
                      <b>0</b>
                    </h3>
                    <p className="text-gray-100 ">Total Delivered</p>
                  </div>

                  <div className="card-icon">
                    <FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon>
                  </div>
                </div>
              </div>

              <div className="col-md-2 w-100">
                <div className="bg-white d-flex justify-content-between py-1 px-3 shadow">
                  <div>
                    <h3 className="mb-0 mt-2">
                      <b>0</b>
                    </h3>
                    <p className="text-gray-100 ">Total Delivered</p>
                  </div>

                  <div className="card-icon">
                    <FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon>
                  </div>
                </div>
              </div>

              <div className="col-md-2 w-100">
                <div className="bg-white d-flex justify-content-between py-1 px-3 shadow">
                  <div>
                    <h3 className="mb-0 mt-2">
                      <b>0</b>
                    </h3>
                    <p className="text-gray-100 ">Total Delivered</p>
                  </div>

                  <div className="card-icon">
                    <FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon>
                  </div>
                </div>
              </div>

              <div className="col-md-2 w-100">
                <div className="bg-white d-flex justify-content-between py-1 px-3 shadow">
                  <div>
                    <h3 className="mb-0 mt-2">
                      <b>0</b>
                    </h3>
                    <p className="text-gray-100 ">Total Delivered</p>
                  </div>

                  <div className="card-icon">
                    <FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon>
                  </div>
                </div>
              </div>

              <div className="col-md-2 w-100">
                <div className="bg-white d-flex justify-content-between py-1 px-3 shadow">
                  <div>
                    <h3 className="mb-0 mt-2">
                      <b>0</b>
                    </h3>
                    <p className="text-gray-100 ">Total Delivered</p>
                  </div>

                  <div className="card-icon">
                    <FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon>
                  </div>
                </div>
              </div>

              <div className="col-md-2 w-100">
                <div className="bg-white d-flex justify-content-between py-1 px-3 shadow">
                  <div>
                    <h3 className="mb-0 mt-2">
                      <b>0</b>
                    </h3>
                    <p className="text-gray-100 ">Total Delivered</p>
                  </div>

                  <div className="card-icon">
                    <FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon>
                  </div>
                </div>
              </div>

              <div className="col-md-2 w-100">
                <div className="bg-white d-flex justify-content-between py-1 px-3 shadow">
                  <div>
                    <h3 className="mb-0 mt-2">
                      <b>0</b>
                    </h3>
                    <p className="text-gray-100 ">Total Delivered</p>
                  </div>

                  <div className="card-icon">
                    <FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon>
                  </div>
                </div>
              </div>

              <div className="col-md-2 w-100">
                <div className="bg-white d-flex justify-content-between py-1 px-3 shadow">
                  <div>
                    <h3 className="mb-0 mt-2">
                      <b>0</b>
                    </h3>
                    <p className="text-gray-100 ">Total Delivered</p>
                  </div>

                  <div className="card-icon">
                    <FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div> */}
      </div>
    </WholeLayout>
  );
};
Dashboard.propTypes = {
  userInfo: PropTypes.object,
};

const mapStateTopProps = (state: { authReducer: { role: any } }) => {
  return { userInfo: state.authReducer };
};
export default connect(mapStateTopProps, null)(Dashboard);
