import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Drawer,
  Form,
  Input,
  PaginationProps,
  Popconfirm,
  Select,
  Space,
  Switch,
  Table,
} from "antd";
import { authenticateToken } from "../../utils/auth";
import { useMemo, useState } from "react";
import ServiceForm from "./ServiceForm";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { responseNotification } from "../../utils/notify";
import FormItem from "antd/es/form/FormItem";

async function getServiceList(
  status: any,
  type: string
) {
  try {
    const isActive = status !== null ? `&isActive=${status}` : "";
    const deleted = status === "deleted" ? `&deleted=true` : "";
    const url = new URL(
      `${process.env.REACT_APP_AUTH_API}/service/by-type?type=${type}&Type=${type}${isActive}${deleted}`
    );
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
      },
    });
    if (!res.ok) throw new Error(res.statusText);

    const output = await res.json();

    return output;
  } catch (error) {
    return [];
  }
}

export default function ServiceList() {
  const type = "Q_PARCEL";
  const [showEditDrawer, setShowEditDrawer] = useState<boolean>(false);
  const [editService, setEditService] = useState();
  const [status, setStatus] = useState<any>(null);
  const [serviceName, setServiceName] = useState("");
  // Queries
  const query = useQuery({
    queryKey: ["getServiceList", status, type],
    queryFn: () => getServiceList(status, type),
  });

  const handleOpenForm = (service?: any) => {
    setShowEditDrawer(true);
    service ? setEditService(service) : setEditService(undefined);
  };

  const onStatusChange = async (id, val) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/service`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            query.refetch();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onDeleteService = async (service) => {
    await fetch(`${process.env.REACT_APP_AUTH_API}/service/remove-service`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        serviceNameId: service?.id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Deleted Successfully", "success");
          query.refetch();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const compare = function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  const filteredServiceList = useMemo(() => {
    if (query.data?.services?.length && serviceName !== "" && status !== null) {
      return query.data?.services?.filter(
        (item) => item?.name.includes(serviceName) && item?.isActive === status
      ).sort(compare);
    } else if (
      query.data?.services?.length &&
      serviceName !== "" &&
      status === null
    ) {
      return query.data?.services?.filter((item) =>
        item?.name.includes(serviceName)
      ).sort(compare);
    } else if (query.data?.services?.length) {
      return query.data?.services?.filter((item) => item).sort(compare);
    }
    return [];
  }, [query.data, serviceName, status]);

  return (
    <div>
      <div className="top-heading-products top-heading-content">
        <div className="container-fluid">
          <div className="row pt-3 pb-3 d-flex-sb">
            <div className="col-lg-6 col-md-6 no-padding">
              <div className="page-heading-content">
                <h2 className="d-flex-l wrap d-flex-wrap d-flex-base">
                  Service List
                </h2>
              </div>
            </div>
            <div className="single-button">
              <Button onClick={() => handleOpenForm()}> + Create</Button>
            </div>
          </div>
        </div>
      </div>

      <div className="" >
      <Input.Group compact>
        <FormItem style={{ minWidth: "20%" }}>
          <Input
            type="text"
            placeholder="Search..."
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value.toUpperCase())}
            style={{  backgroundColor: 'white' }}
          />
        </FormItem>

        <FormItem style={{ minWidth: "10%" }}>
          <Select
            options={[
              { value: null, label: "All" },
              { value: true, label: "Active" },
              { value: false, label: "Inactive" },
            ]}
            value={status}
            onChange={(value) => setStatus(value)}
          />
        </FormItem>
      </Input.Group>
      </div>


      <Table
        // pagination={false}
        columns={[
          {
            title: "Service Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
          },
          {
            title: "Status",
            key: "isActive",
            dataIndex: "isActive",
            render: (_, record) => (
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={record?.isActive}
                onChange={(val, e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onStatusChange(record?.id, val);
                }}
              />
            ),
          },
          {
            title: "Action",
            key: "action",
            render: (_, record) => (
              <Space size="middle">
                <Button
                  onClick={() => handleOpenForm(record)}
                  style={{ margin: "0 8px" }}
                >
                  <EditOutlined />
                </Button>
                <Popconfirm
                  //   placement="left"
                  title="Are you sure to delete?"
                  onConfirm={() => onDeleteService(record)}
                >
                  <Button danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
        dataSource={filteredServiceList.map((item, index) => ({
          key: index,
          ...item,
        }))}
        loading={query.isLoading}
      />

      <Drawer
        destroyOnClose={true}
        title="Admin Create Form"
        width={600}
        onClose={() => setShowEditDrawer(false)}
        visible={!!showEditDrawer}
        bodyStyle={{ paddingBottom: 0 }}
        footer={
          <div
            style={{
              textAlign: "left",
            }}
          ></div>
        }
      >
        <ServiceForm
          visibleData={editService}
          onCloseMethod={() => {
            setShowEditDrawer(false);
            query.refetch();
          }}
        />
      </Drawer>
    </div>
  );
}
