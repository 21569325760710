import React from "react";
import OtpsendForm from "../components/otpsendForm";

const OTPPage = () => {
  return (
    <>
      <OtpsendForm />
    </>
  );
};

export default OTPPage;
