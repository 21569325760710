import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Drawer,
  Space,
  Switch,
  Table,
} from "antd";
import { authenticateToken } from "../../utils/auth";
import { useMemo, useState } from "react";
import GroupForm from "./GroupForm";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { responseNotification } from "../../utils/notify";

async function getGroupList(
  type: string
) {
  try {
    const url = new URL(
      `${process.env.REACT_APP_AUTH_API}/group/all?type=${type}`
    );
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
      },
    });
    if (!res.ok) throw new Error(res.statusText);

    const output = await res.json();

    return output;
  } catch (error) {
    return [];
  }
}

export default function GroupList() {
  const type = "Q_PARCEL";
//   const type = "Q_COMMERCE";
  const [showEditDrawer, setShowEditDrawer] = useState<boolean>(false);
  const [editGroup, setEditGroup] = useState();
  // Queries
  const query = useQuery({
    queryKey: ["getGroupList", type],
    queryFn: () => getGroupList(type),
  });

  const handleOpenForm = (group?: any) => {
    setShowEditDrawer(true);
    group ? setEditGroup(group) : setEditGroup(undefined);
  };

  const onStatusChange = async (id, val) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/group`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(res?.message ?? "Status Updated Successfully", "success");
            query.refetch();
          } else if (res.status === 500) {
            responseNotification(res?.message ?? "Internal server error", "error");
          } else {
            responseNotification(res?.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const filteredList = useMemo(() => {
    if(query.data?.groups?.length) {
        return query.data?.groups?.filter(item => item);
    }
    return [];
  },[query.data]);

  return (
    <div>
      <div className="top-heading-products top-heading-content">
        <div className="container-fluid">
          <div className="row pt-3 pb-3 d-flex-sb">
            <div className="col-lg-6 col-md-6 no-padding">
              <div className="page-heading-content">
                <h2 className="d-flex-l wrap d-flex-wrap d-flex-base">
                  Group List
                </h2>
              </div>
            </div>
            <div className="single-button">
              <Button onClick={() => handleOpenForm()}> + Create</Button>
            </div>
          </div>
        </div>
      </div>

      <Table
        // pagination={false}
        columns={[
          {
            title: "Group Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
          },
          {
            title: "Status",
            key: "isActive",
            dataIndex: "isActive",
            render: (_, record) => (
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={record?.isActive}
                onChange={(val, e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onStatusChange(record?.id, val);
                }}
              />
            ),
          },
          {
            title: "Action",
            key: "action",
            render: (_, record) => (
              <Space size="middle">
                <Button
                  onClick={() => handleOpenForm(record)}
                  style={{ margin: "0 8px" }}
                >
                  <EditOutlined />
                </Button>
              </Space>
            ),
          },
        ]}
        dataSource={filteredList.map((item, index) => ({
          key: index,
          ...item,
        }))}
        loading={query.isLoading}
      />

      <Drawer
        destroyOnClose={true}
        title="Admin Create Form"
        width={600}
        onClose={() => setShowEditDrawer(false)}
        visible={!!showEditDrawer}
        bodyStyle={{ paddingBottom: 0 }}
        footer={
          <div
            style={{
              textAlign: "left",
            }}
          ></div>
        }
      >
        <GroupForm
          visibleData={editGroup}
          onCloseMethod={() => {
            setShowEditDrawer(false);
            query.refetch();
          }}
        />
      </Drawer>
    </div>
  );
}
