import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
  Switch,
} from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";

// interface AddMerchantProps {
//   visibleData?: any;
//   onCloseMethod?: () => void;
// }

enum chargeEnum {
  // insideDhaka = "insideDhaka",
  // dhakaSuburbs = "dhakaSuburbs",
  // outsideDhaka = "outsideDhaka",
  // sameDayDelivery = "sameDayDelivery",
  sameCity = "sameCity",
  dhakaSuburbs = "dhakaSuburbs",
  outsideCity = "outsideCity",
  sameDayDelivery = "sameDayDelivery",
}

const AddMerchant = ({
  data,
  onCloseMethod,
}: {
  data?: any;
  onCloseMethod: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [merchant, setMerchant] = useState<any>();
  const [form] = Form.useForm();

  useEffect(() => {
    setMerchant(data);
  }, [data]);

  const onSubmit = async (data) => {
    setLoading(true);

    await fetch(
      `${process.env.REACT_APP_USER_API}${
        merchant ? "/business" : "/business/registration"
      }`,
      {
        method: merchant ? "PUT" : "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: merchant?.id,
          ...data,
          mobileNumber: `+88${data?.mobileNumber}`,
          [chargeEnum.sameCity]: data[chargeEnum.sameCity]?.[0],
          [chargeEnum.dhakaSuburbs]: data[chargeEnum.dhakaSuburbs]?.[0],
          [chargeEnum.outsideCity]: data[chargeEnum.outsideCity]?.[0],
          [chargeEnum.sameDayDelivery]: data[chargeEnum.sameDayDelivery]?.[0],
        }),
      }
    )
      .then((res) => res.json())
      .then(async (res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          await editPayment(
            {
              bankInfo: data?.bankInfo,
            },
            "bank"
          );
          await editPayment(
            {
              mfs: data?.mfsInfo,
            },
            "mfs"
          );
          responseNotification(
            `Merchant ${merchant ? "Updated" : "Created"} Successfully`,
            "success"
          );
          form.resetFields();
          if (onCloseMethod) {
            onCloseMethod();
          }
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const editPayment = async (data, type) => {
    setLoading(true);
    await fetch(`${process.env.REACT_APP_USER_API}/business/${type}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        merchantId: merchant?.id,
        ...data,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    if (merchant) {
      form.resetFields();
    }
  }, [merchant]);

  const chargeMap = useMemo(() => {
    return {
      [chargeEnum.sameCity]: [
        {
          charge: 0,
          next: 0,
          time: "",
          ...(merchant?.[chargeEnum.sameCity] || {}),
        },
      ],
      [chargeEnum.dhakaSuburbs]: [
        {
          charge: 0,
          next: 0,
          time: "",
          ...(merchant?.[chargeEnum.dhakaSuburbs] || {}),
        },
      ],
      [chargeEnum.outsideCity]: [
        {
          charge: 0,
          next: 0,
          time: "",
          ...(merchant?.[chargeEnum.outsideCity] || {}),
        },
      ],
      [chargeEnum.sameDayDelivery]: [
        {
          charge: 0,
          next: 0,
          time: "",
          ...(merchant?.[chargeEnum.sameDayDelivery] || {}),
        },
      ],
    };
  }, [merchant]);

  return (
    <div className="add-rider drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          // initialValues={{
          //   ...visibleData,
          //   mobileNumber: visibleData?.mobileNumber?.replace("+88", ""),
          //   drivingLicense: visibleData?.drivingLicense?.drivingLicenseNumber,
          //   carRegistrationNumber: visibleData?.car?.carRegistrationNumber,
          //   manufacturer: visibleData?.car?.manufacturer,
          //   model: visibleData?.car?.model,
          //   year: moment(visibleData?.car?.year),
          // }}
          initialValues={{ ...(merchant || {}), ...chargeMap }}
          form={form} // like ref
          layout="vertical"
          // requiredMark="optional"
          autoComplete="off"
        >
          <>
            <h4 className="title">Basic Information:</h4>
            {console.log(merchant)}
            <Form.Item
              hasFeedback
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: "Company Name is Required!",
                },
              ]}
              name="companyName"
            >
              <Input id="name" type="text" placeholder="Enter Company Name" />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Owners Name"
              rules={[
                {
                  required: true,
                  message: "Owners Name is Required!",
                },
              ]}
              name="ownersName"
            >
              <Input id="name" type="text" placeholder="Enter Owners Name" />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Mobile Number 01..."
              rules={[
                {
                  required: true,
                  message: "Mobile Number is required!",
                },
                // { type: 'string', max: 11, min: 11 },
                {
                  validator: async (_, names) => {
                    if (merchant) return true;
                    if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                      return Promise.reject(
                        new Error("Enter valid mobile number")
                      );
                    }
                  },
                },
              ]}
              name="mobileNumber"
            >
              <Input
                id="mobile"
                type="mobile"
                addonBefore={"+88"}
                placeholder="Enter Mobile Number"
                disabled={merchant?.mobileNumber}
                // allowClear
              />
              {/* <InputNumber addonBefore={selectBefore} addonAfter={selectAfter} defaultValue={100} /> */}
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Email Address"
              rules={[
                {
                  required: true,
                  message: "Email Address is Required!",
                },
              ]}
              name="emailAddress"
            >
              <Input id="name" type="email" placeholder="Enter Email Address" />
            </Form.Item>

            {!merchant && (
              <Form.Item
                hasFeedback
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Password is Required!",
                  },
                ]}
                name="password"
              >
                <Input id="name" type="text" placeholder="Enter Password" />
              </Form.Item>
            )}
          </>
          <>
            <h4 className="title">Payment Information:</h4>
            <div style={{ width: "100%" }}>
              <b>MFS</b>
            </div>
            <Form.Item
              hasFeedback
              label="MFS Name"
              rules={[
                {
                  required: false,
                  message: "MFS Name is Required!",
                },
              ]}
              name={["mfsInfo", "mfsName"]}
            >
              <Input id="name" type="text" placeholder="Enter MFS Name" />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Mobile Number"
              rules={[
                {
                  required: false,
                  message: "Mobile Number is Required!",
                },
              ]}
              name={["mfsInfo", "mobileNumber"]}
            >
              <Input id="name" type="text" placeholder="Enter Mobile Number" />
            </Form.Item>

            <div style={{ width: "100%" }}>
              <b>Bank</b>
            </div>

            <Form.Item
              hasFeedback
              label="Account Name"
              rules={[
                {
                  required: false,
                  message: "Account Name is Required!",
                },
              ]}
              name={["bankInfo", "accountName"]}
            >
              <Input id="name" type="text" placeholder="Enter Account Name" />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Account Number"
              rules={[
                {
                  required: false,
                  message: "Account Number is Required!",
                },
              ]}
              name={["bankInfo", "accountNumber"]}
            >
              <Input id="name" type="text" placeholder="Enter Account Number" />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Bank Name"
              rules={[
                {
                  required: false,
                  message: "Bank Name is Required!",
                },
              ]}
              name={["bankInfo", "bankName"]}
            >
              <Input id="name" type="text" placeholder="Enter Bank Name" />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Branch Name"
              rules={[
                {
                  required: false,
                  message: "Branch Name is Required!",
                },
              ]}
              name={["bankInfo", "branchName"]}
            >
              <Input id="name" type="text" placeholder="Enter Branch Name" />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Routing Number"
              rules={[
                {
                  required: false,
                  message: "Routing Number is Required!",
                },
              ]}
              name={["bankInfo", "routingNumber"]}
            >
              <Input id="name" type="text" placeholder="Enter Routing Number" />
            </Form.Item>

            {!merchant && (
              <Form.Item
                hasFeedback
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Password is Required!",
                  },
                ]}
                name="password"
              >
                <Input id="name" type="text" placeholder="Enter Password" />
              </Form.Item>
            )}
          </>

          {merchant && (
            <div>
              <h4 className="title">Charge Information:</h4>

              <div style={{ width: "100%" }}>
                {/* <Form.Item
                  hasFeedback
                  label="COD Charge"
                  rules={[
                    {
                      required: true,
                      message: "COD Charge is Required!",
                    },
                  ]}
                  name="codCharge"
                >
                  <InputNumber
                    id="name"
                    type="number"
                    placeholder="Enter COD Charge"
                    addonAfter="%"
                    min={0}
                  />
                </Form.Item> */}
              </div>
              <Form.List name={chargeEnum.sameCity}>
                {(fields, {}) => (
                  <>
                    {fields.map((field) => (
                      <Space key={field.key} align="end">
                        <Form.Item>
                          <h4
                            style={{
                              fontSize: 16,
                              padding: 0,
                              color: "green",
                              width: 140,
                            }}
                          >
                            Same City:
                          </h4>
                        </Form.Item>

                        <Form.Item
                          {...field}
                          label="Base Charge"
                          name={[field.name, "charge"]}
                          style={{width:110}}
                          rules={[
                            {
                              required: true,
                              message: "Missing Base Charge",
                            },
                          ]}
                        >
                          <Input min={0} />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Next per 1kg"
                          name={[field.name, "next"]}
                          style={{width:110}}
                          rules={[
                            {
                              required: true,
                              message: "Missing Next Per Kg Price",
                            },
                          ]}
                        >
                          <Input min={0} />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Time"
                          name={[field.name, "time"]}
                          style={{width:150}}
                          rules={[{ required: true, message: "Missing Time" }]}
                        >
                          <Input type="text" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          label="COD Charge"
                          name={[field.name, "codCharge"]}
                          style={{width:150}}
                          rules={[{ required: true, message: "Missing COD Charge" }]}
                          
                        >
                          <Input type="text" addonAfter="%"/>
                        </Form.Item>

                        <Form.Item
                          {...field}
                          label="Return Charge"
                          name={[field.name, "returnCharge"]}
                        //   style={{width:100}}
                          rules={[{ required: false }]}
                          valuePropName="checked"
                        >
                          <Switch/>
                        </Form.Item>
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
              <Form.List name={chargeEnum.dhakaSuburbs}>
                {(fields, {}) => (
                  <>
                    {fields.map((field) => (
                      <Space key={field.key} align="end">
                        <Form.Item>
                          <h4
                            style={{
                              fontSize: 16,
                              padding: 0,
                              color: "green",
                              width: 140,
                            }}
                          >
                            Dhaka Suburbs:
                          </h4>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Base Charge"
                          name={[field.name, "charge"]}
                          style={{width:110}}
                          rules={[
                            {
                              required: true,
                              message: "Missing Base Charge",
                            },
                          ]}
                        >
                          <Input min={0} />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Next per 1kg"
                          name={[field.name, "next"]}
                          style={{width:110}}
                          rules={[
                            {
                              required: true,
                              message: "Missing Next Per Kg Price",
                            },
                          ]}
                        >
                          <Input min={0} />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Time"
                          name={[field.name, "time"]}
                          rules={[{ required: true, message: "Missing Time" }]}
                          style={{width:150}}
                        >
                          <Input type="text" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          label="COD Charge"
                          name={[field.name, "codCharge"]}
                          style={{width:150}}
                          rules={[{ required: true, message: "Missing COD Charge" }]}
                          
                        >
                          <Input type="text" addonAfter="%"/>
                        </Form.Item>

                        <Form.Item
                            {...field}
                            label="Return Charge"
                            name={[field.name, "returnCharge"]}
                            //   style={{width:100}}
                            rules={[{ required: false }]}
                            valuePropName="checked"
                        >
                            <Switch/>
                        </Form.Item>
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
              <Form.List name={chargeEnum.outsideCity}>
                {(fields, {}) => (
                  <>
                    {fields.map((field) => (
                      <Space key={field.key} align="end">
                        <Form.Item>
                          <h4
                            style={{
                              fontSize: 16,
                              padding: 0,
                              color: "green",
                              width: 140,
                            }}
                          >
                            Outside City:
                          </h4>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Base Charge"
                          name={[field.name, "charge"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Base Charge",
                            },
                          ]}
                          style={{width:110}}
                        >
                          <Input min={0} />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Next per 1kg"
                          name={[field.name, "next"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Next Per Kg Price",
                            },
                          ]}
                          style={{width:110}}
                        >
                          <Input min={0} />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Time"
                          name={[field.name, "time"]}
                          rules={[{ required: true, message: "Missing Time" }]}
                          style={{width:150}}
                        >
                          <Input type="text" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          label="COD Charge"
                          name={[field.name, "codCharge"]}
                          style={{width:150}}
                          rules={[{ required: true, message: "Missing COD Charge" }]}
                          
                        >
                          <Input type="text" addonAfter="%"/>
                        </Form.Item>

                        <Form.Item
                            {...field}
                            label="Return Charge"
                            name={[field.name, "returnCharge"]}
                            //   style={{width:100}}
                            rules={[{ required: false }]}
                            valuePropName="checked"
                        >
                            <Switch/>
                        </Form.Item>
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
              <Form.List name={chargeEnum.sameDayDelivery}>
                {(fields, {}) => (
                  <>
                    {fields.map((field) => (
                      <Space key={field.key} align="end">
                        <Form.Item>
                          <h4
                            style={{
                              fontSize: 16,
                              padding: 0,
                              color: "green",
                              width: 140,
                            }}
                          >
                            Same Day Delivery:
                          </h4>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Base Charge"
                          name={[field.name, "charge"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Base Charge",
                            },
                          ]}
                          style={{width:110}}
                        >
                          <Input min={0} />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Next per 1kg"
                          name={[field.name, "next"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Next Per Kg Price",
                            },
                          ]}
                          style={{width:110}}
                        >
                          <Input min={0} />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Time"
                          name={[field.name, "time"]}
                          rules={[{ required: true, message: "Missing Time" }]}
                          style={{width:150}}
                        >
                          <Input type="text" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          label="COD Charge"
                          name={[field.name, "codCharge"]}
                          style={{width:150}}
                          rules={[{ required: true, message: "Missing COD Charge" }]}
                          
                        >
                          <Input type="text" addonAfter="%"/>
                        </Form.Item>

                        <Form.Item
                            {...field}
                            label={"Return Charge"}
                            name={[field.name, "returnCharge"]}
                            //   style={{width:100}}
                            rules={[{ required: false }]}
                            valuePropName="checked"
                        >
                            <Switch/>
                        </Form.Item>
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          )}

          <div className="buttons-container">
            <Button
              disabled={loading}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={() => form?.resetFields()}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
AddMerchant.propTypes = {
  visibleData: PropTypes.any,
  onCloseMethod: PropTypes.func,
};

export default AddMerchant;
