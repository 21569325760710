import React, { useContext, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faPhone,
  faUnlock,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { SellerContext } from "../../lib/context";
import { responseNotification } from "../../utils/notify";
import Link from "antd/lib/typography/Link";
import { useCookies } from "react-cookie";
import Verify from "../signupVerify/verify";
import { login } from "../../redux/auth/authAction";
import { useDispatch } from "react-redux";

// library.add(faPhone, faUnlock, faUser, faEnvelope);

const SignUp = () => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const { infos, setInfos } = useContext(SellerContext);
  const [cookie, setCookie] = useCookies(["sellerToken"]);
  const [error, setError] = useState();
  const [data, setData] = useState<any>();
  const [showOtpForm, setShowOtpForm] = useState<boolean>();
  const dispatch = useDispatch();

  const sendOtp = (formData?: any) => {
    setData(formData);
    if (formData?.password === formData?.cpassword) {
      try {
        setLoading(true);

        fetch(`${process.env.NEXT_PUBLIC_AUTH_API}/admins/registration`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            mobileNumber: `+88${formData.mobileNumber}`,
            email: formData.email,
            name: `${formData.firstName} ${formData.lastName}`,
            password: formData.password,
            role: "ADMIN",
          }),
        })
          .then((data) => data.json())
          .then(async (data) => {
            setLoading(false);
            if (data?.statusCode === 200) {
              setShowOtpForm(true);
              setLoading(false);
              responseNotification("Registration successful!");
              await login(dispatch, {
                email: formData.email,
                password: formData.password,
              });
            } else {
              setLoading(false);
              responseNotification(data?.message, "error");
            }
          });
      } catch (e) {
        setLoading(false);
        responseNotification(e?.message || "Login failed");
      }
    } else {
      responseNotification("Password mismatched!", "warning");
    }
  };
  useEffect(() => setError(error), [errors]);

  return (
    <div>
      <div className="grid grid-cols-12 gap-0 login-wrapper admin-login-form">
        <div className="col-span-12 lg:col-span-4 intro-y dstyle">
          <div className="row">
            {/* {!showOtpForm ? ( */}
            {true ? (
              <>
                <form
                  onSubmit={handleSubmit(sendOtp)}
                  className="bg-white formobilev"
                >
                  <div className="">
                    <div className="mt-8 lunlock">
                      <div className="flex single-form-field">
                        <FontAwesomeIcon icon="user" className="d-icon" />
                        <input
                          placeholder="Full Name"
                          name="name"
                          type="text"
                          ref={register({
                            required: "Full Name is required!",
                            maxLength: { value: 20, message: "Max 20 digit" },
                          })}
                          className="u2style"
                        />
                      </div>
                      <p className="highlighted-txt login-error text-left">
                        {errors?.name?.message}
                      </p>
                    </div>

                    {/* <div className="mt-8 lunlock">
                      <div className="flex single-form-field">
                        <FontAwesomeIcon icon="user" className="d-icon" />
                        <input
                          placeholder="Last Name"
                          name="lastName"
                          type="text"
                          ref={register({
                            required: 'Last Name is required!',
                            maxLength: { value: 20, message: 'Max 20 digit' },
                          })}
                          className="u2style"
                        />
                      </div>
                      <p className="highlighted-txt login-error text-left">
                        {errors?.lastName?.message}
                      </p>
                    </div> */}
                  </div>
                  <div className="mt-8 text-current luser">
                    <div className="flex single-form-field">
                      <FontAwesomeIcon icon="phone" className="d-icon" />
                      <input
                        placeholder="Mobile Number"
                        name="mobileNumber"
                        //type="tel"
                        maxLength={11}
                        // autoComplete="Mobile"
                        ref={register({
                          required: "Mobile Number is required!",
                          pattern: {
                            value: /01[0-9]{9}/,
                            message: "Invalid Mobile Number",
                          },
                        })}
                        className="u2style"
                      />
                    </div>
                    <p className="highlighted-txt login-error text-left">
                      {errors?.mobileNumber?.message}
                    </p>
                  </div>
                  <div className="mt-8 text-current luser">
                    <div className="flex single-form-field">
                      <FontAwesomeIcon icon="envelope" className="d-icon" />
                      <input
                        placeholder="Email Address"
                        name="email"
                        //type="tel"
                        // maxLength={11}
                        // autoComplete="Mobile"
                        ref={register({
                          required: "Email is required!",
                          pattern: {
                            value:
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            message: "Invalid Email Address",
                          },
                        })}
                        className="u2style"
                      />
                    </div>
                    <p className="highlighted-txt login-error text-left">
                      {errors?.email?.message}
                    </p>
                  </div>
                  <div className="mt-8 lunlock">
                    <div className="flex single-form-field">
                      <FontAwesomeIcon icon="unlock" className="d-icon" />
                      <input
                        placeholder="Password"
                        name="password"
                        type="password"
                        ref={register({
                          required: "Password is required!",
                          minLength: { value: 6, message: "Min 6 digit" },
                        })}
                        className="u2style"
                      />
                    </div>
                    <p className="highlighted-txt login-error text-left">
                      {errors?.password?.message}
                    </p>
                  </div>
                  <div className="mt-8 lunlock">
                    <div className="flex single-form-field">
                      <FontAwesomeIcon icon="unlock" className="d-icon" />
                      <input
                        placeholder="Confirm Password"
                        name="cpassword"
                        type="password"
                        ref={register({
                          required: "Password is required!",
                          minLength: { value: 6, message: "Min 6 digit" },
                        })}
                        className="u2style"
                      />
                    </div>
                    <p className="highlighted-txt login-error text-left">
                      {errors?.cpassword?.message}
                    </p>
                  </div>
                  <div className=" mt-8 df">
                    <h2
                      className="forgot ml-2 text-center"
                      style={{ fontWeight: 100 }}
                    >
                      {" "}
                      By clicking next, you agree to these
                      <Link href="/termsConditions">
                        <a> Terms & Conditions</a>
                      </Link>
                    </h2>
                  </div>
                  <div className="bt mb-3">
                    <button type="submit" className="button">
                      Next
                    </button>
                  </div>
                  <div className="create">
                    <h2 className="fcreate">
                      Already a member? <Link href="/login"> Sign in</Link>
                    </h2>
                  </div>
                </form>
              </>
            ) : (
              <Verify data={data} sendOtp={() => sendOtp(data)} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
