import { Button, Steps } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DocUpload from "./DocUpload";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import AddRider from "./AddRider"; 

const { Step } = Steps;

const steps = [
  {
    title: "Personal Info",
  },
  {
    title: "Profile",
  },
  {
    title: "NID",
  },
  {
    title: "Registration",
  },
  {
    title: "License",
  },
  {
    title: "Complete",
  },
];

const EditRider = ({ singleRiderData, fetchRiderDetails }) => {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(
    () =>
      singleRiderData?.profilePictureUrl
        ? singleRiderData?.nid
          ? singleRiderData?.registrationPaper
            ? singleRiderData?.drivingLicense
              ? setCurrent(5)
              : setCurrent(4)
            : setCurrent(3)
          : setCurrent(2)
        : setCurrent(1),
    []
  );

  return (
    <div className="edit-campagin-area">
      <div className="ant-form ant-form-vertical add-campaign-form">
        <Steps
          current={current}
          onChange={(curr) => setCurrent(curr)}
          responsive
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>

        <div className="steps-content">
          {current === 0 ? (
            <AddRider visibleData={singleRiderData} />
          ) : undefined}
          {current === 1 ? (
            <DocUpload
              driverId={singleRiderData?.driverId}
              documentType="profile_picture"
              onNext={() => setCurrent(current + 1)}
              frontImage={singleRiderData?.profilePictureUrl}
              fetchRiderDetails={fetchRiderDetails}
            />
          ) : undefined}
          {current === 2 ? (
            <DocUpload
              driverId={singleRiderData?.driverId}
              documentType="nid"
              onNext={() => setCurrent(current + 1)}
              frontImage={singleRiderData?.nid?.frontUrl}
              backImage={singleRiderData?.nid?.backUrl}
              documentIdNumber={singleRiderData?.nid?.nidNumber}
              fetchRiderDetails={fetchRiderDetails}
            />
          ) : undefined}
          {current === 3 ? (
            <DocUpload
              driverId={singleRiderData?.driverId}
              documentType="registration_paper"
              onNext={() => setCurrent(current + 1)}
              frontImage={singleRiderData?.registrationPaper?.frontUrl}
              backImage={singleRiderData?.registrationPaper?.backUrl}
              fetchRiderDetails={fetchRiderDetails}
            />
          ) : undefined}
          {current === 4 ? (
            <DocUpload
              driverId={singleRiderData?.driverId}
              documentType="driving_license"
              onNext={() => setCurrent(current + 1)}
              frontImage={singleRiderData?.drivingLicense?.frontUrl}
              backImage={singleRiderData?.drivingLicense?.backUrl}
              documentIdNumber={
                singleRiderData?.drivingLicense?.drivingLicenseNumber
              }
              fetchRiderDetails={fetchRiderDetails}
            />
          ) : undefined}
          {current === 5 ? (
            <div className="d-flex d-flex-dir-col p-5">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1200 1200"
                enableBackground="new 0 0 200 200"
                xmlSpace="preserve"
                style={{ width: 200 }}
              >
                <g>
                  <path
                    fill="#7AC043"
                    d="M888.918,424.521c25.524,53.158,39.842,112.674,39.842,175.477c0,224.339-182.511,406.85-406.85,406.85
                    c-224.334,0-406.845-182.511-406.845-406.85c0-224.334,182.51-406.845,406.845-406.845c94.918,0,182.307,32.717,251.589,87.41
                    c12.624-17.436,24.687-35.387,36.897-53.711c-79.823-61.863-179.911-98.763-288.486-98.763C261.7,128.088,50,339.788,50,599.998
                    c0,260.214,211.7,471.914,471.91,471.914c260.215,0,471.914-211.7,471.914-471.914c0-81.238-20.655-157.735-56.962-224.547
                    C920.55,391.411,904.12,407.157,888.918,424.521z"
                    style={{ fill: "rgb(158, 223, 109)" }}
                  ></path>
                  <path
                    fill="#7AC043"
                    d="M542.839,657.424l-165.583-189.05l-106.688,76.564l281.418,316.595
		                C777.121,316.287,1150,157.989,1150,157.989v-29.901C782.398,247.691,542.839,657.424,542.839,657.424z"
                    style={{ fill: "#faad14" }}
                  ></path>
                </g>
              </svg>
              <h3 className="d-flex">All stages are completed!</h3>
            </div>
          ) : undefined}
        </div>

        <div className="steps-action mt-4">
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              <LeftOutlined />
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              <RightOutlined />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
EditRider.propTypes = {
  singleRiderData: PropTypes.object,
  fetchRiderDetails: PropTypes.func,
};
export default EditRider;
