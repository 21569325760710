import React, { ReactElement } from "react";
import Layout from "../../components/Layout";
import SingleOrder from "../../components/Orders/Details";

const OrderDetailsPage = (): ReactElement => {
  return (
    <Layout>
      <SingleOrder />
    </Layout>
  );
};

export default OrderDetailsPage;
