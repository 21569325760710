import React, { ReactElement } from "react";
import Layout from "../../components/Layout";
import DetailsPickeMe from "../../components/Pickme/Details";

const PickmeDetailsPage = (): ReactElement => {
  return (
    <Layout>
      <DetailsPickeMe />
    </Layout>
  );
};

export default PickmeDetailsPage;
