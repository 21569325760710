import { Button, Cascader, DatePicker, Form, Input, Select, Spin } from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useCreateStore } from "../../hooks/use-create-store";
import { authenticateToken } from "../../utils/auth";
import { locations } from "../../utils/location";
import { responseNotification } from "../../utils/notify";
import '../../styles/css/update-form-inputs.css';

interface Props {
  visibleData?: any;
  onCloseMethod?: () => void;
}

type uniqueZones = any;
type uniqueAreas = any;

const AddRider = ({ visibleData, onCloseMethod }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [manufacturerList, setManufacturerList] = useState({
    loading: false,
    list: [],
  });
  const formRef = useRef();
  const hook = useCreateStore(formRef);
  const [options, setOptions] = useState([]);

  const filter = (inputValue, path) =>
  path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );
  
  const onSubmit = async (data) => {
    
    setLoading(true);

    if (visibleData) {
        await fetch(`${process.env.REACT_APP_USER_API}/admin/driver`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...data,
            // driverId: driverData.driverId,
            id: visibleData?.id,
            mobileNumber: `+88${data?.mobileNumber}`,
            city : hook.city,
            zone : hook.zone,
            area : hook.area,
            drivingLicenseNumber : data?.drivingLicenseNumber,
            year: moment(data?.year).format("YYYY"),
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);
            if (res.statusCode === 200) {
              responseNotification("Driver Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {

          await fetch(`${process.env.REACT_APP_USER_API}/admin/driver`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...data,
              city : hook.city,
              zone : hook.zone,
              area : hook.area,
              drivingLicenseNumber : data?.drivingLicenseNumber,
              mobileNumber: `+88${data?.mobileNumber}`,
              year: moment(data?.year).format("YYYY"),
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              setLoading(false);
      
              if (res.statusCode === 200) {
                responseNotification("Rider Create Successfully", "success");
                form.resetFields();
                if (onCloseMethod) {
                  onCloseMethod();
                }
              } else if (res.status === 500) {
                responseNotification("Internal server error", "error");
              } else {
                responseNotification(res.message || "something wrong", "warning");
              }
            })
            .catch((err) => {
              setLoading(false);
              responseNotification(`${"Internal server error"} ${err}`, "error");
              console.error("err", err);
            });
      }
  };

  const getManufecturer = useCallback(async () => {
    setManufacturerList({ loading: true, list: [] });

    await axios
      .get(`${process.env.REACT_APP_USER_API}/vehicle-brand`, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setManufacturerList({
            loading: false,
            list: res.data?.vehicleBrands || [],
          });
        }
      })
      .catch((err) => {
        setManufacturerList({
          loading: false,
          list: [],
        });
        console.error("AddRider - Error: ", err);
      });
  }, []);

  useEffect(() => {
    if (visibleData) {
      form.resetFields();
      console.log("visibleData", visibleData);
    }
  }, [form, visibleData]);

  useEffect(() => {
    getManufecturer();
  }, [getManufecturer]);

  useEffect(() => {
    setOptions(
      locations.map((location) => {
        let uniqueZones:uniqueZones = new Set(location?.zones);
        uniqueZones = [...uniqueZones];

        return {
          value: location?.district,
          label: location?.district,
          children: uniqueZones?.map((zone) => {
            let uniqueAreas:uniqueAreas = new Set(zone?.areas);
            uniqueAreas = [...uniqueAreas];
            return {
              value: zone?.name,
              label: zone?.name,
              code: zone.postCode,
              
            };
          }),
        };
      })
    );
  }, []);


  return (
    <div className="add-rider drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          initialValues={{
            ...visibleData,
            mobileNumber: visibleData?.mobileNumber?.replace("+88", ""),
            // drivingLicense: visibleData?.drivingLicense?.drivingLicenseNumber,
            drivingLicenseNumber: visibleData?.drivingLicenseNumber,
            nid: visibleData?.nid,
            carRegistrationNumber: visibleData?.car?.carRegistrationNumber,
            manufacturer: visibleData?.car?.manufacturer,
            model: visibleData?.car?.model,
            year: moment(visibleData?.car?.year),
          }}
          form={form} // like ref
          layout="vertical"
          // requiredMark="optional"
          autoComplete="off"
        >
          <h4 className="title">Basic Information:</h4>

          <Form.Item
            hasFeedback
            label="Rider Name"
            rules={[
              {
                required: true,
                message: "Rider Name is Required!",
              },
            ]}
            name="name"
            style={{ width: '100%' }}
          >
            <Input id="name" type="text" placeholder="Enter Rider Name" />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Mobile Number 01..."
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: "Mobile Number is required!",
              },
              // { type: 'string', max: 11, min: 11 },
              {
                validator: async (_, names) => {
                  console.log(names);
                  if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                    return Promise.reject(
                      new Error("Enter valid mobile number")
                    );
                  }
                },
              },
            ]}
            name="mobileNumber"
            initialValue={"01"}
          >
            <Input
              id="mobile"
              type="mobile"
              addonBefore={"+88"}
              placeholder="Enter Mobile Number"
              // allowClear
            />
            {/* <InputNumber addonBefore={selectBefore} addonAfter={selectAfter} defaultValue={100} /> */}
          </Form.Item>
          {/* <Form.Item
            hasFeedback
            label="City"
            rules={[
              {
                required: true,
                message: "City is required!",
              },
            ]}
            name="city"
          >
            <Select
              placeholder="Select City"
              showSearch={true}
              optionFilterProp="children"
              allowClear
            >
              <Select.Option value="DHAKA">DHAKA</Select.Option>
            </Select>
          </Form.Item> */}

          <Form.Item
            hasFeedback
            label="Driving License Number"
            
            style={{ width: '100%' }}
            name="drivingLicenseNumber"
          >
            <Input
              id="drivingLicenseNumber"
              type="text"
              placeholder="Enter Driving License Number"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="NID"
            
            style={{ width: '100%' }}
            name="nid"
          >
            <Input
              id="nid"
              type="text"
              placeholder="Enter NID"
            />
          </Form.Item>

          <div className="" style={{ width: '50%' }}>
              <label htmlFor="city">City</label>
              <Cascader
                options={options}
                size='large'
                value={
                  hook.city || hook.zone
                    ? [hook.city, hook.zone, hook.area]
                    : undefined
                }
                //displayRender={displayRender}
                style={{ width: '100%', borderRadius: 4 }}
                // bordered={false}
                placeholder='Select location'
                showSearch={{ filter }}
                onChange={hook.handleLocation}
                onSearch={(value) => console.log('search', value)}
                
                      />
            </div>

          {/* <h4 className="title">Vehicle Information:</h4>

          <Form.Item
            hasFeedback
            label="Car Registration Number"
            rules={[
              {
                required: true,
                message: "Car Registration Number is required!",
              },
            ]}
            name="carRegistrationNumber"
          >
            <Input
              id="carRegistrationNumber"
              type="text"
              placeholder="Enter Car Registration Number"
              name="carRegistrationNumber"
            />
          </Form.Item>

          <Form.Item hasFeedback label="Vehicle Type" name="vehicleType">
            <Select
              placeholder="Select Vehicle Type"
              showSearch={true}
              optionFilterProp="children"
              onChange={() => {
                form.resetFields(["manufacturer"]);
                getManufecturer();
              }}
            >
              <Select.Option value="CAR">CAR</Select.Option>
              <Select.Option value="BIKE">BIKE</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Manufacturer"
            rules={[
              {
                required: true,
                message: "Manufacturer required!",
              },
            ]}
            name="manufacturer"
            // validateStatus=""
            // help="Should be combination of numbers & alphabets"
          >
            <Select
              placeholder="Select Manufacturer"
              showSearch={true}
              optionFilterProp="children"
              allowClear
              notFoundContent={
                manufacturerList?.loading ? <Spin size="small" /> : null
              }
            >
              {manufacturerList?.list
                ?.filter((manufacturer) => manufacturer?.status === "ACTIVE")
                ?.map((item) => (
                  <Select.Option value={item?.brandName} key={item?.id}>
                    {item?.brandName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Model"
            rules={[
              {
                required: true,
                message: "Model is required!",
              },
            ]}
            name="model"
          >
            <Input
              id="model"
              type="text"
              placeholder="Enter Model"
              name="model"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Year"
            rules={[
              {
                required: true,
                message: "Year is required!",
              },
              {
                type: "date",
              },
            ]}
            name="year"
            style={{ flex: "0 1 calc(50% - 15px)" }}
          >
            
            <DatePicker
              id="year"
              placeholder="Enter Year"
              picker="year"
              mode="year"
              className="year-picker"
            />
          </Form.Item>
           */}

          <div className="buttons-container">
            <Button
              disabled={loading}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={() => form?.resetFields()}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
AddRider.propTypes = {
  visibleData: PropTypes.any,
  onCloseMethod: PropTypes.func,
};

export default AddRider;
