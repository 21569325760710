import {
  AutoComplete,
  Avatar,
  Button,
  Form,
  Input,
  Rate,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { authenticateToken } from "../../utils/auth";
import { getParamValue, riderStatuses } from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import TopHeading from "./TopHeading";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const { Option } = Select;

const OFFLinrRiderList = (): ReactElement => {
  const [limit, setLimit] = useState(16);
  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedRiderId, setSelectedRiderId] = useState(undefined);
  const [data, setData] = useState<any>({ loading: false, item: [] });
  const [status, setStatus] = useState("");

  const [riderOptions, setRiderOptions] = useState<any>({
    list: [],
    fetching: false,
  });

  const ref = useRef(null);

  const router = useLocation();
  const page = getParamValue(router?.search, "page");

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  const [form] = Form.useForm();

  const reseAllFieldData = () => {
    setMobileNumber("");
    setStatus("");
    form.resetFields();
  };

  const fetchRiders = useCallback(() => {
    try {
      setData({ loading: true, item: [] });

      const url =
        `${process.env.REACT_APP_USER_API}/vehicle/by-status?status=OFFLINE?` +
        `page=${page || 0}` +
        `&limit=${limit || 16}` +
        (mobileNumber
          ? `&mobile=%2B88${mobileNumber?.replace("+88", "")}`
          : ``) +
        (status ? `&status=${status}` : ``);
      fetch(url, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setData({ loading: false, item: data });
        })
        .catch(() => {
          setData({ loading: true, item: [] });
        });
    } catch (error) {
      setData({ loading: true, item: [] });
      console.log(error, "error");
    }
  }, [limit, page, status, mobileNumber]);

  const getRiderOptions = useCallback(
    async (val) => {
      setRiderOptions({ fetching: false, list: [] });

      if (val) {
        setRiderOptions({ fetching: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_USER_API}`;

        const res = await axios.get(
          `${encodedUri}/vehicle/by-status?status=OFFLINE?page=0&limit=20` +
            // (searchName ? `&name=${encodeURIComponent(searchName)}` : ``) +
            (val ? `&mobile=%2B88${val}` : ``) +
            (status ? `&status=${status}` : ``),

          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        );
        setRiderOptions({
          fetching: false,
          list: res?.data?.drivers?.map(
            (rider: { name: any; mobileNumber: any }) => {
              return {
                value: rider?.mobileNumber,
                label: rider?.name,
              };
            }
          ),
        });
      }
    },
    [status]
  );

  useEffect(() => {
    fetchRiders();
  }, [status, mobileNumber, page, limit, fetchRiders]);

  const debouncedSearch = useCallback(
    debounce((val) => getRiderOptions(val), 800),
    []
  );

  const handleSearch = (val: string) => {
    debouncedSearch(val);
  };

  return (
    <div className="rider-lists">
      <TopHeading total={data?.item?.totalElements} />
      {/* <section className="search_area">
          <Form form={form} className="search_form">
            <Form.Item name="search">
              <AutoComplete
                onSearch={handleSearch}
                onSelect={(val) => setMobileNumber(val)}
                options={riderOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  riderOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  size="large"
                  placeholder="Search by Phone (01...)"
                  onSearch={(val) => setMobileNumber(val)}
                  enterButton
                  loading={riderOptions.loading}
                  pattern={`[0-9]`}
                  maxLength={11}
                />
              </AutoComplete>
            </Form.Item>
            <Form.Item>
              {" "}
              <Select
                defaultValue={status}
                placeholder="Rider Status"
                onChange={handleChangeStatus}
                value={status}
                style={{ minWidth: 300 }}
              >
                <Option value={""}>ALL</Option>
                {Object.values(riderStatuses)?.map((status, i) => (
                  <Option value={status} key={i}>
                    {status?.split("_").join(" ")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
  
          <div className="search_btn">
            <Button type="primary" danger size="large" onClick={reseAllFieldData}>
              Reset
            </Button>
          </div>
        </section> */}
      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  {/* <div className="single-rider-heading">
                        <span>Rider Id</span>
                      </div> */}
                  <div className="single-rider-heading">
                    <span>Rider Name</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Phone Number</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Area</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Rattings</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Status</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {data.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div className="rider-table-area">
                  {data.item?.vehicleLocationList?.length ? (
                    data?.item?.vehicleLocationList?.map(
                      (rider: any, index: number) => (
                        <React.Fragment key={index}>
                          <Link to={`/riders/${rider?.id}/details`}>
                            <div className="single-rider-wrapper">
                              <div className="single-rider">
                                <Avatar
                                  size={{
                                    xs: 50,
                                    sm: 50,
                                    md: 50,
                                    lg: 40,
                                    xl: 40,
                                    xxl: 40,
                                  }}
                                  src={rider?.driver?.profilePictureUrl}
                                />
                                <span className="name ml-3">
                                  {rider?.driver?.name}
                                </span>
                              </div>

                              <div className="single-rider">
                                <span className="name">
                                  {rider?.driver?.mobileNumber?.split("+88")}
                                </span>
                              </div>
                              <div className="single-rider">
                                <span className="name">{rider?.area}</span>
                              </div>
                              <div className="single-rider">
                                <Rate
                                  disabled
                                  defaultValue={rider?.rating || 1}
                                  style={{ fontSize: 14, marginRight: 8 }}
                                />
                                <small style={{ color: "#777" }}>
                                  ({rider?.numberOfRating})
                                </small>
                              </div>
                              <div className="single-rider">
                                <span
                                  className={
                                    rider?.status === "VERIFIED"
                                      ? `radius-btn ${rider?.status?.toLowerCase()}`
                                      : `radius-btn ${rider?.status?.toLowerCase()}`
                                  }
                                >
                                  {rider?.status?.split("_").join(" ")}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </React.Fragment>
                      )
                    )
                  ) : (
                    <p className="red-txt pt-5 text-center">No riders found</p>
                  )}
                  {/* <Drawer
                        title="Rider Details"
                        width={800}
                        onClose={() => {
                          setSelectedRiderId(null);
                          fetchRiders();
                        }}
                        visible={!!selectedRiderId}
                        bodyStyle={{ paddingBottom: 80 }}
                        footer={
                          <div
                            style={{
                              textAlign: "right",
                            }}
                          ></div>
                        }
                      >
                        <RiderDetails selectedRiderId={selectedRiderId} />
                      </Drawer> */}
                </div>
              </div>
            )}
          </div>
          <PaginationTwo
            {...data.item}
            limit={limit}
            page={data?.item?.currentPageNumber || 0}
          />
        </div>
      </div>
    </div>
  );
};

export default OFFLinrRiderList;
