import PropTypes from "prop-types";
import { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getUpdatedUrl } from "../../utils";
const PaginationTwo = ({
  first,
  totalElements,
  limit,
  currentPageNumber,
}: any): ReactElement => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setitemsPerPage] = useState<number>(20);

  const [pageNumberLimit, setpageNumberLimit] = useState<number>(10);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState<number>(10);
  const [minPageNumberLimit, setminPageNumberLimit] = useState<number>(0);

  const history = useHistory();

  useEffect(() => {
    setCurrentPage(currentPageNumber + 1 || 1);
    setitemsPerPage(limit || 20);
  }, [currentPageNumber, limit]);

  const handleClick = (event: any) => {
    setCurrentPage(Number(event.target.id));

    history.push(
      getUpdatedUrl(
        "page",
        `${event.target.id - 1 >= 0 ? event.target.id - 1 : 0}`
      )
    );
  };

  const pages = [];
  const queryParams = new URLSearchParams(window.location.search);
  const page = queryParams.get("page");
  let start = 1;
  let end = 10;
  if (page === null) {
    start = 1;
    end = 10;
  } else {
    const floorValue = Math.floor(parseInt(page) / 10);
    const ceilValue = Math.ceil(parseInt(page) / 10);
    start = 10 * floorValue + 1;
    end = Math.min(10 * ceilValue, totalElements);
    if (start > end) end = Math.min(start + 9, totalElements);
  }

  for (let i = 1; i <= Math.ceil((totalElements || 0) / itemsPerPage); i++) {
    pages.push(i);
  }

  // const indexOfLastItem = currentPage * itemsPerPage
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage

  const renderPageNumbers = pages.map((number) => {
    if (number >= start && number <= end) {
      return (
        <li
          key={number}
          id={number.toString()}
          onClick={handleClick}
          className={currentPage === number ? "active" : undefined}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    history.push(
      getUpdatedUrl(
        "page",
        `${currentPage + 1 - 1 >= 0 ? currentPage + 1 - 1 : 0}`
      )
    );
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
    history.push(
      getUpdatedUrl(
        "page",
        `${currentPage - 1 - 1 >= 0 ? currentPage - 1 - 1 : 0}`
      )
    );
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  useEffect(() => {
    if (first) {
      setCurrentPage(1);
    }
  }, [first]);

  return (
    <div className="admin-pagiantion">
      <div className="row">
        <div className="col-md-5 d-flex-l">
          {totalElements ? (
            <p>
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min((currentPage - 1) * itemsPerPage + itemsPerPage , totalElements)}
              {" "}of {totalElements} entries
            </p>
          ) : undefined}
        </div>
        <div className="col-md-7">
          {totalElements > itemsPerPage ? (
            <div className="pagination-content">
              <ul className="pageNumbers">
                <li>
                  <button
                    onClick={handlePrevbtn}
                    disabled={currentPage === pages[0] ? true : false}
                  >
                    Previous
                  </button>
                </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <li>
                  <button
                    onClick={handleNextbtn}
                    disabled={
                      currentPage === pages[pages.length - 1] ? true : false
                    }
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};
PaginationTwo.propTypes = {
  first: PropTypes.bool,
  last: PropTypes.bool,
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  numberOfElements: PropTypes.number,
  currentPageNumber: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  orderTable: PropTypes.string,
};
export default PaginationTwo;
