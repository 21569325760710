import { ReactElement } from 'react'
import Layout from '../../../components/Layout'
import ShopOrders from '../../../components/Merchants/Orders'

const ShopOrderPage = (): ReactElement => {
  return (
    <Layout >
      <ShopOrders />
    </Layout>
  )
}

export default ShopOrderPage
