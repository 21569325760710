import { ReactElement } from "react";
import Layout from "../../components/Layout";
import PickupOrderList from "../../components/pickup-orders";

const PickupOrderPage = (): ReactElement => {
  return (
    <Layout>
      <PickupOrderList />
    </Layout>
  );
};

export default PickupOrderPage;
