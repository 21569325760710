import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Button,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from "antd";
import { locations } from "../../../utils/location";
import { useCreateStore } from "../../../hooks/use-create-store";
import { debounce } from "lodash";
import axios from "axios";
import { authenticateToken } from "../../../utils/auth";
import { getParamValue } from "../../../utils";
import _ from "lodash";
import OrderList from "..";
import { responseNotification } from "../../../utils/notify";
import { getAreaList, getCityList, getZoneList } from "../../../utils/services";

type SelectOptionType = {
  label: string;
  value: string;
  city: any;
  id : string;
};

const SingleOrderCreateForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const fetchRef = useRef(null);
  const hook = useCreateStore(formRef);
  const [options, setOptions] = useState([]);
  const [OptionsUser, setOptionsUser] = useState({
    loading: false,
    list: null,
  });

  const [status, setStatus] = useState();
  const [storeList, setStoreList] = useState<SelectOptionType[]>([]);

  const [amountToCollect, SetAmountToCollect] = useState("");
  const [deliveryType, setDeliveryType] = useState("sameCity");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState();
  const [productType, setProductType] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [hubId, setHubId] = useState("");
  const [hubList, setHubList] = useState([]);
  const [merchantId, setMerchantId] = useState("");
  const [recipientArea, setRecipientArea] = useState("");
  const [recipientCity, setRecipientCity] = useState("");
  const [recipientZone, setRecipientZone] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [recipientPhone, setRecipientPhone] = useState("");
  const [intruction, setIntruction] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [store, setStore] = useState("");
  const [storeId, setStoreId] = useState("");
  const [merchantOrderId, setMerchantOrderId] = useState("");
  const [city, setCity] = useState<any>();
  const [area, setArea] = useState<any>();
  const [zone, setZone] = useState<any>();

  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const handleRecpetionName = (value) => {
    setRecipientName(value);
  };
  const hadlemerchantOrderId = (value) => {
    setMerchantOrderId(value);
  };
  const HandleRecipentPhone = (value) => {
    setRecipientPhone(value);
  };
  const handleAmountToCollect = (value) => {
    SetAmountToCollect(value);
  };

  const handleRecipientAddress = (value) => {
    setRecipientAddress(value);
  };
  const handleQuantity = (value) => {
    setQuantity(value);
  };
  const handleInstructionChange = (value) => {
    setIntruction(value);
  };

  const handleMerchantChange = (value) => {
    getAllStoreList(value);
  };
  const handleStoreChange = (value) => {
    console.log(value)
    const s = storeList.find(store=>store.value === value)
    console.log(s)
    setStore(value);
    setStoreId(s?.id)
  };
  const handleProductTypeChange = (value) => {
    setProductType(value);
  };
  const handleDeliveryChange = (value) => {
    console.log(value)
    setDeliveryType(value);
  };
  const handleTotalWeightChange = (value) => {
    setTotalWeight(value);
  };
  const handlDescriptione = (value) => {
    setDescription(value);
  };
  const handlePickupLocation = (value) => {
    setPickupLocation(value);
  };

  const handleCity = function (value: { value: string; label: React.ReactNode,id:string }) {
    console.log(value);
    setCity(value);
  };

  const handleZone = function (val) {
    setZone(val);
  };

  const handleArea = function (val) {
    setArea(val);
  };

  const getOptionsUser = useCallback(
    async (mobileNumber) => {
      setOptionsUser({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${500}` +
            // (status ? `&status=${status}` : ``) +
            (mobileNumber
              ? `&mobileNumber=%2B88${mobileNumber.replaceAll("+88", "")}`
              : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOptionsUser({
            loading: false,
            list: _.without(
              res.data?.businesses?.map((user) => {
                if (user?.id && user?.mobileNumber) {
                  return {
                    label: `${user?.companyName} (${user?.mobileNumber})`,
                    value: user?.id,
                  };
                }
              }),
              undefined
            ),
          });
          console.log(res.data?.businesses);
        })
        .catch((err) => {
          setOptionsUser({ loading: false, list: [] });
          console.error("Trips: Error", err);
        });
    },
    [status]
  );
  const getAllStoreList = useCallback((mId: string) => {
    fetch(
      `${process.env.REACT_APP_USER_API}/store/getStoreDetailsByMerchant?merchantId=${mId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const filter = data?.stores.map((item: any) => {
          return { label: item.name, value: item.name, city: item.city, id: item.id };
        });
        setStoreList(filter);
      })
      .catch((err) => console.log(err));
  }, []);

  const getHubList = useCallback(() => {
    fetch(`${process.env.REACT_APP_CATALOG_READER_API}/pi-hubs`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const filter = data?.piHubs.map((item: any) => {
          return { label: item.name, value: item.id };
        });
        setHubList(filter);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getOptionsUser("");
  }, [getOptionsUser]);

  useEffect(() => {
    getHubList();
  }, [getHubList]);

  

  const getUserOptionsDebounce = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptionsUser({ loading: false, list: null });

      if (fetchId !== fetchRef.current) {
        return;
      }

      getOptionsUser(value);
    };

    return debounce(loadOptions, 800);
  }, [getOptionsUser]);

  const handleAreaClick = (e) => {
    e.stopPropagation();
  };
  

  const onSubmit = async (data) => {
    //console.log(data);
    const readyData = {
      orders: [
        {
          amountToCollect: data.amountToCollect,
          deliveryType: data.deliveryType,
          itemDesc: data.description,
          itemQuantity: data.quantity,
          itemType: data.productType,
          itemWeight: data.totalWeight,
          merchantId: data.merchantId,
          // recipientArea: data.deliveryArea[2],
          // recipientCity: data.deliveryArea[0],
          // recipientZone: data.deliveryArea[1],

          recipientArea: area?.value,
          recipientCity: city?.value,
          recipientZone: zone?.value,

          recipientName: data.recipientName,
          recipientPhone: data.recipientPhone,
          recipientAddress: data.recipientAddress,
          specialInstruction: data.intruction,
          storeName: data.store,
          pickupLocation: data.pickupLocation,
          merchantOrderId: data.merchantOrderId,
          //piHubId: hubId,
        },
      ],
    };
    console.log(readyData);
    
    await fetch(`${process.env.REACT_APP_ORDER_API}/admin/order/parcel/bulk`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          responseNotification("Order Created Successfully", "success");
          form.resetFields();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const onChange = (values, selectedOptions) => {
    console.log(values, selectedOptions);
    if (
      deliveryType === "sameCity" &&
      values?.[0].toLowerCase() === recipientCity.toLowerCase()
    ) {
      setRecipientCity(values?.[0]);
      setRecipientZone(values?.[1]);
      setRecipientArea(values?.[2]);
    } else if (
      deliveryType === "outsideCity" &&
      values?.[0].toLowerCase() !== recipientCity.toLowerCase()
    ) {
      setRecipientCity(values?.[0]);
      setRecipientZone(values?.[1]);
      setRecipientArea(values?.[2]);
    } else if (
      deliveryType === "sameDayDelivery" ||
      deliveryType === "dhakaSuburbs"
    ) {
      setRecipientCity(values?.[0]);
      setRecipientZone(values?.[1]);
      setRecipientArea(values?.[2]);
    } else {
      setRecipientCity(values?.[0]);
      setRecipientZone(values?.[1]);
      setRecipientArea(values?.[2]);
    }

    if (selectedOptions) {
      // if(form.deliveryType === 'sameDayDelivery' || form.deliveryType === 'dhakaSuburbs'){
      //   form.handleDeliveryType(selectedOptions[0].type)
      // }else{
      //   form.handleDeliveryType(form.deliveryType)
      // }
      if (
        values?.[0].toLowerCase() === "dhaka" &&
        (values?.[1].toLowerCase() === "savar" ||
          values?.[1].toLowerCase() === "keraniganj")
      ) {
        setDeliveryType("dhakaSuburbs");
      }
      if (
        values?.[0].toLowerCase() === "gazipur" ||
        values?.[0].toLowerCase() === "narayanganj"
      ) {
        setDeliveryType("dhakaSuburbs");
      }
    }
  };

  const districtDisabled = (district) => {
    if (deliveryType === "sameCity") {
      let CityValue;
      storeList.map((va) => {
        CityValue = va?.city;
      });
      //console.log(CityValue);
      //console.log(district, form?.selectedStore?.city, district != form?.selectedStore?.city)
      return district != CityValue;
    } else if (deliveryType === "outsideCity") {
      return district == store;
    } else if (
      (deliveryType === "sameDayDelivery" || deliveryType === "sameCity") &&
      district?.toLowerCase() == "dhaka"
    ) {
      return false;
    } else if (
      deliveryType === "dhakaSuburbs" &&
      (district?.toLowerCase() == "dhaka" ||
        district?.toLowerCase() == "gazipur" ||
        district?.toLowerCase() == "narayanganj")
    ) {
      return false;
    } else return true;
  };

  const zoneDisabled = (district, zone) => {
    // if(form.deliveryType === 'sameCity'){
    //   if(district?.toLowerCase() == 'dhaka'){
    //     if(zone?.toLowerCase() =='savar' || zone?.toLowerCase() =='keraniganj'){
    //       return true;
    //     }else return false;
    //   }
    // }

    if (deliveryType === "dhakaSuburbs") {
      if (district?.toLowerCase() == "dhaka") {
        if (
          zone?.toLowerCase() == "savar" ||
          zone?.toLowerCase() == "keraniganj"
        ) {
          return false;
        } else return true;
      } else if (
        district?.toLowerCase() == "gazipur" ||
        district?.toLowerCase() == "narayanganj"
      ) {
        return false;
      } else return true;
    } else return false;
  };

  // useEffect(() => {
  //   //clear address value if delivery type changes
  //   //form.resetDeliveryArea()
  //   console.log(recipientCity, recipientZone, recipientArea);
  //   console.log(store);
  //   console.log(deliveryType);
  //   setOptions(
  //     locations.map((location) => {
  //       let uniqueZones: any = new Set(location?.zones);
  //       uniqueZones = [...uniqueZones];

  //       return {
  //         value: location?.district,
  //         label: location?.district,
  //         type: location?.type,
  //         disabled: districtDisabled(location?.district),
  //         children: uniqueZones?.map((zone) => {
  //           let uniqueAreas: any = new Set(zone?.areas);
  //           uniqueAreas = [...uniqueAreas];
  //           return {
  //             value: zone?.name,
  //             label: zone?.name,
  //             code: zone.postCode,
  //             disabled:
  //               districtDisabled(location?.district) ||
  //               zoneDisabled(location?.district.toLowerCase(), zone?.name),
  //             children: uniqueAreas?.map((area) => ({
  //               value: area,
  //               label: area,
  //               disabled:
  //                 districtDisabled(location?.district) ||
  //                 zoneDisabled(location?.district.toLowerCase(), zone?.name),
  //             })),
  //           };
  //         }),
  //       };
  //     })
  //   );
  // }, [deliveryType, store]);

  useEffect(() => {
    setLoading(true)
    if(!deliveryType){
      setDeliveryType('sameCity')
    }
    setCityList([])
    setZoneList([])
    setAreaList([])
    form.setFieldsValue({ city: null ,zone:null,area:null});
    getFormattedCityList().then((result)=>setCityList(result));
    setLoading(false)
  }, [deliveryType,storeId]);

  const getFormattedCityList = useCallback(async()=>{
    console.log(storeId,deliveryType)
    if(deliveryType && storeId){
      const cityList = await getCityList(storeId,deliveryType);
      return cityList?.commonCities?.map((city)=>{return {
        label: city.name,
        value : city.name,
        id : city.id,
      }})
    }
  },[storeId,deliveryType])

  useEffect(()=>{
    setLoading(true)
    getFormattedZoneList().then(zoneList=>setZoneList(zoneList));
    setLoading(false)
  },[city])

  const getFormattedZoneList = useCallback( async()=>{
    const zoneList = await getZoneList(city?.id , deliveryType);
    //console.log(zoneList)
    return zoneList?.commonZones?.map((zone)=>{return {
      label: zone.name,
      value : zone.name,
      id : zone.id,
    }})
  },[city])

  useEffect(()=>{
    setLoading(true)
    getFormattedAreaList().then(a=>setAreaList(a))
    setLoading(false)
  },[zone])

  const getFormattedAreaList = useCallback(async()=>{
    const areaList = await getAreaList(zone?.id , deliveryType);
    //console.log(areaList)
    return areaList?.commonAreas?.map((area)=>{return {
      label: area.name,
      value : area.name,
      id : area.id
    }})
  },[zone])

  return (
    <div>
      <div className="single-order-form">
        <h3>
          <b>New Order</b>
        </h3>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form an"
          initialValues={{ remember: true }}
          form={form}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-md-3">
              <Form.Item
                label="Merchant"
                name="merchantId"
                rules={[
                  {
                    required: true,
                    message: "Please select your merchant number!",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  value={merchantId}
                  showSearch
                  onSearch={getUserOptionsDebounce}
                  onChange={handleMerchantChange}
                  options={OptionsUser?.list}
                  filterOption={(input, option) =>
                    ((option?.label ?? "") as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </div>
            <div className="col-md-3">
              <Form.Item
                label="Store"
                name="store"
                rules={[
                  {
                    required: true,
                    message: "Please slect your store name!",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  value={store}
                  onChange={(e)=>{
                    handleStoreChange(e)
                  }}
                  options={storeList}
                />
              </Form.Item>
            </div>
            <div className="col-md-3">
              <Form.Item
                label="Product Type"
                name="productType"
                rules={[
                  { required: true, message: "Please Select Product Type!" },
                ]}
              >
                <Select
                  defaultValue="Select Product Type"
                  style={{ width: "100%" }}
                  value={productType}
                  onChange={handleProductTypeChange}
                  options={[
                    { value: "PARCEL", label: "PARCEL" },
                    { value: "DOCUMENT", label: "DOCUMENT" },
                    { value: "FRAGILE", label: "FRAGILE" },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-md-3">
              <Form.Item
                label="Merchant Order Id"
                name="merchantOrderId"
                // rules={[
                //   { required: true, message: "Please input merchantOrderId!" },
                // ]}
              >
                <Input
                  value={merchantOrderId}
                  onChange={hadlemerchantOrderId}
                  placeholder="merchantOrderId"
                />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h4>Recipient Details</h4>
              <div className="col-md-12">
                <Form.Item
                  label="Recipient’s Name"
                  name="recipientName"
                  rules={[
                    { required: true, message: "Please input your Name!" },
                  ]}
                >
                  <Input
                    value={recipientName}
                    onChange={handleRecpetionName}
                    placeholder="Name"
                  />
                </Form.Item>
              </div>

              <div className="col-md-12">
                <Form.Item
                  label="Recipient’s Phone"
                  name="recipientPhone"
                  rules={[
                    {
                      required: true,
                      message: "Please input recipient phone num!",
                    },
                  ]}
                >
                  <Input
                    value={recipientPhone}
                    onChange={HandleRecipentPhone}
                    placeholder="Phone Number"
                  />
                </Form.Item>
              </div>


              <Row className='' style={{justifyContent:"space-between"}}>
                <Col >
                  <Form.Item
                    label='City'
                    name={'city'}
                 
                  >
                    <AutoComplete
                      placeholder='Select City'
                      
                      options={cityList}
                      defaultValue={city?.id}
                      onChange={(_,option)=>handleCity(option)}
                      filterOption={(inputValue, option) =>
                        option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                    
                  </Form.Item>
                </Col>
                &nbsp;
                <Col >
                  <Form.Item label='Zone' name='zone'>
                    <AutoComplete
                  
                      placeholder='Select Zone'
                      options={zoneList}
                      value={zone?.id}
                      onChange={(_,option)=>{
                        handleZone(option)
                      }}
                      filterOption={(inputValue, option) =>
                        option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  </Form.Item>
                </Col>
                <Col >
                  <Form.Item label='Area' name='area'>
                    <AutoComplete
                    
                      placeholder='Select Area'
                      options={areaList}
                      value={area?.id}
                      onChange={(_,option)=>{
                        handleArea(option) 
                      }}
                      filterOption={(inputValue, option) =>
                        option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* <div className="col-md-12">
                <Form.Item
                  label="Delivery Area"
                  name="deliveryArea"
                  rules={[
                    { required: true, message: "Please Select Delivery Area!" },
                  ]}
                >
                  <Cascader
                    options={options}
                    size="large"
                    value={
                      hook.city || hook.zone || hook.area
                        ? [hook.city, hook.zone, hook.area]
                        : undefined
                    }
                    displayRender={displayRender}
                    style={{ width: "100%", borderRadius: 4 }}
                    // bordered={false}
                    placeholder="Select location"
                    showSearch={{ filter }}
                    onChange={onChange}
                    onSearch={(value) => console.log("search", value)}
                  />
                </Form.Item>
              </div> */}
              <div className="col-md-12">
                <Form.Item
                  label="Recipient’s Address"
                  name="recipientAddress"
                  rules={[{ required: true, message: "Please input address!" }]}
                >
                  <Input.TextArea
                    value={recipientAddress}
                    onChange={handleRecipientAddress}
                    rows={4}
                  />
                </Form.Item>
              </div>
              <div className="col-md-12">
                <Form.Item
                  label="Instruction"
                  name="intruction"
                  // rules={[
                  //   { required: true, message: "Please input your username!" },
                  // ]}
                >
                  <Input
                    value={intruction}
                    onChange={handleInstructionChange}
                    placeholder="Basic usage"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6">
              <h4>Delivery Details</h4>
              <div className="col-md-12">
                <Form.Item
                  label="Delivery Type"
                  name="deliveryType"
                  rules={[
                    {
                      required: true,
                      message: "Please select your delivery type!",
                    },
                  ]}
                >
                  <Select
                    defaultValue="sameCity"
                    style={{ width: "100%" }}
                    value={deliveryType}
                    onChange={handleDeliveryChange}
                    options={[
                      { value: "sameCity", label: "Same City" },
                      { value: "outsideCity", label: "Outside City" },
                      { value: "dhakaSuburbs", label: "Dhaka Suburbs" },
                      { value: "sameDayDelivery", label: "Same Day Delivery" },
                    ]}
                  />
                </Form.Item>
              </div>

              <div className="col-md-12">
                <Form.Item
                  label="Total Weight"
                  name="totalWeight"
                  rules={[
                    { required: true, message: "Please input total weight!" },
                  ]}
                >
                  <Select
                    defaultValue="Select Weight"
                    value={totalWeight}
                    style={{ width: "100%" }}
                    onChange={handleTotalWeightChange}
                    options={[
                      { value: 1, label: "0-1" },
                      { value: 2, label: "1-2" },
                      { value: 3, label: "2-3" },
                      { value: 4, label: "3-4" },
                      { value: 5, label: "4-5" },
                      { value: 6, label: "5-6" },
                      { value: 7, label: "6-7" },
                      { value: 8, label: "7-8" },
                      { value: 9, label: "8-9" },
                      { value: 10, label: "9-10" },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      label="Quantity"
                      name="quantity"
                      style={{ width: "100%" }}
                      // rules={[
                      //   { required: true, message: "Please select weight!" },
                      // ]}
                    >
                      <InputNumber
                        value={quantity}
                        onChange={handleQuantity}
                        style={{ width: "100%", height: "40px" }}
                        placeholder="Quantity"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Amount to Collect"
                      name="amountToCollect"
                      style={{ width: "100%" }}
                      rules={[
                        { required: true, message: "Please input Amount!" },
                      ]}
                    >
                      <InputNumber
                        value={amountToCollect}
                        onChange={handleAmountToCollect}
                        style={{ width: "100%", height: "40px" }}
                        placeholder="Amount"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <Form.Item
                  label="Item Description & Price"
                  name="description"
                  // rules={[
                  //   { required: true, message: "Please input Description!" },
                  // ]}
                >
                  <Input.TextArea
                    onChange={handlDescriptione}
                    value={description}
                    rows={4}
                  />
                </Form.Item>
              </div>

              <div className="col-md-12">
                <Form.Item
                  label="Pickup Location"
                  name="pickupLocation"
                  // rules={[
                  //   { required: true, message: "Please input your username!" },
                  // ]}
                >
                  <Input
                    value={pickupLocation}
                    onChange={handlePickupLocation}
                    placeholder="Pickup Location"
                  />
                </Form.Item>
              </div>

              <div className="col-md-12">
                <Form.Item label="Hub" name="hubId">
                  <Select
                    defaultValue="Select Hub"
                    value={hubId}
                    style={{ width: "100%" }}
                    onChange={(val) => setHubId(val)}
                    options={hubList}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: "15px", height: "40px" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SingleOrderCreateForm;
