import { ReactElement } from "react";
import Layout from "../../../components/Layout";
import LogisticOrderList from "../../../components/logistic-orderlist";

const LogisticOrderListPage = (): ReactElement => {
  return (
    <Layout>
      <LogisticOrderList />
    </Layout>
  );
};

export default LogisticOrderListPage;
