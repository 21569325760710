import { Button } from 'antd'
import PropTypes from 'prop-types'
import React, { ReactElement } from 'react'
import { connect } from 'react-redux'

const AddShopBtn = (props): ReactElement => {

  return (
    <div className="search-box-shadow add-search-box-shadow">
      <Button
        {...props}
        className={'add-btn'
        }
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
            stroke="#34A852"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 6.66699V13.3337"
            stroke="#34A852"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66667 10H13.3333"
            stroke="#34A852"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Create
      </Button>
    </div>
  )
}
AddShopBtn.propTypes = {
  onClick: PropTypes.func
}

export default AddShopBtn
