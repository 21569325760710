import { Button, Drawer, Form } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement, useEffect, useState } from "react";
import AddBtn from "../common/AddBtn";
import AddRider from "../Riders/AddRider";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import {difference} from 'lodash'


const TopHeading = ({ total, refetch, runsheet, orders }): ReactElement => {
  const [visible, setvisible] = useState<boolean>(false);
  const [parcelSortingForm] = Form.useForm();
  const [totalScanned, setTotalScanned] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  
  const showDrawer = () => {
    setvisible(true);
  };
  useEffect(()=>{
    setOrderList(orders?.map(order=> order.orderId))
    console.log(runsheet)
  },[orders])

  const verifyOrder = (formData)=>{
    const scannedOrderIds = formData.orderIds?.split("\n").filter(Boolean);
    const intersection = scannedOrderIds.filter(element => !orderList.includes(element));
    console.log(intersection)
    if(intersection?.length == 0){
      const request = {
        'orderIds' : scannedOrderIds,
      }
      axios.put(`${process.env.REACT_APP_ORDER_API}/courier/run-sheet-receive/${runsheet?.id}`,request,
      {
          headers: {
              Authorization: `Bearer ${authenticateToken()}`,
          },
      }
      ).then((res) => {
        // console.log(res)
        setLoading(false)
        if(res?.status === 200){
            responseNotification('Order Verfied successfully','success')
            refetch?.();
        }else{
            responseNotification(res?.data?.message,'error')
        }
      })
      .catch((err) => {
          console.error("Order Sorting: Error", err);
      });
    }else{
      responseNotification('Order ID ['+intersection + '] are not in the runsheet.','error')
    }
    }
    

  const onClose = () => {
    setvisible(false);
  };

  return (
    <div className="top-heading-products top-heading-content">
      <div className="container-fluid">
        <div className="row pt-3 pb-3 d-flex-sb">
          <div className="col-lg-6 col-md-6 no-padding">
            <div className="page-heading-content">
              <h2 className="d-flex-l wrap d-flex-wrap d-flex-base">
                Parcel Runsheet Order Data{" "}
                <span className="d-flex-l ml-1">{total} Order(s)</span>
              </h2>
            </div>
          </div>
          {runsheet && runsheet?.status === 'OPEN' &&
          <div className="single-button">
            <Button onClick={showDrawer} type="primary" >Verify Parcel Order</Button>
          </div>}

          <Drawer
            title={`Scan Order ID To Verify Runsheet`}
            onClose={() => {
              onClose()
              setTotalScanned(0);
              parcelSortingForm.resetFields();
            }}
            visible={!!visible}
            destroyOnClose={true}
            width="460px"
          >
            <Form
              layout="vertical"
              form={parcelSortingForm}
              onFinish={verifyOrder}
            >
              
              <Form.Item
                label={`Scan Order ID (Total Scanned : ${totalScanned})`}
                name="orderIds"
                rules={[
                  {
                    required: true,
                    message: "Order id is required!",
                  },
                ]}
              >
                <TextArea
                  onChange={(e) => {
                    const val = e.target.value?.split("\n").length - 1;
                    // console.log(val);
                    setTotalScanned(val);
                  }}
                  rows={25}
                />
              </Form.Item>
              <div className="buttons-container d-flex">
                <Button
                  type="ghost"
                  htmlType="button"
                  onClick={() => parcelSortingForm.resetFields()}
                  className="reset-submit-btn text-center mr-2"
                >
                  Reset
                </Button>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="add-submit-btn text-center mr-2"
                >
                  Verify All Orders
                </Button>
              </div>
            </Form>
          </Drawer>
          
        </div>
      </div>
    </div>
  );
};

TopHeading.propTypes = {
  UserId: PropTypes.string,
  GetStatus: PropTypes.string,
  GetPrime: PropTypes.string,
  GetFlagship: PropTypes.string,
  GetRocket: PropTypes.string,
  name: PropTypes.string,
};

export default TopHeading;
