import {
  Alert,
  Button,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Select,
} from "antd";
import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";

import { getParamValue, OrderStatus } from "../../utils";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
// @ts-ignore
import builkcsv from "../../demo-files/order-ids.csv";
// @ts-ignore
import bulkordercsv from "../../demo-files/bulk-order.csv";
import * as d3 from "d3";
import axios from "axios";
import moment from "moment";
import Loader from "../common/Loader";

import { useLocation } from "react-router-dom";
import { LeftCircleOutlined } from "@ant-design/icons";
const { TextArea } = Input;

const TopHeading = ({
  total,
  refetch,
  filterData,
  orders: items,
  afterStatusUpdate,
  inShop = true,
}): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [orderDataForDownload, setOrderDataForDownload] = useState({
    loading: false,
    data: [],
  });
  const [orders, setOrders] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [modal, setModal] = useState(false);

  const location = useLocation();
  const orderStatus = getParamValue(location.search, "status") || "";

  const fetchRef = useRef(0);
  const navigte = useHistory();

  const [form2] = Form.useForm();

  useEffect(() => {
    setOrderIds(items?.map((i) => i?.orderId) || []);
    setOrders(items || []);
  }, [items]);

  const onSubmitStatus = async (data) => {
    if (data?.status && orderIds?.length) {
      const readyData = {
        orderIds: orderIds,
        status: data?.status?.toUpperCase(),
        note: data?.note || "",
      };
      //${process.env.REACT_APP_ORDER_API}
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/order/status-update`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            responseNotification(
              "Selected Order Statuses Updated Successfully",
              "success"
            );
            afterStatusUpdate?.();

            setModal(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      responseNotification(
        `Please select a status & upload minimum 1 order ID`,
        "error"
      );
    }
  };

  // download as csv
  const downloadAllOrders = useCallback(async () => {
    if (orders) return orders;
  }, [orders]);

  function getVal(val) {
    let escaped = val;
    if (typeof val === "string") {
      escaped = ("" + val).replace(/"/g, '"');
      // @ts-ignore
      escaped = escaped?.replace(/[\t\n\"]/gi, " ");
    }
    return `"${escaped}"`; // To escape the comma in a address like string.
  }

  async function getMerchantInfo(id) {

    refetch();
    console.log(filterData)
    return await axios
      .get(`${process.env.REACT_APP_USER_API}/business/${id}`, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        if (data.status !== 200) {
          responseNotification(
            data.statusText || "Something went wrong",
            "error"
          );
        }
        return data?.data?.business;
      })
      .catch((err) => {
        responseNotification("Error occured", "error");
        console.log(err);
      });
  }

  console.log(orders,inShop);

  if (orderDataForDownload?.loading) return <Loader />;

  return (
    <div className="top-heading-products top-heading-content">
      <div className="container-fluid">
        <div className="row pt-3 pb-3 d-flex-sb">
          <div className="col-lg-6 col-md-6 no-padding">
            <div className="page-heading-content">
              <h2 className="d-flex justify-content-start align-items-center">
                <Link to="/runsheet">
                  <LeftCircleOutlined
                    style={{
                      fontSize: "30px",
                      color: "#e62d26",
                      marginRight: "10px",
                    }}
                  />
                </Link>
                Pickup Runsheet{" "}
                <span className="d-flex-l ml-1">{total} Runsheet(s)</span>
              </h2>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 no-padding d-flex-r">
            {
            // !inShop && (
            //   <>
            //     <div className="single-button ml-2">
            //       <Button
            //         type="dashed"
            //         shape="round"
            //         size="large"
            //         loading={orderDataForDownload?.loading}
            //         disabled={orders && orders?.length == 0}
            //         onClick={() => {
            //           downloadAllOrders().then((res) => {
            //             if (res) {
            //               const orders = [];
            //               res?.forEach((order) => {
            //                 console.log(order);
            //                 let obj = {};
            //                 obj["orderId"] = order?.orderId;
            //                 obj["status"] = order?.status;
            //                 obj["createdAt"] = moment(order?.createdAt).format(
            //                   "MM-DD-yyyy hh:mma"
            //                 );
            //                 obj["updatedAt"] = moment(order?.updatedAt).format(
            //                   "MM-DD-yyyy hh:mma"
            //                 );
            //                 if (
            //                   order?.status == "RETURN" ||
            //                   order?.status == "HOLD"
            //                 ) {
            //                   obj["reason"] =
            //                     order?.statusLogs[
            //                       order?.statusLogs.length - 1
            //                     ]?.note;
            //                 } else {
            //                   obj["reason"] = "";
            //                 }
            //                 const store = order?.parcelOrder?.stores?.find(
            //                   (store) =>
            //                     store.name === order?.parcelOrder?.storeName
            //                 );

            //                 if (store) {
            //                   obj["storeContactNumber"] = store?.contactNumber;
            //                 } else {
            //                   obj["storeContactNumber"] = "";
            //                 }
            //                 if (order?.parcelOrder?.merchantOrderId) {
            //                   obj["merchantOrderId"] =
            //                     order?.parcelOrder?.merchantOrderId;
            //                 } else {
            //                   obj["merchantOrderId"] = "";
            //                 }

            //                 delete order?.parcelOrder?.stores;

            //                 obj = {
            //                   ...obj,
            //                   //...(copiedParcelOrder || {}),
            //                   ...(order?.parcelOrder || {}),
            //                   expectedPayoutAmount:
            //                     order?.parcelOrder?.amountToCollect -
            //                     order?.parcelOrder?.deliveryCharge -
            //                     order?.parcelOrder?.codCharge,
            //                 };

            //                 obj["orderNote"] = order?.orderNote;

            //                 for (let [key, val] of Object.entries(obj)) {
            //                   obj[key] = getVal(val);
            //                 }
            //                 orders?.push(obj);
            //               });
            //               const titleKeys = Object.keys(orders[0]).map((key) =>
            //                 getVal(key)
            //               );

            //               const refinedData = [];
            //               refinedData.push(titleKeys);

            //               orders.forEach((item) => {
            //                 refinedData.push(Object.values(item));
            //               });
            //               let csvContent = "";
            //               refinedData.forEach((row) => {
            //                 csvContent += row.join(",") + "\n";
            //               });

            //               // @ts-ignore
            //               const blob = new Blob([csvContent], {
            //                 type: "text/csv;charset=utf-8,",
            //               });

            //               const objUrl = URL.createObjectURL(blob);
            //               const link = document.createElement("a");
            //               link.setAttribute("href", objUrl);
            //               link.setAttribute(
            //                 "download",
            //                 `orders-${orders?.length}.csv`
            //               );
            //               link?.click();
            //             } else {
            //               // done(false);
            //               responseNotification("No data", "warning");
            //             }
            //           });
            //         }}
            //       >
            //         {orderDataForDownload?.loading
            //           ? "Loading..."
            //           : "Download"}
            //       </Button>
            //     </div>
            //   </>
            // )
            }
            <div className="single-button ml-2">
              <Button
                shape="round"
                size="large"
                onClick={() => setModal(true)}
                loading={loading}
                disabled={!orderIds?.length}
                danger
              >
                Status Update
              </Button>
            </div>
          </div>

          <Modal visible={modal} onCancel={() => setModal(false)} footer={null}>
            <Form layout="vertical" onFinish={onSubmitStatus} form={form2}>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Status is required!",
                  },
                ]}
              >
                <Select
                  className="w-full w-100"
                  placeholder="Select status"
                  onChange={(val) => {
                    console.log(val);
                  }}
                >
                  <Select.Option value="picked">Picked</Select.Option>
                  <Select.Option value="unpicked">UnPicked</Select.Option>
                </Select>
              </Form.Item>

              <div className="buttons-container mt-4">
                <Button
                  disabled={loading}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="add-submit-btn text-center mr-2"
                >
                  Update Status
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    </div>
  );
};

TopHeading.propTypes = {
  UserId: PropTypes.string,
  GetStatus: PropTypes.string,
  GetPrime: PropTypes.string,
  GetFlagship: PropTypes.string,
  GetRocket: PropTypes.string,
  name: PropTypes.string,
};

export default TopHeading;
