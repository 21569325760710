import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
  WarningFilled,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Rate, Drawer, Image, Avatar, Form, Select, Alert, Space, Modal, Popconfirm, Row, Col, Card, Input, Cascader } from "antd";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { authenticateToken } from "../../../utils/auth";
import { responseNotification } from "../../../utils/notify";
import { useHistory, useParams } from "react-router";
import _, { debounce } from "lodash";
import ShopLayout from "../Layout";

import ShopHeader from "../Layout/topHeader";
import { useDispatch } from "react-redux";
import { SET_MERCHANT_INFO, SET_SHOP_INFO } from "../../../redux/auth/authType";
import { qTypeArray } from "../../../utils";
import AddMerchant from "../AddMerchant";
import { locations } from "../../../utils/location";
const { Option } = Select;

const ShopDetails = (): ReactElement => {
  const [type, setType] = useState(qTypeArray?.[0]);
  const [visible, setVisible] = useState(undefined);
  const [visibleDrawer, setVisibleDrawer] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [merchantDetailsId,setMerchantDetailsId] = useState("");
  const [merchantDetailsStatus,setMerchantDetailsStatus] = useState("");
  const [shopEditModalOpen, setShopEditModalOpen] = useState(false);
  const [shopName, setShopName] = useState('');
  const [contactNum, setContactNum] = useState('');
  const [contactName, setContactName] = useState('');
  const [address, setAddress] = useState('');
  const [shopStatus, setShopStatus] = useState('');
  const [storeId, setStoreId] = useState('');

  const [options, setOptions] = useState([]);
  const [city, setCity] = useState('');
  const [zone, setZone] = useState('');
  const [area, setArea] = useState('');

  const route = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedShopForEdit, setSelectedShopForEdit] = useState(undefined);
  const [shopId, setShopId] = useState<any>();
  const [singleShopInfo, setSingleShopInfo] = useState({
    loading: false,
    data: null,
  });
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [store,setStore] = useState([])

  const ACTIVE = "ACTIVE";
  const REJECT = "REJECT";
  // const [categoryData, setCategoryData] = useState({
  //   loading: false,
  //   list: null,
  // });

  const resetModalForm = ()=>{
    setShopId(undefined);
    setShopName('');
    setContactName('');
    setContactNum('');
    setAddress('')
    setShopStatus('')
    setCity('');
    setZone('');
    setArea('')
  }
  const fetchShopDetails = useCallback((merchantId) => {
    if (merchantId) {
      try {
        setSingleShopInfo({ loading: true, data: undefined });
        axios
          .get(`${process.env.REACT_APP_USER_API}/business/${merchantId}`, {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
              "Content-Type": "application/json",
            },
          })
          .then((data) => {
            if (data.status === 200) {
              setSingleShopInfo({
                loading: false,
                data: data?.data?.business,
              });
              setStore(data.data.stores);
              dispatch({
                type: SET_MERCHANT_INFO,
                payload: {
                  merchantInfo: data?.data?.business,
                },
              });
            } else {
              setSingleShopInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleShopInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleShopInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  const getShopsOptions = useCallback(
    async (val?: string) => {
      setShopsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop?page=0&limit=20` +
          (val ? `&key=${val}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      );
      setShopsOptions({
        loading: false,
        list: res?.data?.shops?.map((shop) => {
          return {
            value: shop?.id,
            label: shop?.name,
          };
        }),
      });
    },
    [type]
  );

  useEffect(() => {
    fetchShopDetails((route as any)?.merchantId);
  }, [fetchShopDetails, (route as any)?.merchantId]);

  useEffect(() => {
    setOptions(
      locations.map((location) => {
        let uniqueZones = new Set(location?.zones) as any;
        uniqueZones = [...uniqueZones];

        return {
          value: location?.district,
          label: location?.district,
          children: uniqueZones?.map((zone) => {
            let uniqueAreas = new Set(zone?.areas) as any;
            uniqueAreas = [...uniqueAreas];
            return {
              value: zone?.name,
              label: zone?.name,
              code: zone.postCode,
              children: uniqueAreas?.map((area) => ({
                value: area,
                label: area,
              })),
            };
          }),
        };
      })
    );
  }, []);


  const filter = (inputValue, path) =>{
    console.log(inputValue, path)
    
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }

const displayRender = (labels, selectedOptions) =>
  labels.map((label, i) => {
    const option = selectedOptions[i];
    if (i === labels.length - 1) {
      return (
        <span key={option?.value}>
          {label}
          {option?.code && (
            <>
              (
              <a onClick={(e) => handleAreaClick(e, label, option)}>
                {option?.code}
              </a>
              )
            </>
          )}
        </span>
      );
    }
    return <span key={option?.value}>{label} / </span>;
  });

  const handleAreaClick = (e, label, option) => {
    e.stopPropagation();
    console.log(label,option)
  };
  // const getCategoryOptions = useCallback(
  //   async (value) => {
  //     setCategoryData({ loading: true, list: null });
  //     const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
  //     axios
  //       .get(
  //         `${encodedUri}/category/search?page=0&limit=20&isParent=true&type=${singleShopInfo?.data?.type}&key=${value}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${authenticateToken()}`,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         setCategoryData({ loading: false, list: res.data });
  //       })
  //       .catch((err) => {
  //         setCategoryData({ loading: false, list: [] });
  //         console.error("Category: Error", err);
  //       });
  //   },
  //   [singleShopInfo?.data?.type]
  // );

  const onConvertShopAsSupplier = async () => {
    if (shopId === singleShopInfo?.data?.id) {
      responseNotification(
        "You can't convert this SHOP into its own SUPPLIER!",
        "error"
      );
    } else {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/converted-to-supplier`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            needToConvertShopId: singleShopInfo?.data?.id,
            tarmerchantId: shopId,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              "Merchant Converted as Supplier Successfully",
              "success"
            );
            history.push(`/shops`);
            setVisibleDrawer(!visibleDrawer);
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  // useEffect(() => {
  //   if (singleShopInfo?.data?.type) {
  //     getCategoryOptions("");
  //   }
  // }, [getCategoryOptions]);

  useEffect(() => {
    if (visibleDrawer) {
      getShopsOptions();
    }
  }, [getShopsOptions, visibleDrawer]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "shop") getShopsOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getShopsOptions]);

  useEffect(() => {
    if (visible) {
      setSelectedShopForEdit(undefined);
    }
  }, [selectedShopForEdit]);

  useEffect(() => {
    setSelectedShopForEdit(undefined);
  }, [visible]);

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const onClose = () => {
    setSelectedShopForEdit(null);
    fetchShopDetails((route as any)?.merchantId);
    setVisibleDrawer(!visibleDrawer);
  };

  const shop = singleShopInfo?.data;

  console.log("merchant : ", shop);

  const onChangeCascaderLocation = (values)=>{
    
      setCity(values?.[0]);
      setZone(values?.[1]);
      setArea(values?.[2]);
    
  }

  const handleOk = (e) => {
    e.preventDefault();
    const encodedUri = `${process.env.REACT_APP_USER_API}`;
    fetch(`${encodedUri}/business/updateMerchantStatus/${merchantDetailsId}/${merchantDetailsStatus}`,{
      method:"PUT",
      headers: { 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authenticateToken()}` },
      
    })
    .then(async response => {
      const data = await response.json();
      if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      fetchShopDetails((route as any)?.merchantId);
      responseNotification("Selected Item Successfully Update.");
  })
  .catch(err => {
      responseNotification(err.message || "Something went wrong to Merchant");
  });

  };

  const handleCancel = (e) => {
    e.preventDefault();
    console.log('cancel')
  };

  const approveMerchants=(id,status)=>{
    setMerchantDetailsId(id)
    setMerchantDetailsStatus(status)
  }

  const showShopEditModal = (storeId) => {
    setShopEditModalOpen(true);
    if(storeId){
      setStoreId(storeId)
      const openedStore = store.find(store => store.id === storeId);
      if(openedStore){
        setShopId(storeId);
        setShopName(openedStore?.name);
        setContactName(openedStore?.contactName);
        setContactNum(openedStore?.contactNumber);
        setAddress(openedStore?.address)
        setShopStatus(openedStore?.status)
        setCity(openedStore?.city);
        setZone(openedStore?.zone);
        setArea(openedStore?.area)
      }
    }else{
      setStoreId(undefined)
      
    }
  };

  const handleShopEditCancel = () => {
    resetModalForm();
    setShopEditModalOpen(false);
  };
  const user_url = process.env.REACT_APP_USER_API;
  const onShopEditSubmit = async() =>{
     console.log(storeId, shopName,contactNum,contactName,address)
     

     if(!shopName){
      responseNotification('Store name cant be empty', 'error');
      return;
     }
     if(!contactNum){
      responseNotification('Contact number cant be empty', 'error');
      return;
     }
     let requestBody = {};
     if(storeId){
      requestBody = {
        id : storeId,
        name : shopName,
        address : address,
        contactNumber :  contactNum,
        contactName : contactName,
        status : shopStatus,
      }
     }else{
      requestBody= {
        name : shopName,
        address : address,
        contactNumber :  contactNum,
        contactName : contactName,
        merchantId : shop?.id,
        city : city,
        zone : zone,
        area : area,
      }
     }
     

    console.log(requestBody)
    try {
      const response = await fetch(user_url + '/store', {
        method: storeId ? 'PUT' : 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authenticateToken()}` },
        body: JSON.stringify(requestBody),
      });
      const result = await response.json();
      if(result && result?.statusCode === 200){
        handleShopEditCancel();
        if(storeId){
          responseNotification('Shop updated successfully.','success')
        }else{
          responseNotification('Shop created successfully.','success')
        }
        
        fetchShopDetails((route as any)?.merchantId);
      }else{
        responseNotification('Shop update error.','error')
      }
    } catch (err) {
      console.log(err);
    }
  }

  console.log(store)

  return (
    <React.Fragment>
      <ShopLayout shopId={(route as any)?.merchantId as string}>
        <div className="bg-white">
          <ShopHeader
            leftarea={"Merchant Information"}
            rightarea={
              <div className="d-flex">
                <Space size="small">
                  <Button
                    type="dashed"
                    onClick={() => setSelectedShopForEdit(singleShopInfo?.data)}
                  >
                    <EditOutlined /> Edit
                  </Button> 
                  {shop?.status==="ACTIVE"? 
                  <Popconfirm
                  title="Are you sure you want to REJECT the Merchant?"
                  onConfirm={handleOk}
                  onCancel={handleCancel}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    danger
                    type="dashed"
                    onClick={() =>approveMerchants(shop?.id,REJECT)}>
                    <CloseOutlined /> Reject
                  </Button>
                  </Popconfirm>
                  :shop?.status==="HOLD"?
                    <>
                     <Popconfirm
                        title="Are you sure you want to ACTIVE the Merchant?"
                        onConfirm={handleOk}
                        onCancel={handleCancel}
                        okText="Yes"
                        cancelText="No">
                        <Button
                        type="dashed"
                        onClick={() =>approveMerchants(shop?.id,ACTIVE)}>
                          <CheckOutlined /> Approve
                        </Button>
                      </Popconfirm>
                      <Popconfirm
                        title="Are you sure you want to REJECT the Merchant?"
                        onConfirm={handleOk}
                        onCancel={handleCancel}
                        okText="Yes"
                        cancelText="No">
                        <Button
                          danger
                          type="dashed"
                          onClick={() =>approveMerchants(shop?.id,REJECT)}>
                          <CloseOutlined /> Reject
                        </Button>
                      </Popconfirm>
                    </>
                  :
                  <Popconfirm
                    title="Are you sure you want to ACTIVE the Merchant?"
                    onConfirm={handleOk}
                    onCancel={handleCancel}
                    okText="Yes"
                    cancelText="No">
                    <Button
                    type="dashed"
                    onClick={() =>approveMerchants(shop?.id,ACTIVE)}
                    >
                    <CheckOutlined /> Approve
                    </Button>
                  </Popconfirm>
                }
                </Space>
              </div>
            }
          />
          <div className="details-area">
            <div className="pt-4">
              <div className="inner-body">
                <div className="single">
                  <span className="info-name">Merchant Name</span>
                  <span className="info-desc">{shop?.ownersName}</span>
                </div>

                <div className="single">
                  <span className="info-name">Company Name</span>
                  <span className="info-desc">{shop?.companyName}</span>
                </div>

                <div className="single">
                  <span className="info-name">Merchant ID</span>
                  <span className="info-desc">{shop?.id}</span>
                </div>

                <div className="single">
                  <span className="info-name">Mobile Number (Login)</span>
                  <span className="info-desc">
                    {shop?.mobileNumber?.split("+88")}
                  </span>
                </div>

                <div className="single">
                  <span className="info-name">Email Address</span>
                  <span className="info-desc">{shop?.emailAddress}</span>
                </div>

                <div className="single">
                  <span className="info-name">Status</span>
                  <div className="info-desc">
                    <span
                      className={`radius-btn text-uppercase ${shop?.status?.toLowerCase()}-btn`}
                    >
                      {shop?.status}
                    </span>
                  </div>
                </div>
              </div>


              <h6 className="mt-5 title" style={{ width: "98%", display:"flex" , justifyContent:"space-between"}}>
                <span>Store Details</span>
                <Button
                  type="dashed"
                  style={{}}
                  onClick={() =>showShopEditModal(undefined)}>
                  <PlusCircleOutlined /> Create Store
                </Button>
              </h6>
              
              <div className="inner-body">
                <>
                  <div className="site-card-wrapper">
                    <Row gutter={16}>
                      {
                         store.map((storeInfo)=>(
                           <>
                              <Col className="mb-3" span={8}>
                                <Card title={`${storeInfo?.name}`} extra={<Button onClick={()=>{
                                  showShopEditModal(storeInfo?.id)
                                }}  type="dashed" danger className=""><EditOutlined/></Button>} headStyle={{backgroundColor:"#f6f6f6"}} bordered={true} style={{textTransform:"capitalize",border:"1px solid #ddd"}}>
                                <div className="single">
                                    <span className="info-name">Store Name</span>
                                    <span className="info-desc">{storeInfo?.name}</span>
                                  </div>
                                  <div className="single">
                                    <span className="info-name">Contact Number</span>
                                    <span className="info-desc">{storeInfo?.contactNumber}</span>
                                  </div>
                                  <div className="single">
                                    <span className="info-name">Zone</span>
                                    <span className="info-desc">{storeInfo?.zone}</span>
                                  </div>
                                  <div className="single">
                                    <span className="info-name">Address</span>
                                    <span className="info-desc">{storeInfo?.address}</span>
                                  </div>
                                </Card>
                              </Col>
                           </>
                          ))
                        }
                        
                    </Row>
                  </div>
                </>
              </div>

              <h6 className="mt-5 title" style={{ width: "98%" }}>
                Payment Details
              </h6>
              <h6
                className="pl-2 mb-2"
                style={{ borderLeft: "3px solid blue", fontWeight: "bold" }}
              >
                MFS Info
              </h6>
              <div className="inner-body ml-2 mb-4">
                <div className="single">
                  <span className="info-name">MFS Name</span>
                  <span className="info-desc">{shop?.mfsInfo?.mfsName}</span>
                </div>
                <div className="single">
                  <span className="info-name">Mobile Number</span>
                  <span className="info-desc">
                    {shop?.mfsInfo?.mobileNumber}
                  </span>
                </div>
              </div>

              <h6
                className="pl-2 mb-2"
                style={{ borderLeft: "3px solid blue", fontWeight: "bold" }}
              >
                Bank Info
              </h6>
              <div className="inner-body ml-2">
                <div className="single">
                  <span className="info-name">Account Name</span>
                  <span className="info-desc">
                    {shop?.bankInfo?.accountName}
                  </span>
                </div>

                <div className="single">
                  <span className="info-name">Account Number</span>
                  <span className="info-desc">
                    {shop?.bankInfo?.accountNumber}
                  </span>
                </div>

                <div className="single">
                  <span className="info-name">Bank Name</span>
                  <span className="info-desc">{shop?.bankInfo?.bankName}</span>
                </div>

                <div className="single">
                  <span className="info-name">Branch Name</span>
                  <span className="info-desc">
                    {shop?.bankInfo?.branchName}
                  </span>
                </div>

                {shop?.bankInfo?.routingNumber ? (
                  <div className="single">
                    <span className="info-name">Routing Number</span>
                    <span className="info-desc">
                      {shop?.bankInfo?.routingNumber}
                    </span>
                  </div>
                ) : undefined}

                {shop?.bankInfo?.swiftCode ? (
                  <div className="single">
                    <span className="info-name">Swift Code</span>
                    <span className="info-desc">
                      {shop?.bankInfo?.swiftCode}
                    </span>
                  </div>
                ) : undefined}
              </div>

              <h6 className="mt-5 title" style={{ width: "98%" }}>
                Charge Information
              </h6>
              <div className="inner-body">
                {/* <div className="single">
                  <span className="info-name">COD Charge</span>
                  <span className="info-desc">{shop?.codCharge}%</span>
                </div> */}

                <div className="single d-flex-l ">
                  <h6 className="info-desc bold radius-btn">Same City</h6>
                  <span className="info-desc">
                    Charge: {shop?.sameCity?.charge}
                  </span>
                  <span className="info-desc">
                    After 2kg: {shop?.sameCity?.next}
                  </span>
                  <span className="info-desc">
                    Time: {shop?.sameCity?.time}
                  </span>
                  <span className="info-desc">
                    COD Charge: {shop?.sameCity?.codCharge}%
                  </span>
                </div>

                <div className="single d-flex-l ">
                  <h6 className="info-desc bold radius-btn">Dhaka Suburbs</h6>
                  <span className="info-desc">
                    Charge: {shop?.dhakaSuburbs?.charge}
                  </span>
                  <span className="info-desc">
                    After 2kg: {shop?.dhakaSuburbs?.next}
                  </span>
                  <span className="info-desc">
                    Time: {shop?.dhakaSuburbs?.time}
                  </span>
                  <span className="info-desc">
                    COD Charge: {shop?.dhakaSuburbs?.codCharge}%
                  </span>
                </div>

                <div className="single d-flex-l ">
                  <h6 className="info-desc bold radius-btn">Outside City</h6>
                  <span className="info-desc">
                    Charge: {shop?.outsideCity?.charge}
                  </span>
                  <span className="info-desc">
                    After 2kg: {shop?.outsideCity?.next}
                  </span>
                  <span className="info-desc">
                    Time: {shop?.outsideCity?.time}
                  </span>
                  <span className="info-desc">
                    COD Charge: {shop?.outsideCity?.codCharge}%
                  </span>
                </div>

                <div className="single d-flex-l ">
                  <h6 className="info-desc bold radius-btn">Same Day Delivery</h6>
                  <span className="info-desc">
                    Charge: {shop?.sameDayDelivery?.charge}
                  </span>
                  <span className="info-desc">
                    After 2kg: {shop?.sameDayDelivery?.next}
                  </span>
                  <span className="info-desc">
                    Time: {shop?.sameDayDelivery?.time}
                  </span>
                  <span className="info-desc">
                    COD Charge: {shop?.sameDayDelivery?.codCharge}%
                  </span>
                </div>
              </div>
            </div>
          </div>

          

              <Modal title={storeId ? "Shop Edit" : "Shop Create"} open={shopEditModalOpen} onCancel={handleShopEditCancel} footer="">
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{ maxWidth: 600 }}
                  autoComplete="off"
                  className="shopEditModalForm"
                  onFinish={onShopEditSubmit}
                  >
                    <div style={{marginBottom:"5px"}}>
                      <label htmlFor="shopName">Store Name</label>
                      <Input name="shopName" id="shopName" value={shopName} type="text" onChange={(e)=>setShopName(e.target.value)}/>
                    </div>

                    <div>
                      <label htmlFor="contactName">Store Contact Name</label>
                      <Input name="mobileNumber" id="contactName" type="text" value={contactName} onChange={(e)=>setContactName(e.target.value)}/>
                    </div>
                    
                    <div>
                      <label htmlFor="contactNum">Store Contact Number</label>
                      <Input name="contactNum" id="contactNum" type="text" value={contactNum} onChange={(e)=>setContactNum(e.target.value)}/>
                    </div>

                    <div>
                      <label htmlFor="contactNum">Store Location</label>
                      <Cascader
                          options={options}
                          size='large'
                          value={
                            city || zone || area
                              ? [city, zone, area]
                              : undefined
                          }
                          displayRender={displayRender}
                          style={{ width: '100%', borderRadius: 4 }}
                          placeholder='Select location'
                          showSearch={{ filter }}
                          onChange={onChangeCascaderLocation}
                          onSearch={(value) => console.log('search', value)}
                        />
                      </div>

                    <div>
                      <label htmlFor="address">Store Address</label>
                      <Input name="address" id="addressId" type="text" value={address} onChange={(e)=>setAddress(e.target.value)}/>
                    </div>
                    
                    {storeId ? 
                      <div style={{marginTop:"10px"}}>
                      <label htmlFor="contactNum">Status</label>
                      <Select
                        defaultValue={shopStatus}
                        style={{width:"100%"}}
                        onChange={(e)=>{
                          setShopStatus(e);
                        }}
                        
                      >
                      <Option value="ACTIVE">
                        ACTIVE
                      </Option>
                      <Option value="INACTIVE">
                        INACTIVE
                      </Option>
                      </Select>
                    </div> : undefined
                    }
                    



                    <Form.Item style={{marginTop:"20px"}}>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
              </Modal>


         
          <Drawer
            title={selectedShopForEdit ? "Edit Merchant" : ""}
            width={700}
            onClose={() => {
              setSelectedShopForEdit(null);
            }}
            visible={selectedShopForEdit}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
          >
            <AddMerchant data={selectedShopForEdit} onCloseMethod={onClose} />
          </Drawer>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};
ShopDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopDetails;
