import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Rate,
  Select,
  Spin,
} from "antd";
import {
  BranchesOutlined,
  ClockCircleOutlined,
  CopyFilled,
  CopyOutlined,
  CopyrightCircleFilled,
  CopyrightTwoTone,
  CopyTwoTone,
  EditOutlined,
} from "@ant-design/icons";

import axios from "axios";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";

import { authenticateToken } from "../../utils/auth";
import {
  getPage,
  getParamValue,
  getUpdatedUrl,
  OrderStatus,
  PickMeStatus,
} from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import TopHeading from "./TopHeading";
import { debounce } from "lodash";

import moment from "moment";
import Currency from "../common/Currency";
import _ from "lodash";
import Empty from "../common/Empty";
import usePrevious from "../../hooks/PreviousState";
import { responseNotification } from "../../utils/notify";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import TextArea from "antd/lib/input/TextArea";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import EditOrder from "./EditOrder/index";
import Tooltip from "antd/es/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBiking } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const { Option } = Select;
const { RangePicker }: any = DatePicker;

const PickMeList = ({ activeSearch = true, inShop = false }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const page = getParamValue(location.search, "page") || 0;
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isAssignModalData, setIsAssignModalData] = useState<any>();
  const [loading, setLoading] = useState(false);
  // const [status, setStatus] = useState(
  //   getParamValue(location.search, "status") || ""
  // );
  const [status, setStatus] = useState(
    PickMeStatus?.[0]
  );
  const [paymentStatus, setPaymentStatus] = useState(
    getParamValue(location.search, "paymentStatus") || ""
  );
  const [range, setRange] = useState({
    from: getParamValue(location.search, "from")
      ? getParamValue(location.search, "from")
      : null,
    to: getParamValue(location.search, "to")
      ? getParamValue(location.search, "to")
      : null,
  });
  const [driverMobileNumber, setDriverMobileNumber] = useState(
    getParamValue(location.search, "driverMobileNumber") || ""
  );
  const [orderOptions, setOrderOptions] = useState({
    list: [],
    loading: false,
  });
  const [userMobileNumber, setUserMobileNumber] = useState<string>(
    (getParamValue(location.search, "userMobileNumber") || "") as string
  );
  // getParamValue(location.search, "userMobileNumber") || ""
  const [limit, setLimit] = useState(16);
  const [tripsData, setTripsData] = useState({
    loading: false,
    data: null,
  });
  const [OptionsDriver, setOptionsDriver] = useState({
    loading: false,
    list: null,
  });
  const [OptionsUser, setOptionsUser] = useState({
    loading: false,
    list: null,
  });
  const [usersOptionsByName, setUsersOptionsByName] = useState({
    loading: false,
    list: null,
  });
  const [orderId, setOrderId] = useState("");
  const [selectedTrip, setSelectedTrip] = useState("");

  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  const previousMobileNumber = usePrevious(userMobileNumber);
  const previousRangeFrom = usePrevious(range.from);
  const previousRangeTo = usePrevious(range.to);
  const previousStatus = usePrevious(status);
  const previousOrderId = usePrevious(orderId);
  const previousPaymentStatus = usePrevious(paymentStatus);
  const [selectedDeliveryManId, setSelectedDeliveryManId] = useState("");

  // checkbox
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [assignment, setAssignment] = useState(false);
  const [deliveryManData, setDeliveryManData] = useState({
    loading: false,
    list: [],
  });

  const { merchantId }: any = useParams();

  const fetchRef = useRef(null);

  const reseAllFieldData = () => {
    setStatus("");
    setDriverMobileNumber("");
    setUserMobileNumber("");
    form.resetFields(["driverSearch", "userSearch", "statusSearch"]);
    history.replace(location.pathname);
    window.location.reload();
    setOrderId("");
    setRange({
      from: null,
      to: null,
    });
  };



  const getTrips = useCallback(async () => {
    console.log(customerName,customerPhone)
    let shouldResetPage = false;

    if (
      userMobileNumber &&
      getParamValue(location.search, "userMobileNumber") != userMobileNumber
    ) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("userMobileNumber", `${userMobileNumber}`));
    }


    if (shouldResetPage) history.push(getUpdatedUrl("page", `${0}`));

    setTripsData({ loading: true, data: null });
    console.log("mb", userMobileNumber);
    axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/admin/order/get-pick-up-info?type=Q_PARCEL` +
          (userMobileNumber
            //? `?mobileNumber=${userMobileNumber?.replace("+88", "")}`
            ? `&mobileNumber=%2B88${userMobileNumber}`
            : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setTripsData({ loading: false, data: res.data });
        }
      })
      .catch((err) => {
        setTripsData({ loading: false, data: [] });
        console.error("Trips: Error", err);
      });
  }, [
    userMobileNumber,
  ]);

  const getOrderOptions = useCallback(
    async (getOrderId) => {
      setOrderOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order?type=Q_PARCEL` +
            (getOrderId
              ? `&orderId=${getOrderId}`
              : `` + getOrderId
              ? `&orderId=${getOrderId}`
              : ``) +
            `&page=${page || 0}` +
            (status ? `&status=${status}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map((order, index) => ({
              key: index,
              label: order.orderId,
              value: order.orderId,
            })),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, status]
  );

  const getOptionsUser = useCallback(
    async (mobileNumber) => {
      if (inShop && !merchantId) return;
      setOptionsUser({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${500}` +
            // (status ? `&status=${status}` : ``) +
            (mobileNumber
              //? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")?.trim()}`
              ? `&mobileNumber=%2B88${mobileNumber}`
              : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOptionsUser({
            loading: false,
            list: _.without(
              res.data?.businesses?.map((user) => {
                if (user?.companyName && user?.mobileNumber) {
                  return {
                    //label: `${user?.mobileNumber?.replace("+88", "")} (${
                    label: `${user?.mobileNumber} (${
                      user?.companyName
                    })`,
                    value: user?.mobileNumber,
                  };
                }
              }),
              undefined
            ),
          });
          merchantId &&
            setUserMobileNumber(
              res.data?.businesses?.find((user) => user?.id === merchantId)
                ?.mobileNumber
            );
        })
        .catch((err) => {
          setOptionsUser({ loading: false, list: [] });
          console.error("Trips: Error", err);
        });
    },
    [status, merchantId, inShop]
  );

  useEffect(() => {
    getOptionsUser("");
  }, [getOptionsUser]);

  useEffect(() => {
    getTrips();
  }, [getTrips]);


  const getUserOptionsDebounce = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptionsUser({ loading: false, list: null });

      if (fetchId !== fetchRef.current) {
        return;
      }

      getOptionsUser(value);
    };

    return debounce(loadOptions, 800);
  }, [getOptionsUser]);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions]);

  console.log(userMobileNumber)

  // checkbox funcs
  const plainOptions = tripsData?.data?.pickups?.map((order) => order?.storeName);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  const onChangeSingle = (id: string) => {
    let list = [...(checkedList || [])];
    list = list?.filter((item) => item !== id);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const getUsersOptionsByName = useCallback(
    async (value) => {
      setUsersOptionsByName({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${20}` +
            (value ? `&companyName=${value}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setUsersOptionsByName({
            loading: false,
            list: res.data?.businesses?.map((user) => ({
              label: user.companyName,
              value: user.companyName,
            })),
          });
        })
        .catch((err) => {
          setUsersOptionsByName({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [status]
  );

  useEffect(() => {
    getUsersOptionsByName("");
  }, [getUsersOptionsByName]);


  const getUsersOptionsDebounceForName = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      getUsersOptionsByName(value);
    };

    return debounce(loadOptions, 800);
  }, []);
  

  const fetchDeliveryMan = useCallback(async (value) => {
    setDeliveryManData({ loading: true, list: [] });
    await fetch(
      `${
        process.env.REACT_APP_USER_API
      }/admin/driver?mobileNumber=%2B88${value?.replace(
        "+88",
        ""
      )}&page=0&limit=20&status=VERIFIED`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setDeliveryManData({
          loading: true,
          list: res?.drivers?.map((driver) => ({
            label: `${driver?.mobileNumber} ${driver?.name}`,
            value: driver?.id,
          })),
        });
      });
  }, []);

  const debounceDeliveryManFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (fetchId !== fetchRef.current) {
        return;
      }
      fetchDeliveryMan(value);
    };
    return debounce(loadOptions, 800);
  }, [fetchDeliveryMan]);

  
  const assignDeliveryHero = () => {
    if (selectedDeliveryManId) {
      setLoading(true);
      axios
        .put(
          `${process.env.REACT_APP_ORDER_API}/admin/order/pickup-assign`,
          {
            deliveryManId: selectedDeliveryManId,
            merchantId:tripsData?.data?.pickups.map(order=>order.merchantId),
            orderIds: tripsData?.data?.pickups.map(order=>order.storeName),
            type: 'Q_PARCEL',
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            responseNotification("Assigned Successfully!");
            setAssignment(false);
          } else {
            responseNotification("Assigmenation Failed!", "error");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(
            err?.message || "Assigmenation Failed!",
            "error"
          );
          console.error(err);
        });
    } else {
      responseNotification("Select a delivery man", "warning");
    }
  };

  const deliveryHeroAssignmentPermitted = ()=>{
    let errorTag =  false;
    setAssignment(!errorTag)
  }

  return (
    <React.Fragment>
      <TopHeading
        total={tripsData?.data?.totalElements}
        refetch={getTrips}
        filterData={{
          orderId,
          userMobileNumber,
          page,
          status,
          paymentStatus,
          limit,
          range,
          previousOrderId,
          previousMobileNumber,
          previousStatus,
          previousRangeFrom,
          previousRangeTo,
          previousPaymentStatus,
        }}
        orders={(tripsData?.data?.pickups || [])?.filter((order) =>
          checkedList?.includes(order?.storeName)
        )}
        afterStatusUpdate={() => {
          getTrips();
          setCheckedList([]);
        }}
        //inShop={inShop}
      />

      {activeSearch && (
        <section className="search_area">
          <Form form={form} className="">
            <div className="asfjkgsdh" style={{display:"flex"}}>
            {/* <Form.Item name="orderId"  initialValue={orderId} className="mr-2">
              <AutoComplete
                dropdownMatchSelectWidth={250}
                style={{ width: 250 }}
                onSearch={(e) => handleSearch(e, "order")}
                onSelect={(val) => {
                  setOrderId(val);
                }}
                options={orderOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  orderOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  size="large"
                  placeholder="Order ID"
                  style={{ minWidth: 100, width: "calc(100% - 0px)" }}
                  onSearch={(val) => {
                    setOrderId(val);
                  }}
                  enterButton
                />
              </AutoComplete>
            </Form.Item> */}
      
              <Form.Item name="userSearch" className="mb-0 mr-2">
                <Select
                  showSearch
                  onSearch={getUserOptionsDebounce}
                  onSelect={(val) =>
                    setUserMobileNumber(
                      val.startsWith("+88") ? val.replace("+88", "") : val
                    
                    )
                  }
                  options={OptionsUser?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    OptionsUser?.loading ? <Spin size="small" /> : "No Data"
                  }
                  placeholder="Select Merchant"
                  optionFilterProp="children"
                  style={{ width: 296 }}
                  filterOption={(input, option) =>
                    ((option?.label ?? "") as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
                       
            {/* <Form.Item name="customerName" initialValue={orderId} className="mr-2">
             
                <Input.Search
                  size="large"
                  placeholder="Customer Name"
                  style={{ minWidth: 100, width: "calc(100% - 0px)" }}
                  onChange={()=>{}}
                  onSearch={(e) => {
                    console.log(customerName)
                    setCustomerName(e)
                  }}
                  enterButton
                />
            </Form.Item> */}

            <div className="search_btn" style={{marginTop:"0px"}}>
              <Button
                type="primary"
                danger
                size="large"
                onClick={reseAllFieldData}
              >
                Reset
              </Button>
            </div>
            </div>
            
            <div className="" style={{display:"flex"}}>
            </div>
          </Form>
        </section>
      )}
      <div className="flex">
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
          className="mb-2"
        >
          Check all
        </Checkbox>
      </div>
      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  <div className="single-rider-headingx"></div>
                  <div className="single-rider-heading ml-4">
                    <span>Store Name</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Merchant Name</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Mobile Number</span>
                  </div>
                  
                  <div className="single-rider-headingx">
                    <span>Count </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {tripsData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div style={{ width: "100%" }}>
                  <Checkbox.Group
                    value={checkedList}
                    onChange={onChange}
                    className="rider-table-area"
                    style={{ width: "100%", height: "100%" }}
                  >
                    {tripsData?.data?.pickups?.length ? (
                      tripsData?.data?.pickups?.map((trip, index) => (
                        <React.Fragment key={index}>
                            <div className="single-rider-wrapper">
                              <div className="single-riderx major-colx mr-2">
                                <Checkbox
                                  value={trip?.storeName}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onChangeSingle(trip?.storeName);
                                  }}
                                />
                              </div>
                            
                              <div className="single-rider major-colx">
                                  {
                                    <>
                                    <span className="name">{trip?.storeName}</span><br/>
                                    </>
                                  }
                              </div>
                              <div className="single-rider major-colx">
                                <span className="name">
                                  {trip?.merchantName}<br/>
                                </span>
                              </div>
                              
                              
                              <div className="single-rider major-colx">
                                <span className="name">
                                  {trip?.mobileNumber}<br/>
                                </span>
                              </div>

                              

                              
                              <div className="single-riderx">
                                <span style={{ fontSize: 12, marginLeft: 15 }}>
                                {trip?.count}<br/>
                                </span>
                              </div>

                            </div>
                        </React.Fragment>
                      ))
                    ) : (
                      <Empty />
                    )}
                  </Checkbox.Group>
                </div>
              </div>
            )}
          </div>

          <Modal
            title={
              <div className="d-flex-l">
                <FontAwesomeIcon
                  icon={faBiking as IconProp}
                  className="mr-2"
                  style={{ color: "#ff9f00" }}
                />{" "}
                Assign a Delivery Man
              </div>
            }
            okButtonProps={{ disabled: !!!selectedDeliveryManId }}
            visible={!!assignment}
            onOk={assignDeliveryHero}
            okText="Assign"
            cancelButtonProps={{
              color: "red",
              type: "ghost",
              danger: true,
            }}
           // confirmLoading={loading}
            onCancel={() => {
              setDeliveryManData({ loading: false, list: [] });
              setSelectedDeliveryManId("");
              setAssignment(false);
            }}
            // destroyOnClose={true}
          >
            <div>
              <Select
                showSearch
                allowClear={true}
                style={{ width: "100%", marginBottom: 15 }}
                value={selectedDeliveryManId}
                placeholder="Select a Delivery Man 01..."
                optionFilterProp="children"
                onChange={(val) => setSelectedDeliveryManId(val as string)}
                onSearch={debounceDeliveryManFetcher}
              >
                {deliveryManData?.list?.map((man, i) => (
                  <Select.Option value={man?.value} key={i}>
                    {man?.label?.replace("+88", "")}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Modal>


       
          <PaginationTwo
            {...tripsData?.data}
            limit={limit}
            page={tripsData?.data?.currentPageNumber || 0}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PickMeList;
