/*
 * @param {Object} order
 * @param {Object} merchant
 * @param {number} amountToCollect
 * @param {number} itemWeight
 * @param {string} zoneType
 */

export const getDeliveryCharge = (
  merchant,
  amountToCollect = 0,
  itemWeight = 1,
  zoneType = "INSIDE_DHAKA"
) => {
  const zoneObj = {
    INSIDE_DHAKA: "insideDhaka",
    DHAKA_SUBURBS: "dhakaSuburbs",
    SAME_DAY_DELIVERY: "sameDayDelivery",
    OUTSIDE_DHAKA: "outsideDhaka",
  };

  const cod = Math.round(
    ((amountToCollect || 0 || 0) / 100) * (merchant?.codCharge || 1)
  );

  const deliveryCharge =
    ((itemWeight || 1) - 1) *
      (merchant?.[zoneObj[zoneType] || zoneObj.INSIDE_DHAKA]?.next || 0) +
    (merchant?.[zoneObj[zoneType] || zoneObj.INSIDE_DHAKA]?.charge || 0);

  const charge = merchant ? cod + deliveryCharge : null;

  console.log("zone", zoneType);
  console.log(
    "now setting",
    merchant,
    merchant?.[zoneObj[zoneType]]?.charge,
    merchant?.[zoneObj[zoneType]]?.next
  );
  console.log("cal charge", charge);
  console.log("cod percentage", merchant?.codCharge);

  return charge;
};

export const getZone = (parcelOrder) => {
  let zone =
    parcelOrder?.recipientCity?.toLowerCase() === "dhaka"
      ? "INSIDE_DHAKA"
      : parcelOrder?.recipientCity?.toLowerCase() === "gazipur" ||
        parcelOrder?.recipientCity?.toLowerCase() === "narayanganj"
      ? "DHAKA_SUBURBS"
      : "OUTSIDE_DHAKA";

      console.log(5555, zone, parcelOrder?.recipientCity?.toLowerCase(), parcelOrder)

  if (zone === "INSIDE_DHAKA") {
    if (
      parcelOrder?.recipientZone?.toLowerCase() === "keraniganj" ||
      parcelOrder?.recipientZone?.toLowerCase() === "savar"
    ) {
      zone = "DHAKA_SUBURBS";
    }
  }

  if (
    parcelOrder?.deliveryType?.toUpperCase()?.trim()?.replace(/ /gi, "_") ===
    "SAME_DAY_DELIVERY"
  ) {
    zone = "SAME_DAY_DELIVERY";
  }

  return zone || "INSIDE_DHAKA";
};
