import { ReactElement } from "react";
import Layout from "../../components/Layout";
import SortingOrderList from "../../components/sorting-orders";

const SortingOrderPage = (): ReactElement => {
  return (
    <Layout>
      <SortingOrderList />
    </Layout>
  );
};

export default SortingOrderPage;
