import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Rate,
  Select,
  Spin,
} from "antd";
import {
  BranchesOutlined,
  ClockCircleOutlined,
  CopyFilled,
  CopyOutlined,
  CopyrightCircleFilled,
  CopyrightTwoTone,
  CopyTwoTone,
  DeliveredProcedureOutlined,
  EditOutlined,
  TagOutlined,
} from "@ant-design/icons";

import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";

import { authenticateToken } from "../../utils/auth";
import {
  getPage,
  getParamValue,
  getUpdatedUrl,
  //   OrderStatus,
} from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import TopHeading from "./TopHeading";
import { debounce } from "lodash";

import moment from "moment";
import Currency from "../common/Currency";
import _ from "lodash";
import Empty from "../common/Empty";
import usePrevious from "../../hooks/PreviousState";
import { responseNotification } from "../../utils/notify";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import TextArea from "antd/lib/input/TextArea";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Tooltip from "antd/es/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBiking } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import EditOrder from "../Orders/EditOrder";
import { CustomerInvoicePdf, CustomerInvoiceSticker } from "../invoice-pdf";
import { loadHubList } from "../../hooks/use-api";

const { Option } = Select;
const { RangePicker }: any = DatePicker;

const OrderStatus = [
  "INITIATED",
  "PENDING",
  "ACCEPTED",
  "PICKED",
  "AT_THE_SORTING_HUB",
];

const SortingOrderList = ({ activeSearch = true, inShop = false }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const page = getParamValue(location.search, "page") || 0;
  const [status, setStatus] = useState(
    getParamValue(location.search, "status") || ""
  );
  const [paymentStatus, setPaymentStatus] = useState(
    getParamValue(location.search, "paymentStatus") || ""
  );
  const [range, setRange] = useState({
    from: getParamValue(location.search, "from")
      ? getParamValue(location.search, "from")
      : null,
    to: getParamValue(location.search, "to")
      ? getParamValue(location.search, "to")
      : null,
  });
  const [driverMobileNumber, setDriverMobileNumber] = useState(
    getParamValue(location.search, "driverMobileNumber") || ""
  );
  const [orderOptions, setOrderOptions] = useState({
    list: [],
    loading: false,
  });
  const [userMobileNumber, setUserMobileNumber] = useState<string>(
    (getParamValue(location.search, "userMobileNumber") || "") as string
  );
  // getParamValue(location.search, "userMobileNumber") || ""
  const [limit, setLimit] = useState(100);
  const [tripsData, setTripsData] = useState({
    loading: false,
    data: null,
  });
  const [OptionsDriver, setOptionsDriver] = useState({
    loading: false,
    list: null,
  });
  const [OptionsUser, setOptionsUser] = useState({
    loading: false,
    list: null,
  });
  const [usersOptionsByName, setUsersOptionsByName] = useState({
    loading: false,
    list: null,
  });
  const [orderId, setOrderId] = useState("");
  const [selectedTrip, setSelectedTrip] = useState("");

  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [pdfModal, setPdfModal] = useState("");

  const previousMobileNumber = usePrevious(userMobileNumber);
  const previousRangeFrom = usePrevious(range.from);
  const previousRangeTo = usePrevious(range.to);
  const previousStatus = usePrevious(status);
  const previousOrderId = usePrevious(orderId);
  const previousPaymentStatus = usePrevious(paymentStatus);
  const [singleOrderSticker, setSingleOrderSticker] = useState();
  const [merchantOrderId, setMerchantOrderId] = useState("");

  // checkbox
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const { merchantId }: any = useParams();

  const fetchRef = useRef(null);
  const [hubId, setHubId] = useState("");

  const reseAllFieldData = () => {
    setStatus("");
    setDriverMobileNumber("");
    setUserMobileNumber("");
    form.resetFields(["driverSearch", "userSearch", "statusSearch"]);
    history.replace(location.pathname);
    window.location.reload();
    setOrderId("");
    setRange({
      from: null,
      to: null,
    });
  };

  const getTrips = useCallback(async () => {
    console.log(customerName, customerPhone);
    let shouldResetPage = false;

    if (
      userMobileNumber &&
      getParamValue(location.search, "userMobileNumber") != userMobileNumber
    ) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("userMobileNumber", `${userMobileNumber}`));
    }

    if (
      driverMobileNumber &&
      getParamValue(location.search, "driverMobileNumber") != driverMobileNumber
    ) {
      shouldResetPage = true;
      history.push(
        getUpdatedUrl("driverMobileNumber", `${driverMobileNumber}`)
      );
    }

    if (status && getParamValue(location.search, "status") != status) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("status", `${status}`));
    }

    if (shouldResetPage) history.push(getUpdatedUrl("page", `${0}`));

    setTripsData({ loading: true, data: null });
    console.log("mb", userMobileNumber);
    axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/admin/order/getAllOrder-for-parcel?orderForSort=yes&type=Q_PARCEL` +
          `&page=${page || 0}` +
          `&limit=${limit || 16}` +
          (status ? `&status=${status}` : ``) +
          (hubId ? `&piHubId=${hubId}` : ``) +
          (paymentStatus ? `&paymentStatus=${paymentStatus}` : ``) +
          (driverMobileNumber
            ? `&driverMobileNumber=${driverMobileNumber}`
            : ``) +
          (orderId ? `&orderId=${orderId}` : ``) +
          (customerName ? `&recipientName=${customerName}` : ``) +
          (customerPhone ? `&recipientPhone=${customerPhone}` : ``) +
          (companyName ? `&companyName=${companyName}` : ``) +
          (merchantOrderId ? `&merchantOrderId=${merchantOrderId}` : ``) +
          (range?.from ? `&from=${moment(range?.from).toISOString()}` : ``) +
          (range?.to ? `&to=${moment(range?.to).toISOString()}` : ``) +
          (userMobileNumber
            ? `&mobileNumber=${userMobileNumber?.replace("+88", "")}`
            : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setTripsData({ loading: false, data: res.data });
        }
      })
      .catch((err) => {
        setTripsData({ loading: false, data: [] });
        console.error("Trips: Error", err);
      });
  }, [
    limit,
    driverMobileNumber,
    userMobileNumber,
    companyName,
    status,
    page,
    orderId,
    range,
    paymentStatus,
    customerName,
    customerPhone,
    merchantOrderId,
    hubId,
  ]);

  const getOrderOptions = useCallback(
    async (getOrderId) => {
      setOrderOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order?type=Q_PARCEL` +
            (getOrderId
              ? `&orderId=${getOrderId}`
              : `` + getOrderId
              ? `&orderId=${getOrderId}`
              : ``) +
            `&page=${page || 0}` +
            (status ? `&status=${status}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map((order, index) => ({
              key: index,
              label: order.orderId,
              value: order.orderId,
            })),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, status]
  );

  const getOptionsUser = useCallback(
    async (mobileNumber) => {
      if (inShop && !merchantId) return;
      setOptionsUser({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${500}` +
            // (status ? `&status=${status}` : ``) +
            (mobileNumber
              ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")?.trim()}`
              : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOptionsUser({
            loading: false,
            list: _.without(
              res.data?.businesses?.map((user) => {
                if (user?.companyName && user?.mobileNumber) {
                  return {
                    label: `${user?.mobileNumber?.replace("+88", "")} (${
                      user?.companyName
                    })`,
                    value: user?.mobileNumber,
                  };
                }
              }),
              undefined
            ),
          });
          merchantId &&
            setUserMobileNumber(
              res.data?.businesses?.find((user) => user?.id === merchantId)
                ?.mobileNumber
            );
        })
        .catch((err) => {
          setOptionsUser({ loading: false, list: [] });
          console.error("Trips: Error", err);
        });
    },
    [status, merchantId, inShop]
  );

  useEffect(() => {
    getOptionsUser("");
  }, [getOptionsUser]);

  useEffect(() => {
    getTrips();
  }, [getTrips]);

  const getUserOptionsDebounce = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptionsUser({ loading: false, list: null });

      if (fetchId !== fetchRef.current) {
        return;
      }

      getOptionsUser(value);
    };

    return debounce(loadOptions, 800);
  }, [getOptionsUser]);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions]);

  // checkbox funcs
  const plainOptions = tripsData?.data?.orders?.map((order) => order?.orderId);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  const onChangeSingle = (id: string) => {
    let list = [...(checkedList || [])];
    list = list?.filter((item) => item !== id);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const getUsersOptionsByName = useCallback(
    async (value) => {
      setUsersOptionsByName({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${20}` +
            (value ? `&companyName=${value}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setUsersOptionsByName({
            loading: false,
            list: res.data?.businesses?.map((user) => ({
              label: user.companyName,
              value: user.companyName,
            })),
          });
        })
        .catch((err) => {
          setUsersOptionsByName({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [status]
  );

  useEffect(() => {
    getUsersOptionsByName("");
  }, [getUsersOptionsByName]);

  const getUsersOptionsDebounceForName = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      getUsersOptionsByName(value);
    };

    return debounce(loadOptions, 800);
  }, []);

  const confirm = async (e: React.MouseEvent<HTMLElement>, orderId: any) => {
    e.preventDefault();

    if (!orderId) {
      responseNotification("Please select an order", "error");
      return;
    }
    const readyData = {
      orderIds: [orderId],
      status: "AT_THE_SORTING_HUB",
      note: "",
    };
    return await fetch(
      `${process.env.REACT_APP_ORDER_API}/admin/order/status-update`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification(
            "Selected Order Statuses Updated Successfully",
            "success"
          );
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
      });
  };

  const [hubList, setHubList] = useState({
    loading: false,
    list: null,
  });

  const getOptionsHub = () => {
    loadHubList().then((response) => {
      setHubList({
        loading: false,
        list: response?.piHubs.map((item: any) => {
          return { label: item.name, value: item.name, id: item.id };
        }),
      });
    });
  };

  useEffect(() => {
    getOptionsHub();
  }, []);

  const handleHubSearch = (value) => {
    if (value == "") {
      getOptionsHub();
    } else {
      const filter = hubList.list.filter((hub) => hub.label.startsWith(value));
      setHubList({
        loading: false,
        list: filter,
      });
    }
  };

  return (
    <React.Fragment>
      <TopHeading
        total={tripsData?.data?.totalElements}
        refetch={getTrips}
        filterData={{
          orderId,
          userMobileNumber,
          page,
          status,
          paymentStatus,
          limit,
          range,
          previousOrderId,
          previousMobileNumber,
          previousStatus,
          previousRangeFrom,
          previousRangeTo,
          previousPaymentStatus,
        }}
        orders={(tripsData?.data?.orders || [])?.filter((order) =>
          checkedList?.includes(order?.orderId)
        )}
        afterStatusUpdate={() => {
          getTrips();
          setCheckedList([]);
        }}
        inShop={inShop}
      />

      {activeSearch && (
        <section className="search_area">
          <Form form={form} className="">
            <div className="asfjkgsdh" style={{ display: "flex" }}>
              <Form.Item name="orderId" initialValue={orderId} className="mr-2">
                <AutoComplete
                  dropdownMatchSelectWidth={250}
                  style={{ width: 250 }}
                  onSearch={(e) => handleSearch(e, "order")}
                  onSelect={(val) => {
                    setOrderId(val);
                  }}
                  options={orderOptions?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    orderOptions?.loading ? <Spin size="small" /> : null
                  }
                >
                  <Input.Search
                    size="large"
                    placeholder="Order ID"
                    style={{ minWidth: 100, width: "calc(100% - 50px)" }}
                    onSearch={(val) => {
                      setOrderId(val);
                    }}
                    enterButton
                  />
                </AutoComplete>
              </Form.Item>
              <Form.Item
                name="merchantOrderId"
                initialValue={merchantOrderId}
                className="mr-2"
              >
                <Input.Search
                  size="large"
                  placeholder="Merchant Order ID"
                  style={{ minWidth: 100, width: "calc(100% - 50px)" }}
                  onSearch={(val) => {
                    setMerchantOrderId(val);
                  }}
                  enterButton
                />
              </Form.Item>
              {!inShop && (
                <Form.Item name="userSearch" className="mb-0 mr-2">
                  <Select
                    showSearch
                    onSearch={getUserOptionsDebounce}
                    onSelect={(val) =>
                      setUserMobileNumber(
                        val.startsWith("+88") ? val.replace("+88", "") : val
                      )
                    }
                    options={OptionsUser?.list}
                    defaultActiveFirstOption={false}
                    notFoundContent={
                      OptionsUser?.loading ? <Spin size="small" /> : "No Data"
                    }
                    placeholder="Select Merchant"
                    optionFilterProp="children"
                    style={{ width: 296 }}
                    filterOption={(input, option) =>
                      ((option?.label ?? "") as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              )}{" "}
              <Form.Item name="companyName">
                <AutoComplete
                  onSearch={getUsersOptionsDebounceForName}
                  onSelect={(val) => setCompanyName(val)}
                  options={usersOptionsByName?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    usersOptionsByName?.loading ? <Spin size="small" /> : null
                  }
                >
                  <Input.Search
                    size="large"
                    style={{ minWidth: 100, width: "calc(100% - 50px)" }}
                    placeholder="Search by Company Name"
                    onSearch={(val) => setCompanyName(val)}
                    enterButton
                    loading={usersOptionsByName.loading}
                  />
                </AutoComplete>
              </Form.Item>
              <Form.Item name="statusSearch" className="mr-2">
                <Select
                  defaultValue={status}
                  placeholder="Status"
                  onChange={(val) => setStatus(val)}
                  value={status}
                  style={{ minWidth: 350 }}
                >
                  <Option value={""}>ALL</Option>
                  {OrderStatus?.map((status) => (
                    <Option value={status}>{status}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="" style={{ display: "flex" }}>
              <Form.Item name="paymentStatusSearch" className="mr-2">
                <Select
                  defaultValue={paymentStatus}
                  placeholder="Payment Status"
                  onChange={(val) => setPaymentStatus(val)}
                  value={paymentStatus}
                  style={{ minWidth: 125 }}
                >
                  <Option value={""}>ALL</Option>
                  {["Paid", "Unpaid"]?.map((status) => (
                    <Option value={status}>{status}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="recordsPerPage" className="mr-2">
                <Select
                  defaultValue={Number(limit)}
                  placeholder="Recors Per page"
                  onChange={(val) => setLimit(val)}
                  value={limit}
                  style={{ minWidth: 125 }}
                >
                  {[100, 150, 200, 300, 400, 500]?.map((rpp) => (
                    <Option value={rpp}>{rpp}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="range" className="mr-2">
                <RangePicker
                  defaultValue={
                    range.from != null && range.to != null
                      ? [moment(range.from), moment(range.to)]
                      : null
                  }
                  onChange={(_, f) => {
                    console.log("momnet", moment.utc(f[0])?.toISOString());
                    setRange({ from: f?.[0], to: f?.[1] });
                  }}
                  // showTime
                />
              </Form.Item>
              <Form.Item
                name="customerName"
                initialValue={orderId}
                className="mr-2"
              >
                <Input.Search
                  size="large"
                  placeholder="Customer Name"
                  style={{ minWidth: 100, width: "calc(100% - 50px)" }}
                  onChange={() => {}}
                  onSearch={(e) => {
                    console.log(customerName);
                    setCustomerName(e);
                  }}
                  enterButton
                />
              </Form.Item>

              <Form.Item
                name="customerPhonenNum"
                initialValue={orderId}
                className="mr-2"
              >
                <Input.Search
                  size="large"
                  placeholder="Customer Phone number"
                  style={{ minWidth: 100, width: "calc(100% - 50px)" }}
                  onChange={() => {}}
                  onSearch={(e) => {
                    setCustomerPhone(e);
                  }}
                  enterButton
                />
              </Form.Item>

              <Form.Item name="hubId">
                <AutoComplete
                  onSearch={handleHubSearch}
                  onSelect={(val, item) => {
                    console.log(val);
                    setHubId(item?.id);
                  }}
                  options={hubList?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    hubList?.loading ? <Spin size="small" /> : null
                  }
                >
                  <Input.Search
                    size="small"
                    style={{ minWidth: 100, width: "calc(100% - 30px)" }}
                    placeholder="Search by Hub"
                    onSearch={(val) => setHubId(val)}
                    enterButton
                    loading={hubList.loading}
                  />
                </AutoComplete>
              </Form.Item>
              <div className="search_btn" style={{ marginTop: "0px" }}>
                <Button
                  type="primary"
                  danger
                  size="large"
                  onClick={reseAllFieldData}
                >
                  Reset
                </Button>
              </div>
            </div>
          </Form>
        </section>
      )}
      <div className="flex">
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
          className="mb-2"
        >
          Check all
        </Checkbox>
      </div>
      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  <div className="single-rider-headingx"></div>
                  <div className="single-rider-heading major-col ml-4">
                    <span>Order Id</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Store Info</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Merchant Info</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Recepient Info</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Product Type</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Total</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Delivery Info</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Payment</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Status</span>
                  </div>

                  <div className="single-rider-headingx">
                    <span>Action </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {tripsData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div style={{ width: "100%" }}>
                  <Checkbox.Group
                    value={checkedList}
                    onChange={onChange}
                    className="rider-table-area"
                    style={{ width: "100%", height: "100%" }}
                  >
                    {tripsData?.data?.orders?.length ? (
                      tripsData?.data?.orders?.map((trip, index) => (
                        <React.Fragment key={index}>
                          <Link to={`/orders/${trip?.orderId}/details`}>
                            <div className="single-rider-wrapper">
                              <div className="single-riderx major-colx mr-2">
                                <Checkbox
                                  value={trip?.orderId}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onChangeSingle(trip?.orderId);
                                  }}
                                />
                              </div>
                              <div className="single-rider major-col">
                                <span className="name d-flex">
                                  {trip?.orderId}
                                  <Tooltip title="Copy">
                                    <span
                                      className="pointer p-1 pb-2 d-block"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const d =
                                          document.querySelector(
                                            ".pointer .anticon"
                                          );
                                        console.log("d", d);

                                        d?.setAttribute?.(
                                          "aria-label",
                                          "Copied!"
                                        );
                                        navigator.clipboard.writeText(
                                          trip?.orderId
                                        );
                                      }}
                                    >
                                      <CopyTwoTone style={{ color: "red" }} />
                                    </span>
                                  </Tooltip>
                                </span>
                                {trip?.parcelOrder?.merchantOrderId ? (
                                  <span
                                    className="name"
                                    style={{ marginLeft: "27px" }}
                                  >
                                    MOID: {trip?.parcelOrder?.merchantOrderId}
                                  </span>
                                ) : undefined}
                              </div>
                              <div className="single-rider major-colx">
                                {
                                  <>
                                    <span className="name">
                                      {trip?.parcelOrder?.stores.find(
                                        (store) =>
                                          store.name ===
                                          trip?.parcelOrder?.storeName
                                      )
                                        ? trip?.parcelOrder?.stores.find(
                                            (store) =>
                                              store.name ===
                                              trip?.parcelOrder?.storeName
                                          ).name
                                        : undefined}
                                    </span>
                                    <br />
                                    <span className="name">
                                      {trip?.parcelOrder?.stores.find(
                                        (store) =>
                                          store.name ===
                                          trip?.parcelOrder?.storeName
                                      )
                                        ? trip?.parcelOrder?.stores.find(
                                            (store) =>
                                              store.name ===
                                              trip?.parcelOrder?.storeName
                                          ).contactNumber
                                        : undefined}
                                    </span>
                                    <br />
                                  </>
                                }
                              </div>
                              <div className="single-rider major-colx">
                                <span className="name">
                                  {trip?.customer?.name}
                                  <br />
                                  {trip?.customer?.mobileNumber}
                                </span>
                              </div>
                              <div className="single-rider major-colx">
                                <span className="name">
                                  {trip?.parcelOrder?.recipientName}
                                  <br />
                                  {trip?.parcelOrder?.recipientPhone}
                                  <br />
                                  {trip?.parcelOrder?.recipientArea}
                                  <br />
                                </span>
                              </div>
                              <div className="single-rider major-col">
                                <span className="name text-uppercase">
                                  {trip?.parcelOrder?.itemType}
                                </span>
                              </div>
                              <div className="single-rider major-col">
                                <span className="name text-uppercase">
                                  ৳ {trip?.total}
                                </span>
                              </div>
                              <div className="single-rider major-col">
                                {trip?.deliveryMan !== null ? (
                                  <>
                                    <span className="name ml-2">
                                      {trip?.deliveryMan?.name}
                                      <br />
                                      <span style={{ marginLeft: "7px" }}>
                                        {trip?.deliveryMan?.mobileNumber}
                                      </span>
                                    </span>
                                    <br />
                                  </>
                                ) : (
                                  <>
                                    <span className="radius-btn-shadow text-muted text-nowrap">
                                      <FontAwesomeIcon
                                        icon={faBiking as IconProp}
                                        className="text-danger pr-1"
                                      />
                                      UNASSIGNED
                                    </span>
                                    <br />
                                  </>
                                )}
                                <span
                                  className="name text-uppercase text-center center ml-2"
                                  style={{
                                    textAlign: "center",
                                    width: "50%",
                                    color: "#1890ff",
                                  }}
                                >
                                  {trip?.parcelOrder?.deliveryType?.replace(
                                    /_/g,
                                    " "
                                  )}
                                </span>
                              </div>

                              <div className="single-rider">
                                <span
                                  className={
                                    trip?.paymentStatus === "Unpaid"
                                      ? "inactive-btn radius-btn"
                                      : trip?.paymentStatus === "Paid"
                                      ? "active-btn radius-btn"
                                      : "inactive-btn radius-btn"
                                  }
                                  style={{ fontSize: 12, width: 60 }}
                                >
                                  {trip?.paymentStatus}
                                </span>
                              </div>
                              <div className="single-rider">
                                <span
                                  className={
                                    trip?.status === "REQUESTED"
                                      ? "pending-btn radius-btn"
                                      : trip?.status === "COMPLETED" ||
                                        trip?.status === "DELIVERED"
                                      ? "active-btn radius-btn"
                                      : "pending-btn radius-btn"
                                  }
                                  style={{
                                    fontSize: 11,
                                    width: 180,
                                    marginBottom: "10px",
                                  }}
                                >
                                  {trip?.status?.replace(/_/g, " ")}
                                </span>
                                <br />
                                <span style={{ fontSize: 11.5 }}>
                                  {moment(trip?.updatedAt).format("ll")}
                                  <br />
                                  {moment(trip?.updatedAt).format("hh:mm:ss A")}
                                </span>
                              </div>

                              <div className="single-riderx">
                                <span
                                  style={{
                                    fontSize: 12,
                                    marginLeft: 15,
                                    display: "block",
                                  }}
                                >
                                  <Popconfirm
                                    title="Confirm?"
                                    onConfirm={(e) => confirm(e, trip?.orderId)}
                                    onCancel={(e) => e.preventDefault()}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button
                                      type="primary"
                                      title="Change this order status to AT_THE_SORTING_HUB?"
                                      disabled={
                                        !OrderStatus.slice(
                                          0,
                                          OrderStatus?.indexOf(
                                            "OUT_FOR_DELIVERY"
                                          )
                                        )
                                          .join("PARTIALLY_DELIVERED")
                                          .includes(trip?.status)
                                      }
                                      danger
                                      icon={<DeliveredProcedureOutlined />}
                                    ></Button>
                                  </Popconfirm>

                                  <Button
                                    title="Click here for edit."
                                    style={{ marginTop: "5px" }}
                                    type="primary"
                                    // style={{width:25, height:25, textAlign:"center"}}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSelectedTrip(trip);
                                    }}
                                    disabled={
                                      !OrderStatus.slice(
                                        0,
                                        OrderStatus?.indexOf("OUT_FOR_DELIVERY")
                                      )
                                        .join("PARTIALLY_DELIVERED")
                                        .includes(trip?.status)
                                    }
                                    danger
                                    icon={<EditOutlined />}
                                  ></Button>

                                  <Button
                                    type="primary"
                                    title="Generate Sticker"
                                    style={{ marginTop: "5px" }}
                                    disabled={
                                      !OrderStatus.slice(
                                        0,
                                        OrderStatus?.indexOf("OUT_FOR_DELIVERY")
                                      )
                                        .join("PARTIALLY_DELIVERED")
                                        .includes(trip?.status)
                                    }
                                    danger
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setPdfModal("STICKER");
                                      console.log(trip);
                                      setSingleOrderSticker(trip);
                                    }}
                                    icon={<TagOutlined />}
                                  ></Button>
                                </span>
                              </div>
                            </div>
                          </Link>
                        </React.Fragment>
                      ))
                    ) : (
                      <Empty />
                    )}
                  </Checkbox.Group>
                </div>
              </div>
            )}
          </div>

          <Drawer
            title="Order Edit"
            width={700}
            onClose={() => {
              setSelectedTrip(null);
              getTrips();
            }}
            visible={!!selectedTrip}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
          >
            <EditOrder
              visibleData={selectedTrip}
              onCloseMethod={() => {
                getTrips();
                setSelectedTrip(undefined);
              }}
            />
          </Drawer>

          <Modal
            visible={!!pdfModal}
            onCancel={() => setPdfModal("")}
            footer={null}
            width={pdfModal === "STICKER" ? 480 : 680}
          >
            {/* @ts-ignore */}
            <>
              {pdfModal === "STICKER" ? (
                <CustomerInvoiceSticker data={[singleOrderSticker]} />
              ) : (
                <CustomerInvoicePdf data={[singleOrderSticker]} />
              )}
            </>
          </Modal>
          <PaginationTwo
            {...tripsData?.data}
            limit={limit}
            page={tripsData?.data?.currentPageNumber || 0}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SortingOrderList;
