import { Drawer } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement, useState } from "react";
import AddBtn from "../common/AddBtn";
import AddRider from "./AddRider";
import { isEditableService } from "../../utils/services";

const TopHeading = ({ total }): ReactElement => {
  const [visible, setvisible] = useState<boolean>(false);

  const showDrawer = () => {
    setvisible(true);
  };

  const onClose = () => {
    setvisible(false);
  };

  return (
    <div className="top-heading-products top-heading-content">
      <div className="container-fluid">
        <div className="row pt-3 pb-3 d-flex-sb">
          <div className="col-lg-6 col-md-6 no-padding">
            <div className="page-heading-content">
              <h2 className="d-flex-l wrap d-flex-wrap d-flex-base">
                Riders List{" "}
                <span className="d-flex-l ml-1">{total} Rider(s)</span>
              </h2>
            </div>
          </div>

            <div className="single-button">
              <AddBtn onClick={showDrawer} />
            </div>

          <Drawer
            destroyOnClose={true}
            title="Add Rider"
            width={920}
            onClose={onClose}
            visible={!!visible}
            bodyStyle={{ paddingBottom: 0 }}
            footer={
              <div
                style={{
                  textAlign: "left",
                }}
              ></div>
            }
          >
            <AddRider onCloseMethod={onClose} />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

TopHeading.propTypes = {
  RiderId: PropTypes.string,
  GetStatus: PropTypes.string,
  GetPrime: PropTypes.string,
  GetFlagship: PropTypes.string,
  GetRocket: PropTypes.string,
  name: PropTypes.string,
};

export default TopHeading;
