import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Rate,
  Select,
  Spin,
} from "antd";
import {
  BranchesOutlined,
  ClockCircleOutlined,
  CopyFilled,
  CopyOutlined,
  CopyrightCircleFilled,
  CopyrightTwoTone,
  CopyTwoTone,
  EditOutlined,
  EyeOutlined,
  FilePdfFilled,
  RedoOutlined,
} from "@ant-design/icons";

import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";

import { authenticateToken } from "../../utils/auth";
import {
  getPage,
  getParamValue,
  getUpdatedUrl,
  OrderStatus,
} from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import TopHeading from "./TopHeading";
import { debounce } from "lodash";
import logoImg from "../../images/Piickme Express.svg";
import moment from "moment";
import _ from "lodash";
import Empty from "../common/Empty";
import usePrevious from "../../hooks/PreviousState";
import { responseNotification } from "../../utils/notify";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const { Option } = Select;
const { RangePicker }: any = DatePicker;

const RunsheetListDetails = ({ activeSearch = true, inShop = false }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const page = getParamValue(location.search, "page") || 0;
  const [status, setStatus] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(
    getParamValue(location.search, "paymentStatus") || ""
  );
  const [range, setRange] = useState({
    from: getParamValue(location.search, "from")
      ? getParamValue(location.search, "from")
      : null,
    to: getParamValue(location.search, "to")
      ? getParamValue(location.search, "to")
      : null,
  });
  const [driverMobileNumber, setDriverMobileNumber] = useState(
    getParamValue(location.search, "driverMobileNumber") || ""
  );
  const [orderOptions, setOrderOptions] = useState({
    list: [],
    loading: false,
  });
  const [userMobileNumber, setUserMobileNumber] = useState<string>(
    (getParamValue(location.search, "userMobileNumber") || "") as string
  );
  // getParamValue(location.search, "userMobileNumber") || ""
  const [limit, setLimit] = useState(100);
  const [tripsData, setTripsData] = useState({
    loading: false,
    data: null,
  });
  const [OptionsUser, setOptionsUser] = useState({
    loading: false,
    list: null,
  });
  const [usersOptionsByName, setUsersOptionsByName] = useState({
    loading: false,
    list: null,
  });
  const [orderId, setOrderId] = useState("");

  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  const [returnModal, setReturnModal] = useState<boolean>(false);
  const [isreturnModalSubmiting, setIsReturnModalSubmiting] =
    useState<boolean>(false);
  const [returnOrderIds, setReturnOrderIds] = useState<String[]>([]);

  const previousMobileNumber = usePrevious(userMobileNumber);
  const previousRangeFrom = usePrevious(range.from);
  const previousRangeTo = usePrevious(range.to);
  const previousStatus = usePrevious(status);
  const previousOrderId = usePrevious(orderId);
  const previousPaymentStatus = usePrevious(paymentStatus);

  // checkbox
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [merchantOrderId, setMerchantOrderId] = useState("");

  const { runsheetId }: any = useParams();

  const fetchRef = useRef(null);

  const getTrips = useCallback(async () => {
    console.log(customerName, customerPhone);
    let shouldResetPage = false;

    if (
      userMobileNumber &&
      getParamValue(location.search, "userMobileNumber") != userMobileNumber
    ) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("userMobileNumber", `${userMobileNumber}`));
    }

    if (
      driverMobileNumber &&
      getParamValue(location.search, "driverMobileNumber") != driverMobileNumber
    ) {
      shouldResetPage = true;
      history.push(
        getUpdatedUrl("driverMobileNumber", `${driverMobileNumber}`)
      );
    }

    if (status && getParamValue(location.search, "status") != status) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("status", `${status}`));
    }

    if (shouldResetPage) history.push(getUpdatedUrl("page", `${0}`));

    setTripsData({ loading: true, data: null });
    console.log("mb", userMobileNumber);
    status.join("%2C");
    // const url = `http://192.168.10.71:8080/api/v1/admin/order/by-runSheetNumber`;
    const url = `${process.env.REACT_APP_ORDER_API}/admin/order/by-runSheetNumber`
    axios
      .get(
        `${url}` +
          `?page=${page || 0}` +
          `&limit=${limit || 16}` +
          (runsheetId ? `&runSheetNumber=${runsheetId}` : ``) +
          (range?.from ? `&from=${moment(range?.from).toISOString()}` : ``) +
          (range?.to ? `&to=${moment(range?.to).toISOString()}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setTripsData({ loading: false, data: res.data });
        }
      })
      .catch((err) => {
        setTripsData({ loading: false, data: [] });
        console.error("Trips: Error", err);
      });
  }, [
    limit,
    status,
    page,
    range,
    runsheetId,
  ]);

  const getOrderOptions = useCallback(
    async (getOrderId) => {
      setOrderOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order?type=Q_PARCEL` +
            (getOrderId
              ? `&orderId=${getOrderId}`
              : `` + getOrderId
              ? `&orderId=${getOrderId}`
              : ``) +
            `&page=${page || 0}` +
            (status ? `&status=${status}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map((order, index) => ({
              key: index,
              label: order.orderId,
              value: order.orderId,
            })),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, status]
  );

  useEffect(() => {
    getTrips();
  }, [getTrips]);

  console.log(runsheetId)

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions]);

  // checkbox funcs
  const plainOptions = tripsData?.data?.orders?.map((order) => order?.orderId);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  const onChangeSingle = (id: string) => {
    let list = [...(checkedList || [])];
    list = list?.filter((item) => item !== id);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const getUsersOptionsByName = useCallback(
    async (value) => {
      setUsersOptionsByName({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_USER_API}`;
      axios
        .get(
          `${encodedUri}/business/all?` +
            `page=${0}` +
            `&limit=${20}` +
            (value ? `&companyName=${value}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setUsersOptionsByName({
            loading: false,
            list: res.data?.businesses?.map((user) => ({
              label: user.companyName,
              value: user.companyName,
            })),
          });
        })
        .catch((err) => {
          setUsersOptionsByName({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [status]
  );

  useEffect(() => {
    getUsersOptionsByName("");
  }, [getUsersOptionsByName]);

  const onSubmitReturnStatus = async (data) => {
    console.log(123, "onSubmitReturnStatus", data);
    const body = {
      ...data,
      ids: returnOrderIds,
    };

    setIsReturnModalSubmiting(true);
    await fetch(
      `${process.env.REACT_APP_ORDER_API}/admin/order/reverseIdGenerate`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification(
            "Selected Order Statuses Updated Successfully",
            "success"
          );
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
      });
    setIsReturnModalSubmiting(false);
    setReturnModal(false);
    getTrips();
  };

  return (
    <React.Fragment>
      <TopHeading
        total={tripsData?.data?.totalElements}
        refetch={getTrips}
        filterData={{
          orderId,
          userMobileNumber,
          page,
          status,
          paymentStatus,
          limit,
          range,
          previousOrderId,
          previousMobileNumber,
          previousStatus,
          previousRangeFrom,
          previousRangeTo,
          previousPaymentStatus,
        }}
        orders={(tripsData?.data?.orders || [])?.filter((order) =>
          checkedList?.includes(order?.orderId)
        )}
        afterStatusUpdate={() => {
          getTrips();
          setCheckedList([]);
        }}
        inShop={inShop}
      />

      {activeSearch && (
        <section className="search_area">
          <Form form={form} className="">
            <div className="asfjkgsdh" style={{ display: "flex" }}>
              <Form.Item name="orderId" initialValue={orderId} className="mr-2">
                <AutoComplete
                  dropdownMatchSelectWidth={250}
                  style={{ width: 250 }}
                  onSearch={(e) => handleSearch(e, "order")}
                  onSelect={(val) => {
                    setOrderId(val);
                  }}
                  options={orderOptions?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    orderOptions?.loading ? <Spin size="small" /> : null
                  }
                >
                  <Input.Search
                    size="large"
                    placeholder="Order ID"
                    style={{ minWidth: 100, width: "calc(100% - 0px)" }}
                    onSearch={(val) => {
                      setOrderId(val);
                    }}
                    enterButton
                  />
                </AutoComplete>
              </Form.Item>

              <Form.Item name="paymentStatusSearch" className="mr-2">
                <Select
                  defaultValue={paymentStatus}
                  placeholder="Payment Status"
                  onChange={(val) => setPaymentStatus(val)}
                  value={paymentStatus}
                  style={{ minWidth: 125 }}
                >
                  <Option value={""}>ALL</Option>
                  {["Paid", "Unpaid"]?.map((status) => (
                    <Option value={status}>{status}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Form>
        </section>
      )}
      <div
        className="flex"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
          className="mb-2"
        >
          Check all
        </Checkbox>
        <p>
          Selected Item: <span className="badge">{checkedList.length}</span>
        </p>
      </div>

      <div className="search_area d-flex w-100 text-center justify-content-between">
        <div className="">
          <p className="mb-0">Runsheet ID:</p>
          <h6 className=" font-weight-bold ">#{tripsData?.data?.orders?.[0]?.runSheet?.runSheetNumber}</h6>
        </div>
        <div className="">
          <p className="mb-0">Rider Name:</p>
          <h6 className=" font-weight-bold ">{tripsData?.data?.orders?.[0]?.deliveryMan?.name}</h6>
        </div>
        <div className="">
          <p className="mb-0">Mobile Number</p>
          <h6 className=" font-weight-bold ">{tripsData?.data?.orders?.[0]?.deliveryMan?.mobileNumber}</h6>
        </div>
        <div className="">
          <p className="mb-0">Created Date</p>
          <h6 className=" font-weight-bold ">{moment(tripsData?.data?.orders?.[0]?.runSheet?.createdAt).format('YYYY-DD-MM')}</h6>
        </div>
        <div className="">
          <p className="mb-0">Status</p>
          <h6 className="font-weight-bold" style={{color:tripsData?.data?.orders?.[0]?.runSheet?.status=='OPEN' ? 'green' : 'red'}}>{tripsData?.data?.orders?.[0]?.runSheet?.status}</h6>
        </div>
        <div className="">
          <img src={logoImg} alt="" />
        </div>
      </div>

      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  <div className="single-rider-headingx"></div>
                  <div className="single-rider-heading major-col">
                    <span>Order ID</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Store Name</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Stote Address</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Date</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Status</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {tripsData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div style={{ width: "100%" }}>
                  <Checkbox.Group
                    value={checkedList}
                    onChange={onChange}
                    className="rider-table-area"
                    style={{ width: "100%", height: "100%" }}
                  >
                    {tripsData?.data?.orders?.length ? (
                      tripsData?.data?.orders?.map((trip, index) => (
                        <React.Fragment key={index}>
                          <div
                            className="single-rider-wrapper"
                            style={{ cursor: "auto" }}
                          >
                            <div className="single-rider major-col">
                              <Checkbox
                                value={trip?.orderId}
                                onClick={(e) => {
                                  e.preventDefault();
                                  onChangeSingle(trip?.orderId);
                                }}
                              />
                              <span className="name ml-2">{trip?.orderId}</span>
                            </div>
                            {/* <div className="single-rider major-col">
                              <span className="name d-flex">Runsheet ID</span>
                            </div> */}
                            <div className="single-rider major-col">
                              {
                                <>
                                  <span className="name">{trip?.shop?.name}</span>
                                </>
                              }
                            </div>
                            <div className="single-rider major-col">
                              <span className="name">{trip?.shop?.city ? 'City: '+trip?.shop?.city+',' : ''} {trip?.shop?.zone ? 'Zone: '+trip?.shop?.zone+',' : ''}
                              {trip?.shop?.area ? 'Area: '+trip?.shop?.area+',' : ''}{trip?.shop?.address ? 'Address: '+trip?.shop?.address : ''}</span>
                            </div>

                            <div className="single-rider major-col">
                              <span style={{ fontSize: 11.5 }}>
                                {moment(trip?.createdAt).format("ll")}
                                <br />
                                {moment(trip?.createdAt).format("hh:mm:ss A")}
                              </span>
                            </div>

                            <div className="single-rider">
                              <span
                                className={
                                  trip?.status === "REQUESTED"
                                    ? "pending-btn radius-btn"
                                    : trip?.status === "COMPLETED" ||
                                      trip?.status === "DELIVERED"
                                    ? "active-btn radius-btn"
                                    : "pending-btn radius-btn"
                                }
                                style={{
                                  fontSize: 11,
                                  width: 180,
                                  marginBottom: "10px",
                                }}
                              >
                                {trip?.status?.replace(/_/g, " ")}
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      ))
                    ) : (
                      <Empty />
                    )}
                  </Checkbox.Group>
                </div>
              </div>
            )}
          </div>

          <Modal
            visible={returnModal}
            onCancel={() => setReturnModal(false)}
            footer={null}
          >
            <Form
              layout="vertical"
              onFinish={onSubmitReturnStatus}
              className="w-full w-100"
            >
              <Form.Item
                hasFeedback
                label="Discount Amount"
                className="w-full w-100"
                rules={[
                  {
                    required: false,
                    message: "Discount Amount is Required!",
                  },
                ]}
                style={{ width: 200 }}
                name="discountAmount"
              >
                <InputNumber
                  className="w-full w-100"
                  id="name"
                  type="number"
                  min={0}
                  placeholder="Enter Discount Amount"
                />
              </Form.Item>
              <div className="w-full w-100 buttons-container">
                <Button
                  disabled={isreturnModalSubmiting}
                  loading={isreturnModalSubmiting}
                  type="primary"
                  htmlType="submit"
                  className="w-full w-100 text-center mr-2"
                >
                  Update Status dgdfg
                </Button>
              </div>
            </Form>
          </Modal>
          <PaginationTwo
            {...tripsData?.data}
            limit={limit}
            page={tripsData?.data?.currentPageNumber || 0}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default RunsheetListDetails;
