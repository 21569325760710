import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Rate,
  Select,
  Spin,
} from "antd";
import {
  BranchesOutlined,
  ClockCircleOutlined,
  CopyFilled,
  CopyOutlined,
  CopyrightCircleFilled,
  CopyrightTwoTone,
  CopyTwoTone,
  EditOutlined,
  EyeOutlined,
  FilePdfFilled,
  RedoOutlined,
} from "@ant-design/icons";

import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";

import { authenticateToken } from "../../utils/auth";
import {
  getPage,
  getParamValue,
  getUpdatedUrl,
  OrderStatus,
} from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import TopHeading from "./TopHeading";
import { debounce } from "lodash";

import moment from "moment";
import _ from "lodash";
import Empty from "../common/Empty";
import usePrevious from "../../hooks/PreviousState";
import { responseNotification } from "../../utils/notify";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RunsheetInvoice } from "../invoice-pdf";

const { Option } = Select;
const { RangePicker }: any = DatePicker;

const RunsheetList = ({ activeSearch = true, inShop = false }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const page = getParamValue(location.search, "page") || 0;
  const [status, setStatus] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(
    getParamValue(location.search, "paymentStatus") || ""
  );
  const [range, setRange] = useState({
    from: getParamValue(location.search, "from")
      ? getParamValue(location.search, "from")
      : null,
    to: getParamValue(location.search, "to")
      ? getParamValue(location.search, "to")
      : null,
  });
  const [driverMobileNumber, setDriverMobileNumber] = useState(
    getParamValue(location.search, "driverMobileNumber") || ""
  );
  const [orderOptions, setOrderOptions] = useState({
    list: [],
    loading: false,
  });
  const [userMobileNumber, setUserMobileNumber] = useState<string>(
    (getParamValue(location.search, "userMobileNumber") || "") as string
  );
  // getParamValue(location.search, "userMobileNumber") || ""
  const [limit, setLimit] = useState(100);
  const [tripsData, setTripsData] = useState({
    loading: false,
    data: null,
  });
  const [riderId, setRiderId] = useState('');
  const [runSheetId, setRunSheetId] = useState("");
  const [riderOptions, setRiderOptions] = useState<any>({
    list: [],
    riderNameList : [],
    fetching: false,
  });
  const [orderId, setOrderId] = useState("");
  const [selectedTrip, setSelectedTrip] = useState("");

  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  const [returnModal, setReturnModal] = useState<boolean>(false);
  const [isreturnModalSubmiting, setIsReturnModalSubmiting] =
    useState<boolean>(false);
  const [returnOrderIds, setReturnOrderIds] = useState<String[]>([]);

  const previousMobileNumber = usePrevious(userMobileNumber);
  const previousRangeFrom = usePrevious(range.from);
  const previousRangeTo = usePrevious(range.to);
  const previousStatus = usePrevious(status);
  const previousOrderId = usePrevious(orderId);
  const previousPaymentStatus = usePrevious(paymentStatus);
  const [selectedInvoiceToPdf, setSelectedInvoiceToPdf] = useState(undefined);

  const onSubmitReturnStatus = async (data) => {
    console.log(123, "onSubmitReturnStatus", data);
    const body = {
      ...data,
      ids: returnOrderIds,
    };

    setIsReturnModalSubmiting(true);
    await fetch(
      `${process.env.REACT_APP_ORDER_API}/admin/order/reverseIdGenerate`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification(
            "Selected Order Statuses Updated Successfully",
            "success"
          );
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
      });
    setIsReturnModalSubmiting(false);
    setReturnModal(false);
    getTrips();
  };

  // checkbox
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [merchantOrderId, setMerchantOrderId] = useState("");

  const { merchantId }: any = useParams();

  const fetchRef = useRef(null);

  const getTrips = useCallback(async () => {
    

    setTripsData({ loading: true, data: null });
    console.log("mb", userMobileNumber);
    status.join("%2C");
    // const url = `http://192.168.10.71:8080/api/v1/sheet/run-sheet-details`;
    const url = `${process.env.REACT_APP_ORDER_API}/sheet/run-sheet-details`;
    axios
      .get(
        `${url}` +
          `?page=${page || 0}` +
          `&limit=${limit || 16}` +
          (riderId ? `&riderId=${riderId}` : ``) +
          (range?.from ? `&from=${moment(range?.from).format('YYYY-MM-DD')}` : ``) +
          (range?.to ? `&to=${moment(range?.to).format('YYYY-MM-DD')}` : ``) +
          (userMobileNumber
            ? `&mobileNumber=${userMobileNumber?.replace("+88", "")}`
            : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setTripsData({ loading: false, data: res.data });
        }
      })
      .catch((err) => {
        setTripsData({ loading: false, data: [] });
        console.error("Trips: Error", err);
      });
  }, [
    limit,
    page,
    runSheetId,
    range,
    riderId,
  ]);
  const getRiderOptions = useCallback(
    async (val) => {
      console.log(val)
      setRiderOptions({ fetching: false, list: [] });

      if (val) {
        setRiderOptions({ fetching: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_USER_API}`;

        const res = await axios.get(
          `${encodedUri}/admin/driver?page=0&limit=20` +
            (val ? `&driverName=${val}` : ``),
            
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        );
        setRiderOptions({
          fetching: false,
          list: res?.data?.drivers?.map(
            (rider: { name: any; mobileNumber: any, id: any }) => {
              return {
                value: rider?.name,
                label: rider?.name,
                id: rider?.id
              };
            }
          ),
        });
      }
    },
    [status]
  );


  useEffect(() => {
    getTrips();
  }, [getTrips]);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "rider") getRiderOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getRiderOptions]);

  // checkbox funcs
  const plainOptions = tripsData?.data?.runSheetResponses?.map((runSheet) => runSheet?.runSheetNumber);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  const onChangeSingle = (id: string) => {
    let list = [...(checkedList || [])];
    list = list?.filter((item) => item !== id);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };



  const generatePDF = async (item, runSheetNumber: any) => {
    const result = await getRunSheetDetails(0, 3000, runSheetNumber);
    console.log(result,item)
    const doc = <RunsheetInvoice data={result.orders} runSheet={item} />;

    setSelectedInvoiceToPdf(doc);
    // setTimeout(() => setSelectedInvoiceToPdf(undefined), 0);
    // const asPdf = pdf();
    // asPdf.updateContainer(doc);
    // const blob = await asPdf.toBlob();
    // saveAs(blob, `invoice-${item.invoiceId}`);
  };
  const getRunSheetDetails = async (page, limit, runSheetNumber) => {
    try {
      const path =
        `${process.env.REACT_APP_ORDER_API}` +
        `/admin/order/by-runSheetNumber?page=${page || 0}&limit=${limit || 5000}` +
        (runSheetNumber ? `&runSheetNumber=${runSheetNumber}` : ``);

      const response = await fetch(path, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
        method: "GET",
      });
      if (!response.ok) return null;
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  return (
    <React.Fragment>
      <TopHeading
        total={tripsData?.data?.totalElements}
        refetch={getTrips}
        filterData={
          undefined
        }
        orders={(tripsData?.data?.orders || [])?.filter((order) =>
          checkedList?.includes(order?.orderId)
        )}
        afterStatusUpdate={() => {
          getTrips();
          setCheckedList([]);
        }}
        inShop={inShop}
      />

      {activeSearch && (
        <section className="search_area">
          <Form form={form} className="">
            <div className="asfjkgsdh" style={{ display: "flex" }}>
              <Form.Item name="range" className="mr-2">
                <RangePicker
                  defaultValue={
                    range.from != null && range.to != null
                      ? [moment(range.from), moment(range.to)]
                      : null
                  }
                  onChange={(_, f) => {
                    console.log("momnet", moment.utc(f[0])?.toISOString());
                    setRange({ from: f?.[0], to: f?.[1] });
                  }}
                  // showTime
                />
              </Form.Item>

              <Form.Item name="runSheetId" initialValue={runSheetId} className="mr-2">
                <AutoComplete
                  dropdownMatchSelectWidth={250}
                  style={{ width: 250 }}
                  onSearch={(e) => handleSearch(e, "order")}
                  onSelect={(val) => {
                    setRunSheetId(val);
                  }}
                  options={orderOptions?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    orderOptions?.loading ? <Spin size="small" /> : null
                  }
                >
                  <Input.Search
                    size="large"
                    placeholder="RunSheet ID"
                    style={{ minWidth: 100, width: "calc(100% - 0px)" }}
                    onSearch={(val) => {
                      setRunSheetId(val);
                    }}
                    enterButton
                  />
                </AutoComplete>
              </Form.Item>

              <Form.Item name="rider" initialValue={''}>
                <AutoComplete
                  onSearch={(e)=>handleSearch(e,'rider')}
                  onSelect={(val,option) =>{
                    console.log(val,option?.id as string)
                    setRiderId(option?.id as string)
                  }}
                  options={riderOptions?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    riderOptions?.loading ? <Spin size="small" /> : null
                  }
                >
                  <Input.Search
                    size="large"
                    placeholder="Search by Rider Name"
                    onSearch={(val,option) =>{
                      console.log(option)
                      setRiderId(val)
                    }
                    }
                    enterButton
                    loading={riderOptions.loading}
                  />
                </AutoComplete>
              </Form.Item>

            </div>
          </Form>
        </section>
      )}
      <div
        className="flex"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
          className="mb-2"
        >
          Check all
        </Checkbox>
        <p>
          Selected Item: <span className="badge">{checkedList.length}</span>
        </p>
      </div>
      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  <div className="single-rider-headingx"></div>
                  <div className="single-rider-heading major-col">
                    <span>Runsheet ID</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Rider Info</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Order Count</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Date</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Status</span>
                  </div>

                  <div className="single-rider-heading">
                    <span>Action </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {tripsData?.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div style={{ width: "100%" }}>
                  <Checkbox.Group
                    value={checkedList}
                    onChange={onChange}
                    className="rider-table-area"
                    style={{ width: "100%", height: "100%" }}
                  >
                    {tripsData?.data?.runSheetResponses?.length ? (
                      tripsData?.data?.runSheetResponses?.map((runSheet, index) => (
                        <React.Fragment key={index}>
                          <div
                            className="single-rider-wrapper"
                            style={{ cursor: "auto" }}
                          >
                            <div className="single-rider major-col">
                              <Checkbox
                                value={runSheet?.runSheetNumber}
                                onClick={(e) => {
                                  e.preventDefault();
                                  onChangeSingle(runSheet?.runSheetNumber);
                                }}
                              />
                              <span className="name ml-2">{runSheet?.runSheetNumber}</span>
                            </div>
                            {/* <div className="single-rider major-col">
                              <span className="name d-flex">Runsheet ID</span>
                            </div> */}
                            <div className="single-rider major-col">
                              {
                                <>
                                  <span className="name">{runSheet?.deliveryMan?.name}<br/>{runSheet?.deliveryMan?.mobileNumber}</span>
                                </>
                              }
                            </div>
                            <div className="single-rider major-col">
                              <span className="name">{runSheet?.totalOrder}</span>
                            </div>

                            <div className="single-rider major-col">
                              <span style={{ fontSize: 11.5 }}>
                                {moment(runSheet?.createAt).format("ll")}
                                <br />
                                {moment(runSheet?.createAt).format("hh:mm:ss A")}
                              </span>
                            </div>

                            <div className="single-rider">
                              <span
                                className={
                                  runSheet?.status === "CLOSE"
                                    ? "pending-btn radius-btn"
                                    : runSheet?.status === "COMPLETED" ||
                                    runSheet?.status === "OPEN"
                                    ? "active-btn radius-btn"
                                    : "pending-btn radius-btn"
                                }
                                style={{
                                  fontSize: 11,
                                  width: 180,
                                  marginBottom: "10px",
                                }}
                              >
                                {runSheet?.status?.replace(/_/g, " ")}
                              </span>
                            </div>

                            <div className="single-rider">
                              <span style={{ fontSize: 12, marginLeft: 15 }}>
                                <Button
                                  type="primary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    generatePDF(runSheet, runSheet?.runSheetNumber);
                                  }}
                                  danger
                                >
                                  Download <FilePdfFilled />
                                </Button>
                              </span>

                              <span style={{ fontSize: 12, marginLeft: 15 }}>
                                <Button
                                  type="primary"
                                  title="View Rider Details"
                                  href={`/runsheet/${runSheet?.runSheetNumber}/details`}
                                  style={{ marginTop: 4 }}
                                  onClick={(e) => {
                                    console.log(e);
                                    //e.preventDefault();
                                  }}
                                >
                                  <EyeOutlined />
                                </Button>
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      ))
                    ) : (
                      <Empty />
                    )}
                  </Checkbox.Group>
                </div>
              </div>
            )}
          </div>

          <Modal
            visible={returnModal}
            onCancel={() => setReturnModal(false)}
            footer={null}
          >
            <Form
              layout="vertical"
              onFinish={onSubmitReturnStatus}
              className="w-full w-100"
            >
              <Form.Item
                hasFeedback
                label="Discount Amount"
                className="w-full w-100"
                rules={[
                  {
                    required: false,
                    message: "Discount Amount is Required!",
                  },
                ]}
                style={{ width: 200 }}
                name="discountAmount"
              >
                <InputNumber
                  className="w-full w-100"
                  id="name"
                  type="number"
                  min={0}
                  placeholder="Enter Discount Amount"
                />
              </Form.Item>
              <div className="w-full w-100 buttons-container">
                <Button
                  disabled={isreturnModalSubmiting}
                  loading={isreturnModalSubmiting}
                  type="primary"
                  htmlType="submit"
                  className="w-full w-100 text-center mr-2"
                >
                  Update Status dgdfg
                </Button>
              </div>
            </Form>
          </Modal>

          <Modal
            open={!!selectedInvoiceToPdf}
            onCancel={() => setSelectedInvoiceToPdf(undefined)}
            width={1000}
          >
            {/* <Loader />
            <div style={{ height: 0, overflow: "hidden" }}> */}
            <div style={{ overflow: "hidden" }}>{selectedInvoiceToPdf}</div>
          </Modal>

          <PaginationTwo
            {...tripsData?.data}
            limit={limit}
            page={tripsData?.data?.currentPageNumber || 0}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default RunsheetList;
