import MAP_STYLE from "../../../utils/map-style-basic-v8.json";
import {
  WalletOutlined,
  ClockCircleOutlined,
  UserOutlined,
  FileTextOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Avatar, Card, Col, Rate, Row, Skeleton, Steps } from "antd";
import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useParams } from "react-router-dom";
import { authenticateToken } from "../../../utils/auth";
import { getImgUrl } from "../../../utils/index";

import Currency from "../../common/Currency";
import moment from "moment";
import ReactMapGL, {
  FlyToInterpolator,
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "mapbox-gl/dist/mapbox-gl.css"; // can be delete
import DetailsTopHeading from "./TopHeader";
import BranchesOutlined from "@ant-design/icons/lib/icons/BranchesOutlined";
import IssuesCloseOutlined from "@ant-design/icons/lib/icons/IssuesCloseOutlined";
import SingleIssue from "../../Issues/Details";
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY; // Set your mapbox token here
export { MAP_STYLE };

function getCursor({ isHovering, isDragging }) {
  return isDragging ? "grabbing" : isHovering ? "pointer" : "default";
}
const { Step } = Steps;

const DetailsPickeMe = (): ReactElement => {
  const [status, setStatus] = useState(undefined);
  const [tripDetails, setTripDetails] = useState(undefined);
  const route = useParams();
  const tripId = (route as any).orderId;

  const [destinationLocation, setDestinationLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });

  const [pickupLocation, setPickupLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });

  const [driverLocation, setDriverLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });

  const [userLocation, setUserLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });

  const mapContainerRef = useRef(null);
  // **************************************
  const [viewport, setViewport] = React.useState({
    container: mapContainerRef.current,
    latitude: destinationLocation?.lat,
    longitude: destinationLocation?.lng,
    width: "100%",
    height: "380px",
    zoom: 11,
    pitch: 50,
    bearing: 0,
    clickRadius: 50,
    // getMapCenterByLngLatPosition: [
    //   destinationLocation?.lng,
    //   destinationLocation?.lat,
    // ],
    // center: [destinationLocation?.lng, destinationLocation?.lat],
    // initialCenterCoordinate: [
    //   destinationLocation?.lng,
    //   destinationLocation?.lat,
    // ],
  });
  // **************************************

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  /*======================================
  Fetch Trip Details  api call
  =======================================*/
  const fetchTripDetails = useCallback(() => {
    if (tripId) {
      fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/order/details?orderId=${tripId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setTripDetails(data?.order);
        })
        .catch((err) => console.log(err));
    }
  }, [tripId]);

  useEffect(() => {
    fetchTripDetails();
  }, [fetchTripDetails]);

  // useEffect(() => {
  //   if (tripDetails) {
  //     setDestinationLocation({
  //       lat: tripDetails?.destination?.latitude,
  //       lng: tripDetails?.destination?.longitude,
  //     });
  //     setPickupLocation({
  //       lat: tripDetails?.pickupLocation?.latitude,
  //       lng: tripDetails?.pickupLocation?.longitude,
  //     });
  //     setDriverLocation({
  //       lat: tripDetails?.driverLocation?.latitude,
  //       lng: tripDetails?.driverLocation?.longitude,
  //     });
  //   }
  // }, [tripDetails]);

  useEffect(() => {
    setViewport({
      container: mapContainerRef.current,
      latitude: destinationLocation?.lat,
      longitude: destinationLocation?.lng,
      width: "100%",
      height: "380px",
      zoom: 11,
      pitch: 50,
      bearing: 0,
      clickRadius: 50,
    });
  }, [destinationLocation]);

  return (
    // <Layout>
    <React.Fragment>
      <DetailsTopHeading refetch={fetchTripDetails} data={tripDetails} />
      <div className="mb-3 px-4 heading-steps">
        <Steps
          type="navigation"
          size="small"
          current={tripDetails?.statusLogs?.length - 1}
          // onChange={onChange}
          className="site-navigation-steps mr-5"
          items={tripDetails?.statusLogs?.map((trip, i) => ({
            title: trip?.value,
            subTitle: moment(trip?.datetime).format("DD/mm/yyyy hh:mma"),
            status:
              i === tripDetails?.statusLogs?.length - 1 ? "process" : "finish",
            description: trip?.note,
          }))}
        />
      </div>
      <div className="user-deatils-area mt-2">
        {!tripDetails ? (
          <Skeleton paragraph={{ rows: 20 }} active />
        ) : (
          <Tab.Container id="left-tabs-example" defaultActiveKey="basic">
            <Row gutter={20}>
              <Col span={8}>
                <div className="user-information-tab-menu">
                  {/* <Card title={tripDetails?.orderId} bordered={false}>
                    {" "}
                    <Steps
                      progressDot
                      direction="vertical"
                      size="small"
                      current={3}
                    >
                      <Step
                        title="Pickup Address"
                        description={tripDetails?.parcelOrder?.storeAddress}
                      />
                      <Step
                        title="Destination Address"
                        description={tripDetails?.parcelOrder?.recipientAddress}
                      />
                    </Steps>
                  </Card> */}
                  {/* <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="basic">
                        <FileTextOutlined />
                         Basic Information
                      </Nav.Link>
                    </Nav.Item>

                    {/* <Nav.Item>
                      <Nav.Link eventKey="ridersInfo">
                        <FileTextOutlined />
                        Product Info
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="usersInfo">
                        <UserOutlined />
                        Recipient Info
                      </Nav.Link>
                    </Nav.Item>
                  </Nav> */}

                   <Card title={tripDetails?.orderId} bordered={false}>
                   <div className="single">
                      <div className="info-name">
                        <span>Order Id</span>
                      </div>
                      <div className="info-desc">
                        <span>{tripDetails?.orderId}</span>
                      </div>
                    </div>

                    <div className="single">
                      <div className="info-name">
                        <span>Date</span>
                      </div>
                      <div className="info-desc">
                        <span>
                          {moment(
                            tripDetails?.createdAt
                          )?.format("LLL")}
                        </span>
                      </div>
                    </div>

                    <div className="single">
                      <div className="info-name">
                        <span>Delivery Type</span>
                      </div>
                      <div className="info-desc">
                        <span>
                          {tripDetails?.parcelOrder?.deliveryType?.replace(
                            /_/g,
                            " "
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="single">
                      <div className="info-name">
                        <span>Merchant Name</span>
                      </div>
                      <div className="info-desc">
                        <span>
                          {
                            tripDetails?.customer?.name
                          }
                        </span>
                      </div>
                    </div>
                    <div className="single">
                      <div className="info-name">
                        <span>Merchant Order Id</span>
                      </div>
                      <div className="info-desc">
                        <span>
                          {
                            tripDetails?.parcelOrder
                              ?.merchantOrderId
                          }
                        </span>
                      </div>
                    </div>
                    
                    <div className="single">
                      <div className="info-name">
                        <span>Merchant Mobile</span>
                      </div>
                      <div className="info-desc">
                        <span>
                          {
                            tripDetails?.customer?.mobileNumber
                          }
                        </span>
                      </div>
                    </div>
                    
                    <div className="single">
                      <div className="info-name">
                        <span>Merchant Type</span>
                      </div>
                      <div className="info-desc">
                        <span>
                          {
                            tripDetails?.customer?.type
                          }
                        </span>
                      </div>
                    </div>
                    <div className="single">
                      <div className="info-name">
                        <span>Status</span>
                      </div>
                      <div className="info-desc">
                        <span
                          className={
                            tripDetails?.status ===
                            "REQUESTED"
                              ? "pending-btn radius-btn d-block"
                              : tripDetails?.status ===
                                "COMPLETED"
                              ? "active-btn radius-btn d-block"
                              : "inactive-btn radius-btn d-block"
                          }
                        >
                          {tripDetails?.status?.replace(
                            /_/g,
                            " "
                          )}
                        </span>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="user-information-tab-menu mt-3">
                <Card title="Parcel Order Charges" bordered={false}>
                <div className="single">
                  <div className="info-name">
                    <span>Amount To Collect</span>
                  </div>
                  <div className="info-desc">
                    <span>
                      {
                        tripDetails?.parcelOrder
                          ?.amountToCollect
                      }
                    </span>
                  </div>
                </div>

                <div className="single">
                  <div className="info-name">
                    <span>COD Charge</span>
                  </div>
                  <div className="info-desc">
                    <span>
                      {
                        tripDetails?.parcelOrder
                          ?.codCharge
                      }
                    </span>
                  </div>
                </div>

                <div className="single">
                  <div className="info-name">
                    <span>Delivery Charge</span>
                  </div>
                  <div className="info-desc">
                    <span>
                      {
                        tripDetails?.parcelOrder
                          ?.deliveryCharge
                      }
                    </span>
                  </div>
                </div>

                <div className="single" style={{borderTop:"1px solid #ddd",fontWeight:"600"}}>
                  <div className="info-name">
                    <span>Total = COD + Delivery Charge</span>
                  </div>
                  <div className="info-desc">
                    <span>
                      {
                        tripDetails?.parcelOrder
                          ?.deliveryCharge+tripDetails?.parcelOrder
                          ?.codCharge
                      }
                    </span>
                  </div>
                </div>

                <div className="single" style={{borderTop:"1px solid #ddd",fontWeight:"bold"}}>
                  <div className="info-name">
                    <span>Total Payout</span>
                  </div>
                  <div className="info-desc">
                    <span>
                      {
                        tripDetails?.parcelOrder
                        ?.amountToCollect - (tripDetails?.parcelOrder
                          ?.deliveryCharge+tripDetails?.parcelOrder
                          ?.codCharge)
                      }
                    </span>
                  </div>
                </div>

                </Card>
                </div>
              </Col>
              <Col span={16} style={{ background: "#fff" }}>
                <Card
                  size="small"
                  bordered={false}
                  className="user-information-show-tab-wrapper p-0"
                >
                  <div className="inner-content">
                    <div className="single-content">                        
                          <React.Fragment>
                            <div className="single-information-user-wrapper mb-3">
                            
                             
                              <div className="single-information-user-content">
                                <div className="info-icon">
                                  <svg
                                    width="23"
                                    height="23"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.4167 1.91669H5.75004C5.24171 1.91669 4.7542 2.11862 4.39475 2.47807C4.03531 2.83751 3.83337 3.32502 3.83337 3.83335V19.1667C3.83337 19.675 4.03531 20.1625 4.39475 20.522C4.7542 20.8814 5.24171 21.0834 5.75004 21.0834H17.25C17.7584 21.0834 18.2459 20.8814 18.6053 20.522C18.9648 20.1625 19.1667 19.675 19.1667 19.1667V7.66669L13.4167 1.91669Z"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M13.4166 1.91669V7.66669H19.1666"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M15.3333 12.4583H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M15.3333 16.2917H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M9.58329 8.625H8.62496H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                                <div className="info-body user-personal-inforamtion">
                                  <h3 className="title">Product Information</h3>
                                  <div className="inner-body-wrapper">
                                    <div className="inner-body">
                                      <div className="single">
                                        <div className="info-name">
                                          <span>Item Type</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {tripDetails?.parcelOrder?.itemType}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Item Quantity</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.itemQuantity
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Item Weight</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.itemWeight
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Item Description</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {tripDetails?.parcelOrder?.itemDesc}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="single-information-user-content">
                                <div className="info-icon">
                                  <svg
                                    width="23"
                                    height="23"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.4167 1.91669H5.75004C5.24171 1.91669 4.7542 2.11862 4.39475 2.47807C4.03531 2.83751 3.83337 3.32502 3.83337 3.83335V19.1667C3.83337 19.675 4.03531 20.1625 4.39475 20.522C4.7542 20.8814 5.24171 21.0834 5.75004 21.0834H17.25C17.7584 21.0834 18.2459 20.8814 18.6053 20.522C18.9648 20.1625 19.1667 19.675 19.1667 19.1667V7.66669L13.4167 1.91669Z"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M13.4166 1.91669V7.66669H19.1666"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M15.3333 12.4583H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M15.3333 16.2917H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M9.58329 8.625H8.62496H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                                <div className="info-body user-personal-inforamtion">
                                  <h3 className="title">
                                    Recipient Information
                                  </h3>
                                  <div className="inner-body-wrapper">
                                    <div className="inner-body">
                                      <div className="single">
                                        <div className="info-name">
                                          <span>Profile Picture</span>
                                        </div>
                                        <div className="info-desc">
                                          <Avatar
                                            src={
                                              tripDetails?.parcelOrder
                                                ?.recipientImage
                                            }
                                            size={120}
                                            shape="square"
                                          />
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Recipient Name</span>
                                        </div>

                                        <div className="info-desc">
                                          <span>
                                            {
                                              tripDetails?.parcelOrder
                                                ?.recipientName
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Phone Number</span>
                                        </div>
                                        <div className="info-desc">
                                          <span>
                                            {tripDetails?.parcelOrder?.recipientPhone?.split(
                                              "+88"
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Recipient City</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.recipientCity
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Recipient Zone</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.recipientZone
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Recipient Area</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.recipientArea
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Recipient Address</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.recipientAddress
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {
                                tripDetails?.parcelOrder?.stores.map((storeItem)=>(
                                  <div className="single-information-user-content">
                                    <div className="info-icon">
                                      <svg
                                        width="23"
                                        height="23"
                                        viewBox="0 0 23 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M13.4167 1.91669H5.75004C5.24171 1.91669 4.7542 2.11862 4.39475 2.47807C4.03531 2.83751 3.83337 3.32502 3.83337 3.83335V19.1667C3.83337 19.675 4.03531 20.1625 4.39475 20.522C4.7542 20.8814 5.24171 21.0834 5.75004 21.0834H17.25C17.7584 21.0834 18.2459 20.8814 18.6053 20.522C18.9648 20.1625 19.1667 19.675 19.1667 19.1667V7.66669L13.4167 1.91669Z"
                                          stroke="white"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M13.4166 1.91669V7.66669H19.1666"
                                          stroke="white"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M15.3333 12.4583H7.66663"
                                          stroke="white"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M15.3333 16.2917H7.66663"
                                          stroke="white"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M9.58329 8.625H8.62496H7.66663"
                                          stroke="white"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>

                                  
                                    <div className="info-body user-personal-inforamtion">
                                      <h3 className="title">Store ({storeItem?.name}) Information</h3>
                                      <div className="inner-body-wrapper">
                                        <div className="inner-body">
                                          <div className="single">
                                              <div className="info-name">
                                                <span>Store Name</span>
                                              </div>
                                              <div className="info-desc">
                                                <span>
                                                  {
                                                    storeItem?.name
                                                  }
                                                </span>
                                              </div>
                                            </div>

                                            <div className="single">
                                              <div className="info-name">
                                                <span>Store Address</span>
                                              </div>
                                              <div className="info-desc">
                                                <span>
                                                  {
                                                    storeItem?.address
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                            <div className="single">
                                              <div className="info-name">
                                                <span>Contact Name</span>
                                              </div>
                                              <div className="info-desc">
                                                <span>
                                                  {
                                                    storeItem?.contactName
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                            <div className="single">
                                              <div className="info-name">
                                                <span>Contact Number</span>
                                              </div>
                                              <div className="info-desc">
                                                <span>
                                                  {
                                                    storeItem?.contactNumber
                                                  }
                                                </span>
                                              </div>
                                            </div>

                                            <div className="single">
                                              <div className="info-name">
                                                <span>Store City</span>
                                              </div>
                                              <div className="info-desc">
                                                <span>
                                                  {
                                                    storeItem?.city
                                                  }
                                                </span>
                                              </div>
                                            </div>

                                            <div className="single">
                                              <div className="info-name">
                                                <span>Store Area</span>
                                              </div>
                                              <div className="info-desc">
                                                <span>
                                                  {
                                                    storeItem?.area
                                                  }
                                                </span>
                                              </div>
                                            </div>

                                            <div className="single">
                                              <div className="info-name">
                                                <span>Store Zone</span>
                                              </div>
                                              <div className="info-desc">
                                                <span>
                                                  {
                                                    storeItem?.zone
                                                  }
                                                </span>
                                              </div>
                                            </div>

                                            <div className="single">
                                              <div className="info-name">
                                                <span>Status</span>
                                              </div>
                                              <div className="info-desc">
                                                <span>
                                                  {
                                                    storeItem?.status
                                                  }
                                                </span>
                                              </div>
                                            </div>

                                          {/* <div className="single">
                                            <div className="info-name">
                                              <span>Recipient Name</span>
                                            </div>
                                            <div className="info-desc">
                                              <span>
                                                {
                                                  tripDetails?.parcelOrder?.recipientName
                                                }
                                              </span>
                                            </div>
                                          </div>

                                          <div className="single">
                                            <div className="info-name">
                                              <span>Recipient Phone</span>
                                            </div>
                                            <div className="info-desc">
                                              <span>
                                                {
                                                  tripDetails?.parcelOrder?.recipientPhone
                                                }
                                              </span>
                                            </div>
                                          </div> */}

                                          {/* <div className="single">
                                            <div className="info-name">
                                              <span>Order Note</span>
                                            </div>
                                            <div className="info-desc">
                                              <p>{tripDetails?.orderNote}</p>
                                            </div>
                                          </div> */}

                                          <div className="single">
                                            <div className="info-name">
                                              <span>Special Instruction</span>
                                            </div>
                                            <div className="info-desc">
                                              <p>
                                                {
                                                  tripDetails?.parcelOrder
                                                    ?.specialInstruction
                                                }
                                              </p>
                                            </div>
                                          </div>

                                          <div className="mt-5 d-none hidden">
                                            {pickupLocation &&
                                              destinationLocation && (
                                                <ReactMapGL
                                                  mapboxApiAccessToken={
                                                    MAPBOX_TOKEN
                                                  }
                                                  {...viewport}
                                                  getCursor={getCursor}
                                                  mapStyle={MAP_STYLE}
                                                  onViewportChange={(viewport) =>
                                                    setViewport(viewport)
                                                  }
                                                  transitionInterpolator={
                                                    new FlyToInterpolator()
                                                  }
                                                >
                                                  <Marker
                                                    latitude={pickupLocation?.lat}
                                                    longitude={pickupLocation?.lng}
                                                    offsetLeft={
                                                      (-viewport.zoom * 1.0) / 2
                                                    }
                                                    offsetTop={
                                                      (-viewport.zoom * 3) / 2
                                                    }
                                                  >
                                                    <svg
                                                      width={viewport.zoom * 2}
                                                      height={viewport.zoom * 2.5}
                                                      viewBox="0 0 39 68"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g filter="url(#filter0_d_0_1)">
                                                        <ellipse
                                                          cx="19.268"
                                                          cy="54.302"
                                                          rx="12.651"
                                                          ry="3.69799"
                                                          fill="#C4C4C4"
                                                          fillOpacity="0.01"
                                                        />
                                                      </g>
                                                      <path
                                                        d="M17.2432 23.1939C17.1807 22.0482 18.0929 21.085 19.2402 21.085H19.8704C21.022 21.085 21.9357 22.0551 21.8668 23.2047L19.8763 56.3798C19.8632 56.5975 19.6829 56.7673 19.4648 56.7673C19.2459 56.7673 19.0651 56.5962 19.0532 56.3776L17.2432 23.1939Z"
                                                        fill="#CD113B"
                                                      />
                                                      <circle
                                                        cx="19.4631"
                                                        cy="19.4631"
                                                        r="19.4631"
                                                        fill="#CD113B"
                                                      />
                                                      <circle
                                                        cx="19.4631"
                                                        cy="19.4631"
                                                        r="19.4631"
                                                        stroke="white"
                                                      />
                                                      <path
                                                        d="M26.1822 29.4063L23.8586 6.68673L9.00299 24.0329L15.6808 26.1321L22.0675 12.4131L19.4984 27.3262L26.1822 29.4063Z"
                                                        fill="white"
                                                      />
                                                      <defs>
                                                        <filter
                                                          id="filter0_d_0_1"
                                                          x="0.617004"
                                                          y="48.604"
                                                          width="37.302"
                                                          height="19.396"
                                                          filterUnits="userSpaceOnUse"
                                                          colorInterpolationFilters="sRGB"
                                                        >
                                                          <feFlood
                                                            floodOpacity="0"
                                                            result="BackgroundImageFix"
                                                          />
                                                          <feColorMatrix
                                                            in="SourceAlpha"
                                                            type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha"
                                                          />
                                                          <feOffset dy="4" />
                                                          <feGaussianBlur stdDeviation="3" />
                                                          <feColorMatrix
                                                            type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
                                                          />
                                                          <feBlend
                                                            mode="normal"
                                                            in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_0_1"
                                                          />
                                                          <feBlend
                                                            mode="normal"
                                                            in="SourceGraphic"
                                                            in2="effect1_dropShadow_0_1"
                                                            result="shape"
                                                          />
                                                        </filter>
                                                      </defs>
                                                    </svg>
                                                  </Marker>
                                                  <Marker
                                                    latitude={
                                                      destinationLocation?.lat
                                                    }
                                                    longitude={
                                                      destinationLocation?.lng
                                                    }
                                                    offsetLeft={
                                                      (-viewport.zoom * 1.0) / 2
                                                    }
                                                    offsetTop={
                                                      (-viewport.zoom * 3) / 2
                                                    }
                                                    // draggable
                                                    // onDragEnd={(e) => {
                                                    //   setDestinationLocation({
                                                    //     lat: e?.lngLat?.[1],
                                                    //     lng: e?.lngLat?.[0],
                                                    //   });
                                                    // }}
                                                  >
                                                    <svg
                                                      width={viewport.zoom * 2}
                                                      height={viewport.zoom * 2.5}
                                                      viewBox="0 0 39 68"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g filter="url(#filter0_d_0_1)">
                                                        <ellipse
                                                          cx="19.268"
                                                          cy="54.302"
                                                          rx="12.651"
                                                          ry="3.69799"
                                                          fill="#C4C4C4"
                                                          fillOpacity="0.01"
                                                        />
                                                      </g>
                                                      <path
                                                        d="M17.2432 23.1939C17.1807 22.0482 18.0929 21.085 19.2403 21.085H19.8704C21.0221 21.085 21.9358 22.0551 21.8668 23.2047L19.8763 56.3798C19.8632 56.5975 19.6829 56.7673 19.4648 56.7673V56.7673C19.2459 56.7673 19.0652 56.5962 19.0533 56.3776L17.2432 23.1939Z"
                                                        fill="#CD113B"
                                                      />
                                                      <circle
                                                        cx="19.4631"
                                                        cy="19.4631"
                                                        r="19.4631"
                                                        fill="#CD113B"
                                                      />
                                                      <circle
                                                        cx="19.4631"
                                                        cy="19.4631"
                                                        r="19.4631"
                                                        stroke="white"
                                                      />
                                                      <path
                                                        d="M19.7852 10C15.4924 10 12 13.4925 12 17.7852C12 19.8729 13.2561 22.4731 15.7333 25.5133C17.5422 27.7335 19.3228 29.3443 19.3977 29.4118L19.7852 29.761L20.1728 29.4118C20.2477 29.3443 22.0283 27.7335 23.8372 25.5133C26.3144 22.4731 27.5705 19.8729 27.5705 17.7852C27.5705 13.4925 24.078 10 19.7852 10V10Z"
                                                        fill="white"
                                                      />
                                                      <path
                                                        d="M19.8842 21.7768C22.0341 21.7768 23.7768 20.034 23.7768 17.8842C23.7768 15.7344 22.0341 13.9916 19.8842 13.9916C17.7344 13.9916 15.9916 15.7344 15.9916 17.8842C15.9916 20.034 17.7344 21.7768 19.8842 21.7768Z"
                                                        fill="#CD113B"
                                                      />
                                                      <defs>
                                                        <filter
                                                          id="filter0_d_0_1"
                                                          x="0.617004"
                                                          y="48.604"
                                                          width="37.302"
                                                          height="19.396"
                                                          filterUnits="userSpaceOnUse"
                                                          colorInterpolationFilters="sRGB"
                                                        >
                                                          <feFlood
                                                            floodOpacity="0"
                                                            result="BackgroundImageFix"
                                                          />
                                                          <feColorMatrix
                                                            in="SourceAlpha"
                                                            type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha"
                                                          />
                                                          <feOffset dy="4" />
                                                          <feGaussianBlur stdDeviation="3" />
                                                          <feColorMatrix
                                                            type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
                                                          />
                                                          <feBlend
                                                            mode="normal"
                                                            in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_0_1"
                                                          />
                                                          <feBlend
                                                            mode="normal"
                                                            in="SourceGraphic"
                                                            in2="effect1_dropShadow_0_1"
                                                            result="shape"
                                                          />
                                                        </filter>
                                                      </defs>
                                                    </svg>
                                                  </Marker>

                                                  <GeolocateControl
                                                    style={{ right: 10, top: 10 }}
                                                    positionOptions={{
                                                      enableHighAccuracy: true,
                                                    }}
                                                    trackUserLocation={true}
                                                    label="Trip Location"
                                                    // auto
                                                  />
                                                  <FullscreenControl
                                                    style={{ right: 10, top: 50 }}
                                                  />
                                                  <NavigationControl
                                                    style={{ right: 10, top: 90 }}
                                                  />
                                                </ReactMapGL>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>


                            {/* <div className="single-information-user-wrapper mb-3">
                              <div className="single-information-user-content">
                                <div className="info-icon">
                                  <svg
                                    width="23"
                                    height="23"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.4167 1.91669H5.75004C5.24171 1.91669 4.7542 2.11862 4.39475 2.47807C4.03531 2.83751 3.83337 3.32502 3.83337 3.83335V19.1667C3.83337 19.675 4.03531 20.1625 4.39475 20.522C4.7542 20.8814 5.24171 21.0834 5.75004 21.0834H17.25C17.7584 21.0834 18.2459 20.8814 18.6053 20.522C18.9648 20.1625 19.1667 19.675 19.1667 19.1667V7.66669L13.4167 1.91669Z"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M13.4166 1.91669V7.66669H19.1666"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M15.3333 12.4583H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M15.3333 16.2917H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M9.58329 8.625H8.62496H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                                <div className="info-body user-personal-inforamtion">
                                  <h3 className="title">
                                    Recipient Information
                                  </h3>
                                  <div className="inner-body-wrapper">
                                    <div className="inner-body">
                                      <div className="single">
                                        <div className="info-name">
                                          <span>Profile Picture</span>
                                        </div>
                                        <div className="info-desc">
                                          <Avatar
                                            src={
                                              tripDetails?.parcelOrder
                                                ?.recipientImage
                                            }
                                            size={120}
                                            shape="square"
                                          />
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Recipient Name</span>
                                        </div>

                                        <div className="info-desc">
                                          <span>
                                            {
                                              tripDetails?.parcelOrder
                                                ?.recipientName
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Phone Number</span>
                                        </div>
                                        <div className="info-desc">
                                          <span>
                                            {tripDetails?.parcelOrder?.recipientPhone?.split(
                                              "+88"
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Recipient City</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.recipientCity
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Recipient Zone</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.recipientZone
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Recipient Area</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.recipientArea
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Recipient Address</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.recipientAddress
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                            {/* <div className="single-information-user-wrapper mb-3">
                              <div className="single-information-user-content">
                                <div className="info-icon">
                                  <svg
                                    width="23"
                                    height="23"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.4167 1.91669H5.75004C5.24171 1.91669 4.7542 2.11862 4.39475 2.47807C4.03531 2.83751 3.83337 3.32502 3.83337 3.83335V19.1667C3.83337 19.675 4.03531 20.1625 4.39475 20.522C4.7542 20.8814 5.24171 21.0834 5.75004 21.0834H17.25C17.7584 21.0834 18.2459 20.8814 18.6053 20.522C18.9648 20.1625 19.1667 19.675 19.1667 19.1667V7.66669L13.4167 1.91669Z"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M13.4166 1.91669V7.66669H19.1666"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M15.3333 12.4583H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M15.3333 16.2917H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M9.58329 8.625H8.62496H7.66663"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                                <div className="info-body user-personal-inforamtion">
                                  <h3 className="title">Product Information</h3>
                                  <div className="inner-body-wrapper">
                                    <div className="inner-body">
                                      <div className="single">
                                        <div className="info-name">
                                          <span>Item Type</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {tripDetails?.parcelOrder?.itemType}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Item Quantity</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.itemQuantity
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Item Weight</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {
                                              tripDetails?.parcelOrder
                                                ?.itemWeight
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <div className="single">
                                        <div className="info-name">
                                          <span>Item Description</span>
                                        </div>
                                        <div className="info-desc">
                                          <span className="d-flex justify-content-start">
                                            {tripDetails?.parcelOrder?.itemDesc}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}


                          </React.Fragment>
                        
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Tab.Container>
        )}{" "}
      </div>
    </React.Fragment>
    // </Layout>
  );
};
DetailsPickeMe.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default DetailsPickeMe;
