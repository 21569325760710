import { ReactElement } from "react";
import Logo from "../../images/logo.svg";
// login features images
import img1 from "../../images/assetsImg/headphone.png";
import img2 from "../../images/assetsImg/github.png";
import img3 from "../../images/assetsImg/messenger.png";
import img5 from "../../images/assetsImg/doller.png";
import img6 from "../../images/assetsImg/warning.png";
import img7 from "../../images/assetsImg/menus.png";
import img8 from "../../images/assetsImg/girlsbag.png";
import img9 from "../../images/assetsImg/quatition.png";
import img10 from "../../images/assetsImg/address.png";
import img11 from "../../images/assetsImg/track.png";

const LoginIntro = (): ReactElement => {
  return (
    <div className="login-introduction">
      <img src={Logo} alt="Piickme Express" width="200" />
      <h3 style={{ fontWeight: "lighter", fontFamily: "inherit" }}>
        Piick<span className="red-txt">me</span> Express
      </h3>
      <div className="role-icon-lists">
        <ul></ul>
      </div>
    </div>
  );
};

export default LoginIntro;
