import {
  AutoComplete,
  Avatar,
  Button,
  Drawer,
  Form,
  Input,
  Rate,
  Select,
  Space,
  Spin,
} from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { authenticateToken } from "../../utils/auth";
import { getParamValue, riderStatuses, getUpdatedUrl } from "../../utils/index";
import Loading from "../common/Loader";
import PaginationTwo from "../common/PaginationTwo";
import TopHeading from "./TopHeading";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";
import { responseNotification } from "../../utils/notify";
import {
  CheckOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import AddRider from "./AddRider";

const { Option } = Select;

const RiderList = (): ReactElement => {
  const router = useLocation();
  const history = useHistory();
  const page = getParamValue(router?.search, "page");
  const [limit, setLimit] = useState(100);
  const [mobileNumber, setMobileNumber] = useState(
    getParamValue(router?.search, "mobileNumber") || ""
  );
  const [selectedRider, setSelectedRider] = useState(undefined);
  const [data, setData] = useState<any>({ loading: false, item: [] });
  const [status, setStatus] = useState(
    getParamValue(router.search, "status") || ""
  );

  const [riderOptions, setRiderOptions] = useState<any>({
    list: [],
    riderNameList: [],
    fetching: false,
  });

  const [riderName, setRiderName] = useState("");

  const ref = useRef(null);

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  const [form] = Form.useForm();

  const reseAllFieldData = () => {
    setMobileNumber("");
    setStatus("");
    form.resetFields(["searchMobile", "searchStatus"]);
    history.replace(router.pathname);
    window.location.reload();
  };

  const fetchRiders = useCallback(() => {
    let shouldResetPage = false;
    if (
      mobileNumber &&
      getParamValue(router?.search, "mobileNumber") != mobileNumber
    ) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("mobileNumber", `${mobileNumber}`));
    }
    if (riderName && getParamValue(router?.search, "riderName") != riderName) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("riderName", `${riderName}`));
    }
    if (status && getParamValue(router?.search, "status") != status) {
      shouldResetPage = true;
      history.push(getUpdatedUrl("status", `${status}`));
    }

    if (shouldResetPage) history.push(getUpdatedUrl("page", `${0}`));

    try {
      setData({ loading: true, item: [] });

      const url =
        `${process.env.REACT_APP_USER_API}/admin/driver?` +
        `page=${page || 0}` +
        `&limit=${limit || 16}` +
        (mobileNumber ? `&mobileNumber=${mobileNumber}` : ``) +
        (riderName ? `&driverName=${riderName}` : ``) +
        (status ? `&status=${status}` : ``);
      fetch(url, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setData({ loading: false, item: data });
        })
        .catch(() => {
          setData({ loading: true, item: [] });
        });
    } catch (error) {
      setData({ loading: true, item: [] });
      console.log(error, "error");
    }
  }, [limit, page, status, mobileNumber, riderName]);

  const getRiderOptions = useCallback(
    async (val) => {
      console.log(val);
      setRiderOptions({ fetching: false, list: [] });

      if (val) {
        setRiderOptions({ fetching: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_USER_API}`;

        const res = await axios.get(
          `${encodedUri}/admin/driver?page=0&limit=20` +
            (val.name ? `&driverName=${val.name}` : ``) +
            (val.mobile ? `&mobileNumber=${val.mobile}` : ``) +
            (status ? `&status=${status}` : ``),

          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        );
        setRiderOptions({
          fetching: false,
          list: res?.data?.drivers?.map(
            (rider: { name: any; mobileNumber: any }) => {
              return {
                value: val?.name ? rider?.name : rider?.mobileNumber,
                label: rider?.name,
              };
            }
          ),
        });
      }
    },
    [status]
  );

  useEffect(() => {
    fetchRiders();
  }, [fetchRiders]);

  const debouncedSearch = useCallback(
    debounce((val) => getRiderOptions(val), 800),
    []
  );

  const handleSearch = (val: string) => {
    setRiderName("");
    const request = {
      name: null,
      mobile: val,
    };
    debouncedSearch(request);
  };
  const handleSearchByName = (name: string) => {
    setMobileNumber("");
    const request = {
      name: name,
      mobile: null,
    };
    debouncedSearch(request);
  };

  function getVal(val) {
    let escaped = val;
    if (typeof val === "string") {
      escaped = ("" + val).replace(/"/g, '"');
      // @ts-ignore
      escaped = escaped?.replace(/[\t\n\"]/gi, " ");
    }
    return `"${escaped}"`; // To escape the comma in a address like string.
  }
  const downloadRidersData = () => {
    if (data?.item?.drivers) {
      const drivers = [];
      data?.item?.drivers?.forEach((driver) => {
        console.log(driver);
        let obj = {};
        obj["orderId"] = driver?.orderId;
        obj["status"] = driver?.status;
        obj["createdAt"] = moment(driver?.createdAt).format(
          "DD-MM-yyyy hh:mma"
        );
        obj["updatedAt"] = moment(driver?.updatedAt).format(
          "DD-MM-yyyy hh:mma"
        );

        //delete order?.parcelOrder?.stores;

        // obj = {
        //   ...obj,
        //   ...(order?.parcelOrder || {}),
        //   expectedPayoutAmount:
        //     order?.parcelOrder?.amountToCollect - order?.parcelOrder?.deliveryCharge - order?.parcelOrder?.codCharge,
        // };

        for (let [key, val] of Object.entries(obj)) {
          obj[key] = getVal(val);
        }
        drivers?.push(obj);
      });
      const titleKeys = Object.keys(drivers[0]).map((key) => getVal(key));

      const refinedData = [];
      refinedData.push(titleKeys);

      drivers.forEach((item) => {
        refinedData.push(Object.values(item));
      });
      let csvContent = "";
      refinedData.forEach((row) => {
        csvContent += row.join(",") + "\n";
      });

      // @ts-ignore
      const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8,",
      });

      const objUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", objUrl);
      link.setAttribute("download", `drivers-${drivers?.length}.csv`);
      link?.click();
    } else {
      // done(false);
      responseNotification("No data", "warning");
    }
  };

  const riderInactive = async (driverId) => {
    await fetch(`${process.env.REACT_APP_USER_API}/admin/driver/statusUpdate`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: driverId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Rider Updated Successfully", "success");
          fetchRiders();
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      });
  };

  return (
    <div className="rider-lists">
      <TopHeading total={data?.item?.totalElements} />
      <section className="search_area">
        <Form form={form} className="search_form">
          <Form.Item name="searchMobile" initialValue={mobileNumber}>
            <AutoComplete
              onSearch={handleSearch}
              onSelect={(val) =>
                setMobileNumber(
                  val.startsWith("+88") ? val.replace("+88", "") : val
                )
              }
              options={riderOptions?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                riderOptions?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                size="large"
                placeholder="Search by Phone (01...)"
                style={{ minWidth: 100, width: "calc(100% - 50px)" }}
                onSearch={(val) =>
                  setMobileNumber(
                    val.startsWith("+88") ? val.replace("+88", "") : val
                  )
                }
                enterButton
                loading={riderOptions.loading}
                pattern={`[0-9]`}
                maxLength={11}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item name="searchName" initialValue={""}>
            <AutoComplete
              onSearch={handleSearchByName}
              onSelect={(val, option) => {
                console.log(val, option?.label as string);
                setRiderName(option?.label as string);
              }}
              options={riderOptions?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                riderOptions?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                size="large"
                placeholder="Search by Rider Name"
                style={{ minWidth: 100, width: "calc(100% - 50px)" }}
                onSearch={(val, option) => {
                  console.log(option);
                  setRiderName(val);
                }}
                enterButton
                loading={riderOptions.loading}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item name="searchStatus" initialValue={{ status }}>
            <Select
              defaultValue={status}
              placeholder="Rider Status"
              onChange={handleChangeStatus}
              value={status}
              style={{ minWidth: 300 }}
            >
              <Option value={""}>ALL</Option>
              {Object.values(riderStatuses)?.map((status, i) => (
                <Option value={status} key={i}>
                  {status?.split("_").join(" ")}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="recordsPerPage" className="mr-2">
            <Select
              defaultValue={Number(limit)}
              placeholder="Recors Per page"
              onChange={(val) => setLimit(val)}
              value={limit}
              style={{ minWidth: 125 }}
            >
              {[100, 200, 300, 400]?.map((rpp) => (
                <Option value={rpp}>{rpp}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>

        <div className="search_btn">
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;
          {/* <Button type="primary" size="large" onClick={downloadRidersData}>
            Download
          </Button> */}
        </div>
      </section>
      <div className="rider-list-item-area white-bg section-padding-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="rider-list-items-inner-content">
                <div className="single-rider-wrapper">
                  {/* <div className="single-rider-heading">
                    <span>Rider Id</span>
                  </div> */}
                  <div className="single-rider-heading">
                    <span>Rider Name</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Phone Number</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Area</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Rattings</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Status</span>
                  </div>
                  <div className="single-rider-heading">
                    <span>Action</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {data.loading ? (
              <Loading />
            ) : (
              <div className="col-lg-12">
                <div className="rider-table-area">
                  {data.item?.drivers?.length ? (
                    data?.item?.drivers?.map((rider: any, index: number) => (
                      <React.Fragment key={index}>
                        <Link
                          to={rider.id ? `/riders/${rider?.id}/details` : `#`}
                        >
                          <div className="single-rider-wrapper">
                            <div className="single-rider">
                              <Avatar
                                size={{
                                  xs: 50,
                                  sm: 50,
                                  md: 50,
                                  lg: 40,
                                  xl: 40,
                                  xxl: 40,
                                }}
                                src={rider?.profilePictureUrl}
                              />
                              <span className="name ml-3">{rider?.name}</span>
                            </div>

                            <div className="single-rider">
                              <span className="name">
                                {rider?.mobileNumber?.split("+88")}
                              </span>
                            </div>
                            <div className="single-rider">
                              <span className="name">{rider?.area}</span>
                            </div>
                            <div className="single-rider">
                              <Rate
                                disabled
                                defaultValue={rider?.rating || 5}
                                style={{ fontSize: 14, marginRight: 8 }}
                              />
                              <small style={{ color: "#777" }}>
                                ({rider?.numberOfRating || 5})
                              </small>
                            </div>
                            <div className="single-rider">
                              <span
                                className={`radius-btn ${rider?.status?.toLowerCase()}`}
                              >
                                {rider?.status?.split("_").join(" ")}
                              </span>
                            </div>

                            <div className="single-rider">
                              <Space>
                                <button
                                  className="btn btn-sm btn-outline-warning mx-1"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    console.log(rider);
                                    setSelectedRider(rider);
                                  }}
                                >
                                  <EditOutlined />
                                </button>
                                {rider.status == "VERIFIED" ? (
                                  <Button
                                    type="dashed"
                                    danger
                                    title="Inactive"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      riderInactive(rider?.id);
                                    }}
                                  >
                                    <CloseCircleOutlined />
                                  </Button>
                                ) : rider.status == "INACTIVE" ? (
                                  <Button
                                    type="dashed"
                                    danger
                                    title="Inactive"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      riderInactive(rider?.id);
                                    }}
                                  >
                                    <CheckOutlined />
                                  </Button>
                                ) : undefined}
                              </Space>
                            </div>
                          </div>
                        </Link>
                      </React.Fragment>
                    ))
                  ) : (
                    <p className="red-txt pt-5 text-center">No riders found</p>
                  )}
                  {/* <Drawer
                    title="Rider Details"
                    width={800}
                    onClose={() => {
                      setSelectedRiderId(null);
                      fetchRiders();
                    }}
                    visible={!!selectedRiderId}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                      <div
                        style={{
                          textAlign: "right",
                        }}
                      ></div>
                    }
                  >
                    <RiderDetails selectedRiderId={selectedRiderId} />
                  </Drawer> */}
                </div>
              </div>
            )}
          </div>
          <PaginationTwo
            {...data.item}
            limit={limit}
            page={data?.item?.currentPageNumber || 0}
          />
        </div>
        <Drawer
          destroyOnClose={true}
          title="Add Rider"
          width={920}
          onClose={() => setSelectedRider(undefined)}
          visible={!!selectedRider}
          bodyStyle={{ paddingBottom: 0 }}
          footer={
            <div
              style={{
                textAlign: "left",
              }}
            ></div>
          }
        >
          <AddRider
            onCloseMethod={() => setSelectedRider(undefined)}
            visibleData={selectedRider}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default RiderList;
