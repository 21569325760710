import { ReactElement } from "react";
import MerchantList from "../../components/Merchants";
import Layout from "../../components/Layout";

const MerchantPage = (): ReactElement => {
  return (
    <Layout>
      <MerchantList />
    </Layout>
  );
};

export default MerchantPage;
