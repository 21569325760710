import { ReactElement } from "react";
import Logo from "../../images/logo.svg";

const LoginIntro = (): ReactElement => {
  return (
    <div className="login-introduction">
      <img src={Logo} alt="Piickme Express" width="200" />
      <h3 style={{ fontWeight: "lighter", fontFamily: "inherit" }}>
        Piick<span className="red-txt">me</span> Express
      </h3>
      <div className="role-icon-lists">
        <ul></ul>
      </div>
    </div>
  );
};

export default LoginIntro;
