import { ReactElement } from 'react'
import Layout from '../../components/Layout'
import RiderList from '../../components/Riders'

const RidersPage = (): ReactElement => {
  return (
    <Layout>
      <RiderList />
    </Layout>
  )
}

export default RidersPage
