import {
  Alert,
  Button,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Select,
} from "antd";
import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AddBtn from "../common/AddBtn";
import {
  CopyOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  InboxOutlined,
  TagOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import { getParamValue, OrderStatus } from "../../utils";
import { useForm } from "antd/lib/form/Form";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
// @ts-ignore
import builkcsv from "../../demo-files/order-ids.csv";
// @ts-ignore
import bulkordercsv from "../../demo-files/bulk-order.csv";
import * as d3 from "d3";
import axios from "axios";
import moment from "moment";
import { debounce } from "lodash";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../common/Loader";
import json2csv from "json2csv";
import { faBiking, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomerInvoicePdf, CustomerInvoiceSticker } from "../invoice-pdf";
// @ts-ignore
import { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { getDeliveryCharge, getZone } from "../../utils/delivery-change";
import { formatMobile } from "../../utils/format-mobile";
import { getData } from "../../utils/functions";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useLocation } from "react-router-dom";
const { TextArea } = Input;

const props = {
  name: "file",
  multiple: false,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("drop: ", e);
  },
};

const simpleFileDownload = () => {
  window.location.href = `${builkcsv}`;
};

const downloadBulkOrder = () => {
  window.location.href = `${bulkordercsv}`;
};

const TopHeading = ({
  total,
  refetch,
  filterData,
  orders: items,
  afterStatusUpdate,
  inShop = true,
}): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [fetchedCSVData, setFetchedCSVData] = useState<any>();
  const [uploadCSVData, setUploadCSVData] = useState<any>();
  const [drawer, setDrawer] = useState<"statusChange" | "uploadFileById">();
  const [isRequired, setIsRequired] = useState(false);
  const [orderDataForDownload, setOrderDataForDownload] = useState({
    loading: false,
    data: [],
  });
  const [orders, setOrders] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [modal, setModal] = useState(false);
  const [openDrawerBarCode, setOpenDrawerBarCode] = useState(false);
  const [totalScanned, setTotalScanned] = useState<number>(0);

  const [pdfModal, setPdfModal] = useState("");
  const location = useLocation();
  const orderStatus = getParamValue(location.search, "status") || "";
  const [assignment, setAssignment] = useState(false);
  const [selectedDeliveryManId, setSelectedDeliveryManId] = useState("");
  const [deliveryManData, setDeliveryManData] = useState({
    loading: false,
    list: [],
  });
  const fetchRef = useRef(0);

  const [form] = useForm();
  const [manualOrderForm] = useForm();
  const [form2] = Form.useForm();
  const [bulkAssignDeliveryManForm] = Form.useForm();

  useEffect(() => {
    setOrderIds(items?.map((i) => i?.orderId) || []);
    setOrders(items || []);
  }, [items]);

  const resetFields = () => {
    setFetchedCSVData(undefined);
    form.resetFields();
  };

  const structurizeData = async (data) => {
    const filteredData = [];
    data?.forEach((row) => {
      if (row?.orderId) {
        filteredData.push(row?.orderId);
      }
    });
    setFetchedCSVData(filteredData);
  };

  const readUploadFile = (val) => {
    const mainFile = val.fileList[0]?.originFileObj;
    if (mainFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        d3.csv(data).then((res) => {
          structurizeData(res);
        });
      };
      reader.readAsDataURL(mainFile);
    } else {
      setFetchedCSVData([]);
    }
  };

  const structurizeBulkOrderData = async (orderList) => {
    console.log(1, orderList);

    const orders = [];
    const zoneType = getZone(orderList[0]);
    if (
      !orderList[0]?.merchantId ||
      orderList?.find((order) => order?.merchantId !== orderList[0]?.merchantId)
    ) {
      responseNotification(
        "Need all orders to be from same merchant!",
        "error"
      );
      return;
    }

    const merchant = await getMerchantInfo(orderList[0]?.merchantId);
    if (!merchant) {
      responseNotification("Invalid merhant!", "error");
      return;
    }

    orderList?.forEach(async (order) => {
      let obj = {};
      obj = {
        ...(order || {}),
        deliveryType: order?.deliveryType?.trim()?.replace(/ /gi, "_"),
        recipientPhone: formatMobile(order?.recipientPhone),
        merchantId: merchant?.id,
        mobileNumber: merchant?.mobileNumber,
        name: merchant?.ownersName,
        type: merchant?.type || "BUSINESS",
        deliveryCharge: getDeliveryCharge(
          merchant,
          order?.amountToCollect,
          order?.itemweight,
          zoneType
        ),
      };
      for (let [key, val] of Object.entries(obj)) {
        obj[key] =
          key === "recipientPhone" || key === "mobileNumber"
            ? val
            : getData(`${val}`);
      }
      //const row = Object.values(obj)?.map((val) => getVal(val))
      orders?.push(obj);
    });
    console.log(orders);
    setUploadCSVData(orders);
  };

  const readManualOrderCSVFile = (val) => {
    const mainFile = val.fileList[0]?.originFileObj;
    if (mainFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        d3.csv(data).then((res) => {
          structurizeBulkOrderData(res);
        });
      };
      reader.readAsDataURL(mainFile);
    } else {
      setUploadCSVData([]);
    }
  };

  const resetManualUploadOrder = () => {
    setUploadCSVData(undefined);
    manualOrderForm.resetFields();
  };

  const uploadBulkOrder = async () => {
    if (uploadCSVData?.length > 0) {
      const readyData = {
        orders: uploadCSVData,
      };
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/order/parcel/bulk`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            responseNotification("All Order Uploaded Successfully", "success");
            refetch?.();
            setDrawer(undefined);
            resetManualUploadOrder();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      responseNotification(`Please upload minimum 1 order`, "error");
    }
  };

  const onSubmit = async (data) => {
    if (data?.status && fetchedCSVData?.length > 0) {
      const readyData = {
        status: data?.status,
        note: data?.note || "",
        orderIds: fetchedCSVData,
      };
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/order/status-update`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            responseNotification(
              "All Order Statuses Updated Successfully",
              "success"
            );
            refetch?.();
            setDrawer(undefined);
            resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      responseNotification(
        `Please select a status & upload minimum 1 order ID`,
        "error"
      );
    }
  };

  const onSubmitStatus = async (data) => {
    if (data?.status && orderIds?.length) {
      const readyData = {
        orderIds: orderIds,
        status: data?.status,
        note: data?.note || "",
      };
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/order/status-update`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            responseNotification(
              "Selected Order Statuses Updated Successfully",
              "success"
            );
            afterStatusUpdate?.();
            form2.resetFields();
            setModal(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      responseNotification(
        `Please select a status & upload minimum 1 order ID`,
        "error"
      );
    }
  };

  const fetchDeliveryMan = useCallback(async (value) => {
    setDeliveryManData({ loading: true, list: [] });
    await fetch(
      `${
        process.env.REACT_APP_USER_API
      }/admin/driver?mobileNumber=%2B88${value?.replace(
        "+88",
        ""
      )}&page=0&limit=20&status=VERIFIED`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authenticateToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setDeliveryManData({
          loading: true,
          list: res?.drivers?.map((driver) => ({
            label: `${driver?.mobileNumber} ${driver?.name}`,
            value: driver?.id,
          })),
        });
      });
  }, []);

  const debounceDeliveryManFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (fetchId !== fetchRef.current) {
        return;
      }
      fetchDeliveryMan(value);
    };
    return debounce(loadOptions, 800);
  }, [fetchDeliveryMan]);

  const assignDeliveryHero = () => {
    if (selectedDeliveryManId) {
      setLoading(true);
      axios
        .put(
          `${process.env.REACT_APP_ORDER_API}/admin/order/bulk-order-update`,
          {
            deliveryManId: selectedDeliveryManId,
            orderIds: orders.map((order) => order.orderId),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            responseNotification("Assigned Successfully!");
            refetch();
            setAssignment(false);
          } else {
            responseNotification("Assigmenation Failed!", "error");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(
            err?.message || "Assigmenation Failed!",
            "error"
          );
          console.error(err);
        });
    } else {
      responseNotification("Select a delivery man", "warning");
    }
  };

  const assignBulkDeliveryMan = (formData) => {
    const scannedOrderIds = formData.orderIds?.split("\n").filter(Boolean);
    console.log(scannedOrderIds);
    console.log(selectedDeliveryManId);
    if (scannedOrderIds && selectedDeliveryManId) {
      setLoading(true);
      axios
        .put(
          `${process.env.REACT_APP_ORDER_API}/admin/order/bulk-order-update`,
          {
            deliveryManId: selectedDeliveryManId,
            orderIds: scannedOrderIds,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            responseNotification("Assigned Successfully!");
            refetch();
            setAssignment(false);
          } else {
            responseNotification("Assigmenation Failed!", "error");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(
            err?.message || "Assigmenation Failed!",
            "error"
          );
          console.error(err);
        });
    } else {
      if (!selectedDeliveryManId) {
        responseNotification("Select a delivery man", "warning");
      } else if (scannedOrderIds?.length == 0) {
        responseNotification(
          "Please Scan some BarCode to assign delivery man.",
          "warning"
        );
      }
    }
  };

  const { orderId, userMobileNumber, page, type, status, limit, range } =
    filterData;

  // download as csv
  const downloadAllOrders = useCallback(async () => {
    if (orders) return orders;
    // getAllCheckList();
    // return;
    // setOrderDataForDownload({ loading: true, data: [] });

    // const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    // if (true) {
    //   const data: any = await axios
    //     .get(
    //       `${encodedUri}/admin/order?type=Q_PARCEL` +
    //         (orderId ? `&orderId=${orderId}` : ``) +
    //         (userMobileNumber ? `&mobileNumber=${userMobileNumber}` : ``) +
    //         (status ? `&status=${status}` : ``) +
    //         (from ? `&from=${from}` : ``) +
    //         (to ? `&to=${to}` : ``) +
    //         (true ? `&page=${0}` : ``) +
    //         (total ? `&limit=${total}` : ``),
    //       {
    //         headers: {
    //           Authorization: `Bearer ${authenticateToken()}`,
    //         },
    //       }
    //     )
    //     .catch((err) => {
    //       setOrderDataForDownload({ loading: false, data: [] });
    //       console.error("Category: Error", err);
    //     });
    //   setOrderDataForDownload({ loading: false, data: data?.data || [] });
    //   return data?.data || [];
    // }
  }, [orders]);

  function getVal(val) {
    let escaped = val;
    if (typeof val === "string") {
      escaped = ("" + val).replace(/"/g, '"');
      // @ts-ignore
      escaped = escaped?.replace(/[\t\n\"]/gi, " ");
    }
    return `"${escaped}"`; // To escape the comma in a address like string.
  }

  async function printOrder(e) {
    e.preventDefault();
    try {
      if (true) {
        const f = async () =>
          await orders.map(async (res) => {
            if (res) {
              const doc = (
                <CustomerInvoicePdf
                  data={res}
                  // store={stores?.find(
                  //   (store) => store?.name === res[0]?.parcelOrder?.storeName
                  // )}
                />
              );
              const asPdf = pdf();
              asPdf.updateContainer(doc);
              const blob = await asPdf.toBlob();
              saveAs(blob, `invoice`);
            } else {
              responseNotification("Something went wrong", "error");
            }
          });
        f();
      } else {
        responseNotification("Invoice ID missing!", "error");
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function getMerchantInfo(id) {
    return await axios
      .get(`${process.env.REACT_APP_USER_API}/business/${id}`, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        if (data.status !== 200) {
          responseNotification(
            data.statusText || "Something went wrong",
            "error"
          );
        }
        return data?.data?.business;
      })
      .catch((err) => {
        responseNotification("Error occured", "error");
        console.log(err);
      });
  }

  if (orderDataForDownload?.loading) return <Loader />;

  return (
    <div className="top-heading-products top-heading-content">
      <div className="container-fluid">
        <div className="row pt-3 pb-3 d-flex-sb">
          <div className="col-lg-6 col-md-6 no-padding">
            <div className="page-heading-content">
              <h2 className="d-flex-l wrap d-flex-wrap d-flex-base">
                Order List{" "}
                <span className="d-flex-l ml-1">{total} Order(s)</span>
              </h2>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 no-padding d-flex-r">
            {!inShop && (
              <>
                <div className="single-button ml-2">
                  <Button
                    type="dashed"
                    shape="round"
                    size="large"
                    loading={orderDataForDownload?.loading}
                    disabled={orders && orders?.length == 0}
                    onClick={() => {
                      downloadAllOrders().then((res) => {
                        if (res) {
                          const orders = [];
                          res?.forEach((order) => {
                            console.log(order);
                            let obj = {};
                            obj["orderId"] = order?.orderId;
                            obj["status"] = order?.status;
                            obj["createdAt"] = moment(order?.createdAt).format(
                              "MM-DD-yyyy hh:mma"
                            );
                            obj["updatedAt"] = moment(order?.updatedAt).format(
                              "MM-DD-yyyy hh:mma"
                            );
                            const store = order?.parcelOrder?.stores?.find(
                              (store) =>
                                store.name === order?.parcelOrder?.storeName
                            );

                            if (store) {
                              obj["storeContactNumber"] = store?.contactNumber;
                            } else {
                              obj["storeContactNumber"] = "";
                            }
                            if (order?.parcelOrder?.merchantOrderId) {
                              obj["merchantOrderId"] =
                                order?.parcelOrder?.merchantOrderId;
                            } else {
                              obj["merchantOrderId"] = "";
                            }
                            delete order?.parcelOrder?.stores;

                            obj = {
                              ...obj,
                              ...(order?.parcelOrder || {}),
                              expectedPayoutAmount:
                                order?.parcelOrder?.amountToCollect -
                                order?.parcelOrder?.deliveryCharge -
                                order?.parcelOrder?.codCharge,
                            };
                            //obj["orderNote"] = order?.orderNote;

                            for (let [key, val] of Object.entries(obj)) {
                              obj[key] = getVal(val);
                            }
                            orders?.push(obj);
                          });
                          const titleKeys = Object.keys(orders[0]).map((key) =>
                            getVal(key)
                          );

                          const refinedData = [];
                          refinedData.push(titleKeys);

                          orders.forEach((item) => {
                            refinedData.push(Object.values(item));
                          });
                          let csvContent = "";
                          refinedData.forEach((row) => {
                            csvContent += row.join(",") + "\n";
                          });

                          // @ts-ignore
                          const blob = new Blob([csvContent], {
                            type: "text/csv;charset=utf-8,",
                          });

                          const objUrl = URL.createObjectURL(blob);
                          const link = document.createElement("a");
                          link.setAttribute("href", objUrl);
                          link.setAttribute(
                            "download",
                            `orders-${orders?.length}.csv`
                          );
                          link?.click();
                        } else {
                          // done(false);
                          responseNotification("No data", "warning");
                        }
                      });
                    }}
                  >
                    {orderDataForDownload?.loading
                      ? "Loading..."
                      : // : orderDataForDownload?.data
                        // ? orderDataForDownload?.data?.length
                        //   ? `Download(${orderDataForDownload?.data?.length})`
                        //   : "Empty"
                        "Download"}
                  </Button>
                </div>
                {/* <div className="single-button ml-2">
                  <Button
                    type="dashed"
                    shape="round"
                    size="large"
                    onClick={() => setDrawer("statusChange")}
                    loading={loading}
                  >
                    <TagOutlined />
                    Bulk Status
                  </Button>
                </div> */}
                {/* <div className="single-button ml-2">
                  <Button
                    type="dashed"
                    shape="round"
                    className="mx-1"
                    onClick={() => {
                      deliveryHeroAssignmentPermitted();
                    }}
                    disabled={orders && orders?.length == 0}
                    title="Assign Delivery Hero"
                  >
                    <FontAwesomeIcon
                      icon={faBiking as IconProp}
                      className={"text-success"}
                    />
                  </Button>
                </div> */}

                {/* <div className="single-button ml-2">
                  <Button
                    type="dashed"
                    shape="round"
                    className="mx-1"
                    onClick={() => {
                      setOpenDrawerBarCode(true);
                    }}
                    title="Assign Delivery Hero For Bulk Order"
                  >
                    <FontAwesomeIcon
                      icon={faBiking as IconProp}
                      className={"text-success"}
                    />{" "}
                    &nbsp;Assign Bulk
                  </Button>
                </div> */}

                {/* <div className="single-button ml-2">
                  <Button
                    type="dashed"
                    shape="round"
                    size="large"
                    onClick={() => setDrawer("uploadFileById")}
                    loading={loading}
                  >
                    <UploadOutlined />
                    Create Bulk
                  </Button>
                </div> */}
              </>
            )}
            <div className="single-button ml-2">
              <Button
                shape="round"
                size="large"
                onClick={() => setModal(true)}
                loading={loading}
                disabled={!orderIds?.length}
                danger
              >
                Status Update
              </Button>
            </div>
            {/* <div className="single-button ml-2">
              <Button
                shape="round"
                size="middle"
                loading={loading}
                disabled={!orderIds?.length}
                danger
                style={{ width: "100%" }}
                // onClick={(e) => printOrder(e, "PDF")}
                onClick={() => setPdfModal("PDF")}
              >
                <FilePdfOutlined />
                PDF
              </Button>
            </div> */}
            <div className="single-button ml-2">
              <Button
                shape="round"
                size="middle"
                loading={loading}
                disabled={!orderIds?.length}
                danger
                style={{ width: "100%" }}
                // onClick={(e) => printOrder(e, "STICKER")}
                onClick={() => setPdfModal("STICKER")}
              >
                <TagOutlined />
                Sticker
              </Button>
            </div>
          </div>

          <Drawer
            title={`Upload Order Ids`}
            onClose={() => setDrawer(undefined)}
            visible={drawer === "statusChange"}
            extra={
              <Button onClick={simpleFileDownload} type="dashed">
                <DownloadOutlined />
                Sample File
              </Button>
            }
            destroyOnClose={true}
            width="460px"
          >
            <Form layout="vertical" onFinish={onSubmit} form={form}>
              <div className="mb-4">
                <Form.Item name="csvFile">
                  <Dragger
                    {...props}
                    onChange={readUploadFile}
                    onRemove={() => setFetchedCSVData(null)}
                    onDrop={(val) => console.log(21111111111, val)}
                    multiple={false}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibit
                      from uploading company data or other band files
                    </p>
                    <Alert
                      message="Upload Only (.csv) File"
                      type="warning"
                      className="m-3"
                    />
                  </Dragger>
                </Form.Item>
              </div>

              <div className="mb-4">
                {fetchedCSVData?.length >= 0 ? (
                  <Alert
                    type={fetchedCSVData?.length > 0 ? "success" : "error"}
                    message={`Uploaded ${fetchedCSVData?.length} Item(s)`}
                  />
                ) : (
                  <Alert type="error" message="No file uploaded yet" />
                )}
              </div>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Status is required!",
                  },
                ]}
              >
                <Select
                  className="w-full w-100"
                  placeholder="Select status"
                  onChange={(val) => {
                    if (
                      val === "HOLD" ||
                      val === "RETURN" ||
                      val === "PARTIALLY_DELIVERED"
                    ) {
                      setIsRequired(true);
                    } else {
                      setIsRequired(false);
                    }
                  }}
                >
                  {OrderStatus?.map((status, i) => (
                    <Select.Option key={i} value={status}>
                      {status}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="note"
                rules={[
                  {
                    required: isRequired,
                    message: "Order note is required!",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
              <div className="buttons-container d-flex">
                <Button
                  disabled={loading || !fetchedCSVData?.length}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="add-submit-btn text-center mr-2"
                >
                  Update
                </Button>
                <Button
                  type="ghost"
                  htmlType="button"
                  onClick={resetFields}
                  className="reset-submit-btn text-center mr-2"
                >
                  Reset
                </Button>
              </div>
            </Form>
          </Drawer>

          <Drawer
            title={`Upload Order Ids`}
            onClose={() => setDrawer(undefined)}
            visible={drawer === "uploadFileById"}
            extra={
              <Button onClick={downloadBulkOrder} type="dashed">
                <DownloadOutlined />
                Sample File
              </Button>
            }
            destroyOnClose={true}
            width="460px"
          >
            <Form layout="vertical" form={manualOrderForm}>
              <div className="mb-4">
                <Form.Item name="csvFile">
                  <Dragger
                    {...props}
                    onChange={readManualOrderCSVFile}
                    onRemove={() => setUploadCSVData(null)}
                    onDrop={(val) => console.log(21111111111, val)}
                    multiple={false}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibit
                      from uploading company data or other band files
                    </p>
                    <Alert
                      message="Upload Only (.csv) File"
                      type="warning"
                      className="m-3"
                    />
                  </Dragger>
                </Form.Item>
              </div>

              <div className="mb-4">
                {uploadCSVData?.length >= 0 ? (
                  <Alert
                    type={uploadCSVData?.length > 0 ? "success" : "error"}
                    message={`Found ${uploadCSVData?.length} Item(s)`}
                  />
                ) : (
                  <Alert type="error" message="No file uploaded yet" />
                )}
              </div>

              <div className="buttons-container d-flex">
                <Button
                  disabled={loading || !uploadCSVData?.length}
                  loading={loading}
                  type="primary"
                  onClick={uploadBulkOrder}
                  className="add-submit-btn text-center mr-2"
                >
                  Update
                </Button>
                <Button
                  type="ghost"
                  htmlType="button"
                  onClick={resetManualUploadOrder}
                  className="reset-submit-btn text-center mr-2"
                >
                  Reset
                </Button>
              </div>
            </Form>
          </Drawer>

          <Drawer
            title={`Scan Order ID Barcode`}
            onClose={() => {
              setOpenDrawerBarCode(false);
              setDeliveryManData({ loading: false, list: [] });
              setSelectedDeliveryManId("");
              setTotalScanned(0);
              bulkAssignDeliveryManForm.resetFields();
            }}
            visible={openDrawerBarCode}
            destroyOnClose={true}
            width="460px"
          >
            <Form
              layout="vertical"
              form={bulkAssignDeliveryManForm}
              onFinish={assignBulkDeliveryMan}
            >
              <Form.Item label="Select Delivery Man By Mobile Number" name="">
                <Select
                  showSearch
                  allowClear={true}
                  style={{ width: "100%", marginBottom: 15 }}
                  value={selectedDeliveryManId}
                  placeholder="Select a Delivery Man 01..."
                  optionFilterProp="children"
                  onChange={(val) => setSelectedDeliveryManId(val as string)}
                  onSearch={debounceDeliveryManFetcher}
                  // filterOption={(input, option) =>
                  //   option.children
                  //     ?.toString()
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()?.replace("+88", "")) >= 0
                  // }
                >
                  {deliveryManData?.list?.map((man, i) => (
                    <Select.Option value={man?.value} key={i}>
                      {man?.label?.replace("+88", "")}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={`Scan Order ID (Total Scanned : ${totalScanned})`}
                name="orderIds"
                rules={[
                  {
                    required: isRequired,
                    message: "Order id is required!",
                  },
                ]}
              >
                <TextArea
                  onChange={(e) => {
                    const val = e.target.value?.split("\n").length - 1;
                    console.log(val);
                    setTotalScanned(val);
                  }}
                  rows={25}
                />
              </Form.Item>
              <div className="buttons-container d-flex">
                <Button
                  type="ghost"
                  htmlType="button"
                  onClick={() => bulkAssignDeliveryManForm.resetFields()}
                  className="reset-submit-btn text-center mr-2"
                >
                  Reset
                </Button>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="add-submit-btn text-center mr-2"
                >
                  Update
                </Button>
              </div>
            </Form>
          </Drawer>

          <Modal
            visible={!!pdfModal}
            onCancel={() => setPdfModal("")}
            footer={null}
            width={pdfModal === "STICKER" ? 480 : 680}
          >
            {/* <CustomerInvoicePdf data={orders} /> */}

            {/* @ts-ignore */}
            {/* <PDFViewer width={600} height={600}> */}
            <>
              {pdfModal === "STICKER" ? (
                <CustomerInvoiceSticker data={orders} />
              ) : (
                <CustomerInvoicePdf data={orders} />
              )}
            </>
            {/* </PDFViewer> */}
          </Modal>

          <Modal
            title={
              <div className="d-flex-l">
                <FontAwesomeIcon
                  icon={faBiking as IconProp}
                  className="mr-2"
                  style={{ color: "#ff9f00" }}
                />{" "}
                Assign a Delivery Man
              </div>
            }
            okButtonProps={{ disabled: !!!selectedDeliveryManId }}
            visible={!!assignment}
            onOk={assignDeliveryHero}
            okText="Assign"
            cancelButtonProps={{
              color: "red",
              type: "ghost",
              danger: true,
            }}
            confirmLoading={loading}
            onCancel={() => {
              setDeliveryManData({ loading: false, list: [] });
              setSelectedDeliveryManId("");
              setAssignment(false);
            }}
            // destroyOnClose={true}
          >
            <div>
              <Select
                showSearch
                allowClear={true}
                style={{ width: "100%", marginBottom: 15 }}
                value={selectedDeliveryManId}
                placeholder="Select a Delivery Man 01..."
                optionFilterProp="children"
                onChange={(val) => setSelectedDeliveryManId(val as string)}
                onSearch={debounceDeliveryManFetcher}
                // filterOption={(input, option) =>
                //   option.children
                //     ?.toString()
                //     .toLowerCase()
                //     .indexOf(input.toLowerCase()?.replace("+88", "")) >= 0
                // }
              >
                {deliveryManData?.list?.map((man, i) => (
                  <Select.Option value={man?.value} key={i}>
                    {man?.label?.replace("+88", "")}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Modal>

          <Modal visible={modal} onCancel={() => setModal(false)} footer={null}>
            <Form layout="vertical" onFinish={onSubmitStatus} form={form2}>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Status is required!",
                  },
                ]}
              >
                <Select
                  className="w-full w-100"
                  placeholder="Select status"
                  onChange={(val) => {
                    if (
                      val === "HOLD" ||
                      val === "RETURN" ||
                      val === "PARTIALLY_DELIVERED"
                    ) {
                      setIsRequired(true);
                    } else {
                      setIsRequired(false);
                    }
                  }}
                >
                  <Select.Option value="AT_THE_SORTING_HUB">
                    AT_THE_SORTING_HUB
                  </Select.Option>
                  <Select.Option value="PROCESSED">
                    PROCESSED
                  </Select.Option>
                  <Select.Option value="UNPROCESSED">
                    UNPROCESSED
                  </Select.Option>
                  <Select.Option value="IN_TRANSIT">
                    IN_TRANSIT
                  </Select.Option>
                  {/* {OrderStatus?.map((status, i) => (
                    <Select.Option key={i} value={status}>
                      {status}
                    </Select.Option>
                  ))} */}
                </Select>
              </Form.Item>
              <Form.Item
                name="note"
                rules={[
                  {
                    required: isRequired,
                    message: "Order note is required!",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
              <div className="buttons-container">
                <Button
                  disabled={loading}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="add-submit-btn text-center mr-2"
                >
                  Update Status
                </Button>
                <Button
                  type="ghost"
                  htmlType="button"
                  onClick={() => form2.resetFields()}
                  className="reset-submit-btn text-center mr-2"
                >
                  Reset
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    </div>
  );
};

TopHeading.propTypes = {
  UserId: PropTypes.string,
  GetStatus: PropTypes.string,
  GetPrime: PropTypes.string,
  GetFlagship: PropTypes.string,
  GetRocket: PropTypes.string,
  name: PropTypes.string,
};

export default TopHeading;
